import { SxProps } from '@mui/material';
import { SortableContainer } from 'react-sortable-hoc';
import { Keyword } from '../../../constants/interfaces/interfaces';
import { KeywordChipVariant, SortableKeywordChip } from './keyword-chip';

interface Props {
  keywords: Keyword[]
  onDelete?: (keyword: Keyword) => void
  handleSynonymReplacement?: (keyword: Keyword, replacement: string) => void
  coverageHits: string[]
  sx?: SxProps
}

function getCoverageVariant(count: number | undefined, index: number): KeywordChipVariant {
  if (!count || count <= 0) {
    return 'default';
  }
  if (index <= 2) {
    // The first three keywords should occur at least twice
    return (count > 1) ? 'activated' : 'medium';
  }
  // other keywords need only one occurrence to be fulfilled
  return 'activated';
}

const SortableKeywordChips = SortableContainer<Props>((props: Props) => {
  const {
    coverageHits, keywords, onDelete, handleSynonymReplacement, ...restProps
  } = props;

  const mappedItems = keywords.map((value: Keyword, index: number) => (
    <SortableKeywordChip
      key={value.name}
      index={index}
      keyword={value}
      variant={getCoverageVariant(coverageHits.filter((x) => x === value.name).length, index)}
      onDelete={onDelete ? (() => onDelete(value)) : undefined}
      handleSynonymReplacement={handleSynonymReplacement ? ((replacement: string) => handleSynonymReplacement(value, replacement)) : undefined}
      {...restProps}
    />
  ));
  return (
    <div style={{ width: '100%' }}>
      {mappedItems}
    </div>
  );
});

export default SortableKeywordChips;
