import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import { useFeedbackSelectionsService } from '../../../context/feedback/feedback-selections-context';
import SelectionDeleteButton from './actions/selection-delete-button';

export default function BlocklistTable() {
  const { getFeedbackSelectionsService } = useFeedbackSelectionsService();
  const selections = getFeedbackSelectionsService.status === REST_STATUS.LOADED ? getFeedbackSelectionsService.payload : [];

  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <TableContainer sx={{ border: `1px solid ${theme.palette.divider}` }}>
      <Table aria-label="sticky table">
        <TableBody>
          {selections.length === 0 && (
            <TableRow>
              <TableCell>
                {t('lo-blocklist-no-blocked-selections')}
              </TableCell>
            </TableRow>
          )}
          {selections.map((selection, i) => (
            <TableRow
              hover
              key={selection.id}
            >
              <TableCell sx={{ width: '100%' }}>
                {selection.selection}
              </TableCell>
              <TableCell>
                <SelectionDeleteButton selection={selection} index={i} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
