import { ShoppingCartOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FlexBoxRow } from '@purple/react-components';
import * as React from 'react';
import {
  Fragment, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleScoreResponse } from '../../../../api/interfaces/article';
import {
  ArticleLinkRecommendationResponse,
  ProductLinkRecommendationResponse,
  ProductScoreResponse,
  RecommendationOriginType,
} from '../../../../api/interfaces/recommendation';
import { useACMUserState } from '../../../../context/acm-page-context';
import {
  CombinedLinkerAction,
  LinkType,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import { AcmExtraColors, purpleCircleStyle, rightDownTransitionStyles } from '../../../../theme/style-utils';
import ArticleLinkRecommendationDetailsContent
  from '../../article-linker/recommendations-details/article-link-recommendation-details-content';
import {
  ArticleLinkRecommendationScores,
} from '../../article-linker/recommendations-details/article-link-recommendation-scores';
import LinkRecommendationDeleteDialog from '../../editor/entities/link-recommendation-delete-dialog';
import ProductLinkRecommendationDetailsContent
  from '../../product-linker/recommendation-details/product-link-recommendation-details-content';
import {
  ProductLinkRecommendationScores,
} from '../../product-linker/recommendation-details/product-link-recommendation-scores';

const LinkRecommendationCardId = 'linkRecommendationCard-';

type Props = {
  recommendation: ArticleLinkRecommendationResponse
  index: number
  linkType: LinkType.ARTICLE
} | {
  recommendation: ProductLinkRecommendationResponse
  index: number
  linkType: LinkType.PRODUCT
};

export default function GenericLinkRecommendationDetails(props: Props) {
  const {
    combinedLinkRecommendationSelections,
    showSelections,
    combinedLinkRecommendations,
    collapsedLinkIds,
  } = useCombinedLinkerPageState();
  const dispatch = useCombinedLinkerPageDispatch();
  const { recommendation, index, linkType } = props;
  const selectedItem = linkType === LinkType.ARTICLE
    ? (combinedLinkRecommendationSelections.get(recommendation) as ArticleScoreResponse || recommendation.articles[0])
    : (combinedLinkRecommendationSelections.get(recommendation) as ProductScoreResponse || recommendation.products[0]);
  const [isInSelectionMode, setIsInSelectionMode] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isClosingAndCollapsed, setIsClosingAndCollapsed] = useState(false);
  const [isMouseover, setIsMouseover] = useState(false);
  const linkRecommendationDetailsEl = useRef<HTMLDivElement>(null);

  const isCollapsed = collapsedLinkIds.has(recommendation.id);
  const showContent = isMouseover || !isCollapsed;
  const showContentTemporary = isMouseover && isCollapsed;

  const isRecommended = useMemo(() => (
    ((linkType === LinkType.ARTICLE ? combinedLinkRecommendations?.article_links : combinedLinkRecommendations?.product_links) as (ArticleLinkRecommendationResponse | ProductLinkRecommendationResponse)[] | undefined)
      ?.some((rec) => rec.id === recommendation.id)
  ), [combinedLinkRecommendations]);

  const { frontendConfig } = useACMUserState();
  const showScores = !!frontendConfig?.lnkopt_config?.show_scores;

  const { t } = useTranslation();

  useEffect(() => {
    // when being expanded (e.g. via the expand-all button), reset the greyed-out state
    if (!isCollapsed) {
      setIsClosingAndCollapsed(false);
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (showSelections === recommendation || isInSelectionMode) {
      setIsInSelectionMode((mode) => !mode);
      if (linkRecommendationDetailsEl.current) {
        linkRecommendationDetailsEl.current.scrollTop = 0;
      }
    }
  }, [showSelections]);

  useEffect(() => {
    if (recommendation && linkRecommendationDetailsEl) {
      dispatch({
        type: CombinedLinkerAction.ADD_DETAILS_ELEMENT,
        payload: {
          recommendation,
          details: {
            ref: linkRecommendationDetailsEl,
          },
        },
      });
    }
  }, [linkRecommendationDetailsEl, recommendation]);

  const handleIconClick = (event: React.MouseEvent<any, MouseEvent>) => {
    dispatch({
      type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY,
      payload: recommendation,
    });
  };

  const handleTitleClick = (event: React.MouseEvent<any, MouseEvent>) => {
    handleCollapseToggle(event);
  };

  const handleRemoveClick = (e: React.MouseEvent) => {
    setDialogOpen(true);
    dispatch({
      type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY,
      payload: recommendation,
    });
    // prevent de-selection due to parent event handler
    e.stopPropagation();
  };

  function handleDialogConfirmation(reason: string) {
    setDialogOpen(false);
    dispatch({
      type: CombinedLinkerAction.REMOVE_RECOMMENDATION,
      payload: {
        entityDataId: recommendation.id,
        reason,
      },
    });
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  const handleCollapseToggle = (event: React.MouseEvent<any, MouseEvent>) => {
    event.stopPropagation();
    setIsClosingAndCollapsed(false);
    setIsMouseover(false);

    dispatch({
      type: isCollapsed ? CombinedLinkerAction.EXPAND_SINGLE : CombinedLinkerAction.COLLAPSE_SINGLE,
      payload: recommendation,
    });
  };

  const handleMouseoverEnter = () => {
    setIsMouseover(true);
  };

  const handleMouseoverLeave = () => {
    if (showContentTemporary) {
      setIsClosingAndCollapsed(true);
    }
    setIsMouseover(false);
  };

  const handleAddClick = (e: React.MouseEvent) => {
    dispatch({
      type: CombinedLinkerAction.ADD_RECOMMENDATION,
      payload: {
        entityDataId: recommendation.id,
      },
    });
    dispatch({
      type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY,
      payload: recommendation,
    });
    // prevent de-selection due to parent event handler
    e.stopPropagation();
  };

  const handleBackOnClick = (e: React.MouseEvent) => {
    setIsMouseover(false);
    dispatch({ type: CombinedLinkerAction.CLEAR_LINK_RECOMMENDATIONS });
    // prevent de-selection due to parent event handler
    e.stopPropagation();
  };

  const recommendationTitle = (
    <FlexBoxRow
      alignItems="center"
      sx={{
        height: '45px',
        maxHeight: '45px',
        minHeight: '45px',
        marginLeft: '0px',
        padding: 0,
        cursor: 'pointer',
        backgroundColor: AcmExtraColors.recommendation,
      }}
      id={LinkRecommendationCardId + index}
      onMouseEnter={handleMouseoverEnter}
      onMouseLeave={handleMouseoverLeave}
    >
      <FlexBoxRow
        sx={{
          alignSelf: 'stretch',
          backgroundColor: linkType === LinkType.ARTICLE ? AcmExtraColors.articleLink : AcmExtraColors.productLink,
          color: 'white',
          width: '40px',
          p: '8px',
        }}
        onClick={handleIconClick}
        data-testid={`${LinkRecommendationCardId + index}-icon`}
      >
        {(linkType === LinkType.ARTICLE) ? (
          <InsertDriveFileOutlinedIcon sx={{ display: 'block', m: 'auto' }} />
        ) : (
          <ShoppingCartOutlined sx={{ display: 'block', m: 'auto' }} />
        )}
      </FlexBoxRow>
      <Box sx={{ width: '40px' }} onClick={handleTitleClick}>
        <ExpandMoreIcon
          sx={{
            display: 'block',
            m: 'auto',
            ...rightDownTransitionStyles(!isCollapsed),
          }}
        />
      </Box>

      <Typography
        noWrap
        variant="h5"
        data-testid={`${LinkRecommendationCardId + index}-title`}
        onClick={handleTitleClick}
        sx={{ flex: '1 1 0px' }}
      >
        {recommendation.keyword}
      </Typography>

      {showSelections ? (
        <Box
          sx={{
            cursor: 'pointer',
            px: '10px',
          }}
          onClick={handleBackOnClick}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <ChevronLeftIcon sx={purpleCircleStyle('24px')} />
            {t('lo-back')}
          </Typography>
        </Box>
      ) : (
        <>
          {showScores && (
            (linkType === LinkType.ARTICLE) ? (
              <ArticleLinkRecommendationScores linkScores={recommendation} />
            ) : (
              <ProductLinkRecommendationScores linkScores={recommendation} />
            )
          )}
          {recommendation.origin !== RecommendationOriginType.EXTERNAL && (
            <IconButton
              size="small"
              data-testid={`${LinkRecommendationCardId + index}-remove-button`}
              onClick={isRecommended ? handleRemoveClick : handleAddClick}
              sx={{ p: '6px', color: 'unset' }}
            >
              {isRecommended ? (
                <RemoveCircleOutlineIcon sx={{ fontSize: '28px' }} />
              ) : (
                <AddCircleOutlineIcon sx={{ fontSize: '28px' }} />
              )}
            </IconButton>
          )}
        </>
      )}
    </FlexBoxRow>
  );

  return (
    <Card
      ref={linkRecommendationDetailsEl}
      sx={{
        borderRadius: '4px',
        opacity: !isRecommended ? '0.4' : '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      data-testid={`${LinkRecommendationCardId + index}-container`}
    >
      {recommendationTitle}
      {!showSelections ? (
        <Collapse
          in={showContent}
          timeout={200}
          collapsedSize={0.1} // this value fixes flickering artifacts on chrome, see DEEP-467
          sx={{ opacity: (showContentTemporary || isClosingAndCollapsed) ? 0.66 : 1 }}
        >
          {(linkType === LinkType.ARTICLE) ? (
            <ArticleLinkRecommendationDetailsContent
              isRecommended={isRecommended}
              recommendation={recommendation}
              article={selectedItem as ArticleScoreResponse}
            />
          ) : (
            <ProductLinkRecommendationDetailsContent
              isRecommended={isRecommended}
              recommendation={recommendation}
              product={selectedItem as ProductScoreResponse}
            />
          )}
        </Collapse>
      ) : (
        (linkType === LinkType.ARTICLE) ? (
          recommendation.articles.map((article, idx) => (
            <Fragment key={article.id}>
              {idx > 0 && <Divider />}
              <ArticleLinkRecommendationDetailsContent
                recommendation={recommendation}
                article={article}
                selectable={!!showSelections}
                checked={selectedItem === article}
              />
            </Fragment>
          ))
        ) : (
          recommendation.products.map((product, idx) => (
            <Fragment key={product.id}>
              {idx > 0 && <Divider />}
              <ProductLinkRecommendationDetailsContent
                recommendation={recommendation}
                product={product}
                selectable={!!showSelections}
                checked={selectedItem === product}
              />
            </Fragment>
          ))
        )
      )}
      <LinkRecommendationDeleteDialog
        dialogOpen={dialogOpen}
        handleDialogConfirmation={handleDialogConfirmation}
        closeDialog={closeDialog}
        textSelection={recommendation.text_selection}
        showBadResultsOption={linkType === LinkType.ARTICLE}
      />
    </Card>
  );
}
