import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function SidebarInfoText(props: PropsWithChildren) {
  const { children } = props;
  return (
    <Typography
      variant="body2"
      sx={{
        padding: '5px',
        fontStyle: 'italic',
        color: '#999',
      }}
    >
      {children}
    </Typography>
  );
}
