import { LINKER_URL } from '../../../../../constants/configs/urls';
import { useACMUserState } from '../../../../../context/acm-page-context';
import { CombinedRecommendationsResponse } from '../../../../interfaces/recommendation';
import useGetRequestService from '../../../_common/requests/get-request';
import { RESTService } from '../../../rest-service';

const useGetCombinedLinksRecommendationService = (articleId?: string | null): RESTService<CombinedRecommendationsResponse> => {
  const { customerToken } = useACMUserState();
  let requestURL;

  if (articleId) {
    requestURL = `${LINKER_URL}/linker/combined_links/${articleId}`;

    if (customerToken) {
      requestURL += `?api_token=${customerToken}`;
    }
  }

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetCombinedLinksRecommendationService;
