import equal from 'fast-deep-equal';
import { ArticleDataState, ArticleTextState } from '../static/types/acm-bridge';
import { AcmServicesBroadcastAdapter, AcmServicesInterface } from '../static/types/acm-services';
import { makeIFrameAdapterFromInside, makeIFrameBroadcastListener, NoFunctions } from './generic-iframe-adapter';

type OnArticleTextStateChange = (articleTextState: ArticleTextState) => void;
type OnArticleDataStateChange = (articleDataState: ArticleDataState) => void;

export default class IFrameBridge {
  private static articleTextStateCache: ArticleTextState;

  private static articleDataStateCache: ArticleDataState;

  private static acmServiceAdapter = makeIFrameAdapterFromInside<AcmServicesInterface, NoFunctions>({});

  /**
   * Connects ACM component embedded in iFrame with Editor.
   *
   * @param onChange can be used to update UI based on changed Editor state.
   */
  static connectWithEditorBridgeText(onChange: OnArticleTextStateChange) {
    const articleTextStateChanged = (articleTextState: ArticleTextState) => {
      if (!equal(articleTextState, this.articleTextStateCache)) {
        onChange(articleTextState);
        this.articleTextStateCache = articleTextState;
      }
    };
    makeIFrameBroadcastListener<Partial<AcmServicesBroadcastAdapter>>({
      articleTextStateChanged,
    });
    this.acmServiceAdapter.getArticleTextState()
      .then(articleTextStateChanged)
      .catch(console.error);
  }

  /**
   * Connects ACM component embedded in iFrame with Editor.
   *
   * @param onChange can be used to update UI based on changed Editor state.
   */
  static connectWithEditorBridgeData(onChange: OnArticleDataStateChange) {
    const articleDataStateChanged = (articleDataState: ArticleDataState) => {
      if (!equal(articleDataState, this.articleDataStateCache)) {
        onChange(articleDataState);
        this.articleDataStateCache = articleDataState;
      }
    };
    makeIFrameBroadcastListener<Partial<AcmServicesBroadcastAdapter>>({
      articleDataStateChanged,
    });
    this.acmServiceAdapter.getArticleDataState()
      .then(articleDataStateChanged)
      .catch(console.error);
  }

  /**
   * Sends 'openEditor' with passed keywords.
   */
  static openEditor(baseURL: string, keywords: string[] = []) {
    return this.acmServiceAdapter.openEditor({
      baseURL, keywords,
    });
  }

  static reloadEditor() {
    return this.acmServiceAdapter.reloadEditor();
  }

  /**
   * Sends a 'editArticleTextState' action containing the edited 'ArticleTextState'.
   * Used for communicating from an iFrame to the parent Component
   *
   * @param state the edited ArticleTextState.
   */
  static updateEditorText(state: ArticleTextState) {
    return this.acmServiceAdapter.editArticleTextState(state);
  }

  /**
   * Sends a 'editArticleDataState' action containing the edited 'ArticleDataState'.
   * Used for communicating from an iFrame to the parent Component
   *
   * @param state the edited ArticleTextState.
   */
  static updateEditorData(state: ArticleDataState) {
    return this.acmServiceAdapter.editArticleDataState(state);
  }
}
