import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { ISINExtractResponse } from '../../../../static/types/acm-services';
import makeRequestError from '../../_common/requests/common';

const postExtractISINs = async (acmToken: string, text: string): Promise<ISINExtractResponse> => {
  if (!text) return { results: [] };

  return fetch(`${CORE_BACKEND_URL}/isin/extract`, {
    method: 'POST',
    body: JSON.stringify({ text }),
    headers: {
      Accept: 'application/json',
      'X-API-KEY': acmToken || '',
      'Content-Type': 'application/json',
    },
  }).then(async (response: Response) => {
    if (response.status >= 300) {
      throw await makeRequestError(response);
    } else {
      try {
        return (await response.json()) as ISINExtractResponse;
      } catch (e) {
        throw new Error(`Invalid response from server: ${e}`);
      }
    }
  });
};

export default postExtractISINs;
