import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  value: number // in range 0 to 100
}

export const ImportanceIndicatorWidth = 50;

/**
 * This component shows a short progress bar representing the given value.
 */
export default function ImportanceIndicator(props: Props) {
  const { value } = props;

  return (
    <Tooltip title={`${value}%`} disableInteractive>
      <span>
        <LinearProgress
          variant="determinate"
          value={value}
          style={{ width: `${ImportanceIndicatorWidth}px`, height: '10px', borderRadius: '2px' }}
        />
      </span>
    </Tooltip>
  );
}
