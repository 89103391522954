import { LINKER_URL } from '../../../../../constants/configs/urls';
import { ProductLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import useGetRequestService from '../../../_common/requests/get-request';
import { RESTService } from '../../../rest-service';

const useGetProductLinkerProfilesService = (): [RESTService<ProductLinkerProfileResponse[]>, () => void] => {
  const requestURL = `${LINKER_URL}/linker/profiles/products`;
  const [result, refresh] = useGetRequestService(requestURL, true);
  return [result, refresh];
};

export default useGetProductLinkerProfilesService;
