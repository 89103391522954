import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FlexBoxColumn, FlexBoxRow } from '@purple/react-components';
import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  title: string
  bottomActionButton?: ReactNode
}>

export default function DashboardCard(props: Props) {
  const {
    title, bottomActionButton, children,
  } = props;

  return (
    <FlexBoxColumn style={{ height: '100%' }}>
      <FlexBoxRow
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: '20px 20px 0px 20px',
          maxHeight: '70px',
        }}
      >
        <Typography variant="h4" sx={{ ml: '12px' }} noWrap>
          {title}
        </Typography>
      </FlexBoxRow>
      <Divider sx={{ mx: '20px', mt: '10px', mb: '2px' }} />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          px: '12px',
        }}
      >
        {children}
      </Box>
      {bottomActionButton && (
        <div>
          <Divider sx={{ mx: '20px', mt: '2px' }} />
          <FlexBoxRow justifyContent="center">
            {bottomActionButton}
          </FlexBoxRow>
        </div>
      )}
    </FlexBoxColumn>
  );
}
