import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DatePickerProps {
  label: string
  date: Date | null
  handleChangeDate: (newDate: Date | null) => void
}

function CustomDatePicker(props: DatePickerProps) {
  const { label, date, handleChangeDate } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{ textField: { placeholder: 'DD/MM/YYYY', variant: 'outlined' } }}
        label={label}
        value={date}
        onChange={handleChangeDate}
        maxDate={new Date()}
      />
    </LocalizationProvider>
  );
}

interface Props {
  fromDate: DatePickerProps
  toDate: DatePickerProps
}

export default function DateRangePicker(props: Props) {
  const { fromDate, toDate } = props;

  return (
    <Grid container justifyContent="space-evenly">
      <CustomDatePicker {...fromDate} />
      <CustomDatePicker {...toDate} />
    </Grid>
  );
}
