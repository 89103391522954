import { LINKER_URL } from '../../../../constants/configs/urls';
import { FeedbackSelectionResponse } from '../../../interfaces/feedback';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetFeedbackSelectionsService = (): [RESTService<FeedbackSelectionResponse[]>, () => void] => {
  const requestURL = `${LINKER_URL}/linker/feedback/blocklist`;
  return useGetRequestService(requestURL);
};

export default useGetFeedbackSelectionsService;
