import { LINKER_URL } from '../../../../../constants/configs/urls';
import { ArticleLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import usePutRequestService from '../../../_common/requests/put-request';
import { RESTService } from '../../../rest-service';

const usePutArticleLinkerProfileService = (profile?: ArticleLinkerProfileResponse): RESTService<ArticleLinkerProfileResponse> => {
  let requestURL;

  if (profile) {
    requestURL = `${LINKER_URL}/linker/profiles/articles/${profile.id}`;
  }

  const [result] = usePutRequestService(requestURL, profile);
  return result;
};

export default usePutArticleLinkerProfileService;
