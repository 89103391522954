import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PurpleBaseColors } from '@purple/react-components';
import { TFunction } from 'i18next';
import { GenericLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import ProfileInfoTooltipContent, { InfoItem } from './profile-info-tooltip-content';

interface Props<P> {
  profile?: P
  infoGetter: (profile: P, t: TFunction) => InfoItem[]
  includeLinkGenerationPart?: boolean
}

export type ConcreteProfileInfoButtonProps<P extends GenericLinkerProfileResponse> = Omit<Props<P>, 'infoGetter'>;

export default function ProfileInfoButton<P extends GenericLinkerProfileResponse>(props: Props<P>) {
  const {
    profile, infoGetter, includeLinkGenerationPart,
  } = props;

  return (
    <Tooltip
      title={
        profile
          ? (
            <ProfileInfoTooltipContent
              profile={profile}
              infoGetter={infoGetter}
              includeLinkGenerationPart={includeLinkGenerationPart}
            />
          )
          : ''
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            color: PurpleBaseColors.purple,
            boxShadow: 1,
            borderRadius: '0px',
            maxWidth: 'none', // allowing tooltips to grow in width as needed for long label texts
          },
        },
      }}
    >
      <IconButton
        disableRipple
        color="primary"
        disabled={!profile}
        sx={{ cursor: 'unset' }}
        data-testid="profile-info-button"
        size="large"
      >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
