import { STEntry, STGroup } from '@purple/react-components';
import * as React from 'react';
import { ArticleLinkerProfileResponse, PremiumFilter } from '../../../api/interfaces/linker-profile';
import {
  makeObjectUpdater,
  ProfileAttributeCategorySelect,
  ProfileAttributeCheckbox,
  ProfileAttributeCoarseSlider,
  ProfileAttributeDateSlider,
  ProfileAttributeSelect,
  ProfileAttributeSlider,
} from '../../link-optimizer/generic/profile/slider-helper';
import { SimilarityWeightSlidersBox, TargetSimilarityWeights } from './similarity-weight-sliders-box';

interface Props {
  linkProfile: ArticleLinkerProfileResponse
  setLinkProfile: React.Dispatch<React.SetStateAction<ArticleLinkerProfileResponse>>
}

export default function ArticleLinkProfileSliders(props: Props) {
  const { linkProfile, setLinkProfile } = props;
  const updateLinkProfile = makeObjectUpdater(setLinkProfile);

  const {
    selection_to_target_weight,
    date_weight,
    sentence_to_target_weight,
    categories,
    date_range,
    source_to_target_weight,
    categories_weight,
    premium,
    allow_heavy_computations,
    target_confidence_weight,
  } = linkProfile.link_parameters;

  function updateTargetSimilarityWeights(newSimilarityToTargetWeights: TargetSimilarityWeights) {
    const { toSelection: selection, toParagraph: paragraph, toSource: source } = newSimilarityToTargetWeights;
    updateLinkProfile((lp) => { lp.link_parameters.selection_to_target_weight = selection; lp.link_parameters.sentence_to_target_weight = paragraph; lp.link_parameters.source_to_target_weight = source; });
  }

  function setDateRange(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.date_range = newValue);
  }

  function setDateWeight(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.date_weight = newValue);
  }

  function setCategories(newValue: string[]) {
    updateLinkProfile((lp) => lp.link_parameters.categories = newValue);
  }

  function setCategoriesWeight(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.categories_weight = newValue);
  }

  function setPremiumFilter(newValue: string) {
    updateLinkProfile((lp) => lp.link_parameters.premium = newValue as PremiumFilter);
  }

  function setTargetConfidenceWeight(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.target_confidence_weight = newValue);
  }

  function setAllowHeavyComputations(newValue: boolean) {
    updateLinkProfile((lp) => lp.link_parameters.allow_heavy_computations = newValue);
  }

  return (
    <>
      <STGroup>
        <STEntry
          label="Date Range Filter"
          helpText="Set a hard filter for the age of suggested articles. When set to zero, no filter will be applied. When set to any other value, articles that have not been modified within the last X days are not suggested."
        >
          <ProfileAttributeDateSlider
            value={date_range}
            setValue={setDateRange}
            testId="date-range-filter"
          />
        </STEntry>
        <STEntry
          label="Recency Weight"
          helpText="Define, how important it is to show recently modified articles as suggestions. When set to 0, articles of all ages are recommended equally. When set to a higher value, articles that have been modified more recently are preferred accordingly."
        >
          <ProfileAttributeSlider
            value={date_weight}
            setValue={setDateWeight}
            testId="recency-weight"
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Categories"
          helpText="Select the categories that should be considered when suggesting articles. If no categories are selected, all categories are considered."
        >
          <ProfileAttributeCategorySelect
            value={categories}
            setValue={setCategories}
            disabled={categories_weight === 0}
            testId="categories"
          />
        </STEntry>
        <STEntry
          label="Category Filter Relevance"
          helpText="Define, how important it is to pick articles that are in the selected categories. When set to 0, articles of all categories are recommended equally. When set to the maximum value, only articles within the selected categories will be recommended. When set to any other value, articles of the given categories are preferred, but others might still be recommended if they fit better regarding the other criteria."
        >
          <ProfileAttributeCoarseSlider
            value={categories_weight}
            setValue={setCategoriesWeight}
            testId="relevance"
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Premium"
          helpText="Filter the results to either return only premium articles, only non-premium articles, or to allow both."
        >
          <ProfileAttributeSelect
            value={premium}
            setValue={setPremiumFilter}
            options={[
              { value: PremiumFilter.ALL, label: 'All' },
              { value: PremiumFilter.PREMIUM, label: 'Premium Articles Only' },
              { value: PremiumFilter.FREE, label: 'Free Articles Only' },
            ]}
            testId="premium"
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Allow heavy computations"
          helpText="When checked, the more heavy and potentially time consuming computations will be executed as well. Better accuracy at cost of processing time."
        >
          <ProfileAttributeCheckbox
            value={allow_heavy_computations}
            setValue={setAllowHeavyComputations}
            testId="allow-heavy-computations"
          />
        </STEntry>
      </STGroup>
      <SimilarityWeightSlidersBox
        targetSimilarityWeights={{
          toSelection: selection_to_target_weight, toParagraph: sentence_to_target_weight, toSource: source_to_target_weight,
        }}
        updateTargetSimilarityWeights={updateTargetSimilarityWeights}
        targetConfidenceWeight={target_confidence_weight}
        setTargetConfidenceWeight={setTargetConfidenceWeight}
      />
    </>
  );
}
