import { useEffect, useState } from 'react';

// Source Code copied from: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci

/**
 * Can be used to debounce (in milliseconds) the value from a useState hook example:
 * const [value, setValue] = useState<string>("");
 * const debouncedValue = useDebounce(value, 300);
 *
 *
 * @param value the value to be debounced
 * @param delay in milliseconds
 */
export default function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
}
