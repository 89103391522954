import { Typography } from '@mui/material';
import { FlexBoxColumn, FlexBoxRow, PrimaryButton } from '@purple/react-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isRestServicesRunning } from '../../../../api/hooks/rest-service';
import { GenericLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import {
  CombinedLinkerAction,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import { useLinkerEditorPageService, useLinkerEditorPageState } from '../../../../context/linker/linker-editor-context';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import ArticleProfileInfoButton from '../../article-linker/profile/article-profile-info-button';
import ProductProfileInfoButton from '../../product-linker/profile/product-profile-info-button';
import {
  ArticleLinkerPhraseProfileSelector,
  ProductLinkerPhraseProfileSelector,
} from '../profile/linker-profile-selector';
import { ConcreteProfileInfoButtonProps } from '../profile/profile-info-button';

interface Props<P extends GenericLinkerProfileResponse> {
  name: string
  profiles: Map<string | undefined, P>
  ProfileSelector: () => JSX.Element
  ProfileInfoButton: (props: ConcreteProfileInfoButtonProps<P>) => JSX.Element
  doRecommendationAction: CombinedLinkerAction.DO_ARTICLE_PHRASE_RECOMMENDATION | CombinedLinkerAction.DO_PRODUCT_PHRASE_RECOMMENDATION
  selectedGlobalProfileId: string | undefined
  selectedPhraseProfileId: string | undefined
}

function LinkerPhraseConfig<P extends GenericLinkerProfileResponse>(props: Props<P>) {
  const {
    name,
    profiles,
    ProfileSelector,
    ProfileInfoButton,
    doRecommendationAction,
    selectedGlobalProfileId,
    selectedPhraseProfileId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useCombinedLinkerPageDispatch();
  const { articleId, phraseSelection } = useLinkerEditorPageState();
  const articleByIdService = useLinkerEditorPageService()[articleId];
  const {
    getCombinedLinksRecommendationService,
    postArticleLinksRecommendationService,
    postArticleLinkRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  } = useCombinedLinkerPageService()[articleId];

  const disableGenerationButton = isRestServicesRunning([
    articleByIdService,
    getCombinedLinksRecommendationService,
    postArticleLinksRecommendationService,
    postArticleLinkRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  ]);

  const handleGetRecommendation = (event: React.MouseEvent<HTMLElement>) => {
    dispatch({ type: doRecommendationAction, payload: phraseSelection });
  };

  const profile = profiles.get(selectedPhraseProfileId) ?? profiles.get(selectedGlobalProfileId);

  return (
    <FlexBoxColumn
      justifyContent="center"
      alignItems="center"
      gap="8px"
      sx={{ height: '100%' }}
    >
      <Typography variant="h4">
        {t('lo-selection-recommendation')} ({name})
      </Typography>
      <FlexBoxRow alignItems="center">
        <ProfileSelector />
        <ProfileInfoButton profile={profile} includeLinkGenerationPart={false} />
      </FlexBoxRow>
      <PrimaryButton
        busy={disableGenerationButton}
        onClick={handleGetRecommendation}
      >
        {t('lo-get-recommendation')}
      </PrimaryButton>
    </FlexBoxColumn>
  );
}

export function ArticleLinkerPhraseConfig() {
  const { t } = useTranslation();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  const { selectedGlobalArticleProfileId, selectedPhraseArticleProfileId } = useCombinedLinkerPageState();

  return (
    <LinkerPhraseConfig
      name={t('lo-article')}
      profiles={articleLinkerProfiles}
      ProfileSelector={ArticleLinkerPhraseProfileSelector}
      ProfileInfoButton={ArticleProfileInfoButton}
      doRecommendationAction={CombinedLinkerAction.DO_ARTICLE_PHRASE_RECOMMENDATION}
      selectedGlobalProfileId={selectedGlobalArticleProfileId}
      selectedPhraseProfileId={selectedPhraseArticleProfileId}
    />
  );
}

export function ProductLinkerPhraseConfig() {
  const { t } = useTranslation();
  const { productLinkerProfiles } = useLinkerProfilesState();
  const { selectedGlobalProductProfileId, selectedPhraseProductProfileId } = useCombinedLinkerPageState();

  return (
    <LinkerPhraseConfig
      name={t('lo-product')}
      profiles={productLinkerProfiles}
      ProfileSelector={ProductLinkerPhraseProfileSelector}
      ProfileInfoButton={ProductProfileInfoButton}
      doRecommendationAction={CombinedLinkerAction.DO_PRODUCT_PHRASE_RECOMMENDATION}
      selectedGlobalProfileId={selectedGlobalProductProfileId}
      selectedPhraseProfileId={selectedPhraseProductProfileId}
    />
  );
}
