import * as React from 'react';
import { useContext, useMemo } from 'react';
import useGetFeedbackSelectionsService from '../../api/hooks/linker/feedback/get-feedback-selections';
import { RESTService } from '../../api/hooks/rest-service';
import { FeedbackSelectionResponse } from '../../api/interfaces/feedback';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface FeedbackSelectionsProps {
  children: React.ReactNode
}

interface FeedbackSelectionsServices {
  getFeedbackSelectionsService: RESTService<FeedbackSelectionResponse[]>
  refreshFeedbackSelections: () => void
}

const FeedbackSelectionsServiceContext = React.createContext<FeedbackSelectionsServices | undefined>(undefined);

function FeedbackSelectionsProvider(props: FeedbackSelectionsProps) {
  const { children } = props;
  const [getFeedbackSelectionsService, refreshFeedbackSelections] = useGetFeedbackSelectionsService();

  const services = useMemo(() => ({
    getFeedbackSelectionsService,
    refreshFeedbackSelections,
  }), [getFeedbackSelectionsService, refreshFeedbackSelections]);

  return (
    <FeedbackSelectionsServiceContext.Provider value={services}>
      {children}
    </FeedbackSelectionsServiceContext.Provider>
  );
}

function useFeedbackSelectionsService(): FeedbackSelectionsServices {
  const context = useContext(FeedbackSelectionsServiceContext);
  if (context === undefined) {
    throw new Error('useFeedbackSelectionsService must be used within a FeedbackSelectionsProvider');
  }
  return context;
}

export {
  FeedbackSelectionsProvider,
  useFeedbackSelectionsService,
};
