import { useEffect } from 'react';
import { makeIFrameAdapterFromInside } from './bridge/generic-iframe-adapter';
import LocalStorageVolatile from './constants/session';
import { AuthNewAdapter, AuthOutsideAdapter } from './static/types/acm-services';

function AuthNew() {
  useEffect(() => {
    const adapter = makeIFrameAdapterFromInside<AuthOutsideAdapter, AuthNewAdapter>({
      auth: async ({ apiToken, userId }) => {
        localStorage.setItem(LocalStorageVolatile.API_TOKEN_ACM_IFRAME, apiToken);
        localStorage.setItem(LocalStorageVolatile.USER_ID_IFRAME, userId);
        console.info('Finished ACM authentication.');
      },
    });
    adapter.authFrameReady().catch(console.error);
    return () => adapter.disconnect();
  }, []);

  return <div />;
}

export default AuthNew;
