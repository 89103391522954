import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmationDialog } from '@purple/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteFeedbackSelectionService from '../../../../api/hooks/linker/feedback/delete-feedback-selection';
import { isRestServiceFinished } from '../../../../api/hooks/rest-service';
import { FeedbackSelectionResponse } from '../../../../api/interfaces/feedback';
import { useFeedbackSelectionsService } from '../../../../context/feedback/feedback-selections-context';
import RestSnackbar from '../../../../theme/sprylab/components/rest-snackbar';

interface Props {
  selection: FeedbackSelectionResponse
  index: number
}
export default function SelectionDeleteButton(props: Props) {
  const { selection, index } = props;
  const { refreshFeedbackSelections } = useFeedbackSelectionsService();
  const [deleteSelectionDialogOpen, setDeleteSelectionDialogOpen] = useState<boolean>(false);
  const [doAction, setDoAction] = useState<boolean>();

  const deleteSelection = useDeleteFeedbackSelectionService(doAction ? selection.id : undefined);

  const { t } = useTranslation();

  useEffect(() => {
    if (doAction && isRestServiceFinished(deleteSelection)) {
      refreshFeedbackSelections();
      setDoAction(false);
    }
  }, [deleteSelection.status]);

  return (
    <>
      <Tooltip title="Delete this selection">
        <IconButton
          size="small"
          onClick={() => setDeleteSelectionDialogOpen(true)}
          data-testid={`deleteSelectionButton-${index}`}
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        dialogTitle={t('lo-blocklist-delete-selection-dialog', { textSelection: selection.selection })}
        confirmText={t('lo-blocklist-delete-confirm', 'Yes')}
        cancelText={t('lo-blocklist-delete-cancel', 'Cancel')}
        open={deleteSelectionDialogOpen}
        onCancel={() => setDeleteSelectionDialogOpen(false)}
        onConfirm={() => setDoAction(true)}
      />
      <RestSnackbar
        loadingMessage={t('lo-blocklist-toast-deleting-selection')}
        noContentMessage={t('lo-blocklist-toast-selection-deleted')}
        service={deleteSelection}
        id="deleteSelection"
      />
    </>
  );
}
