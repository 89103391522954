import { pluralize } from './common-utils';

export default function calcDateDifference(oldDateString: string): string {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const currentDate = new Date();
  const currentDateUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const oldDate = new Date(oldDateString);
  const oldDateUTC = Date.UTC(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate());

  let dateDifference;
  let timeSuffix = 'ago';
  if (currentDateUTC >= oldDateUTC) {
    dateDifference = Math.floor((currentDateUTC - oldDateUTC) / MS_PER_DAY);
  } else {
    dateDifference = Math.floor((oldDateUTC - currentDateUTC) / MS_PER_DAY);
    timeSuffix = 'from now';
  }

  if (dateDifference >= 365) {
    const years = Math.floor(dateDifference / 365);
    return `${years} ${pluralize(years, 'year')} ${timeSuffix}`;
  } else if (dateDifference >= 30) {
    const months = Math.floor(dateDifference / 30);
    return `${months} ${pluralize(months, 'month')} ${timeSuffix}`;
  } else if (dateDifference >= 1) {
    return `${dateDifference} ${pluralize(dateDifference, 'day')} ${timeSuffix}`;
  } else if (dateDifference < 1 && dateDifference >= 1 / 24) {
    const hours = dateDifference * 24;
    return `${hours} ${pluralize(hours, 'hours')} ${timeSuffix}`;
  } else if (dateDifference < 1 / 24) {
    const minutes = dateDifference * 24 * 60;
    return `${minutes} ${pluralize(minutes, 'minute')} ${timeSuffix}`;
  } else return '';
}
