import {
  Box,
  ButtonGroupProps, Theme, Typography, useMediaQuery,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { FlexBoxRow, GridBox } from '@purple/react-components';
import * as React from 'react';
import PageActionBars from './page-action-bars';

interface Props {
  leftHeaderTitle: string
  leftHeaderButton: React.ReactNode
  leftContent: React.ReactNode
  rightHeaderButton: React.ReactElement<ButtonGroupProps>
  rightContent: React.ReactNode
  hasUnsavedChanges?: boolean
}

export default function SplitViewPage(props: Props) {
  const {
    leftHeaderTitle, leftHeaderButton, leftContent, rightHeaderButton, rightContent, hasUnsavedChanges,
  } = props;
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const stretchedColumnFlexStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  };

  const leftContainer = (
    <Paper
      square={smallScreen}
      elevation={2}
      sx={{
        minHeight: 0, // needed to prevent overflow in css grid
        transition: 'border-radius 0.3s ease-in-out',
        ...stretchedColumnFlexStyle,
        padding: '20px',
      }}
    >
      <FlexBoxRow
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
        sx={{
          pl: '24px',
          pr: '4px',
          flexGrow: 0,
        }}
      >
        <Typography
          variant="h4"
          noWrap
          sx={{ flexGrow: 1, flexShrink: 1, userSelect: 'none' }}
        >
          {leftHeaderTitle}
        </Typography>
        {leftHeaderButton}
      </FlexBoxRow>
      <Divider sx={{ mt: '20px', mb: '3px' }} />
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          overflowX: 'hidden',
          overscrollBehaviorY: 'none', // prevent "scroll beyond the end" effect e.g. on mac, because the connection lines do not look good with it
          position: 'relative',
          // move the scrollbar out:
          mr: '-20px',
          pr: '20px',
        }}
      >
        {leftContent}
      </Box>
    </Paper>
  );

  const rightContainer = (
    <Box
      sx={{
        minHeight: 0, // needed to prevent overflow in css grid
        ...stretchedColumnFlexStyle,
        gap: '5px',
        pt: '20px',
      }}
    >
      <Box sx={{ pr: '10px' }}>
        {rightHeaderButton}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowX: 'hidden',
          overflowY: 'scroll',
          overscrollBehaviorY: 'none', // prevent "scroll beyond the end" effect e.g. on mac, because the connection lines do not look good with it
          position: 'relative',
        }}
      >
        {rightContent}
      </Box>
    </Box>
  );

  const pageContent = (
    <GridBox
      gridTemplateColumns="minmax(0, 1fr) minmax(0, 1fr)"
      gap={smallScreen ? '20px' : '44px'}
      sx={{
        transition: 'gap 0.3s ease-in-out',
        width: '100%',
        height: '100%',
      }}
    >
      {leftContainer}
      {rightContainer}
    </GridBox>
  );

  return (
    <PageActionBars pageContent={pageContent} hasUnsavedChanges={hasUnsavedChanges} />
  );
}
