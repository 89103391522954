// TODO keep in sync with the same list in the backend, see acm/domain/model/entity.py
export enum EntityType {
    ACT = 'activity',
    ANA = 'anatomy',
    DAT = 'date',
    DIS = 'disease',
    EVE = 'event',
    FOOD = 'food',
    ID = 'identifier',
    LANG = 'language',
    LOC = 'location',
    MEAS = 'measure',
    MON = 'money',
    ORG = 'company',
    PER = 'person',
    PROD = 'product',
    SPE = 'species',
    SUB = 'substance',
    TIT = 'title',
    TRAN = 'transport',
    QUAN = 'quantity',
    MISC = 'general',
    NONE = 'none',
}

export interface EntityResponse {
  name: string
  kind: EntityType
  matched_text: MatchedTextResponse
  types: string[] | null
  fields: string[] | null
  wiki_link: string | null
  trend_score: number | null
  aliases: Alias[] | null
}

export interface MatchedTextResponse {
  text: string
  start_char: number
  end_char: number
  confidence: number | null
}

export interface Alias {
  alias: string
  trend_score: number | null
}
