import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { ActionButton } from '@purple/react-components';
import { useTranslation } from 'react-i18next';
import { ProductLinkRecommendationResponse, ProductScoreResponse } from '../../../../api/interfaces/recommendation';
import {
  LinkType,
} from '../../../../context/linker/combined-linker-context';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import GenericLinkRecommendationDetailsContent
  from '../../combined-linker/recommendation-details/generic-link-recommendation-details-content';

interface Props {
  product: ProductScoreResponse
  recommendation: ProductLinkRecommendationResponse
  selectable?: boolean
  checked?: boolean
  isRecommended?: boolean
}

export default function ProductLinkRecommendationDetailsContent(props: Props) {
  const { product, recommendation, ...rest } = props;
  const { productLinkerProfiles } = useLinkerProfilesState();
  const usedProfile = productLinkerProfiles.get(recommendation.profile_id);

  const { t } = useTranslation();

  return (
    <GenericLinkRecommendationDetailsContent
      linkType={LinkType.PRODUCT}
      item={product}
      recommendation={recommendation}
      usedProfile={usedProfile}
      captionPhrases={[
        [product.price, `${product.price} €`],
        [product.customer_reviews_star_rating, t('lo-caption-rating', { value: product.customer_reviews_star_rating })],
        [product.customer_reviews_count, t('lo-caption-reviews', { value: product.customer_reviews_count })],
      ]}
      {...rest}
    >
      <CardActions disableSpacing>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href={product.link}
            target="_blank"
          >
            <ActionButton>
              {t('lo-open-product-page')}
            </ActionButton>
          </Link>
        </Grid>
      </CardActions>
    </GenericLinkRecommendationDetailsContent>
  );
}
