import { Box, Collapse, Typography } from '@mui/material';
import { FlexBox } from '@purple/react-components';
import { useTranslation } from 'react-i18next';
import { RecommendationOriginType } from '../../../../api/interfaces/recommendation';
import {
  isArticleRR,
  isProductRR,
  LinkType,
  linkTypeFilter,
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import DataLoading from '../../../../theme/sprylab/components/data-loading';
import { LINK_CARD_ANIMATION_MS } from '../../../../theme/style-utils';
import ContainerShrinkPrevention from '../../../util/container-shrink-prevention';
import { DataLoadingPostArticleLinks, DataLoadingPostProductLinks } from './data-loading-generate-links';
import GenericLinkRecommendationDetails from './generic-link-recommendation-details';

export default function CombinedLinkRecommendationsDetails() {
  const { t } = useTranslation();
  const {
    showSelections,
    combinedLinkRecommendationElements,
    currentLinkTypeFilter,
    articleId,
    collapsedLinkIds,
  } = useCombinedLinkerPageState();
  const services = useCombinedLinkerPageService();
  const getLinksRecommendationService = services[articleId].getCombinedLinksRecommendationService;

  const shownKeys = combinedLinkRecommendationElements
    ? Array.from(combinedLinkRecommendationElements.keys())
      .filter((recommendation) => recommendation.origin !== RecommendationOriginType.EXTERNAL)
    : [];

  return (
    <DataLoading
      service={getLinksRecommendationService}
      justify="flex-start"
      errorMessage="Couldn't load recommendations"
    >
      <DataLoadingPostArticleLinks>
        <DataLoadingPostProductLinks>
          {combinedLinkRecommendationElements && shownKeys.length ? (
            <>
              {shownKeys.map((recommendation, index) => (
                <Collapse
                  key={recommendation.id}
                  in={linkTypeFilter(recommendation, currentLinkTypeFilter)}
                  timeout={LINK_CARD_ANIMATION_MS}
                >
                  <Box
                    sx={{
                      marginTop: '30px', // spacing between recommendation cards
                      marginLeft: '10px',
                      marginRight: '10px',
                      '&:first-child': {
                        marginTop: '10px',
                      },
                      '&:last-child': {
                        marginBottom: '10px',
                      },
                      display: showSelections && showSelections !== recommendation ? 'none' : undefined,
                    }}
                  >
                    {isArticleRR(recommendation) && (
                      <GenericLinkRecommendationDetails recommendation={recommendation} index={index + 1} linkType={LinkType.ARTICLE} />
                    )}
                    {isProductRR(recommendation) && (
                      <GenericLinkRecommendationDetails recommendation={recommendation} index={index + 1} linkType={LinkType.PRODUCT} />
                    )}
                  </Box>
                </Collapse>
              ))}
              <div style={{ height: 20 /* adding a little bit of space below the link cards */, width: 0 }} />
              <ContainerShrinkPrevention resetDeps={[currentLinkTypeFilter, collapsedLinkIds, showSelections]} resetDurationMs={500} />
            </>
          ) : (
            <FlexBox alignItems="center" justifyItems="center" height="100%">
              <Typography variant="overline" sx={{ m: 'auto', opacity: '50%' }}>
                {t('lo-no-links-yet')}
              </Typography>
            </FlexBox>
          )}
        </DataLoadingPostProductLinks>
      </DataLoadingPostArticleLinks>
    </DataLoading>
  );
}
