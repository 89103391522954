import Paper from '@mui/material/Paper';
import { GridBox } from '@purple/react-components';
import * as React from 'react';
import { AcmExtraColors } from '../../theme/style-utils';
import AcmAppBarAndDrawer from '../app-bar/acm-app-bar-and-drawer';

interface Props {
  cards: React.ReactNode[]
}

export default function PageWithCards(props: Props) {
  const { cards } = props;

  return (
    <AcmAppBarAndDrawer>
      <div
        style={{
          backgroundColor: AcmExtraColors.background,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <GridBox
          gridTemplateColumns="repeat(2, minmax(0, 1fr))"
          gap="20px"
          sx={{ p: '20px' }}
        >
          {cards.map((card) => (
            <Paper
              elevation={1}
              style={{ height: '370px' }}
            >
              {card}
            </Paper>
          ))}
        </GridBox>
      </div>
    </AcmAppBarAndDrawer>
  );
}
