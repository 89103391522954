import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import useEveryFrame from '../../hooks/use-every-frame';
import useTimer from '../../util/use-timer';

function isScrolledToTop(element: HTMLElement) {
  return element.scrollTop === 0 && (!element.parentElement || isScrolledToTop(element.parentElement));
}

// How much of the content should always be visible, even if it suddenly shrinks a lot
const MINIMUM_CONTENT_VISIBILITY_PIXELS = 100;

type Props = {
  /** If any of these variables change, reset and disable this component for a short time */
  resetDeps?: any[]
  /** How long this component should stay disabled after the resetDeps have changed */
  resetDurationMs?: number
};

/**
 * This component makes sure that its parent container does not automatically scroll up when its content shrinks.
 * It is an invisible element that:
 *  - always moves down to the lower end of the content of its parent container
 *  - only moves up again when out of view
 * It effectively creates temporary empty space below its siblings.
 */
export default function ContainerShrinkPrevention(props: Props) {
  const { resetDeps, resetDurationMs } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [yPosition, setYPosition] = useState(0);
  const [reset, isResetting] = useTimer(resetDurationMs ?? 0);
  useEffect(reset, resetDeps ?? []);

  const adjustYPosition = useCallback(() => {
    if (isResetting) {
      setYPosition(0);
      return;
    }
    if (!ref.current) return;
    const container = ref.current.parentElement;
    if (!container) return;
    if (isScrolledToTop(container)) {
      // disable ourselves when the container is scrolled to the top
      setYPosition(0);
      return;
    }

    const siblings = Array.from(container.children).filter((child) => child !== ref.current);
    const contentLowestScreenPosition = Math.max(...siblings.map((sibling) => {
      const siblingRect = sibling.getBoundingClientRect();
      return siblingRect.top + siblingRect.height;
    }));
    const parentTop = container.getBoundingClientRect().top;
    const contentHeight = contentLowestScreenPosition - parentTop;
    if (contentHeight >= yPosition) {
      setYPosition(contentHeight);
    } else {
      // shrink until we are at the bottom edge of the screen:
      const maxShrinkAmount = ref.current.getBoundingClientRect().bottom - (window.innerHeight + 1);
      // always show at least a tiny bit of content, never stay scrolled down further than that
      const maxYPosAllowed = container.getBoundingClientRect().height + contentHeight - MINIMUM_CONTENT_VISIBILITY_PIXELS;
      const newPosition = Math.min(
        maxYPosAllowed,
        Math.max(contentHeight, yPosition - maxShrinkAmount),
      );
      if (newPosition < yPosition) {
        setYPosition(newPosition);
      }
    }
  }, [ref.current, yPosition, isResetting]);

  useEveryFrame(adjustYPosition);

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        top: yPosition,
        left: 0,
        width: '100%',
        height: 1,
        backgroundColor: 'transparent',
      }}
    />
  );
}
