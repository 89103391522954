import { BoxChip, FlexBoxRow } from '@purple/react-components';
import { useRef, useState } from 'react';
import { EntityResponse } from '../../../../api/interfaces/entity';
import ActionButtonsArrowPopper from '../../../../theme/action-buttons-arrow-popper';
import { EditableList } from '../../../../util/use-editable-list';
import EntityClickActionPopoverButtons from '../entity-click-action-popover-buttons';

type Props = {
  entities: EntityResponse[]
  keywords: EditableList<string>
}

export default function EntityList(props: Props) {
  const { entities, keywords } = props;

  return (
    <FlexBoxRow
      flexWrap="wrap"
      justifyContent="flex-start"
      gap="4px"
    >
      {entities.map((entity) => (
        <EntityListItem
          key={entity.name}
          entity={entity}
          alreadyAddedAsKeyword={keywords.current.includes(entity.name)}
          onEntityAddOrRemoveAsKeyword={() => keywords.toggle(entity.name)}
        />
      ))}
    </FlexBoxRow>
  );
}

type ItemProps = {
  entity: EntityResponse
  alreadyAddedAsKeyword: boolean
  onEntityAddOrRemoveAsKeyword: () => void
}

function EntityListItem(props: ItemProps) {
  const {
    entity, alreadyAddedAsKeyword, onEntityAddOrRemoveAsKeyword,
  } = props;

  const chipRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      <BoxChip
        ref={chipRef}
        label={entity.name}
        sx={{ m: '3px' }}
        onClick={() => setPopoverOpen((o) => !o)}
      />
      <ActionButtonsArrowPopper
        elementRef={chipRef}
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
      >
        <EntityClickActionPopoverButtons
          selectedEntity={entity}
          alreadyAddedAsKeyword={alreadyAddedAsKeyword}
          onKeywordAddOrRemove={() => {
            onEntityAddOrRemoveAsKeyword();
            setPopoverOpen(false);
          }}
        />
      </ActionButtonsArrowPopper>
    </>
  );
}
