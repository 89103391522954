import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { ButtonBase, Collapse } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PurpleBaseColors } from '@purple/react-components';
import * as React from 'react';
import { useState } from 'react';
import { upsideDownTransitionStyles } from '../../../theme/style-utils';

type Props = {
  title: string
  children: React.ReactNode
  defaultExpanded?: boolean
  grayBackground?: boolean
}

export default function SidebarExpansionPanel(props: Props) {
  const {
    title, children, defaultExpanded, grayBackground,
  } = props;

  const [expanded, setExpanded] = useState(defaultExpanded ?? false);

  return (
    <>
      <ButtonBase
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          px: '20px',
          height: '66px',
          '&:hover': {
            backgroundColor: PurpleBaseColors.neutralLightGray,
          },
        }}
        onClick={() => setExpanded((e) => !e)}
      >
        <Typography variant="h5">
          {title}
        </Typography>
        <ExpandCircleDownOutlinedIcon sx={upsideDownTransitionStyles(expanded)} />
      </ButtonBase>
      <Collapse
        in={expanded}
        sx={{
          px: '20px',
          backgroundColor: grayBackground ? PurpleBaseColors.neutralLightGray : undefined,
        }}
      >
        {children}
      </Collapse>
    </>
  );
}
