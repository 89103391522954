import { Divider } from '@mui/material';
import { FlexBoxColumn, PurpleBaseColors } from '@purple/react-components';
import { ArticleTextState } from '../../../static/types/acm-bridge';
import { EditableList } from '../../../util/use-editable-list';
import SidebarPanelArticleRecommendations from './sidebar-panel-article-recommendations';
import SidebarPanelKeywords from './sidebar-panel-keywords';

interface Props {
  articleTextState?: ArticleTextState
  keywords: EditableList<string>
  keywordsAreSaving?: boolean
}

export default function SidebarPanels(props: Props) {
  const {
    articleTextState, keywords, keywordsAreSaving,
  } = props;

  return (
    <FlexBoxColumn sx={{ color: PurpleBaseColors.purple }}>
      <Divider light sx={{ mx: '20px' }} />
      <SidebarPanelKeywords
        articleTextState={articleTextState}
        keywords={keywords}
        keywordsAreSaving={keywordsAreSaving}
      />
      <Divider light sx={{ mx: '20px' }} />
      <SidebarPanelArticleRecommendations
        articleId={articleTextState?.article.article_id}
        content={articleTextState?.article.rawContent}
        keywords={keywords.current}
      />
      <Divider light sx={{ mx: '20px' }} />
    </FlexBoxColumn>
  );
}
