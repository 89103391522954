import * as React from 'react';
import {
  useContext, useEffect, useMemo, useReducer,
} from 'react';
import useGetArticleLinkerProfilesService from '../../api/hooks/linker/profiles/articles/get-articles-linker-profiles';
import useGetProductLinkerProfilesService
  from '../../api/hooks/linker/profiles/products/get-product-linker-profiles.ts';
import { REST_STATUS, RESTService } from '../../api/hooks/rest-service';
import {
  ArticleLinkerProfileResponse,
  DefaultArticleLinkerProfile,
  DefaultProductLinkerProfile,
  GenericLinkerProfileResponse,
  ProductLinkerProfileResponse,
} from '../../api/interfaces/linker-profile';
import { assertNever } from '../../util/reducer-utils';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

export interface LinkerProfilesState {
  articleLinkerProfiles: Map<string | undefined, ArticleLinkerProfileResponse>
  productLinkerProfiles: Map<string | undefined, ProductLinkerProfileResponse>
  selectedArticleLinkerId?: string
  selectedProductLinkerId?: string
  isProfileDirty: boolean
}

interface LinkerProfilesProps {
  children: React.ReactNode
}

interface LinkerProfilesServices {
  getArticleLinkerProfilesService: RESTService<ArticleLinkerProfileResponse[]>
  refreshArticleProfiles: () => void
  getProductLinkerProfilesService: RESTService<ProductLinkerProfileResponse[]>
  refreshProductProfiles: () => void
}

export enum ProfileType {
  ARTICLE,
  PRODUCT,
}

export enum LinkerProfilesAction {
  SET_ARTICLE_LINKER_PROFILES,
  SET_PRODUCT_LINKER_PROFILES,
  SELECT_ARTICLE_LINKER_PROFILE,
  SELECT_PRODUCT_LINKER_PROFILE,
  SET_PROFILE_DIRTY,
}

type Action =
  | { type: LinkerProfilesAction.SET_ARTICLE_LINKER_PROFILES, payload: ArticleLinkerProfileResponse[] }
  | { type: LinkerProfilesAction.SET_PRODUCT_LINKER_PROFILES, payload: ProductLinkerProfileResponse[] }
  | { type: LinkerProfilesAction.SELECT_ARTICLE_LINKER_PROFILE, payload?: string }
  | { type: LinkerProfilesAction.SELECT_PRODUCT_LINKER_PROFILE, payload?: string }
  | { type: LinkerProfilesAction.SET_PROFILE_DIRTY, payload: boolean }

type LinkerProfilesDispatch = (action: Action) => void

const defaultLinkerProfilesState: LinkerProfilesState = {
  articleLinkerProfiles: new Map<string, ArticleLinkerProfileResponse>(),
  productLinkerProfiles: new Map<string, ProductLinkerProfileResponse>(),
  isProfileDirty: false,
};

const LinkerProfilesServiceContext = React.createContext<LinkerProfilesServices | undefined>(undefined);
const LinkerProfilesStateContext = React.createContext<LinkerProfilesState | undefined>(undefined);
const LinkerProfilesDispatchContext = React.createContext<LinkerProfilesDispatch | undefined>(undefined);

function linkerProfilesReducer(state: LinkerProfilesState, action: Action): LinkerProfilesState {
  switch (action.type) {
    case LinkerProfilesAction.SET_ARTICLE_LINKER_PROFILES: {
      return {
        ...state,
        articleLinkerProfiles: new Map<string, ArticleLinkerProfileResponse>(action.payload.map((p) => [p.id, p])),
      };
    }
    case LinkerProfilesAction.SET_PRODUCT_LINKER_PROFILES: {
      return {
        ...state,
        productLinkerProfiles: new Map<string, ProductLinkerProfileResponse>(action.payload.map((p) => [p.id, p])),
      };
    }
    case LinkerProfilesAction.SELECT_ARTICLE_LINKER_PROFILE: {
      return {
        ...state,
        selectedArticleLinkerId: action.payload,
      };
    }
    case LinkerProfilesAction.SELECT_PRODUCT_LINKER_PROFILE: {
      return {
        ...state,
        selectedProductLinkerId: action.payload,
      };
    }
    case LinkerProfilesAction.SET_PROFILE_DIRTY: {
      return {
        ...state,
        isProfileDirty: action.payload,
      };
    }
    default: throw assertNever(action);
  }
}

function LinkerProfilesProvider(props: LinkerProfilesProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(linkerProfilesReducer, defaultLinkerProfilesState);
  const [getArticleLinkerProfilesService, refreshArticleProfiles] = useGetArticleLinkerProfilesService();
  const [getProductLinkerProfilesService, refreshProductProfiles] = useGetProductLinkerProfilesService();

  const services = {
    getArticleLinkerProfilesService,
    refreshArticleProfiles,
    getProductLinkerProfilesService,
    refreshProductProfiles,
  };

  useEffect(() => {
    refreshArticleProfiles();
    refreshProductProfiles();
  }, []);

  useEffect(() => {
    if (getArticleLinkerProfilesService.status === REST_STATUS.LOADED) {
      dispatch({
        type: LinkerProfilesAction.SET_ARTICLE_LINKER_PROFILES,
        payload: getArticleLinkerProfilesService.payload,
      });
    }
  }, [getArticleLinkerProfilesService.status]);

  useEffect(() => {
    if (getProductLinkerProfilesService.status === REST_STATUS.LOADED) {
      dispatch({
        type: LinkerProfilesAction.SET_PRODUCT_LINKER_PROFILES,
        payload: getProductLinkerProfilesService.payload,
      });
    }
  }, [getProductLinkerProfilesService.status]);

  return (
    <LinkerProfilesServiceContext.Provider value={services}>
      <LinkerProfilesStateContext.Provider value={state}>
        <LinkerProfilesDispatchContext.Provider value={dispatch}>
          {children}
        </LinkerProfilesDispatchContext.Provider>
      </LinkerProfilesStateContext.Provider>
    </LinkerProfilesServiceContext.Provider>
  );
}

function useGetProfilesByType(profileType: ProfileType): GenericLinkerProfileResponse[] {
  const { articleLinkerProfiles, productLinkerProfiles } = useLinkerProfilesState();

  return useMemo(() => {
    switch (profileType) {
      case ProfileType.ARTICLE:
        return Array.from(articleLinkerProfiles.values());
      case ProfileType.PRODUCT:
        return Array.from(productLinkerProfiles.values());
      default:
        return [] as GenericLinkerProfileResponse[];
    }
  }, [profileType, articleLinkerProfiles, productLinkerProfiles]);
}

function getDefaultProfileByType(profileType: ProfileType): GenericLinkerProfileResponse | undefined {
  switch (profileType) {
    case ProfileType.ARTICLE:
      return DefaultArticleLinkerProfile;
    case ProfileType.PRODUCT:
      return DefaultProductLinkerProfile;
    default:
      return undefined;
  }
}

function useLinkerProfilesState(): LinkerProfilesState {
  const context = useContext(LinkerProfilesStateContext);
  if (context === undefined) {
    throw new Error('useLinkerProfilesState must be used within a LinkerProfilesProvider');
  }
  return context;
}

function useLinkerProfilesDispatch(): LinkerProfilesDispatch {
  const context = useContext(LinkerProfilesDispatchContext);
  if (context === undefined) {
    throw new Error('useLinkerProfilesDispatch must be used within a LinkerProfilesProvider');
  }
  return context;
}

function useLinkerProfilesService(): LinkerProfilesServices {
  const context = useContext(LinkerProfilesServiceContext);
  if (context === undefined) {
    throw new Error('useLinkerProfilesService must be used within a LinkerProfilesProvider');
  }
  return context;
}

export {
  LinkerProfilesProvider,
  useLinkerProfilesState,
  useLinkerProfilesDispatch,
  useLinkerProfilesService,
  useGetProfilesByType,
  getDefaultProfileByType,
};
