import { Box } from '@mui/material';
import {
  BackgroundGradient, BaseLoginMask, PurpleBaseColors,
} from '@purple/react-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RequestError from '../../api/interfaces/RequestError';
import { authenticate, useACMUserDispatch, useACMUserState } from '../../context/acm-page-context';
import AcmAppBarAndDrawer from '../app-bar/acm-app-bar-and-drawer';
import VersionNumberInACorner from '../common/version-number';

/**
* Login component, providing basic authentication
*/
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useACMUserDispatch();
  const { user } = useACMUserState();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (user) {
      navigate(location.state?.from || '/');
    }
  }, [user]);

  function login(username: string, password: string) {
    authenticate(dispatch, username, password)
      .then(() => navigate(location.state?.from?.pathname || '/'))
      .catch((error: RequestError) => {
        console.warn('Failed to login', error);
        if (error.responseCode === 401) {
          setMessage('Invalid username or password!');
        } else {
          setMessage('Login failed!');
        }
      });
  }

  return (
    <AcmAppBarAndDrawer>
      <Box sx={{ width: '100%', height: '100%', backgroundColor: PurpleBaseColors.purpleDark }}>
        <BackgroundGradient sx={{ width: '100%', height: '100%' }}>
          <BaseLoginMask
            onLogin={login}
            showErrorMessage={message}
            onClearErrorMessage={() => setMessage(undefined)}
          />
          <VersionNumberInACorner />
        </BackgroundGradient>
      </Box>
    </AcmAppBarAndDrawer>
  );
}
