import {
  Card, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import { LinkOptimizerDashboardStatistics } from '../../../api/interfaces/linkoptimizer-dashboard-statistics';

export default function LinkStatisticsTable(props: LinkOptimizerDashboardStatistics) {
  const {
    generated_published_links,
    generated_deleted_links,
    manual_published_links,
    manual_deleted_links,
  } = props || {};

  const generatedLinks = generated_published_links + generated_deleted_links;
  const manualLinks = manual_published_links + manual_deleted_links;
  const publishedLinks = generated_published_links + manual_published_links;
  const deletedLinks = generated_deleted_links + manual_deleted_links;
  const totalLinks = generatedLinks + manualLinks;

  const rows = [
    ['', 'Published Links', 'Deleted Links', ''],
    ['Generated Links', generated_published_links, generated_deleted_links, generatedLinks],
    ['Manual Links', manual_published_links, manual_deleted_links, manualLinks],
    ['', publishedLinks, deletedLinks, `TOTAL: ${totalLinks}`],
  ];

  return (
    <TableContainer component={Card}>
      <Table>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row[0]}>
              <TableCell align="center">{row[0]}</TableCell>
              <TableCell align="center">{row[1]}</TableCell>
              <TableCell align="center">{row[2]}</TableCell>
              <TableCell align="center">{row[3]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
