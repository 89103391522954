import { useEffect, useState } from 'react';
import { LINKER_URL } from '../../../../../constants/configs/urls';
import { SelectedPhraseData } from '../../../../../constants/interfaces/interfaces';
import { ProductLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import { CombinedRecommendationsResponse } from '../../../../interfaces/recommendation';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

interface PostProductLinkRecommendation {
  selection: {
    text_selection: string
    text_paragraph?: string
    index_paragraph?: number
    occurrence_in_paragraph: number
  }
}

const usePostCombinedProductLinkRecommendationService = (articleId?: string, profile?: ProductLinkerProfileResponse, selectedPhraseData?: SelectedPhraseData): RESTService<CombinedRecommendationsResponse> => {
  const [url, setURL] = useState<string>();
  const [payload, setPayload] = useState<PostProductLinkRecommendation>();

  useEffect(() => {
    if (articleId) {
      let profileId = '';
      if (profile) {
        profileId = `?profile_id=${profile.id}`;
      }
      setURL(`${LINKER_URL}/linker/combined_links/products/${articleId}${profileId}`);
    } else {
      setURL(undefined);
    }
  }, [articleId, profile]);

  useEffect(() => {
    let body: PostProductLinkRecommendation | undefined;
    if (selectedPhraseData) {
      const {
        selectedText, paragraphText, paragraphIndex, occurrence,
      } = selectedPhraseData;
      if (selectedText && occurrence !== undefined && occurrence > -1) {
        body = {
          selection: paragraphText ? {
            text_selection: selectedText,
            text_paragraph: paragraphText,
            occurrence_in_paragraph: occurrence,
          } : {
            text_selection: selectedText,
            index_paragraph: paragraphIndex,
            occurrence_in_paragraph: occurrence,
          },
        };
      }
    }
    setPayload(body);
  }, [selectedPhraseData]);

  const [result] = usePostRequestService(payload ? url : undefined, payload);
  return result;
};

export default usePostCombinedProductLinkRecommendationService;
