import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import getGenericProfileInfo from './profile-info-statements';
import ProfileInfoTooltipGroup from './profile-info-tooltip-group';

export interface InfoItem {
  text: string
  weight?: number
}

function shouldItemBeShown(item: InfoItem) {
  return item.weight === undefined || item.weight > 0;
}

interface Props<P> {
  profile: P
  infoGetter: (profile: P, t: TFunction) => InfoItem[]
  includeLinkGenerationPart?: boolean
}

export default function ProfileInfoTooltipContent<P extends GenericLinkerProfileResponse>(props: Props<P>) {
  const { profile, infoGetter, includeLinkGenerationPart } = props;

  const { t } = useTranslation();

  const genericInfo = useMemo(() => (
    (includeLinkGenerationPart ?? true) ? getGenericProfileInfo(profile.article_parameters, t).filter(shouldItemBeShown) : []
  ), [profile, includeLinkGenerationPart]);

  const concreteInfo = useMemo(() => {
    const rawInfo: InfoItem[] = infoGetter(profile, t).filter(shouldItemBeShown);
    if (rawInfo.length === 0) {
      rawInfo.push({ text: 'No Criteria on the Results' });
    }
    return rawInfo;
  }, [profile, includeLinkGenerationPart]);

  return (
    <List dense data-testid="profile-info-tooltip">
      {(includeLinkGenerationPart ?? true) && (
        <>
          <ProfileInfoTooltipGroup title={t('lo-link-selection-generation')} items={genericInfo} />
          <Divider />
        </>
      )}
      <ProfileInfoTooltipGroup title={t('lo-result-criteria')} items={concreteInfo} />
    </List>
  );
}
