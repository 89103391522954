import BarChartIcon from '@mui/icons-material/BarChart';
import { Theme, Tooltip, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { ActionButton } from '@purple/react-components';
import { useTranslation } from 'react-i18next';
import { useLinkerEditorPageState } from '../../../context/linker/linker-editor-context';
import LinkStatisticsTooltipContent from './link-statistics-tooltip-content';

export default function LinkStatisticsTooltip() {
  const { articleById, editorStateHtml } = useLinkerEditorPageState();
  const theme = useTheme();
  const smallScreen = useMediaQuery((t: Theme) => t.breakpoints.down('xl'));

  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        editorStateHtml
          ? (
            <LinkStatisticsTooltipContent
              articleContent={editorStateHtml}
            />
          )
          : ''
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[3],
            borderRadius: '0px',
            maxWidth: 'none', // allowing tooltips to grow in width as needed for long label texts
          },
        },
      }}
    >
      <span
        style={{ display: 'inline-block' }} // for safari, an element with "inline-block" is needed so that the tooltip works correctly, see https://github.com/mui/material-ui/issues/17997#issuecomment-804403905
      >
        <ActionButton
          disableRipple
          icon={<BarChartIcon fontSize="inherit" />}
          disabled={!articleById}
          sx={{ cursor: 'unset' }}
          data-testid="link-statistics-tooltip-button"
        >
          {smallScreen ? '' : t('lo-statistics')}
        </ActionButton>
      </span>
    </Tooltip>
  );
}
