import { TFunction } from 'i18next';
import { ProductLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import ProfileInfoButton, { ConcreteProfileInfoButtonProps } from '../../generic/profile/profile-info-button';
import { InfoItem } from '../../generic/profile/profile-info-tooltip-content';
import { formatEuroToString } from '../../generic/profile/slider-helper';

export default function ProductProfileInfoButton(props: ConcreteProfileInfoButtonProps<ProductLinkerProfileResponse>) {
  return (
    <ProfileInfoButton
      infoGetter={getProductProfileInfo}
      {...props}
    />
  );
}

function getProductProfileInfo(profile: ProductLinkerProfileResponse, t: TFunction): InfoItem[] {
  const info: InfoItem[] = [];

  // zero means "filter disabled" for both price filters
  const hasMinPrice = profile.link_parameters.minimum_price_cents > 0;
  const hasMaxPrice = profile.link_parameters.maximum_price_cents > 0;
  if (hasMinPrice && hasMaxPrice) {
    if (profile.link_parameters.minimum_price_cents === profile.link_parameters.maximum_price_cents) {
      info.push({
        text: `${t('lo-price')}: ${t('lo-exactly')} ${formatEuroToString(profile.link_parameters.minimum_price_cents / 100)}`,
      });
    } else {
      info.push({
        text: `${t('lo-price-range')}: ${formatEuroToString(profile.link_parameters.minimum_price_cents / 100)} - ${formatEuroToString(profile.link_parameters.maximum_price_cents / 100)}`,
      });
    }
  } else if (hasMinPrice) {
    info.push({
      text: `${t('lo-min-price')}: ${formatEuroToString(profile.link_parameters.minimum_price_cents / 100)}`,
    });
  } else if (hasMaxPrice) {
    info.push({
      text: `${t('lo-max-price')}: ${formatEuroToString(profile.link_parameters.maximum_price_cents / 100)}`,
    });
  }

  const hasMinUserRatingFilter = profile.link_parameters.minimum_user_rating_stars > 1;
  if (hasMinUserRatingFilter) {
    info.push({
      text: `${t('lo-min-user-rating')}: ${profile.link_parameters.minimum_user_rating_stars} ${t('lo-stars')}`,
    });
  }

  if (profile.link_parameters.prime_only) {
    info.push({
      text: t('lo-prime-only'),
    });
  }

  if (profile.link_parameters.category_id && profile.link_parameters.category_id !== '0' && profile.link_parameters.category_id !== '1') {
    info.push({
      text: `${t('lo-category')}: ${profile.link_parameters.category_id}`,
    });
  }

  if (profile.link_parameters.associate_tag) {
    info.push({
      text: `${t('lo-custom-associate-tag')}: ${profile.link_parameters.associate_tag}`,
    });
  }

  return info;
}
