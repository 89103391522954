import { useEffect, useRef, useState } from 'react';
import { useACMUserState } from '../../../../context/acm-page-context';
import { isRestServiceRunning, REST_STATUS, RESTService } from '../../rest-service';
import makeRequestError from './common';

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export default function useGenericRequestService(method: RequestMethod, requestURL?: string, body?: Object, changedProperty?: string, skipFirstRequest?: boolean): [RESTService<any>, () => void] {
  const { acmToken } = useACMUserState();
  const [refresh, setRefresh] = useState<number>(0);
  const [_url, setUrl] = useState<string>();
  const [_body, setBody] = useState<string>();
  const abortRef = useRef<AbortController>();
  const paramBodySerialized = JSON.stringify(body);
  const [result, setResult] = useState<RESTService<any>>({
    status: REST_STATUS.INIT,
  });

  const refreshService = () => {
    setRefresh((refreshCounter) => ++refreshCounter);
  };

  useEffect(() => {
    setResult({ status: REST_STATUS.INIT });
  }, [_url]);

  useEffect(() => {
    if (isRestServiceRunning(result)) {
      abortRef.current?.abort();
      abortRef.current = undefined;
      setResult({ status: REST_STATUS.INIT });
    }
    setUrl(requestURL);
    setBody(paramBodySerialized);
  }, [requestURL, paramBodySerialized]);

  useEffect(() => {
    if (skipFirstRequest && refresh === 0) {
      // When "skipFirstRequest" is given, we do not automatically make a request,
      // but instead wait for the refresh function to be called at least once.
      // This way, we can put the refresh function in a "useEffect" block without making two requests at once.
      return;
    }
    if (_url && (_body || method === 'GET')) {
      setResult({ status: REST_STATUS.LOADING });
      const body_json = method === 'GET' ? undefined : _body;
      abortRef.current = new AbortController();
      const { signal } = abortRef.current;
      fetch(_url, {
        signal,
        method,
        body: body_json,
        headers: {
          Accept: 'application/json',
          'X-API-KEY': acmToken || '',
          'Content-Type': 'application/json',
        },
      }).then(async (response: Response) => {
        if (response.status >= 300) {
          throw await makeRequestError(response);
        } else if (response.status === 204) {
          return null;
        } else {
          try {
            return await response.json();
          } catch (e) {
            throw new Error(`Invalid response from server: ${e}`);
          }
        }
      }).then((response) => {
        if (!response) {
          setResult({ status: REST_STATUS.NO_CONTENT });
        } else {
          setResult({ status: REST_STATUS.LOADED, payload: response });
        }
      }).catch((error) => {
        if (error.name === 'AbortError') {
          // Ignore the error if the request was aborted. See https://developer.chrome.com/blog/abortable-fetch/#reacting-to-an-aborted-fetch
        } else {
          setResult({ status: REST_STATUS.ERROR, error, requestURL: _url });
        }
      });
    }
  }, [_url, _body, refresh]);
  return [result, refreshService];
}
