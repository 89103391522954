import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import { useLinkerEditorPageState } from '../../../../context/linker/linker-editor-context';
import { EntityProps } from '../../../common/editor/acm-editor';
import { useLinkEntityStyle } from './generic-link-recommendation-entity';

export default function ExternalLinkRecommendationEntity(props: EntityProps) {
  const { decoratedText, entityKey } = props;
  const { editorState } = useLinkerEditorPageState();

  const tooltipTitle = useMemo(() => (
    editorState.getCurrentContent().getEntity(entityKey)?.getData()?.url ?? ''
  ), [entityKey]);

  return (
    <Tooltip
      placement="top"
      title={tooltipTitle}
    >
      <span style={useLinkEntityStyle(true, false)}>
        {decoratedText}
      </span>
    </Tooltip>
  );
}
