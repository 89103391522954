import { AlertColor } from '@mui/material/Alert/Alert';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REST_STATUS, RESTService } from '../../../api/hooks/rest-service';
import { ToastMessagesActionType, useToastMessagesDispatch } from '../../../context/toast-messages-context';

interface Props {
  service: RESTService<any>
  id: string
  loadingMessage?: string
  loadedMessage?: string
  errorMessage?: string
  noContentMessage?: string
  autoHideDuration?: number
  successAutoHideDuration?: number
}

export default function RestSnackbar(props: Props) {
  const {
    service,
    id,
    loadingMessage,
    loadedMessage,
    errorMessage,
    noContentMessage,
    autoHideDuration,
    successAutoHideDuration,
  } = props;
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useToastMessagesDispatch();
  const [serviceToDisplay, setServiceToDisplay] = useState<RESTService<any>>();

  const [restErrorMessage, setRestErrorMessage] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    if (service.status !== REST_STATUS.INIT) {
      setServiceToDisplay(service);
      setShowMessage(true);
    }
  }, [service]);

  useEffect(() => {
    if (serviceToDisplay?.status === REST_STATUS.ERROR) {
      setRestErrorMessage(serviceToDisplay.error.detailMessage);
    } else {
      setRestErrorMessage(undefined);
    }
  }, [serviceToDisplay]);

  const severity = useMemo<AlertColor>(() => {
    switch (serviceToDisplay?.status) {
      case REST_STATUS.LOADING:
        return 'info';
      case REST_STATUS.LOADED:
        return 'success';
      case REST_STATUS.ERROR:
        return 'error';
      case REST_STATUS.NO_CONTENT:
        return 'warning';
      default:
        return 'info';
    }
  }, [serviceToDisplay]);

  const message = useMemo<string>(() => {
    switch (serviceToDisplay?.status) {
      case REST_STATUS.LOADING:
        return loadingMessage || t('lo-processing');
      case REST_STATUS.LOADED:
        return loadedMessage || t('lo-done');
      case REST_STATUS.ERROR:
        return errorMessage || t('lo-error');
      case REST_STATUS.NO_CONTENT:
        return noContentMessage || t('lo-no-content');
      default:
        return '';
    }
  }, [serviceToDisplay]);

  let finalErrorMessage = message;
  let finalAutoHideDuration: number | undefined = autoHideDuration || 4000;
  if (serviceToDisplay?.status === REST_STATUS.LOADED && successAutoHideDuration) {
    finalAutoHideDuration = successAutoHideDuration;
  }
  if (restErrorMessage) {
    finalErrorMessage += `: ${restErrorMessage}`;
    finalAutoHideDuration = undefined; // never auto-hide detailed error messages
  }

  useEffect(() => {
    if (showMessage) {
      dispatch({
        type: ToastMessagesActionType.SHOW_TOAST_MESSAGE,
        payload: {
          id,
          message: finalErrorMessage,
          durationMs: finalAutoHideDuration,
          severity,
        },
      });
    }
  }, [showMessage, id, finalErrorMessage, finalAutoHideDuration, severity]);
  return null;
}

type MultiServicesProps = Omit<Props, 'service'> & { services: Array<Props['service']> };

export function RestSnackbarMultiple(props: MultiServicesProps) {
  const { services, ...rest } = props;
  return (
    <>
      {services.map((service, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <RestSnackbar service={service} key={idx} {...rest} />
      ))}
    </>
  );
}
