export enum FeatureFlag {
  // needs to be kept in sync with acm-backend/main/acm/apis/helper/feature_flags.py
  LINK_OPTIMIZER = 'LINK_OPTIMIZER',
  LINK_OPTIMIZER_ARTICLE_LINKING = 'LINK_OPTIMIZER_ARTICLE_LINKING',
  LINK_OPTIMIZER_PRODUCT_LINKING = 'LINK_OPTIMIZER_PRODUCT_LINKING',
  LINK_OPTIMIZER_AUTOMATIC_BUTTON = 'LINK_OPTIMIZER_AUTOMATIC_BUTTON',
  RECOMMENDATION_ENGINE = 'RECOMMENDATION_ENGINE',
  ISIN_SEARCH = 'ISIN_SEARCH',
}

export const ALL_FEATURE_FLAGS = Object.values(FeatureFlag).sort((a, b) => a.localeCompare(b));

export type FeatureFlags = { [key in FeatureFlag]: boolean }
