export type EditableList<T> = {
  current: T[]
  add: (newElement: T) => void
  remove: (element: T) => void
  toggle: (element: T) => void
  replace: (newElements: T[]) => void
}

/**
 * Wrap a state and a setter for an array of elements
 * to provide a simple combined interface for reading and modifying this list.
 */
export function useEditableList<T>(current: T[], replace: (newElements: T[]) => void): EditableList<T> {
  const add = (newElement: T) => {
    replace([...current, newElement]);
  };
  const remove = (element: T) => {
    replace(current.filter((e) => e !== element));
  };
  const toggle = (element: T) => {
    if (current.includes(element)) {
      remove(element);
    } else {
      add(element);
    }
  };

  return {
    current, add, remove, toggle, replace,
  };
}
