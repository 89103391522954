import { useTranslation } from 'react-i18next';
import { ArticleScoreResponse } from '../../../../api/interfaces/article';
import { ArticleLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import GenericLinkScoresTooltip from '../../combined-linker/recommendation-details/generic-link-scores-tooltip';

export interface RecommendationScoreProps {
  articleScores: ArticleScoreResponse
  profile?: ArticleLinkerProfileResponse
  label?: string
}
export function ArticleLinkScores(props: RecommendationScoreProps) {
  const {
    articleScores, profile, label,
  } = props;
  const { t } = useTranslation();

  return (
    <GenericLinkScoresTooltip
      mainScore={{
        scoreValue: articleScores.score,
        label,
      }}
      tooltipScoreDataList={[
        {
          scoreValue: articleScores.score_selection_to_target,
          label: t('lo-similarity-to-selection'),
          weight: profile?.link_parameters.selection_to_target_weight,
        },
        {
          scoreValue: articleScores.score_sentence_to_target,
          label: t('lo-similarity-to-sentence'),
          weight: profile?.link_parameters.sentence_to_target_weight,
        },
        {
          scoreValue: articleScores.score_source_to_target,
          label: t('lo-similarity-to-article'),
          weight: profile?.link_parameters.source_to_target_weight,
        },
        {
          scoreValue: articleScores.score_recency,
          label: t('lo-recency'),
          weight: profile?.link_parameters.date_weight,
        },
        {
          scoreValue: articleScores.score_retention_time,
          label: t('lo-retention-time'),
          weight: 0, // TODO: the retention time score has no weight slider ?
        },
      ]}
    />
  );
}
