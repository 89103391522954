import { LINKER_URL } from '../../../../constants/configs/urls';
import { StatisticsResponse } from '../../../interfaces/linkoptimizer-statistics';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetArticleLinkStatisticsService = (): RESTService<StatisticsResponse[]> => {
  const requestURL = `${LINKER_URL}/linker/statistics/articles`;
  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetArticleLinkStatisticsService;
