import ImageIcon from '@mui/icons-material/Image';
import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

interface Props {
    url?: string
}

export default function ImagePreview(props: Props) {
  const { url } = props;

  return (
    <Box sx={{
      width: '100px',
      height: '100px',
      backgroundColor: '#ddd',
    }}
    >
      <CardMedia
        image={url}
        sx={{
          width: '100px',
          height: '100px',
          position: 'absolute',
          zIndex: 1,
        }}
      />
      <ImageIcon
        color="disabled"
        sx={{
          width: '30px',
          height: '30px',
          marginLeft: '35px',
          marginTop: '35px',
        }}
      />
    </Box>
  );
}
