import {
  Box, Fade,
} from '@mui/material';
import {
  ActionButton, BaseTextInput, FlexBoxColumn, FlexBoxRow, propAnimation,
} from '@purple/react-components';
import { useCallback, useState } from 'react';
import { SortEnd } from 'react-sortable-hoc';
import { Keyword } from '../../../constants/interfaces/interfaces';
import SortableKeywordChips from '../../../theme/sprylab/components/keyword-chips';
import { EditableList } from '../../../util/use-editable-list';
import SidebarArea from '../common/sidebar-area';

interface Props {
  keywords: EditableList<string>
  keywordsAreSaving?: boolean
  coverageHits?: string[]
}

export default function SelectedKeywordsArea(props: Props) {
  const { keywords, keywordsAreSaving, coverageHits } = props;

  function handleSortEnd(sort: SortEnd) {
    keywords.current.splice(sort.newIndex, 0, keywords.current.splice(sort.oldIndex, 1)[0]);
    keywords.replace(keywords.current);
  }

  function handleDeleteKeyword(keyword: Keyword) {
    keywords.remove(keyword.name);
  }

  function handleSynonymReplacement(keyword: Keyword, replacement: string) {
    const keywordsWithReplacedSynonym = keywords.current.map((k) => (k === keyword.name ? replacement : k));
    keywords.replace(keywordsWithReplacedSynonym);
  }

  function addKeyword(newKeyword: string) {
    if (newKeyword.trim().length === 0) {
      return;
    }
    keywords.add(newKeyword);
  }

  return (
    <SidebarArea
      title={(
        <>
          Keywords Used
          {' '}
          <Fade in={keywordsAreSaving} timeout={{ appear: 500, enter: 500, exit: 0 }}>
            <span>
              <Box
                component="span"
                sx={{
                  animation: propAnimation('opacity', 0.5, 1, '0.5s'),
                  fontStyle: 'italic',
                }}
              >
                saving...
              </Box>
            </span>
          </Fade>
        </>
      )}
      helpText={keywords.current.length === 0 ? undefined : (
        <>
          Keywords at the top have a greater impact on coverage, and should be repeated.<br /><br />
          <b>Green:</b> Sufficient keyword coverage.<br />
          <b>Yellow:</b> Keyword appears, but more occurrences would improve SEO.<br />
          <b>Grey:</b> Keyword not found in the text.
        </>
      )}
    >
      <FlexBoxColumn gap="10px">
        <SortableKeywordChips
          axis="xy"
          keywords={keywords.current.map((keyword) => ({ name: keyword }))}
          coverageHits={coverageHits || []}
          distance={1}
          onSortEnd={handleSortEnd}
          onDelete={handleDeleteKeyword}
          handleSynonymReplacement={handleSynonymReplacement}
        />
        <NewKeywordInput onNewKeyword={addKeyword} />
      </FlexBoxColumn>
    </SidebarArea>
  );
}

function NewKeywordInput({ onNewKeyword }: { onNewKeyword: (keyword: string) => void }) {
  const [inputValue, setInputValue] = useState('');

  const submit = useCallback(() => {
    onNewKeyword(inputValue.trim());
    setInputValue('');
  }, [inputValue, onNewKeyword]);

  function onChange(input: string) {
    setInputValue(input.replace(/,/g, '').substring(0, maxLength));
  }

  const maxLength = 100; // TODO keep in sync with backend, see acm/apis/article_coverage_api.py /entities route
  return (
    <FlexBoxRow
      gap="10px"
      sx={{
        alignSelf: 'flex-start',
        m: '3px',
      }}
    >
      <BaseTextInput
        value={inputValue}
        setValue={onChange}
        placeholder="New Keyword"
        inputProps={{ maxLength }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        sx={{
          width: '150px',
        }}
      />
      <ActionButton
        onClick={submit}
        disabled={inputValue.length === 0}
      >
        Add
      </ActionButton>
    </FlexBoxRow>
  );
}
