import {
  Box, MenuItem, MenuList,
} from '@mui/material';
import List from '@mui/material/List';
import {
  PurpleAppLayout, PurpleDarkDivider, UnsavedHighlightFrame,
} from '@purple/react-components';
import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PageConfig from '../../constants/configs/pages';
import { ACMPrivatePage, ACMPublicPage } from '../../constants/interfaces/page';
import {
  AdminTabData, LinkOptimizerTabData, ReaderTabData,
} from '../../constants/statics/pageData';
import { useACMAppBarState } from '../../context/acm-app-bar-context';
import { ACMUserActionType, useACMUserDispatch, useACMUserState } from '../../context/acm-page-context';
import { AcmDrawerWidthPx } from '../../theme/style-utils';
import { inIframe } from '../../util/dom-utils';
import VersionNumberInACorner from '../common/version-number';
import AppBarCollapseGroup from './app-bar-collapse-group';
import AppBarItemGroup from './app-bar-item-group';
import AppBarListItem from './app-bar-list-item';

type Props = PropsWithChildren<{
  actionBar?: React.ReactNode
  hasUnsavedChanges?: boolean
}>

export default function AcmAppBarAndDrawer(props: Props) {
  const { actionBar, children, hasUnsavedChanges } = props;
  const { user, isAdmin, featureFlags } = useACMUserState();
  const { disable, hideHeader } = useACMAppBarState();
  const { t } = useTranslation();
  const userDispatch = useACMUserDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const hideDrawer = useMemo(() => (
    disable || inIframe() || pathname === PageConfig[ACMPublicPage.LOGIN].path
  ), [disable]);

  const handleACMIconClick = () => {
    navigate(PageConfig[ACMPrivatePage.DASHBOARD].path);
  };

  const handleLogoutClick = () => {
    userDispatch({ type: ACMUserActionType.LOGOUT });
  };

  const sidebarItems = (
    <>
      <div style={{ height: '10px' }} />
      {featureFlags?.LINK_OPTIMIZER && (
        <>
          <AppBarCollapseGroup label="Link Optimizer" items={LinkOptimizerTabData} />
          <PurpleDarkDivider />
        </>
      )}
      {featureFlags?.RECOMMENDATION_ENGINE && (
        <>
          <AppBarItemGroup items={ReaderTabData} />
          <PurpleDarkDivider />
        </>
      )}
      {isAdmin && (
        <>
          <AppBarCollapseGroup label="Admin Dashboard" items={AdminTabData} />
          <PurpleDarkDivider />
        </>
      )}
      <List>
        <AppBarListItem label={t('menu-logout')} onClick={handleLogoutClick} />
      </List>
      <Box height="50px" /* to make space below the last menu entry for the version number */ />
      <VersionNumberInACorner />
    </>
  );

  return (
    <PurpleAppLayout
      disableContentPadding
      drawerWidth={AcmDrawerWidthPx}
      showHeader={!hideHeader}
      showSidebar={!hideDrawer}
      sidebarItems={[sidebarItems]}
      iconClick={handleACMIconClick}
      onNavigate={navigate}
      userName={user?.name}
      menuItems={[]}
      userPopupContent={(
        <MenuList>
          <MenuItem onClick={handleLogoutClick}>
            Logout
          </MenuItem>
        </MenuList>
      )}
      additionalComponent={actionBar}
    >
      <UnsavedHighlightFrame
        hasUnsavedChanges={!!hasUnsavedChanges}
        borderWidthPx={4}
        sx={{ width: '100%', height: '100%' }}
      >
        {children}
      </UnsavedHighlightFrame>
    </PurpleAppLayout>
  );
}
