import { LINKER_URL } from '../../../../../constants/configs/urls';
import { ProductLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

const usePostProductLinkerProfileService = (profile?: ProductLinkerProfileResponse): RESTService<ProductLinkerProfileResponse> => {
  let requestURL;

  if (profile) {
    requestURL = `${LINKER_URL}/linker/profiles/products`;
  }

  const { id: _, ...profileWithoutId } = { ...profile };

  const [result] = usePostRequestService(requestURL, profileWithoutId);
  return result;
};

export default usePostProductLinkerProfileService;
