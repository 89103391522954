import Divider from '@mui/material/Divider';
import Grid, { GridDirection } from '@mui/material/Grid';
import { ArticleResponse } from '../../api/interfaces/article';
import ArticleCard, { ArticleCardProps } from './article-card';

type Props = {
  articles: ArticleResponse[]
  gridDirection: GridDirection
  columns?: number
  hideSeparator?: boolean
} & Omit<ArticleCardProps, 'article'>;

export default function ArticleList(props: Props) {
  const {
    articles,
    gridDirection,
    columns,
    hideSeparator,
    ...restProps
  } = props;
  const isColumnNotRow = gridDirection === 'column' || gridDirection === 'column-reverse';

  const actualColumnCount = isColumnNotRow ? 1 : (columns ?? articles.length);

  const columnWidth = `calc(100% / ${actualColumnCount})`;

  function showSeparatorAbove(index: number) {
    if (hideSeparator) return false;
    return index >= actualColumnCount; // yes for all entries in the second row and beyond
  }

  return (
    <Grid
      container
      direction={gridDirection}
      justifyContent="flex-start"
      alignItems="stretch"
      gap="8px"
    >
      {articles.map((article, index) => (
        <Grid item key={article.id} sx={{ width: columnWidth }}>
          {showSeparatorAbove(index) && (
            <Divider sx={{ mb: '8px' }} />
          )}
          <ArticleCard article={article} {...restProps} />
        </Grid>
      ))}
    </Grid>
  );
}
