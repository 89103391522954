import { CircularProgress, Typography } from '@mui/material';
import { FlexBoxColumn } from '@purple/react-components';
import { isRestServiceFinished, REST_STATUS, RESTService } from '../../api/hooks/rest-service';
import useDashboardCardStyles from './topic-dashboard-card-styles';

type Props<T> = {
  service: RESTService<T[]>
  emptyDataMessage?: string
  children: (item: T, index: number) => React.ReactNode
}

export default function DashboardCardContentList<T>(props: Props<T>) {
  const { service, children, emptyDataMessage } = props;
  const classes = useDashboardCardStyles();

  if (!isRestServiceFinished(service)) {
    return (
      <div className={classes.centeringWrapper}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  const data: T[] = service.status === REST_STATUS.LOADED ? service.payload : [];

  if (data.length === 0) {
    return (
      <div className={classes.centeringWrapper}>
        <Typography color="textPrimary">
          {emptyDataMessage ?? 'No data available.'}
        </Typography>
      </div>
    );
  }

  return (
    <FlexBoxColumn
      justifyContent="flex-start"
      alignItems="stretch"
      gap="10px"
      sx={{ pl: '20px', pr: '10px' }}
    >
      {data.map((entry: T, index) => children(entry, index))}
    </FlexBoxColumn>
  );
}
