import { PurpleBaseColors } from '@purple/react-components';

export const AcmExtraColors = {
  header: PurpleBaseColors.purpleDark,
  recommendation: PurpleBaseColors.neutralGray,
  recommendationDimmed: `${PurpleBaseColors.neutralGray}66`, // with a bit of alpha
  background: '#F0F1F5',
  articleLink: PurpleBaseColors.purple,
  productLink: PurpleBaseColors.purpleGrowthDarkBlue,
};

export const AcmDrawerWidthPx = 220;

export function upsideDownTransitionStyles(open: boolean) {
  return {
    transition: 'transform 0.3s ease-in-out',
    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
  };
}

export function rightDownTransitionStyles(open: boolean) {
  return {
    transition: 'transform 0.3s ease-in-out',
    transform: open ? 'rotate(0)' : 'rotate(-90deg)',
  };
}

export const StickyButtonStyle = {
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
} as const;

export const LINK_CARD_ANIMATION_MS = 500;

export function purpleCircleStyle(size: string) {
  return {
    borderRadius: '50%',
    border: `1px solid ${PurpleBaseColors.purpleDark}`,
    width: size,
    height: size,
  };
}
