import {
  Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { FlexBoxRow, PurpleBaseColors } from '@purple/react-components';
import {
  lazy, useEffect, useState, Suspense,
} from 'react';
import { REST_STATUS, RESTService } from '../../../api/hooks/rest-service';
import { ProfileItemResponse, StatisticsResponse } from '../../../api/interfaces/linkoptimizer-statistics';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import { generateUniqueCssColorString } from '../../../util/common-utils';

const Pie = lazy(() => import('react-chartjs-2').then((module) => ({ default: module.Pie })));

interface Props {
  title: string
  statisticsService: RESTService<StatisticsResponse[]>
}

export default function LinkOptimizerStatisticsTable(props: Props) {
  const { title, statisticsService } = props;
  const [statistics, setStatistics] = useState<StatisticsResponse[]>();

  const pieOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: true,
    responsive: false,
  };

  useEffect(() => {
    if (statisticsService.status === REST_STATUS.LOADED) {
      setStatistics(statisticsService.payload);
    }
  }, [statisticsService]);

  function formatPeriod(period: string) { // example: 2021-06
    const periodData = period.split('-');
    if (periodData.length !== 2) {
      return period;
    }
    // month starts with 0 in Date(), hence the -1
    const parsedPeriod = new Date(Number(periodData[0]), Number(periodData[1]) - 1, 1);
    const month = parsedPeriod.toLocaleString('default', { month: 'long' });
    return `${month} ${periodData[0]}`;
  }

  function getColorByLabel(label: string) {
    const fixedColors = {
      'Manual Links': 'rgba(0, 63, 92, 1)',
      'Generated Links': 'rgba(122, 81, 149, 1)',
      'Deleted Links': 'rgba(255, 166, 0, 1)',
      default: 'rgb(72,155,204)',
      Evergreen: 'rgb(66,159,15)',
      unknown: 'rgb(131,131,131)',
      custom: 'rgb(208,102,25)',
    };
    if (fixedColors[label]) return fixedColors[label];
    return generateUniqueCssColorString(label, 0.5, 0.5);
  }

  function renderStatisticsPie(labels: string[], data: number[]) {
    const backgroundColor = labels.map(getColorByLabel);
    const pie_data = {
      labels,
      datasets: [{
        data,
        borderWidth: 1,
        backgroundColor,
      }],
    };
    if (data.every((value) => value === 0)) {
      return <div style={{ height: '130px' }} />;
    }
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Pie data={pie_data} options={pieOptions} height={130} width={130} />
      </Suspense>
    );
  }

  function renderLinksPie(statistic: StatisticsResponse) {
    const labels = ['Manual Links', 'Generated Links', 'Deleted Links'];
    const data = [statistic.total_manual_links, statistic.total_generated_links, statistic.total_deleted_links];

    return renderStatisticsPie(labels, data);
  }

  function renderProfilesPie(profiles: ProfileItemResponse[]) {
    const labels: string[] = [];
    const data: number[] = [];

    for (const profile of profiles) {
      labels.push(profile.name);
      data.push(profile.total);
    }

    return renderStatisticsPie(labels, data);
  }

  return (
    <FlexBoxRow alignItems="stretch">
      <DataLoading service={statisticsService} errorMessage="Could not load link statistics">
        <Table
          stickyHeader
          sx={{ '& td': { p: '12px' } }}
        >
          <TableHead
            sx={{
              whiteSpace: 'nowrap',
              wordWrap: 'normal',
              '& th': {
                backgroundColor: PurpleBaseColors.neutralLightBlue,
              },
            }}
          >
            <TableRow>
              <TableCell align="center" colSpan={9999}>
                <Typography align="center" variant="h5" style={{ height: 30 }}>{title}</Typography>
              </TableCell>
            </TableRow>
            {statistics && statistics.length > 0 && (
              <TableRow>
                <TableCell align="center" style={{ top: 63 }}>
                  Period
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  <div>Total</div>
                  <div>Articles</div>
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  <div>Links</div>
                  <div>Total / Average</div>
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  <div>Manual Links</div>
                  <div>Total / Average</div>
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  <div>Generated Links</div>
                  <div>Total / Average</div>
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  <div>Deleted Links</div>
                  <div>Total / Average</div>
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  Links Distribution
                </TableCell>
                <TableCell align="center" style={{ top: 63 }}>
                  Profiles Distribution
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {statistics && statistics.map((statistic) => (
              <TableRow
                key={statistic.period}
                sx={{
                  '&:hover': {
                    backgroundColor: PurpleBaseColors.neutralLightBlue,
                  },
                }}
              >
                <TableCell align="center" data-testid="stats-cell-period">
                  {formatPeriod(statistic.period)}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-total-articles">
                  {statistic.total_articles}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-links">
                  {statistic.total_links} / {statistic.avg_links.toFixed(2)}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-manual-links">
                  {statistic.total_manual_links} / {statistic.avg_manual_links.toFixed(2)}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-generated-links">
                  {statistic.total_generated_links} / {statistic.avg_generated_links.toFixed(2)}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-deleted-links">
                  {statistic.total_deleted_links} / {statistic.avg_deleted_links.toFixed(2)}
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-links-distribution">
                  <div style={{ margin: 'auto', width: '130px' }}>
                    {renderLinksPie(statistic)}
                  </div>
                </TableCell>
                <TableCell align="center" data-testid="stats-cell-profiles-distribution">
                  <div style={{ margin: 'auto', width: '130px' }}>
                    {renderProfilesPie(statistic.profiles)}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {statistics && statistics.length === 0 && (
              <TableRow key="no-data">
                <TableCell align="center" colSpan={9999}>
                  <Typography align="center" variant="caption">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DataLoading>
    </FlexBoxRow>
  );
}
