import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { FrontendConfigurationDetailResponse } from '../../../interfaces/frontend-configuration';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetFrontendConfigService = (): RESTService<FrontendConfigurationDetailResponse> => {
  const requestURL = `${CORE_BACKEND_URL}/configurations`;

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetFrontendConfigService;
