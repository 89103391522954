import Typography from '@mui/material/Typography';
import { FlexBoxColumn, FlexBoxRow } from '@purple/react-components';
import { useEffect, useState } from 'react';
import { REST_STATUS, RESTService } from '../../api/hooks/rest-service';
import { ArticleResponse } from '../../api/interfaces/article';
import RestService from '../../api/rest.service';
import calcDateDifference from '../../util/date-utils';
import MuiRouterLink from '../util/MuiRouterLink';
import DashboardCard from './dashboard-card';
import DashboardCardContentList from './dashboard-card-content-list';
import useDashboardCardStyles from './topic-dashboard-card-styles';

/**
 * Last articles dashboard card. Displays the most recent 4 articles indexed by ACM.
 */
export default function RecentArticlesDashboardCard() {
  const [articles, setArticles] = useState<ArticleResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const classes = useDashboardCardStyles();

  useEffect(() => {
    RestService.getLastArticles(5)
      .then((response: ArticleResponse[]) => {
        setArticles(response);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const restService: RESTService<ArticleResponse[]> = {
    status: loading ? REST_STATUS.LOADING : REST_STATUS.LOADED,
    payload: articles,
  };

  return (
    <DashboardCard title="Recent Articles">
      <DashboardCardContentList service={restService} emptyDataMessage="No articles found.">
        {(article, index) => (
          <FlexBoxRow gap="12px" alignItems="center">
            <div className={classes.articlePerformanceBulletPoint}>{index + 1}</div>
            <MuiRouterLink
              to={article.link}
              title={article.title}
              target="_blank"
              sx={{ minWidth: '0' }}
              underline="hover"
            >
              <FlexBoxColumn sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.articlePerformanceSubtitle}
                  noWrap
                >
                  {article.title}
                </Typography>
                {article.published && (
                  <Typography variant="caption">
                    {calcDateDifference(article.published)}
                  </Typography>
                )}
              </FlexBoxColumn>
            </MuiRouterLink>
          </FlexBoxRow>
        )}
      </DashboardCardContentList>
    </DashboardCard>
  );
}
