import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { ArticleResponse } from '../../../interfaces/article';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetExternalArticlesService = (keywords: string[], limit: number = 6): RESTService<ArticleResponse[]> => {
  let requestURL;

  if (keywords.length) {
    requestURL = `${CORE_BACKEND_URL}/news/articles?limit=${limit}&keywords=${keywords.join()}`;
  }

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetExternalArticlesService;
