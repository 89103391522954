import {
  Typography,
  Box,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { FlexBoxRow, UnsavedHighlightFrame } from '@purple/react-components';
import * as React from 'react';
import { PropsWithChildren, ReactNode } from 'react';
import PageActionBars from './page-action-bars';

type Props = PropsWithChildren<{
  actionBar?: React.ReactElement
  title?: string
  subtitle?: string
  buttons?: ReactNode
  wide?: boolean
  noPadding?: boolean
  hasUnsavedChanges?: boolean
}>

export default function PageWithHeader(props: Props) {
  const {
    actionBar, title, subtitle, buttons, children, wide, noPadding, hasUnsavedChanges,
  } = props;

  const renderPageContent = (
    <FlexBoxRow
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
    >
      <Paper
        elevation={2}
        sx={{
          width: { xs: '100%', lg: wide ? '100%' : '75%' },
          height: '100%',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <UnsavedHighlightFrame
          hasUnsavedChanges={!!hasUnsavedChanges}
          sx={{
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: { xs: '8px', lg: '32px' },
            py: '20px',

            transition: 'padding 0.3s ease-in-out',
          }}
        >
          {title && (
            <Typography
              variant="h4"
              sx={{ maxHeight: '32px' }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="body1"
              sx={{ mt: '12px', maxHeight: '12px' }}
            >
              {subtitle}
            </Typography>
          )}
          {buttons && (
            <FlexBoxRow
              justifyContent="center"
              alignItems="center"
              gap="8px"
              sx={{
                maxHeight: '72px',
                mt: '48px',
              }}
            >
              {buttons}
            </FlexBoxRow>
          )}
          {(title || subtitle || buttons) && (
            <Divider
              sx={{
                my: (subtitle || buttons) ? '20px' : '12px',
                width: (subtitle || buttons) ? 'calc(100% - 120px)' : '100%',
              }}
            />
          )}
          <Box sx={{
            flexGrow: 1,
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            px: noPadding
              ? '0px'
              : wide
                ? '40px'
                : '80px',
          }}
          >
            {children}
          </Box>
        </UnsavedHighlightFrame>
      </Paper>
    </FlexBoxRow>
  );

  return (
    <PageActionBars
      actionBar={actionBar}
      pageContent={renderPageContent}
    />
  );
}
