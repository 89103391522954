import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FlexBoxColumn } from '@purple/react-components';
import { useEffect, useRef, useState } from 'react';

interface Props {
  feedbackFormUrl?: string
}

export default function FeedbackForm(props: Props) {
  const { feedbackFormUrl } = props;

  const [feedbackExpanded, setFeedbackExpanded] = useState(false);
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iFrameRef.current && !feedbackExpanded) {
      setTimeout(() => {
        if (iFrameRef.current) {
          // reload iframe content on close
          iFrameRef.current.src += '';
        }
      }, 500);
    }
  }, [feedbackExpanded]);

  function onFeedbackButtonClick() {
    setFeedbackExpanded(!feedbackExpanded);
  }

  if (!feedbackFormUrl) {
    return null;
  }

  return (
    <FlexBoxColumn
      alignItems="flex-end"
      sx={{
        position: 'fixed',
        right: '50px',
        bottom: feedbackExpanded ? '50px' : '-490px',
        zIndex: 2000,
        width: 'auto',
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          width: '150px',
          height: '28px',
          borderRadius: '2px',
        }}
      >
        <Button
          variant="outlined"
          onClick={onFeedbackButtonClick}
          sx={{
            width: '150px',
            height: '30px',
          }}
        >
          Feedback
        </Button>
      </Paper>
      <Paper
        elevation={feedbackExpanded ? 20 : 2}
        sx={{
          border: '2px solid #ccc',
        }}
      >
        {/* This iframe config is adapted from the microsoft forms embedding code */}
        <iframe
          ref={iFrameRef}
          title="Feedback Form"
          width="640px"
          height="480px"
          src={feedbackFormUrl}
          frameBorder="0"
          marginWidth={0}
          marginHeight={0}
          style={{
            border: 'none', maxWidth: '100%', maxHeight: '100vh', borderRadius: '2px',
          }}
          allowFullScreen
        />
      </Paper>
    </FlexBoxColumn>
  );
}
