import { Typography } from '@mui/material';
import { FlexBoxColumn, FlexBoxRow, HelpHoverButton } from '@purple/react-components';
import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  title: ReactNode
  helpText?: ReactNode
}>

export default function SidebarArea(props: Props) {
  const { title, helpText, children } = props;

  return (
    <FlexBoxColumn gap="20px" sx={{ mb: '20px' }}>
      <FlexBoxRow justifyContent="space-between">
        <Typography variant="body1">
          {title}
        </Typography>
        {helpText && (
          <HelpHoverButton hoverText={helpText} sx={{ p: 0 }} />
        )}
      </FlexBoxRow>
      {children}
    </FlexBoxColumn>
  );
}
