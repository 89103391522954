import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { LinkType } from '../../../../context/linker/combined-linker-context';
import { UserNameResponse } from '../../../interfaces/user';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetLinkOptimizerUserNames = (linkType: LinkType): RESTService<UserNameResponse[]> => {
  const requestURL = `${CORE_BACKEND_URL}/configurations/link-optimizer-enabled/${linkType}`;
  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetLinkOptimizerUserNames;
