import { Card, CardHeader } from '@mui/material';

interface Props {
  title: string
  value: number | undefined
}

export default function ValueDisplayer(props: Props) {
  const { title, value } = props;
  return (
    <Card variant="outlined">
      <CardHeader
        title={(
          <>
            {title}<span style={{ float: 'right' }}>{value && Math.round(value * 100) / 100}</span>
          </>
        )}
      />
    </Card>
  );
}
