import AddIcon from '@mui/icons-material/Add';
import Anchor from '@mui/icons-material/Anchor';
import CloudDownload from '@mui/icons-material/CloudDownload';
import CloudUpload from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import SettingsIcon from '@mui/icons-material/Settings';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import { Box, Paper } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import {
  ActionButton, PrimaryButton, PurpleBaseColors,
} from '@purple/react-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageResponse, UserResponse } from '../../api/interfaces/user';
import RestService from '../../api/rest.service';
import AcmAppBarAndDrawer from '../app-bar/acm-app-bar-and-drawer';
import AdminAction from './admin-action';
import { FeatureFlagInfoTooltip } from './feature-flag-info';
import NewCustomer from './new-customer';

/**
 * The customer dashboard displays a list of current customers for the admin and modification tools.
 */
export default function CustomerTable() {
  const [customers, setCustomers] = useState<UserResponse[]>([]);
  const [newCustomerDialogOpen, setNewCustomerDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [snackbarMessage, setSnackbarMessage] = useState('Customer setup started...');
  const [disabledButtons, setDisabledButtons] = useState<Set<string>>(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  /**
   * The list of buttons an admin can click to administer a customer profile
   */
  const adminActions: AdminAction[] = [
    new AdminAction(
      'Edit',
      <EditIcon />,
      (user) => navigate(`/admin/customer/detail/${user.public_id}`), // using "window.location.href = ..." directly can be problematic together with the react-router
      false,
      PurpleBaseColors.purpleGrowthBlue,
    ),
    new AdminAction(
      'Delete',
      <DeleteIcon />,
      (user) => RestService.deleteCustomer(user).then(() => getCustomers()),
      true,
      PurpleBaseColors.error,
    ),
    new AdminAction(
      'Setup',
      <SettingsIcon />,
      (user) => bootstrapCustomer(user),
    ),
    new AdminAction(
      'Pull Index',
      <SystemUpdateIcon />,
      (user) => RestService.pullIndexCustomer(user),
    ),
    new AdminAction(
      'Regenerate Article Categories List',
      <ReplayIcon />,
      (user) => RestService.computeCategories(user),
    ),
    new AdminAction(
      'Anchor Text Extraction',
      <Anchor />,
      (user) => RestService.anchorTextExtraction(user),
    ),
    new AdminAction(
      'Backup: Export',
      <CloudUpload />,
      (user) => RestService.backupExport(user),
    ),
    new AdminAction(
      'Backup: Import',
      <CloudDownload />,
      (user) => RestService.backupImport(user),
    ),
  ];

  function executeAction(action: AdminAction, customer: UserResponse) {
    setDisabledButtons((prev) => new Set(Array.from(prev)).add(customer.public_id));
    const enableButtonAgain = () => setDisabledButtons((prev) => new Set(Array.from(prev).filter((id) => id !== customer.public_id)));

    action.execute(customer).then((response) => {
      if (response !== AdminAction.ABORT_TOKEN && action.showPopups) {
        const responseString = JSON.stringify(response, null, 2);
        alert(`Action "${action.label}" executed successfully for user "${customer.name}"\n\n${responseString}`);
      }
      enableButtonAgain();
    }).catch((...args) => {
      const errorString = JSON.stringify(args, null, 2);
      alert(`Error executing action ${action.label}.\n\n${args[0]?.message}\n${args[0]?.detailMessage}\n\n${errorString}`);
      enableButtonAgain();
    });
  }

  async function bootstrapCustomer(user: UserResponse) {
    return RestService.setupCustomer(user)
      .then((response: MessageResponse) => {
        if (response) {
          setSnackbarSeverity('info');
          setSnackbarMessage('Customer setup started...');
          setSnackbarOpen(true);
          getUserSetupStatus(user);
        }
      });
  }

  function getUserSetupStatus(user: UserResponse) {
    setTimeout(() => {
      RestService.setupCustomerStatus(user)
        .then((response: MessageResponse) => {
          if (response.message === 'User setup running') {
            setSnackbarSeverity('info');
            setSnackbarMessage('Customer setup running...');
            setSnackbarOpen(true);
            getUserSetupStatus(user);
          } else {
            setSnackbarSeverity('success');
            setSnackbarMessage('Customer setup completed!');
            setSnackbarOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setSnackbarSeverity('error');
          setSnackbarMessage('Something went wrong during customer setup. Maybe try again?');
          setSnackbarOpen(true);
        });
    }, 60000); // TODO(pg): we need to be sure that XXX-content on the Airflow side is created
  }

  function getCustomers() {
    RestService.getAllCustomers()
      .then((customersResponse: UserResponse[]) => {
        setCustomers(customersResponse);
      })
      .catch(console.error);
  }

  function handleSnackbarClose() {
    setSnackbarOpen(false);
  }

  return (
    <AcmAppBarAndDrawer>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <Table stickyHeader id="customers" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Features</TableCell>
              {adminActions.map((action) => (
                <TableCell
                  key={action.label}
                  align="center"
                  sx={{ fontSize: action.label.split(' ').length >= 3 ? '11px' : undefined }}
                >
                  {action.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow
                key={customer.public_id}
                hover
                sx={{
                  backgroundColor: 'white',
                  '&:nth-of-type(odd)': {
                    filter: 'brightness(97%)',
                  },
                  '&.MuiTableRow-root:hover': {
                    backgroundColor: PurpleBaseColors.neutralLightBlue,
                  },
                }}
              >
                <TableCell sx={{ fontFamily: 'monospace', fontSize: '90%' }}>{customer.public_id}</TableCell>
                <TableCell>
                  <span style={{ fontWeight: 'bold' }}>{customer.name}</span>
                  <br />
                  <span style={{ fontFamily: 'monospace', fontSize: '90%' }}>{customer.username}</span>
                </TableCell>
                <TableCell sx={{ fontSize: '80%' }}>{customer.role}</TableCell>
                <TableCell><FeatureFlagInfoTooltip user={customer} /></TableCell>
                {adminActions.map((action) => (
                  <TableCell key={action.label} align="center">
                    {disabledButtons.has(customer.public_id) && <CircularProgress size="1em" />}
                    <Tooltip title={action.label} placement="top">
                      <div>
                        <ActionButton
                          icon={action.icon}
                          disabled={disabledButtons.has(customer.public_id)}
                          onClick={() => executeAction(action, customer)}
                          textAndBorderColor={action.color}
                        >
                          {action.shortLabel}
                        </ActionButton>
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* so that the feature flags of the last customer can also be read: */}
        <Box height="300px" />
      </Paper>
      <PrimaryButton
        floating
        sx={{
          position: 'absolute',
          bottom: '32px',
          right: '32px',
          width: '64px',
          height: '64px',
        }}
        onClick={() => setNewCustomerDialogOpen(true)}
      >
        <AddIcon />
      </PrimaryButton>
      <NewCustomer
        dialogOpen={newCustomerDialogOpen}
        setDialogOpen={setNewCustomerDialogOpen}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </AcmAppBarAndDrawer>
  );
}
