import { LINKER_URL } from '../../../../../constants/configs/urls';
import { useACMUserState } from '../../../../../context/acm-page-context';
import { ProductLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import { CombinedRecommendationsResponse } from '../../../../interfaces/recommendation';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

const usePostCombinedProductLinksRecommendationService = (
  articleId: string | undefined,
  profile: ProductLinkerProfileResponse | undefined,
  removedLinkRecommendations: CombinedRecommendationsResponse | undefined,
): RESTService<CombinedRecommendationsResponse> => {
  const { customerToken } = useACMUserState();
  let requestURL;

  if (profile && articleId) {
    const profileId = `?profile_id=${profile.id}`;

    requestURL = `${LINKER_URL}/linker/combined_links/products/${articleId}/generate${profileId}`;

    if (customerToken) {
      requestURL += `&api_token=${customerToken}`;
    }
  }

  const [result] = usePostRequestService(requestURL, {
    deleted_link_ids: removedLinkRecommendations?.product_links.map((l) => l.id),
  });
  return result;
};

export default usePostCombinedProductLinksRecommendationService;
