/** A Promise where you can call resolve and reject from the outside */
export default class ManualPromise<T> extends Promise<T> {
  public readonly resolve: (value: T) => void;

  public readonly reject: (reason?: any) => void;

  constructor(
    executor?: (
            resolve: (value: T) => void,
            reject: (reason?: any) => void,
        ) => void,
  ) {
    let resolve: (value: T) => void;
    let reject: (reason?: any) => void;
    super((res, rej) => {
      resolve = res;
      reject = rej;
      executor?.(res, rej);
    });
    this.resolve = resolve!;
    this.reject = reject!;
  }
}
