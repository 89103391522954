import { CardActionArea } from '@mui/material';
import { PurpleBaseColors, sxMerge } from '@purple/react-components';
import { ComponentProps } from 'react';
import RouterCardActionArea from '../util/RouterCardActionArea';

type Props = Omit<ComponentProps<typeof RouterCardActionArea>, 'to'> & {
  to?: string
}

export default function RoundedActionCard(props: Props) {
  const {
    to, title, sx: propsSx, ...restProps
  } = props;

  const sx = sxMerge({
    transition: 'border-color 0.1s ease-in-out',
    border: '2px solid transparent',
    '&:hover': {
      borderColor: PurpleBaseColors.neutralGray,
    },
    borderRadius: '6px',
  }, propsSx);

  if (to) {
    return (
      <RouterCardActionArea
        to={to}
        title={title}
        sx={sx}
        {...restProps}
      />
    );
  } else {
    return (
      <CardActionArea
        sx={sx}
        {...restProps}
      />
    );
  }
}
