import AutoRenewIcon from '@mui/icons-material/Autorenew';
import Typography from '@mui/material/Typography';
import { ActionIconButton, FlexBoxRow, GridBox } from '@purple/react-components';
import { Fragment, useEffect, useState } from 'react';
import useGetSynonyms from '../../../api/hooks/core/article-coverage/get-synonyms';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import { SynonymResponse } from '../../../api/interfaces/article';
import { Keyword } from '../../../constants/interfaces/interfaces';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import SidebarInfoText from '../common/SidebarInfoText';

const SLICE_SYNONYMS = 8;

interface Props {
  keyword: Keyword
  onReplace?: (replacement: string) => void
}

export default function Synonyms(props: Props) {
  const { keyword, ...restProps } = props;
  const [synonymsResponse, setSynonymsResponse] = useState<SynonymResponse>();

  const getSynonymsService = useGetSynonyms(keyword.name);

  useEffect(() => {
    if (getSynonymsService.status === REST_STATUS.LOADED) {
      setSynonymsResponse(getSynonymsService.payload);
    }
  }, [getSynonymsService]);

  return (
    <DataLoading
      service={getSynonymsService}
      errorMessage="Couldn't load synonyms"
    >
      <div style={{ padding: '10px' }}>
        {(!synonymsResponse || synonymsResponse.synonyms.length === 0) ? (
          <SidebarInfoText>
            No synonyms available
          </SidebarInfoText>
        ) : (
          <SynonymsDisplay keyword={keyword} synonyms={synonymsResponse} {...restProps} />
        )}
      </div>
    </DataLoading>
  );
}

function SynonymsDisplay(props: Props & {synonyms: SynonymResponse}) {
  const {
    keyword, synonyms, onReplace,
  } = props;

  function handleReplacementClick(replacement: string) {
    onReplace?.(replacement);
  }

  const synonymRows = synonyms.synonyms.map((synonym) => {
    const isCurrentOne = synonym === keyword.name;

    return (
      <Fragment key={synonym}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 'normal' }}
        >
          {synonym}
        </Typography>
        <FlexBoxRow
          alignItems="center"
          justifyContent="flex-end"
        >
          {onReplace && (
            <ActionIconButton
              size="large"
              onClick={() => handleReplacementClick(synonym)}
              disabled={isCurrentOne}
              sx={{
                width: '42px',
                height: '24px',
                margin: '0px 0px 5px 5px',
                float: 'right',
              }}
            >
              <AutoRenewIcon sx={{ height: '18px' }} />
            </ActionIconButton>
          )}
        </FlexBoxRow>
      </Fragment>
    );
  });

  return (
    <GridBox gridTemplateColumns="1, 1">
      <Typography
        variant="overline"
        sx={{
          gridColumn: '1 / span 2',
          margin: '0px 5px 5px 0px',
          display: 'inline-block',
        }}
      >
        Synonyms:
      </Typography>
      {synonymRows.slice(0, SLICE_SYNONYMS)}
    </GridBox>
  );
}
