import { useEffect, useState } from 'react';
import RestService from '../../../api/rest.service';

const ArticleContentByIdCache = new Map<string, string>();

export default function useLazyArticleContentLoader(articleId: string | undefined): string {
  const [content, setContent] = useState('Loading...');

  useEffect(() => {
    if (articleId) {
      const cachedResponse = ArticleContentByIdCache.get(articleId);
      if (cachedResponse) {
        setContent(cachedResponse);
      } else {
        RestService.getArticleById(articleId).then((article) => {
          ArticleContentByIdCache.set(articleId, article.content);
          setContent(article.content);
        });
      }
    }
  }, [articleId]);

  return content;
}
