import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { EntityResponse } from '../../../interfaces/entity';
import usePostRequestService from '../../_common/requests/post-request';
import { RESTService } from '../../rest-service';

const useGetEntitiesByTextService = (text?: string): RESTService<EntityResponse[]> => {
  let requestURL;

  if (text) {
    requestURL = `${CORE_BACKEND_URL}/article-coverage/entities`;
  }

  const [result] = usePostRequestService(requestURL, { text });
  return result;
};

export default useGetEntitiesByTextService;
