import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { FeatureFlags } from '../../../interfaces/feature-flags';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetUserFeatureFlagsService = (publicId?: string): RESTService<FeatureFlags> => {
  let requestURL;

  if (publicId) {
    requestURL = `${CORE_BACKEND_URL}/users/${publicId}/feature_flags`;
  }

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetUserFeatureFlagsService;
