export interface ArticleLinkerProfileResponse {
  id: string
  name: string
  is_default: boolean
  article_parameters: ArticleLevelParametersBody
  link_parameters: ArticleLinkParametersBody
}

export interface ArticleLevelParametersBody {
  avg_links_per_paragraph: number
  preferred_selection_length: number
  selection_length_weight: number
  articles_per_selection: number
  selection_to_source_weight: number
  include_generic_products: boolean
}

export enum PremiumFilter {
  ALL = 'all',
  PREMIUM = 'premium',
  FREE = 'free',
}

export interface ArticleLinkParametersBody {
  date_range: number
  date_weight: number
  selection_to_target_weight: number
  sentence_to_target_weight: number
  source_to_target_weight: number
  target_confidence_weight: number
  categories: string[]
  categories_weight: number
  premium: PremiumFilter
  allow_heavy_computations: boolean
}

export interface ProductLinkParametersBody {
  category_id?: string
  minimum_price_cents: number
  maximum_price_cents: number
  minimum_user_rating_stars: number
  prime_only: boolean
  associate_tag?: string
}

export interface ProductLinkerProfileResponse {
  id: string
  name: string
  is_default: boolean
  article_parameters: ArticleLevelParametersBody
  link_parameters: ProductLinkParametersBody
}

export type GenericLinkerProfileResponse = ArticleLinkerProfileResponse | ProductLinkerProfileResponse;

export const DefaultArticleLinkerProfile: ArticleLinkerProfileResponse = {
  id: '',
  name: 'default',
  is_default: false,
  // Keep values in sync with backend: acm-backend/main/acm/persistence/mongo/documents/linker/abstract.py
  article_parameters: {
    avg_links_per_paragraph: 0.5,
    preferred_selection_length: 3,
    selection_length_weight: 50,
    articles_per_selection: 10,
    selection_to_source_weight: 50,
    include_generic_products: false,
  },
  // Keep values in sync with backend: acm-backend/main/acm/persistence/mongo/documents/linker/article_links.py
  link_parameters: {
    date_range: 0,
    date_weight: 50,
    selection_to_target_weight: 60, // Sum of 3 similarity weights must be 100
    sentence_to_target_weight: 30,
    source_to_target_weight: 10,
    categories: [],
    categories_weight: 100,
    premium: PremiumFilter.ALL,
    allow_heavy_computations: false,
    target_confidence_weight: 40,
  },
};

export const DefaultProductLinkerProfile: ProductLinkerProfileResponse = {
  id: '',
  name: 'default',
  is_default: false,
  // Keep values in sync with backend: acm-backend/main/acm/persistence/mongo/documents/linker/abstract.py
  article_parameters: {
    avg_links_per_paragraph: 0.5,
    preferred_selection_length: 3,
    selection_length_weight: 50,
    articles_per_selection: 10,
    selection_to_source_weight: 50,
    include_generic_products: false,
  },
  // Keep values in sync with backend: acm-backend/main/acm/persistence/mongo/documents/linker/product_links.py
  link_parameters: {
    category_id: undefined,
    minimum_price_cents: 5,
    maximum_price_cents: 0,
    minimum_user_rating_stars: 3,
    prime_only: false,
    associate_tag: undefined,
  },
};
