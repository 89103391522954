import { Dropdown } from '@purple/react-components';
import { useMemo } from 'react';
import { GenericLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import {
  CombinedLinkerAction,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import { HEADER_CONTROLS_HEIGHT } from '../header-controls';

type Props<P> = {
  profiles: Map<string | undefined, P>
  selectedProfileId: string | undefined
  profileChangeAction: CombinedLinkerAction.SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID | CombinedLinkerAction.SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID | CombinedLinkerAction.SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID | CombinedLinkerAction.SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID
  testId: string
}

/**
 * Component for customizing the link optimizer request profile.
 */
function LinkerProfileSelector<P extends GenericLinkerProfileResponse>(props: Props<P>) {
  const {
    profiles, selectedProfileId, profileChangeAction, testId,
  } = props;
  const dispatch = useCombinedLinkerPageDispatch();

  const profilesArray = useMemo(() => (
    Array.from(profiles.values())
  ), [profiles]);

  const handleProfileChange = (newProfile: string) => dispatch({ type: profileChangeAction, payload: newProfile });

  const profileOptions = profilesArray.map((profile) => ({ value: profile.id, label: profile.name }));

  return (
    <Dropdown
      options={profileOptions}
      value={`${selectedProfileId}`} // value must be a string for initial display to work
      setValue={handleProfileChange}
      data-testid={testId}
      sx={{
        minWidth: '170px',
        backgroundColor: 'white',
        borderRadius: '100px',
        height: HEADER_CONTROLS_HEIGHT,
      }}
    />
  );
}

function LinkerProfileSelectorGlobal<P extends GenericLinkerProfileResponse>(props: Omit<Props<P>, 'testId'>) {
  return <LinkerProfileSelector testId="global-profile-selector" {...props} />;
}

function LinkerProfileSelectorPhrase<P extends GenericLinkerProfileResponse>(props: Omit<Props<P>, 'testId'>) {
  return <LinkerProfileSelector testId="phrase-profile-selector" {...props} />;
}

export function ArticleLinkerProfileSelector() {
  const { selectedGlobalArticleProfileId } = useCombinedLinkerPageState();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  return (
    <LinkerProfileSelectorGlobal
      profiles={articleLinkerProfiles}
      selectedProfileId={selectedGlobalArticleProfileId}
      profileChangeAction={CombinedLinkerAction.SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID}
    />
  );
}

export function ProductLinkerProfileSelector() {
  const { selectedGlobalProductProfileId } = useCombinedLinkerPageState();
  const { productLinkerProfiles } = useLinkerProfilesState();
  return (
    <LinkerProfileSelectorGlobal
      profiles={productLinkerProfiles}
      selectedProfileId={selectedGlobalProductProfileId}
      profileChangeAction={CombinedLinkerAction.SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID}
    />
  );
}

export function ArticleLinkerPhraseProfileSelector() {
  const { selectedPhraseArticleProfileId } = useCombinedLinkerPageState();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  return (
    <LinkerProfileSelectorPhrase
      profiles={articleLinkerProfiles}
      selectedProfileId={selectedPhraseArticleProfileId}
      profileChangeAction={CombinedLinkerAction.SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID}
    />
  );
}

export function ProductLinkerPhraseProfileSelector() {
  const { selectedPhraseProductProfileId } = useCombinedLinkerPageState();
  const { productLinkerProfiles } = useLinkerProfilesState();
  return (
    <LinkerProfileSelectorPhrase
      profiles={productLinkerProfiles}
      selectedProfileId={selectedPhraseProductProfileId}
      profileChangeAction={CombinedLinkerAction.SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID}
    />
  );
}
