import { Theme, Typography, useMediaQuery } from '@mui/material';
import { GridBox } from '@purple/react-components';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export default function BlocklistTab(props: PropsWithChildren) {
  const { children } = props;
  const { t } = useTranslation();

  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <GridBox
      gridTemplateColumns="1fr 1fr"
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        gap: smallScreen ? '8px' : '24px',
        transition: 'gap 0.3s ease-in-out',
      }}
    >
      {children}
      <Typography
        variant="caption"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('lo-blocklist-caption-info')}
        <br />
        {t('lo-blocklist-caption-action')}
      </Typography>
    </GridBox>
  );
}
