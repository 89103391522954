import { ArticleResponse, ArticleScoreResponse } from './article';

export interface CombinedRecommendationsResponse {
  content: string
  article_links: ArticleLinkRecommendationResponse[]
  article_profile_id: string
  product_links: ProductLinkRecommendationResponse[]
  product_profile_id: string
  created: string
  modified: string
}

export interface ArticleRecommendationsResponse {
  content: string
  links: ArticleLinkRecommendationResponse[]
  profile_id: string
}

export interface ProductRecommendationsResponse {
  content: string
  links: ProductLinkRecommendationResponse[]
  profile_id: string
  created: string
  modified: string
}

export interface ArticleLinkRecommendationResponse {
  articles: ArticleScoreResponse[]
  id: string
  index_paragraph: number
  keyword: string
  profile_id: string
  occurrence_in_paragraph: number
  score: number
  score_articles: number | null
  score_length: number | null
  score_selection_to_source: number | null
  selected_suggestion_id: string
  status: RecommendationStatusType
  reason: string
  origin: RecommendationOriginType
  text_selection: string
}

export interface ProductLinkRecommendationResponse {
  id: string
  index_paragraph: number
  keyword: string
  occurrence_in_paragraph: number
  products: ProductScoreResponse[]
  profile_id: string
  reason: string
  score: number
  score_articles: number
  score_selection_to_source: number
  selected_suggestion_id: string
  status: RecommendationStatusType
  origin: RecommendationOriginType
  text_selection: string
}

export type GenericLinkRecommendationResponse = ArticleLinkRecommendationResponse | ProductLinkRecommendationResponse;

export interface ProductScoreResponse {
  asin: string
  price: number
  customer_reviews_count: number
  customer_reviews_star_rating: number
  title: string
  categories: string[]
  description: string
  id: string
  image_url: string
  link: string
  score: number
  source: {
    href: string
    id: string
    title: string
  }
  suggested_by: string
}

export enum RecommendationStatusType {
  PUBLISHED = 'published',
  DELETED = 'deleted',
}

export enum RecommendationOriginType {
  EXTERNAL = 'external',
  GENERATED = 'generated',
  MANUAL = 'manual',
}

export enum DeletionReasonType {
  BAD_SELECTION = 'bad_selection',
  BAD_SELECTION_IN_CONTEXT = 'bad_selection_in_context',
  BAD_RESULTS = 'bad_results',
  NONE = 'none'
}

export interface ContentRecommendationResponse {
  title?: string
  experiment: string
  variation: string
  items: ArticleResponse[]
}

export interface RecommendationWeights {
  source_target: number
  recency: number
  retention_time: number
}
