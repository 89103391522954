import * as React from 'react';
import { DefaultArticleLinkerProfile, DefaultProductLinkerProfile } from '../../../../api/interfaces/linker-profile';
import {
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import DataLoading from '../../../../theme/sprylab/components/data-loading';
import useTimeEstimate from '../../generic/timeEstimation';

export function DataLoadingPostArticleLinks(props: {children: React.ReactNode}) {
  const { children } = props;
  const { articleLinkerProfiles } = useLinkerProfilesState();
  const { articleId, selectedGlobalArticleProfileId } = useCombinedLinkerPageState();
  const services = useCombinedLinkerPageService();
  const postLinksRecommendationService = services[articleId].postArticleLinksRecommendationService;

  const selectedGlobalProfile = articleLinkerProfiles.get(selectedGlobalArticleProfileId);

  const timeEstimate = useTimeEstimate({
    timePerWord: 0.01, // roughly one second per 100 words (NER, candidates, scoring, final greedy link selection, ...)
    timePerLink: 0.25, // we can do about 4 links per second, mostly restricted by the elastic queries (see also results in DEEP-175)
  }, selectedGlobalProfile || DefaultArticleLinkerProfile);

  return (
    <DataLoading
      service={postLinksRecommendationService}
      errorMessage="Couldn't generate recommendations"
      expectedWaitTimeSeconds={timeEstimate}
    >
      {children}
    </DataLoading>
  );
}

export function DataLoadingPostProductLinks(props: { children: React.ReactNode }) {
  const { children } = props;
  const { productLinkerProfiles } = useLinkerProfilesState();
  const { articleId, selectedGlobalProductProfileId } = useCombinedLinkerPageState();
  const services = useCombinedLinkerPageService();
  const postLinksRecommendationService = services[articleId].postProductLinksRecommendationService;

  const selectedGlobalProfile = productLinkerProfiles.get(selectedGlobalProductProfileId);

  const timeEstimate = useTimeEstimate({
    timePerWord: 0.002, // roughly one second per 500 words (NER, candidates, scoring, ...)
    timePerLink: 1.3, // one second per link (PAAPI requests) and a bit more
  }, selectedGlobalProfile || DefaultProductLinkerProfile);

  return (
    <DataLoading
      service={postLinksRecommendationService}
      errorMessage="Couldn't generate recommendations"
      expectedWaitTimeSeconds={timeEstimate}
    >
      {children}
    </DataLoading>
  );
}
