import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { UserResponse } from '../../../interfaces/user';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetUserDataService = (publicId?: string): RESTService<UserResponse> => {
  let requestURL;

  if (publicId) {
    requestURL = `${CORE_BACKEND_URL}/users/${publicId}`;
  }

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetUserDataService;
