import { useState } from 'react';

/**
 * Use this hook to force a component to rerender.
 * Example usage:
 * ```ts
 * const rerender = useRerender();
 * useEffect(() => (
 *     setTimeout(rerender, 1000)
 * ), [deps]);
 * ```
 */
export default function useRerender(): () => void {
  const [, setTick] = useState(0);
  return () => setTick((tick) => tick + 1);
}
