import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { SynonymResponse } from '../../../interfaces/article';
import usePostRequestService from '../../_common/requests/post-request';
import { RESTService } from '../../rest-service';

const useGetSynonyms = (keyword?: string): RESTService<SynonymResponse> => {
  let requestURL;

  if (keyword) {
    requestURL = `${CORE_BACKEND_URL}/article-coverage/synonyms`;
  }

  const [result] = usePostRequestService(requestURL, { keyword }, keyword);
  return result;
};

export default useGetSynonyms;
