import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PurpleBaseColors } from '@purple/react-components';
import { useEffect, useState } from 'react';
import useGetUserFeatureFlagsService from '../../api/hooks/core/users/get-feature-flags';
import { REST_STATUS } from '../../api/hooks/rest-service';
import { ALL_FEATURE_FLAGS, FeatureFlags } from '../../api/interfaces/feature-flags';
import { UserResponse } from '../../api/interfaces/user';
import DataLoading from '../../theme/sprylab/components/data-loading';

interface Props {
  user: UserResponse
}

export default function FeatureFlagInfo(props: Props) {
  const { user } = props;
  const featureFlagService = useGetUserFeatureFlagsService(user.public_id);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();

  useEffect(() => {
    if (featureFlagService.status === REST_STATUS.LOADED) {
      setFeatureFlags(featureFlagService.payload);
    }
  }, [featureFlagService]);

  const disabledFlags = ALL_FEATURE_FLAGS.filter((flag) => !featureFlags?.[flag]);
  const enabledFlags = ALL_FEATURE_FLAGS.filter((flag) => !disabledFlags.includes(flag));

  function flagListInfo(flags: string[]) {
    if (flags.length <= 0) {
      return <p>(none)</p>;
    }
    return (
      <ul>
        {flags.map((flag) => (
          <li key={flag}>{flag}</li>
        ))}
      </ul>
    );
  }

  return (
    <DataLoading
      service={featureFlagService}
      errorMessage="Cannot access feature flags! (Invalid Configuration?)"
    >
      <div>
        <h1>Feature Flags:</h1>
        <h2>Enabled:</h2>
        {flagListInfo(enabledFlags)}
        <h2>Disabled:</h2>
        {flagListInfo(disabledFlags)}
      </div>
    </DataLoading>
  );
}

export function FeatureFlagInfoTooltip(props: Props) {
  return (
    <Tooltip
      title={<FeatureFlagInfo {...props} />}
      placement="right-start"
    >
      <span>
        <IconButton
          size="large"
          style={{ cursor: 'default' }}
        >
          <FlagIcon sx={{ color: PurpleBaseColors.purpleLight }} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
