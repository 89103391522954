import { stateFromHTML } from '@sprylab/draft-js-import-html';
import * as Draft from 'draft-js';
import * as React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import useGetArticleByIdService from '../../api/hooks/core/articles/get-article-by-id';
import { REST_STATUS, RESTService } from '../../api/hooks/rest-service';
import { ArticleByIdResponse } from '../../api/interfaces/article';
import EditorHelper from '../../components/common/editor/editor-helper';
import { SelectedPhraseData } from '../../constants/interfaces/interfaces';
import { articleToHtml } from '../../util/article';
import clearWindowSelection from '../../util/editor';
import { assertNever } from '../../util/reducer-utils';
import { ToastMessagesActionType, useToastMessagesDispatch } from '../toast-messages-context';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface LinkerEditorPageState {
  articleId: string
  articleById?: ArticleByIdResponse
  editorState: Draft.EditorState
  editorStateHtml?: string
  phraseSelection?: SelectedPhraseData
}

interface Props {
  articleId?: string
  postType?: string
  children: React.ReactNode
}

interface LinkerEditorService {
  [articleId: string]: RESTService<ArticleByIdResponse>
}

export enum LinkerEditorAction {
  SET_ARTICLE_ID,
  SET_ARTICLE_BY_ID,
  SET_EDITOR_STATE_HTML,
  SET_PHRASE_SELECTION,
  CLEAR_PHRASE_SELECTION,
}

type Action =
  | { type: LinkerEditorAction.SET_EDITOR_STATE_HTML, payload: string }
  | { type: LinkerEditorAction.SET_ARTICLE_ID, payload: string }
  | { type: LinkerEditorAction.SET_ARTICLE_BY_ID, payload: ArticleByIdResponse }
  | { type: LinkerEditorAction.SET_PHRASE_SELECTION, payload?: SelectedPhraseData }
  | { type: LinkerEditorAction.CLEAR_PHRASE_SELECTION }

type LinkerEditorDispatch = (action: Action) => void

const defaultState: LinkerEditorPageState = {
  articleId: '',
  articleById: undefined,
  editorState: Draft.EditorState.createWithContent(stateFromHTML('')),
};

const LinkerEditorPageServiceContext = React.createContext<LinkerEditorService>({});
const LinkerEditorPageStateContext = React.createContext<LinkerEditorPageState | undefined>(undefined);
const LinkerEditorPageDispatchContext = React.createContext<LinkerEditorDispatch | undefined>(undefined);

function LinkerEditorReducer(state: LinkerEditorPageState, action: Action): LinkerEditorPageState {
  switch (action.type) {
    case LinkerEditorAction.SET_EDITOR_STATE_HTML: {
      clearWindowSelection();
      return {
        ...state,
        editorState: EditorHelper.createEditorStateWithContent(action.payload),
        editorStateHtml: action.payload,
        phraseSelection: undefined,
      };
    }
    case LinkerEditorAction.SET_ARTICLE_ID: {
      if (action.payload === state.articleId) {
        return state;
      }
      return {
        ...state,
        articleId: action.payload,
        editorState: Draft.EditorState.createWithContent(stateFromHTML('')),
        editorStateHtml: undefined,
        articleById: undefined,
      };
    }
    case LinkerEditorAction.SET_ARTICLE_BY_ID: {
      const htmlContent = articleToHtml(action.payload);
      return {
        ...state,
        articleById: action.payload,
        editorState: EditorHelper.createEditorStateWithContent(htmlContent),
        editorStateHtml: htmlContent,
      };
    }
    case LinkerEditorAction.SET_PHRASE_SELECTION: {
      return {
        ...state,
        phraseSelection: action.payload,
      };
    }
    case LinkerEditorAction.CLEAR_PHRASE_SELECTION: {
      clearWindowSelection();
      return {
        ...state,
        phraseSelection: undefined,
      };
    }
    default: throw assertNever(action);
  }
}

function LinkerEditorPageProvider(props: Props) {
  const { articleId, postType, children } = props;

  const [state, dispatch] = useReducer(LinkerEditorReducer, {
    ...defaultState,
    articleId: articleId || '',
  });

  const serviceId = articleId || state.articleId;

  const articleByIdService = useGetArticleByIdService(serviceId, postType);

  const services: LinkerEditorService = {
    [serviceId]: articleByIdService,
  };

  const dispatchToast = useToastMessagesDispatch();

  useEffect(() => {
    dispatch({
      type: LinkerEditorAction.SET_ARTICLE_ID,
      payload: serviceId,
    });
  }, [serviceId]);

  useEffect(() => {
    if (articleByIdService.status === REST_STATUS.LOADED) {
      dispatch({
        type: LinkerEditorAction.SET_ARTICLE_BY_ID,
        payload: articleByIdService.payload,
      });
    }
  }, [articleByIdService.status]);

  useEffect(() => {
    // When switching articles, clear all currently shown toast messages.
    dispatchToast({ type: ToastMessagesActionType.HIDE_ALL_TOASTS });
  }, [state.articleId]);

  return (
    <LinkerEditorPageServiceContext.Provider value={services}>
      <LinkerEditorPageStateContext.Provider value={state}>
        <LinkerEditorPageDispatchContext.Provider value={dispatch}>
          {children}
        </LinkerEditorPageDispatchContext.Provider>
      </LinkerEditorPageStateContext.Provider>
    </LinkerEditorPageServiceContext.Provider>
  );
}

function useLinkerEditorPageState(): LinkerEditorPageState {
  const context = useContext(LinkerEditorPageStateContext);
  if (context === undefined) {
    throw new Error('useLinkerEditorPageState must be used within a LinkerEditorPageProvider');
  }
  return context;
}

function useLinkerEditorPageDispatch(): LinkerEditorDispatch {
  const context = useContext(LinkerEditorPageDispatchContext);
  if (context === undefined) {
    throw new Error('useLinkerEditorPageDispatch must be used within a LinkerEditorPageProvider');
  }
  return context;
}

function useLinkerEditorPageService(): LinkerEditorService {
  const context = useContext(LinkerEditorPageServiceContext);
  if (context === undefined) {
    throw new Error('useLinkerEditorPageService must be used within a LinkerEditorPageProvider');
  }
  return context;
}

export {
  LinkerEditorPageProvider,
  useLinkerEditorPageState,
  useLinkerEditorPageDispatch,
  useLinkerEditorPageService,
};
