import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import RestService from '../../api/rest.service';
import {
  CORE_BACKEND_URL,
  INDEXER_URL,
  LINKER_URL,
  RECOMMENDATION_URL,
} from '../../constants/configs/urls';
import { AcmDrawerWidthPx } from '../../theme/style-utils';
import cachedOnWindowObject from '../../util/cached-on-window-object';

export default function VersionNumberInACorner() {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
      }}
    >
      <VersionNumber />
    </div>
  );
}

function VersionNumber() {
  const versionInfo = useVersionInfo();

  const allVersionsSame = areAllVersionsSame(versionInfo.subVersions);
  const showWarning = !allVersionsSame || versionInfo.mainVersion === UNKNOWN_VERSION;

  return (
    <Tooltip
      title={(
        <div>
          {versionInfo.subVersions.map((subVersion) => (
            <React.Fragment key={subVersion.name}>
              <b>{subVersion.name}:</b> {subVersion.version}<br />
            </React.Fragment>
          ))}
        </div>
      )}
    >
      <Button
        disableRipple
        color="info"
        variant="outlined"
        endIcon={showWarning ? <WarningIcon /> : undefined}
        sx={{
          fontSize: '14px', // a bit smaller than default, so that all text fits in the button
          // app bar width -1px border -2x10px margin, to fit exactly inside the app bar
          width: `${AcmDrawerWidthPx - 1 - 20}px`,
          margin: '10px',
          cursor: 'help',
          backgroundColor: 'transparent',
          textTransform: 'initial',
        }}
      >
        Version: {versionInfo.mainVersion}
      </Button>
    </Tooltip>
  );
}

type SubVersionInfo = {
  name: string
  version: string
}

interface VersionInfo {
  mainVersion: string
  subVersions: SubVersionInfo[]
}

const UNKNOWN_VERSION = 'unknown';
const CURRENT_VERSION = import.meta.env.VITE_CURRENT_VERSION || UNKNOWN_VERSION;
const VERSION_ENDPOINTS = Object.freeze([
  { name: 'Core', baseUrl: CORE_BACKEND_URL },
  { name: 'Indexer', baseUrl: INDEXER_URL },
  { name: 'Recommendations', baseUrl: RECOMMENDATION_URL },
  { name: 'Linker', baseUrl: LINKER_URL },
].map(({ name, baseUrl }) => ({ name, url: `${baseUrl}/version` })));

function useVersionInfo(): VersionInfo {
  const apiVersionStates = VERSION_ENDPOINTS.map(({ name, url }) => (
    { name, version: useEndpointVersion(url) }
  ));

  // If all backend services have the same version, collapse them into a single entry
  const backendVersions = areAllVersionsSame(apiVersionStates)
    ? [{ name: 'Backend', version: apiVersionStates[0].version }]
    : apiVersionStates;

  const versions = [
    { name: 'Frontend', version: CURRENT_VERSION },
    ...backendVersions,
  ];

  return {
    mainVersion: getHighestVersionNumber(versions.map(({ version }) => version)),
    subVersions: versions,
  };
}

function useEndpointVersion(endpoint: string): string {
  const [version, setVersion] = useState<string>(CURRENT_VERSION);
  useEffect(() => {
    cachedOnWindowObject<string>(
      `version:${endpoint}`,
      () => RestService.getRequest(endpoint).then((x) => x.version),
    ).then((v) => {
      setVersion(v);
    }, (error) => {
      console.log(`Failed to get version from ${endpoint}: ${error}`);
      setVersion(UNKNOWN_VERSION);
    });
  }, [endpoint]);
  return version;
}

function getHighestVersionNumber(versionNumbers: string[]): string {
  const versionNumberParts = versionNumbers
    .map((num) => num.split('.'))
    .filter((parts) => parts.length === 3);
  if (versionNumberParts.length === 0) {
    return UNKNOWN_VERSION;
  }
  const highestVersionNumber = versionNumberParts.reduce((highest, current) => {
    if (current[0] > highest[0]) return current;
    if (current[0] === highest[0] && current[1] > highest[1]) return current;
    if (current[0] === highest[0] && current[1] === highest[1] && current[2] > highest[2]) return current;
    return highest;
  }, versionNumberParts[0]);
  return highestVersionNumber.join('.');
}

function areAllVersionsSame(subVersions: SubVersionInfo[]): boolean {
  return new Set(subVersions.map(({ version }) => version)).size <= 1;
}
