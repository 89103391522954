import { LINKER_URL } from '../../../../../constants/configs/urls';
import useDeleteRequestService from '../../../_common/requests/delete-request';
import { RESTService } from '../../../rest-service';

const useDeleteArticleLinkerProfileService = (linkerProfileId?: string): RESTService<void> => {
  let requestURL;

  if (linkerProfileId) {
    requestURL = `${LINKER_URL}/linker/profiles/articles/${linkerProfileId}`;
  }

  const [result] = useDeleteRequestService(requestURL, {});
  return result;
};

export default useDeleteArticleLinkerProfileService;
