import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { KeywordCoverageResponse } from '../../../api/interfaces/article';
import RestService from '../../../api/rest.service';
import { ArticleTextState } from '../../../static/types/acm-bridge';
import htmlToText from '../../../util/html-text-converter';
import { EditableList } from '../../../util/use-editable-list';
import ProgressBar from '../common/progress-bar';
import SidebarArea from '../common/sidebar-area';
import SidebarExpansionPanel from '../common/sidebar-expansion-panel';
import EntitiesArea from './article-entities/entities-area';
import SelectedKeywordsArea from './selected-keywords-area';

interface Props {
  articleTextState?: ArticleTextState
  keywords: EditableList<string>
  keywordsAreSaving?: boolean
}

export default function SidebarPanelKeywords(props: Props) {
  const {
    articleTextState, keywords, keywordsAreSaving,
  } = props;
  const [keywordCoverage, setKeywordCoverage] = useState<KeywordCoverageResponse>();

  useEffect(() => {
    RestService.getKeywordCoverage(getText(), keywords.current)
      .then((keywordCoverageResponse) => setKeywordCoverage(keywordCoverageResponse))
      .catch((error) => console.error('Failed to retrieve keyword coverage ...', error));
  }, [keywords]);

  function getText(): string {
    if (!articleTextState) return '';

    const bodyText = articleTextState.article.structuredContent
      .map((element) => {
        if (element.originalContent == null) {
          return null;
        }
        return htmlToText(element.originalContent);
      })
      .filter((element) => element != null)
      .join(' ');
    return [bodyText, articleTextState.article.title].join(' ');
  }

  return (
    <SidebarExpansionPanel defaultExpanded title="Keywords">
      <SidebarArea title="Coverage">
        <ProgressBar value={keywordCoverage?.coverage || 0}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'normal' }}
          >
            {Math.round((keywordCoverage?.coverage || 0) * 100)}%
          </Typography>
        </ProgressBar>
      </SidebarArea>
      <SelectedKeywordsArea
        keywords={keywords}
        keywordsAreSaving={keywordsAreSaving}
        coverageHits={keywordCoverage?.hits || []}
      />
      <EntitiesArea
        articleTextState={articleTextState}
        keywords={keywords}
      />
    </SidebarExpansionPanel>
  );
}
