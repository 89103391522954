import { FlexBoxColumn, FlexBoxRow } from '@purple/react-components';
import { Parser } from 'html-to-react';
import { ReactNode, useState } from 'react';
import { ArticleByIdResponse, ArticleResponse } from '../../api/interfaces/article';
import RestService from '../../api/rest.service';
import { ContentType } from '../../constants/interfaces/interfaces';
import PageWithHeader from '../../theme/sprylab/components/pages/page-with-header';
import { articleToEnrichedHtml } from '../../util/article';
import ContentRecommendationWidget from '../../widgets/content-recommendation-widget';
import AutocompleteArticles from '../autocomplete/autocomplete-articles';
import ContentViewerMenu from './content-viewer-menu';
import Recommendation, { RecommendationModelType } from './recommendation/recommendation';

/**
 * Content viewer page. Displays the selected article, and several types of recommendation widgets.
 */
export default function ContentViewer() {
  const COLUMNS = 3;

  const htmlToReactParser = Parser();
  const [selectedArticleId, setSelectedArticleId] = useState('');
  const [article, setArticle] = useState<undefined | ArticleByIdResponse>();
  const [parsedContent, setParsedContent] = useState<undefined | ReactNode>();
  const [contentTypeFilter, setContentTypeFilter] = useState<ContentType[]>([]);

  const onArticleChange = async (value: ArticleResponse | null) => {
    if (!value) {
      return;
    }
    const articleId = value.id;

    try {
      const articleById = await RestService.getArticleById(articleId);

      setArticle(articleById);
      setSelectedArticleId(articleId);
      setParsedContent(htmlToReactParser.parse(`${articleToEnrichedHtml(articleById)}`));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWithHeader>
      <FlexBoxColumn gap="20px" mt="20px">
        <FlexBoxRow gap="8px" alignItems="center">
          <AutocompleteArticles
            onChange={onArticleChange}
            sx={{ mt: 0, width: '100%' }}
          />
          <ContentViewerMenu
            contentTypeFilter={contentTypeFilter}
            setContentTypeFilter={setContentTypeFilter}
          />
        </FlexBoxRow>
        {parsedContent ?? ''}
        {article && (
          <>
            <Recommendation
              title="More like this"
              itemId={article.id}
              columns={COLUMNS}
              recsysModel={RecommendationModelType.MULTI_CRITERIA}
              gridDirection="row"
            />
            <ContentRecommendationWidget
              itemId={selectedArticleId}
              limit={6}
              recsysModel={RecommendationModelType.MULTI_CRITERIA}
            />
          </>
        )}
      </FlexBoxColumn>
    </PageWithHeader>
  );
}
