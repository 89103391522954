import LocalStorageVolatile from '../constants/session';
import { inIframe } from '../util/dom-utils';

export function authAwareHeader(headersDict: {}) {
  const headers = new Headers(headersDict);
  let token;

  if (inIframe()) {
    token = getToken(LocalStorageVolatile.API_TOKEN_ACM_IFRAME);
  } else {
    token = getToken(LocalStorageVolatile.API_TOKEN_ACM);
  }

  if (token != null) {
    headers.append('X-API-KEY', token);
  }

  return headers;
}

export function setToken(tokenKey: LocalStorageVolatile, value: string | undefined) {
  if (value) {
    localStorage.setItem(tokenKey, value);
  } else {
    localStorage.removeItem(tokenKey);
  }
}

export function getToken(tokenKey: LocalStorageVolatile): string | undefined {
  return localStorage.getItem(tokenKey) || undefined;
}
