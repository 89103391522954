import useGetArticleLinkStatisticsService from '../../../api/hooks/linker/statistics/get-article-link-statistics';
import useGetProductLinkStatisticsService from '../../../api/hooks/linker/statistics/get-product-link-statistics';
import { useACMUserState } from '../../../context/acm-page-context';
import PageWithHeader from '../../../theme/sprylab/components/pages/page-with-header';
import LinkOptimizerStatisticsTable from './link-optimizer-statistics-table';

export default function LinkOptimizerStatistics() {
  const articleStatistics = useGetArticleLinkStatisticsService();
  const productStatistics = useGetProductLinkStatisticsService();
  const { featureFlags } = useACMUserState();

  return (
    <PageWithHeader wide title="Link Optimizer Usage Statistics">
      {featureFlags?.LINK_OPTIMIZER_ARTICLE_LINKING && (
        <LinkOptimizerStatisticsTable
          title="Article Linking"
          statisticsService={articleStatistics}
        />
      )}
      {featureFlags?.LINK_OPTIMIZER_PRODUCT_LINKING && (
        <LinkOptimizerStatisticsTable
          title="Product Linking"
          statisticsService={productStatistics}
        />
      )}
    </PageWithHeader>
  );
}
