import { Divider, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { ArticleSource } from '../api/interfaces/article';
import { RecommendationModelType } from '../components/content-viewer/recommendation/recommendation';
import { ContentType } from '../constants/interfaces/interfaces';
import { useACMUserState } from '../context/acm-page-context';

// TODO this should get migrated to use the "sx" prop instead of makeStyles
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 'calc(100% + 8px)',
    marginTop: '50px',
    marginBottom: '10px',
  },

  section: {
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: '#fff',
  },

  headline: {
    marginBottom: '10px',
    textTransform: 'uppercase',
    fontSize: '2em',
  },

  divider: {
    marginBottom: '8px',
  },
}));

interface Props {
  itemId: string
  limit: number
  contentTypeFilter?: ContentType[]
  sourceFilter?: ArticleSource[]
  recsysModel?: RecommendationModelType
}

export default function ContentRecommendationWidget(props: Props) {
  const classes = useStyles();
  const {
    itemId, limit, contentTypeFilter, sourceFilter, recsysModel,
  } = props;
  const { acmToken } = useACMUserState();
  useEffect(() => {
    if (window.acm && itemId && limit) {
      const configuration = {
        acmConfig: {
          apiToken: acmToken,
        },
      };
      const requestOptions = {
        item_id: itemId,
        limit,
        types: contentTypeFilter || [],
        sources: sourceFilter ? sourceFilter.map((source) => source.id) : [],
        weights: { source_target: 50, recency: 10, retention_time: 5 },
        model: recsysModel,
      };

      const widgetConfigs = [{
        widgetId: 'acm-content-recommendations',
        requestOptions,
      }, {
        widgetId: 'acm-content-recommendations-2',
        requestOptions,
      }];

      // window.acm.recommend(configuration, requestOptions)
      window.acm.recommendMultiple(configuration, widgetConfigs);
    }
  }, [itemId, limit]);

  return (
    <>
      <div className={classes.root}>
        <section
          id="acm-content-recommendations"
          className={classes.section}
        >
          <Typography
            variant="h1"
            className={classes.headline}
          >
            Widget Integration
          </Typography>
          <Divider className={classes.divider} />
          {/* Content is coming from window.acm.recommend(configuration) */}
        </section>
      </div>
      <div className={classes.root}>
        <section
          id="acm-content-recommendations-2"
          className={classes.section}
        >
          <Typography
            variant="h1"
            className={classes.headline}
          >
            Widget Integration 2
          </Typography>
          <Divider className={classes.divider} />
          {/* Content is coming from window.acm.recommend(configuration) */}
        </section>
      </div>
    </>
  );
}
