import { Alert, Snackbar } from '@mui/material';
import { CompositeDecorator } from 'draft-js';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { SelectedPhraseData } from '../../../constants/interfaces/interfaces';
import {
  LinkerEditorAction,
  useLinkerEditorPageDispatch,
  useLinkerEditorPageState,
} from '../../../context/linker/linker-editor-context';
import useEventListener from '../../../hooks/event-listener';
import {
  automaticFullWordSelection,
  otherLinksInSelection,
  isFullWordSelected,
  isNotValidSelection,
  removeTrailingSpacesFromSelection,
} from '../../../util/draft-js-util';
import { ACMEditor } from '../../common/editor/acm-editor';
import EditorHelper from '../../common/editor/editor-helper';
import { CombinedLinkRecommendationEntity } from './entities/generic-link-recommendation-entity';

const LinkerEditorDecorator = new CompositeDecorator([{
  strategy: EditorHelper.findEntityRangesByType('LINK'),
  component: CombinedLinkRecommendationEntity,
}]);

/**
 * The link optimizer editor. Displays the content of an article enriched with link selections.
 */
export default function LinkerEditor() {
  const { editorState, phraseSelection } = useLinkerEditorPageState();
  const dispatch = useLinkerEditorPageDispatch();
  const [fullWordInfoOpen, setFullWordInfoOpen] = useState<boolean>(false);
  const acmEditorRef = useRef<HTMLDivElement>(null);

  useEventListener('mouseup', (event: Event) => {
    const selection = document.getSelection();
    let selectionData: SelectedPhraseData | undefined;
    if (selection && !selection.isCollapsed) {
      removeTrailingSpacesFromSelection(selection); // To guarantee expected behavior across OS/WebBrowsers. See DEEP-383
      automaticFullWordSelection(selection);
      removeTrailingSpacesFromSelection(selection);

      if (isNotValidSelection(selection) || otherLinksInSelection(selection)) {
        setFullWordInfoOpen(true);
      } else if (selection.anchorNode) {
        if (acmEditorRef && acmEditorRef.current) {
          const editorNode = ReactDOM.findDOMNode(acmEditorRef.current);
          if (editorNode && editorNode.contains(selection.anchorNode)) {
            let parent = (selection.anchorNode as HTMLElement);
            do {
              if (parent.parentElement) {
                parent = parent.parentElement;
              }
            } while (!parent.hasAttribute('data-block'));

            if (parent && parent.nodeName === 'DIV' && parent.textContent && isFullWordSelected(selection, parent)) {
              const selectionRange = selection.getRangeAt(0);
              const selectionText = selectionRange.toString();
              const text = parent.textContent.slice(0, EditorHelper.getSelectionCharacterOffset(parent).end);

              let count = 0;
              let pos = text.indexOf(selectionText);

              while (pos !== -1) {
                count++;
                pos = text.indexOf(selectionText, pos + 1);
              }

              if (count !== 0) {
                setFullWordInfoOpen(false);
                selectionData = {
                  selectedText: selectionText,
                  paragraphText: parent.textContent,
                  occurrence: count - 1,
                  recommendationId: undefined,
                };
              } else { // Multiple Paragraphs selected
                setFullWordInfoOpen(true);
              }
            } else {
              setFullWordInfoOpen(true);
            }
          }
        }
      }
    } else {
      setFullWordInfoOpen(false);
    }

    dispatch({
      type: LinkerEditorAction.SET_PHRASE_SELECTION,
      payload: selectionData,
    });
  }, (ReactDOM.findDOMNode(acmEditorRef.current) as Element));

  useEventListener('mousedown', () => {
    // To prevent weird visual bugs in the case the user is in the process of making a new selection
    // while one is still active and is being restored periodically by the code below,
    // we clear any active selection as soon as a mouse-down event comes.
    dispatch({ type: LinkerEditorAction.CLEAR_PHRASE_SELECTION });
  }, (ReactDOM.findDOMNode(acmEditorRef.current) as Element));

  useEffect(() => {
    // Start a periodic job to keep the current selection selected.
    // When e.g. a text box is focused (e.g. for the associate tag in the profiles)
    // or when (in firefox) the profile selection dropdown is clicked on,
    // the text selection can disappear, while our webpage still conceptually has an active selection.
    // With this method, we always re-create a selection around the text that the internal state thinks should be currently selected.
    function keepSelection() {
      interval = window.requestAnimationFrame(keepSelection);
      const { activeElement } = document;
      if (activeElement && activeElement.nodeName === 'INPUT' && (activeElement as HTMLInputElement).type === 'text') {
        return; // do not do anything as long as the user is writing text
      }

      const selection = document.getSelection();
      if (!selection || !phraseSelection || !phraseSelection.selectedText) return;
      const editorElement = ReactDOM.findDOMNode(acmEditorRef.current) as HTMLDivElement;
      const paragraphs = editorElement.querySelectorAll('div[data-block]');
      const correctParagraph = Array.from(paragraphs).find((paragraph) => paragraph.textContent === phraseSelection.paragraphText);
      if (!correctParagraph) {
        console.error('No correct paragraph found');
        return;
      }
      const element = correctParagraph;
      if (!element) return;
      const textLeafs = $(element).find('*').filter(function filter() {
        return $(this).text().includes(phraseSelection.selectedText) && $(this).children().length === 0;
      });
      const text = phraseSelection.selectedText;
      let remainingOccurrences = phraseSelection.occurrence + 1;
      // There might be multiple text leafs with 0, 1, or more occurrences of the selection.
      // We need to find the correct text leaf and the correct offset for the given occurrence
      for (const textLeaf of Array.from(textLeafs)) {
        const actualElement = textLeaf as HTMLElement;
        const content = actualElement.innerText;
        let offset = -1;
        // search for more occurrences as long as we can (offset still positive) and as long as we need to (remainingOccurrences > 0)
        while (true) {
          offset = content.indexOf(text, offset + 1);
          if (offset === -1) break;
          remainingOccurrences--;
          if (remainingOccurrences === 0) break;
        }
        if (offset !== -1) {
          console.assert(remainingOccurrences === 0, 'remainingOccurrences should be 0 when we have a valid offset.');
          // We found the current selection in the editor - now we select it.
          const range = document.createRange();
          range.setStart(actualElement.firstChild as Node, offset);
          range.setEnd(actualElement.firstChild as Node, offset + text.length);
          selection.removeAllRanges();
          selection.addRange(range);
          return;
        }
      }
      console.error('Did not find enough occurrences of the selection in the paragraph!');
    }
    let interval = window.requestAnimationFrame(keepSelection);
    return () => window.cancelAnimationFrame(interval);
  }, [phraseSelection]);

  useEffect(() => {
    const draftJsToolbarEl = (Array.from(document.querySelectorAll('div[class^=\'draftJsToolbar__toolbar__\'], div[class*=\' draftJsToolbar__toolbar__\']'))[0] as HTMLElement);

    if (draftJsToolbarEl) {
      if (!fullWordInfoOpen && phraseSelection?.paragraphText) {
        draftJsToolbarEl.style.visibility = 'visible';
        draftJsToolbarEl.style.transform = 'translate(-50%) scale(1)';
      } else {
        draftJsToolbarEl.style.visibility = 'hidden';
        draftJsToolbarEl.style.transform = 'translate(-50%) scale(0)';
      }
    }
  }, [phraseSelection]);

  const handleFullWordInfoClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setFullWordInfoOpen(false);
  };

  return (
    <>
      <ACMEditor
        rootRef={acmEditorRef}
        decorator={LinkerEditorDecorator}
        editorState={editorState}
      />
      <Snackbar
        open={fullWordInfoOpen}
        onClose={handleFullWordInfoClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleFullWordInfoClose} severity="info">
          You must select full words. You cannot select headers, existing links, or multiple paragraphs.
        </Alert>
      </Snackbar>
    </>
  );
}
