import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  PrimaryButton, STDivider, STEntry, STHeading, SettingsTable, TextInput, UnsavedHighlightFrame,
} from '@purple/react-components';
import equal from 'fast-deep-equal';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import usePutProductLinkerProfileService from '../../../api/hooks/linker/profiles/products/put-product-linker-profile';
import { isRestServiceFinished, isRestServiceSuccessful } from '../../../api/hooks/rest-service';
import { ProductLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import {
  LinkerProfilesAction,
  ProfileType,
  useGetProfilesByType,
  useLinkerProfilesDispatch,
  useLinkerProfilesService,
  useLinkerProfilesState,
} from '../../../context/profiles/linker-profiles-context';
import RestSnackbar from '../../../theme/sprylab/components/rest-snackbar';
import { StickyButtonStyle } from '../../../theme/style-utils';
import assertShouldNowBeOfType from '../../../util/assertShouldNowBeOfType';
import ArticleProfileSliders from '../../link-optimizer/generic/profile/article-profile-sliders';
import GeneralLinkProfileSliders from './general-link-profile-sliders';
import ProductLinkProfileSliders from './product-link-profile-sliders';

export default function ProductLinkProfileConfiguration() {
  const { productLinkerProfiles, selectedProductLinkerId, isProfileDirty } = useLinkerProfilesState();
  const { refreshProductProfiles } = useLinkerProfilesService();
  const dispatch = useLinkerProfilesDispatch();
  const [linkProfile, setLinkProfile] = useState<ProductLinkerProfileResponse | undefined>();
  const [rawProfileName, setRawProfileName] = useState<string | undefined>(linkProfile?.name);
  const [doAction, setDoAction] = useState<boolean>();
  const updateProductProfileService = usePutProductLinkerProfileService(doAction ? linkProfile : undefined);
  const currentProfiles = useGetProfilesByType(ProfileType.PRODUCT);
  const selectedProductProfile = productLinkerProfiles.get(selectedProductLinkerId);

  useEffect(() => {
    if (!selectedProductProfile || !linkProfile) return;
    dispatch({
      type: LinkerProfilesAction.SET_PROFILE_DIRTY,
      payload: !equal(linkProfile, selectedProductProfile),
    });
  }, [linkProfile, selectedProductProfile]);

  const nameExists = useMemo(() => {
    if (!linkProfile) return false;
    if (selectedProductProfile?.name === linkProfile.name) return false;
    if (selectedProductProfile?.id !== linkProfile.id) return false; // wait for update via useEffect
    return currentProfiles.some((p) => p.name === linkProfile.name);
  }, [currentProfiles, selectedProductLinkerId, linkProfile]);

  useEffect(() => {
    if (!selectedProductLinkerId) return;
    if (selectedProductProfile) {
      setLinkProfile(selectedProductProfile);
      setRawProfileName(selectedProductProfile.name);
    }
  }, [productLinkerProfiles, selectedProductLinkerId]);

  useEffect(() => {
    if (doAction) {
      if (isRestServiceSuccessful(updateProductProfileService)) {
        refreshProductProfiles();
      }
      if (isRestServiceFinished(updateProductProfileService)) {
        setDoAction(false);
      }
    }
  }, [updateProductProfileService.status]);

  useEffect(() => {
    setLinkProfile((lp) => {
      if (!lp || rawProfileName === undefined) return lp;
      return {
        ...lp,
        name: rawProfileName.trim(),
      };
    });
  }, [rawProfileName]);

  const handleSaveClick = () => {
    setDoAction(true);
  };

  if (!linkProfile) {
    return null;
  }
  assertShouldNowBeOfType<React.Dispatch<React.SetStateAction<ProductLinkerProfileResponse>>>(setLinkProfile);

  return (
    <UnsavedHighlightFrame
      hasUnsavedChanges={isProfileDirty}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        gap: 2,
        p: '16px',
        px: '24px',
      }}
    >
      <SettingsTable>
        <STEntry label="Profile Name">
          <TextInput
            error={nameExists}
            helperText={nameExists && 'Name exists already.'}
            value={rawProfileName}
            setValue={setRawProfileName}
          />
        </STEntry>
        <STDivider />
        <STHeading>Link Selection Configuration</STHeading>
        <ArticleProfileSliders
          showProductSpecificProperties
          recommendedEntitiesName="Products"
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
        <STHeading>Product Recommendation Configuration</STHeading>
        <ProductLinkProfileSliders
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
        <STHeading>Profile Configuration</STHeading>
        <GeneralLinkProfileSliders
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
      </SettingsTable>
      <Grid
        container
        xs={1}
        justifyContent="flex-end"
        alignSelf="flex-end"
        sx={StickyButtonStyle}
      >
        <Box p={1.5}>
          <PrimaryButton
            floating
            disabled={nameExists || !isProfileDirty}
            onClick={handleSaveClick}
            data-testid="update-profile-button"
          >
            Update
          </PrimaryButton>
          <RestSnackbar
            loadingMessage="Updating product profile ..."
            service={updateProductProfileService}
            id="updateProductProfileService"
          />
        </Box>
      </Grid>
    </UnsavedHighlightFrame>
  );
}
