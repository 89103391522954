import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  PrimaryButton, STDivider, STHeading, STEntry, SettingsTable, TextInput, UnsavedHighlightFrame,
} from '@purple/react-components';
import equal from 'fast-deep-equal';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import usePutArticleLinkerProfileService from '../../../api/hooks/linker/profiles/articles/put-article-linker-profile';
import { isRestServiceFinished, isRestServiceSuccessful } from '../../../api/hooks/rest-service';
import { ArticleLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import {
  LinkerProfilesAction,
  ProfileType,
  useGetProfilesByType,
  useLinkerProfilesDispatch,
  useLinkerProfilesService,
  useLinkerProfilesState,
} from '../../../context/profiles/linker-profiles-context';
import RestSnackbar from '../../../theme/sprylab/components/rest-snackbar';
import { StickyButtonStyle } from '../../../theme/style-utils';
import assertShouldNowBeOfType from '../../../util/assertShouldNowBeOfType';
import ArticleProfileSliders from '../../link-optimizer/generic/profile/article-profile-sliders';
import ArticleLinkProfileSliders from './article-link-profile-sliders';
import GeneralLinkProfileSliders from './general-link-profile-sliders';

export default function ArticleLinkProfileConfiguration() {
  const { articleLinkerProfiles, selectedArticleLinkerId, isProfileDirty } = useLinkerProfilesState();
  const { refreshArticleProfiles } = useLinkerProfilesService();
  const dispatch = useLinkerProfilesDispatch();
  const [linkProfile, setLinkProfile] = useState<ArticleLinkerProfileResponse>();
  const [rawProfileName, setRawProfileName] = useState<string | undefined>(linkProfile?.name);
  const [doAction, setDoAction] = useState<boolean>();
  const updateArticleProfileService = usePutArticleLinkerProfileService(doAction ? linkProfile : undefined);
  const currentProfiles = useGetProfilesByType(ProfileType.ARTICLE);
  const selectedArticleProfile = articleLinkerProfiles.get(selectedArticleLinkerId);

  useEffect(() => {
    if (!selectedArticleProfile || !linkProfile) return;
    dispatch({
      type: LinkerProfilesAction.SET_PROFILE_DIRTY,
      payload: !equal(linkProfile, selectedArticleProfile),
    });
  }, [linkProfile, selectedArticleProfile]);

  const nameExists = useMemo(() => {
    if (!linkProfile) return false;
    if (selectedArticleProfile?.name === linkProfile.name) return false;
    if (selectedArticleProfile?.id !== linkProfile.id) return false; // wait for update via useEffect
    return currentProfiles.some((p) => p.name === linkProfile.name);
  }, [currentProfiles, selectedArticleLinkerId, linkProfile]);

  useEffect(() => {
    if (!selectedArticleLinkerId) return;
    if (selectedArticleProfile) {
      setLinkProfile(selectedArticleProfile);
      setRawProfileName(selectedArticleProfile.name);
    }
  }, [articleLinkerProfiles, selectedArticleLinkerId]);

  useEffect(() => {
    if (doAction) {
      if (isRestServiceSuccessful(updateArticleProfileService)) {
        refreshArticleProfiles();
      }
      if (isRestServiceFinished(updateArticleProfileService)) {
        setDoAction(false);
      }
    }
  }, [updateArticleProfileService.status]);

  useEffect(() => {
    setLinkProfile((lp) => {
      if (!lp || rawProfileName === undefined) return lp;
      return {
        ...lp,
        name: rawProfileName.trim(),
      };
    });
  }, [rawProfileName]);

  const handleSaveClick = () => {
    setDoAction(true);
  };

  if (!linkProfile) {
    return null;
  }
  assertShouldNowBeOfType<React.Dispatch<React.SetStateAction<ArticleLinkerProfileResponse>>>(setLinkProfile);

  return (
    <UnsavedHighlightFrame
      hasUnsavedChanges={isProfileDirty}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        gap: 2,
        p: '16px',
        px: '24px',
      }}
    >
      <SettingsTable>
        <STEntry label="Profile Name">
          <TextInput
            error={nameExists}
            helperText={nameExists && 'Name exists already.'}
            value={rawProfileName}
            setValue={setRawProfileName}
          />
        </STEntry>
        <STDivider />
        <STHeading>Link Selection Configuration</STHeading>
        <ArticleProfileSliders
          recommendedEntitiesName="Articles"
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
        <STHeading>Link Recommendation Configuration</STHeading>
        <ArticleLinkProfileSliders
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
        <STHeading>Profile Configuration</STHeading>
        <GeneralLinkProfileSliders
          linkProfile={linkProfile}
          setLinkProfile={setLinkProfile}
        />
        <STDivider />
      </SettingsTable>
      <Grid
        container
        xs={1}
        justifyContent="flex-end"
        alignSelf="flex-end"
        sx={StickyButtonStyle}
      >
        <Box p={1.5}>
          <PrimaryButton
            floating
            disabled={nameExists || !isProfileDirty}
            onClick={handleSaveClick}
            data-testid="update-profile-button"
          >
            Update
          </PrimaryButton>
          <RestSnackbar
            loadingMessage="Updating article profile ..."
            service={updateArticleProfileService}
            id="updateArticleProfileService"
          />
        </Box>
      </Grid>
    </UnsavedHighlightFrame>
  );
}
