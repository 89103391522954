import CardMedia from '@mui/material/CardMedia';
import { CSSProperties } from '@mui/styles';

interface Props {
  imageUrl?: string
  hidePlaceholderImage?: boolean
}

export default function ArticleImage(props: Props) {
  const { imageUrl, hidePlaceholderImage } = props;

  const style: CSSProperties = {
    backgroundColor: '#A7A7A7',
    height: '100px',
    borderRadius: '6px',
    marginBottom: '8px',
  };

  if (!imageUrl) {
    if (hidePlaceholderImage) {
      return null;
    }
    return (
      <div style={style} />
    );
  }

  return (
    <CardMedia
      style={{ ...style, objectFit: 'cover' }}
      image={imageUrl}
      title="Card-image"
    />
  );
}
