import List from '@mui/material/List';
import { ACMPageList, shouldShowPageList } from '../../constants/interfaces/page';
import { useACMUserState } from '../../context/acm-page-context';
import AppBarListItem from './app-bar-list-item';

interface Props {
  items: Array<ACMPageList>
}

export default function AppBarItemGroup(props: Props) {
  const { items } = props;
  const { featureFlags } = useACMUserState();

  return (
    <List>
      {items.filter((x) => shouldShowPageList(x, featureFlags)).map(({ pageData }) => (
        <AppBarListItem label={<pageData.label />} path={pageData.path} key={pageData.path} />
      ))}
    </List>
  );
}
