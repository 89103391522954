import { RecommendationModelType } from '../components/content-viewer/recommendation/recommendation';
import {
  CORE_BACKEND_URL,
  INDEXER_URL,
  LINKER_URL,
  RECOMMENDATION_URL,
} from '../constants/configs/urls';
import { authAwareHeader } from './auth-functions';
import makeRequestError from './hooks/_common/requests/common';
import {
  ArticleByIdResponse,
  ArticleResponse,
  KeywordCoverageResponse,
} from './interfaces/article';
import { ConfigurationDetailResponse, UserConfigurationPayload } from './interfaces/configuration';
import { StatisticsResponse } from './interfaces/linkoptimizer-statistics';
import { ContentRecommendationResponse, RecommendationWeights } from './interfaces/recommendation';
import {
  AddUserPayload, LoginResponse, MessageResponse, UserResponse,
} from './interfaces/user';

class RestService {
  /**
   * Headers for requesting protected endpoints.
   */
  public static getHeaders(includeContentTypeHeader = true): Headers {
    const headers = {
      Accept: 'application/json',
    };

    if (includeContentTypeHeader) {
      headers['Content-Type'] = 'application/json';
    }

    return authAwareHeader(headers);
  }

  /**
   * General purpose method to perform a request.
   *
   * @param url the resource url.
   * @param method HTTP method string.
   * @param body JSON Object which will be serialized to JSON string.
   */
  private static request(url: string, method: string, body?: Object) {
    return fetch(url, {
      method,
      headers: this.getHeaders(method !== 'GET'),
      body: body ? JSON.stringify(body) : null,
    }).then(async (response) => {
      if (response.status >= 300) {
        throw await makeRequestError(response);
      } else if (response.status === 204) {
        return null;
      } else {
        try {
          return await response.json();
        } catch (e) {
          throw new Error(`Invalid response from server: ${e}`);
        }
      }
    });
  }

  /**
   * Performs a DELETE request on specified `url`.
   *
   * @param url the resource url.
   * @param body an optional delete body
   */
  public static deleteRequest(url: string, body?: Object) {
    return this.request(url, 'DELETE', body)
      .catch((error: Error) => {
        throw error;
      });
  }

  /**
   * Performs a POST request with JSON body on specified url.
   *
   * @param url the request url.
   * @param body JSON Object which will be serialized to JSON string.
   */

  public static postRequest(url: string, body: Object) {
    return this.request(url, 'POST', body);
  }

  /**
   * Performs a PUT request with JSON body on specified url.
   * @param url
   * @param body
   */
  public static putRequest(url: string, body: Object) {
    return this.request(url, 'PUT', body);
  }

  /**
   * Performs a GET request on specified url.
   * @param url the resource url.
   */
  public static getRequest(url: string) {
    return this.request(url, 'GET');
  }

  public static login(username: string, password: string): Promise<LoginResponse> {
    return this.postRequest(`${CORE_BACKEND_URL}/users/login`, {
      username,
      password,
    });
  }

  /**
   * Fetches all articles from database.
   *
   * @param search query to match articles.
   * @param types optional type to filter by.
   */
  public static searchContent(search: string = '', types: string[] = []): Promise<ArticleResponse[]> {
    let url = `${CORE_BACKEND_URL}/articles?text=${search}`;
    if (types != null && types.length > 0) {
      url += `&type=${types.join(',')}`;
    }

    return this.getRequest(url);
  }

  /**
   * Fetches the last n articles
   * @param limit the amount of articles to fetch
   */
  public static getLastArticles(limit: number = 5): Promise<ArticleResponse[]> {
    const url = `${CORE_BACKEND_URL}/articles?limit=${limit}`;
    return this.getRequest(url);
  }

  /**
   * Fetches article by {@param articleId}.
   *
   * @param articleId external article id.
   */
  public static getArticleById(articleId: string): Promise<ArticleByIdResponse> {
    return this.getRequest(`${CORE_BACKEND_URL}/articles/${articleId}`);
  }

  /**
   * Fetches recommendation.
   *
   * @param model model that will be used for recommendation.
   * @param itemId id of the given item for which we want to display recommendations.
   * @param limit use to limit the number of recommended links.
   * @param types list of types to filter by.
   * @param weights dictionary of weights that will be used in the model.
   */
  public static getRecommendations(
    itemId: string | undefined,
    model?: RecommendationModelType,
    limit: number = 6,
    types: string[] = [],
    weights: RecommendationWeights = {} as RecommendationWeights,
  ): Promise<ContentRecommendationResponse> {
    const body = {
      item_id: itemId,
      limit,
      types,
      weights,
      model,
    };

    return this.postRequest(`${RECOMMENDATION_URL}/recommendation`, body);
  }

  /**
   * Calculates the coverage of keywords based on the passed article.
   *
   * @param text
   * @param keywords
   */
  public static getKeywordCoverage(text: string, keywords: string[]): Promise<KeywordCoverageResponse> {
    return this.postRequest(`${CORE_BACKEND_URL}/article-coverage/keyword_coverage`, {
      text,
      keywords,
    });
  }

  /**
   * Fetches all Customers.
   */
  public static getAllCustomers(): Promise<UserResponse[]> {
    return this.getRequest(`${CORE_BACKEND_URL}/users`);
  }

  public static getArticleLinkOptimizerStatistics(): Promise<StatisticsResponse[]> {
    return this.getRequest(`${LINKER_URL}/linker/statistics/articles`);
  }

  public static getProductLinkOptimizerStatistics(): Promise<StatisticsResponse[]> {
    return this.getRequest(`${LINKER_URL}/linker/statistics/products`);
  }

  /**
   * Get customer detail for customer with id.
   *
   * @param publicId id of customer
   */
  public static getUser(publicId: string): Promise<UserResponse> {
    return this.getRequest(`${CORE_BACKEND_URL}/users/${publicId}`);
  }

  /**
   * Get Configuration detail for a specific user by their id.
   * @param publicId id of customer
   */
  public static getUserConfig(publicId: string): Promise<ConfigurationDetailResponse> {
    return this.getRequest(`${CORE_BACKEND_URL}/users/${publicId}/configurations`);
  }

  /**
   * Create a new Configurations detail for any admin or unique for logged in user.
   */
  public static postConfigurations(userConfig: UserConfigurationPayload) {
    return this.postRequest(`${CORE_BACKEND_URL}/configurations`, userConfig);
  }

  /**
   * Update a User.
   *
   * @param user from UserResponse.
   */
  public static updateUserDetail(user: UserResponse) {
    return this.putRequest(`${CORE_BACKEND_URL}/users/${user.public_id}`, user);
  }

  /**
   * Update the user configuration.
   * @param config the new configuration
   */
  public static updateUserConfig(config: ConfigurationDetailResponse) {
    return this.putRequest(`${CORE_BACKEND_URL}/configurations/${config.public_id}`, config);
  }

  /**
   * Delete a Customer.
   *
   * @param customer from UserResponse.
   */
  public static deleteCustomer(customer: UserResponse): Promise<MessageResponse> {
    return this.deleteRequest(`${CORE_BACKEND_URL}/users/${customer.public_id}`);
  }

  /**
   * Setup a Customer according to the customer configuration.
   *
   * @param customer from UserResponse.
   */
  public static setupCustomer(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${CORE_BACKEND_URL}/users/${customer.public_id}/setup`, {});
  }

  /**
   * Get the status of the setup of a specific customer.
   * @param customer the customer in question
   */
  public static setupCustomerStatus(customer: UserResponse): Promise<MessageResponse> {
    return this.getRequest(`${CORE_BACKEND_URL}/users/${customer.public_id}/setup`);
  }

  /**
   * Creates a new customer.
   *
   */
  public static postCustomer(customer: AddUserPayload) {
    return this.postRequest(`${CORE_BACKEND_URL}/users`, customer);
  }

  public static pullIndexCustomer(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${INDEXER_URL}/index/articles/pull`, {
      username: customer.username,
      clean: true,
    });
  }

  public static computeCategories(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${CORE_BACKEND_URL}/categories/${customer.public_id}/compute`, {});
  }

  public static backupExport(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${INDEXER_URL}/index/articles/backup/export?username=${customer.username}`, {});
  }

  public static backupImport(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${INDEXER_URL}/index/articles/backup/import?username=${customer.username}`, {});
  }

  public static anchorTextExtraction(customer: UserResponse): Promise<MessageResponse> {
    return this.postRequest(`${CORE_BACKEND_URL}/articles/anchor_texts`, { username: customer.username, max_articles: 5000 });
  }
}

export default RestService;
