import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PurpleBaseColors } from '@purple/react-components';

// TODO this should get migrated to use the "sx" prop instead of makeStyles
const useDashboardCardStyles = makeStyles((theme: Theme) => createStyles({
  bulletPoint: {
    margin: '0 5px 0 5px',
    display: 'inline-block',
    color: '#A7A7A7',
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    lineHeight: '18px',
  },
  button: {
    margin: '20px',
    height: '30px',
    color: '#222222',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0.3px',
  },
  articlePerformancesDetails: {
    height: 'auto',
    margin: '0px 20px',
  },
  articlePerformanceDetails: {
    margin: '12px 0px',
  },
  articlePerformanceSubtitle: {
    display: 'inline-block',
    flex: '1',
  },
  subtitle: {
    display: 'inline-block',
    paddingRight: '5px',
  },
  centeringWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  articlePerformanceBulletPoint: {
    width: '24px',
    height: '24px',
    flexShrink: 0,
    lineHeight: '26px',
    fontSize: '16px',
    background: PurpleBaseColors.neutralLightGray,
    color: PurpleBaseColors.purpleGrowthDarkBlue,
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '999px',
  },
}));

export default useDashboardCardStyles;
