import { useEffect } from 'react';
import postExtractISINs from './api/hooks/core/isin/post-extract-isins';
import postGenerateLinksAutomatically from './api/hooks/linker/links/generate-links-automatically';
import { makeIFrameAdapterFromInside, NoFunctions } from './bridge/generic-iframe-adapter';
import { useACMUserState } from './context/acm-page-context';
import { ExternalAcmFunctionsAdapter } from './static/types/acm-services';

/**
 * This page can get hosted by ACM and embedded in an invisible iframe on a CMS page,
 * so that the CMS code can interact with selected ACM functions directly and without any CORS problems.
 */
function ExternalAcmFunctions() {
  const { acmToken } = useACMUserState();

  useEffect(() => {
    const adapter = makeIFrameAdapterFromInside<NoFunctions, ExternalAcmFunctionsAdapter>({
      extractISINs: ({ text }) => postExtractISINs(acmToken ?? '', text),
      generateLinksAutomatically: ({ id, title, contentHtml }) => postGenerateLinksAutomatically(acmToken ?? '', id, title, contentHtml),
    });
    return () => adapter.disconnect();
  }, [acmToken]);

  return <div />;
}

export default ExternalAcmFunctions;
