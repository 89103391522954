import { LINKER_URL } from '../../../../constants/configs/urls';
import { GenerateLinksAutomaticallyResponse } from '../../../../static/types/acm-services';
import makeRequestError from '../../_common/requests/common';

const postGenerateLinksAutomatically = async (acmToken: string, articleId: string, articleTitle: string, htmlContent: string): Promise<GenerateLinksAutomaticallyResponse> => {
  if (!htmlContent) {
    return {
      html_content: '',
      newly_generated_links: 0,
    };
  }

  return fetch(`${LINKER_URL}/linker/generateLinksAutomatically`, {
    method: 'POST',
    body: JSON.stringify({
      article_id: articleId,
      article_title: articleTitle,
      html_content: htmlContent,
    }),
    headers: {
      Accept: 'application/json',
      'X-API-KEY': acmToken || '',
      'Content-Type': 'application/json',
    },
  }).then(async (response: Response) => {
    if (response.status >= 300) {
      throw await makeRequestError(response);
    } else {
      try {
        return (await response.json()) as GenerateLinksAutomaticallyResponse;
      } catch (e) {
        throw new Error(`Invalid response from server: ${e}`);
      }
    }
  });
};

export default postGenerateLinksAutomatically;
