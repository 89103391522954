/*
 * This file contains generic helper functions for Promise-based type-safe communication across iframes.
 * Parent windows can make requests to iframes and receive responses, or the other way around.
 * Broadcasts are also supported, which are one-way messages across iframe boundaries.
 * See the bottom of this file for an example of how to use this.
 */

import ManualPromise from '../util/manual-promise';

export type BaseAdapterMethod = (params: any) => Promise<any>;
export type BaseAdapter = Record<string, BaseAdapterMethod>;
export type ConcreteAdapter<T extends BaseAdapter> = T;

export type BaseBroadcastAdapterMethod = (params: any) => void;
export type BaseBroadcastAdapter = Record<string, BaseBroadcastAdapterMethod>;
export type ConcreteBroadcastAdapter<T extends BaseBroadcastAdapter> = T;

export type NoFunctions = ConcreteAdapter<{}>;

function randomString() {
  return Math.random().toString(36).substring(2, 15);
}

type IFrameEventRequest = {
  acmIFrameAdapterRequest: true
  requestId: string
  type: string
  params: any
}
function isIFrameEventRequest(data: any): data is IFrameEventRequest {
  return data?.acmIFrameAdapterRequest === true && data.requestId && data.type;
}

type IFrameEventResponse = {
  acmIFrameAdapterResponse: true
  requestId: string
  success: boolean
  result: any
}
function isIFrameEventResponse(data: any): data is IFrameEventResponse {
  return data?.acmIFrameAdapterResponse === true && data.requestId && data.success !== undefined;
}

type IFrameBroadcastEvent = {
  acmIFrameAdapterBroadcast: true
  type: string
  params: any
}
function isIFrameBroadcastEvent(data: any): data is IFrameBroadcastEvent {
  return data?.acmIFrameAdapterBroadcast === true && data.type;
}

export type AdapterMetaMethods = {
  disconnect: () => void
}

export type TargetWindowsDefinition = Window[] | (() => Window[]);

function sendAny(payload: any, targetWindowsDef: TargetWindowsDefinition) {
  const stringified = JSON.stringify(payload);
  const actualTargetWindows = Array.isArray(targetWindowsDef) ? targetWindowsDef : targetWindowsDef();
  for (const w of actualTargetWindows) {
    w.postMessage(stringified, '*');
  }
}

export function makeIFrameAdapter<TOther extends BaseAdapter, TSelf extends BaseAdapter>(listener: TSelf, targetWindows: TargetWindowsDefinition): TOther & AdapterMetaMethods {
  const responsePromises = new Map<string, ManualPromise<any>>();

  function handleIncomingResponse(data: IFrameEventResponse) {
    const prom = responsePromises.get(data.requestId);
    responsePromises.delete(data.requestId);
    if (data.success) {
      prom?.resolve(data.result);
    } else {
      prom?.reject(data.result);
    }
  }

  function handleIncomingRequest(data: IFrameEventRequest) {
    const requestHandlerFunction = listener[data.type];
    if (!requestHandlerFunction) {
      return;
    }
    requestHandlerFunction(data.params).then((result) => sendResponse({
      requestId: data.requestId,
      success: true,
      result,
    })).catch((error) => sendResponse({
      requestId: data.requestId,
      success: false,
      result: error,
    }));
  }

  const eventListener = (event: MessageEvent) => {
    let parsedData;
    try {
      parsedData = JSON.parse(event.data);
    } catch (e) {
      return; // ignored, not a JSON message
    }
    if (isIFrameEventRequest(parsedData)) {
      handleIncomingRequest(parsedData);
    } else if (isIFrameEventResponse(parsedData)) {
      handleIncomingResponse(parsedData);
    }
  };
  window.addEventListener('message', eventListener);
  const disconnectFunction = () => window.removeEventListener('message', eventListener);

  function sendRequest(event: { requestId: string, type: string, params: any }) {
    const payload: IFrameEventRequest = {
      acmIFrameAdapterRequest: true,
      ...event,
    };
    sendAny(payload, targetWindows);
  }

  function sendResponse(event: { requestId: string, success: boolean, result: any }) {
    const payload: IFrameEventResponse = {
      acmIFrameAdapterResponse: true,
      ...event,
    };
    sendAny(payload, targetWindows);
  }

  return new Proxy({} as TOther & AdapterMetaMethods, {
    get: ((target, prop): BaseAdapterMethod | AdapterMetaMethods['disconnect'] | undefined => {
      if (typeof prop !== 'string') {
        return undefined;
      }
      if (prop === 'disconnect') {
        return disconnectFunction;
      }
      return (params) => {
        const prom = new ManualPromise<any>();
        const requestId = randomString();
        responsePromises.set(requestId, prom);
        sendRequest({ requestId, type: prop, params });
        return prom;
      };
    }),
  });
}

export function makeIFrameAdapterFromInside<TOther extends BaseAdapter, TSelf extends BaseAdapter>(handler: TSelf) {
  return makeIFrameAdapter<TOther, TSelf>(handler, [window.parent]);
}

function getAllIFrames(): Window[] {
  return Array.from(document.querySelectorAll('iframe')).map((f) => f.contentWindow).filter((w): w is Window => w !== null);
}

export function makeIFrameAdapterFromOutside<TOther extends BaseAdapter, TSelf extends BaseAdapter>(handler: TSelf) {
  return makeIFrameAdapter<TOther, TSelf>(handler, () => getAllIFrames());
}

/** This adapter will disconnect after the first request+response */
export function makeSingleUseIFrameAdapter<T extends BaseAdapter>(targetWindows: TargetWindowsDefinition): T {
  const adapter = makeIFrameAdapter<T, NoFunctions>({}, targetWindows);
  return new Proxy(adapter, {
    get: ((target, prop): BaseAdapterMethod | undefined => {
      if (typeof prop !== 'string') {
        return undefined;
      }
      return async (params) => {
        try {
          return await target[prop](params);
        } finally {
          adapter.disconnect();
        }
      };
    }),
  });
}

export function makeIFrameBroadcastListener<T extends BaseBroadcastAdapter>(listener: T): AdapterMetaMethods {
  const eventListener = (event: MessageEvent) => {
    let parsedData;
    try {
      parsedData = JSON.parse(event.data);
    } catch (e) {
      return; // ignored, not a JSON message
    }
    if (isIFrameBroadcastEvent(parsedData)) {
      listener[parsedData.type]?.(parsedData.params);
    }
  };
  window.addEventListener('message', eventListener);
  const disconnectFunction = () => window.removeEventListener('message', eventListener);

  return {
    disconnect: disconnectFunction,
  };
}

export function makeIFrameBroadcastSender<T extends BaseBroadcastAdapter>(targetWindows?: TargetWindowsDefinition): T {
  return new Proxy({} as T, {
    get: ((target, prop): BaseBroadcastAdapterMethod | undefined => {
      if (typeof prop !== 'string') {
        return undefined;
      }
      return (params) => {
        const payload: IFrameBroadcastEvent = {
          acmIFrameAdapterBroadcast: true,
          type: prop,
          params,
        };
        sendAny(payload, targetWindows ?? getAllIFrames());
      };
    }),
  });
}

// @ts-ignore
// noinspection JSUnusedLocalSymbols
async function usageExampleAdapter() {
  // shared type declarations
  type InboundAdapter = ConcreteAdapter<{
    extractISINs: (params: { text: string }) => Promise<string[]>
  }>
  type OutboundAdapter = ConcreteAdapter<{
    reloadEditor: () => Promise<void>
    getArticleTextState: () => Promise<{ articleText: string }>
  }>

  // inside iframe
  const myInsideAdapter: InboundAdapter = {
    extractISINs: async ({ text: _ }) => (['DE000A0D6554']),
  };
  const outer = makeIFrameAdapterFromInside<OutboundAdapter, InboundAdapter>(myInsideAdapter);
  const state = await outer.getArticleTextState();
  if (state.articleText === '') {
    outer.reloadEditor().catch(console.error);
  }

  // outside iframe, i.e. in the parent window
  const myOutsideAdapter: OutboundAdapter = {
    reloadEditor: async () => {},
    getArticleTextState: async () => ({ articleText: 'foobar' }),
  };
  const inner = makeIFrameAdapterFromOutside<InboundAdapter, OutboundAdapter>(myOutsideAdapter);
  inner.extractISINs({ text: 'DE000A0D6554' }).then((result) => console.log('extracted isins', result));
}

// @ts-ignore
// noinspection JSUnusedLocalSymbols
async function usageExampleBroadcast() {
  type BroadcastAdapter = ConcreteBroadcastAdapter<{
    stateChanged: (params: {newState: string}) => void
  }>

  // listening side, e.g. inside iframe
  makeIFrameBroadcastListener<BroadcastAdapter>({
    stateChanged: ({ newState }) => console.log('new state', newState),
  });

  // sending side, e.g. outside iframe
  const broadcaster = makeIFrameBroadcastSender<BroadcastAdapter>();
  broadcaster.stateChanged({ newState: 'foobar' });
}
