import { PlaylistAdd } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReplayIcon from '@mui/icons-material/Replay';
import SubjectIcon from '@mui/icons-material/Subject';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Popper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  ActionButton, ActionButtonGroup, FlexBoxColumn, FlexBoxRow, PurpleBaseColors,
} from '@purple/react-components';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isRestServicesRunning, REST_STATUS } from '../../../api/hooks/rest-service';
import { GenericLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import {
  CombinedLinkerAction,
  LinkType,
  linkTypeFilter,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../context/linker/combined-linker-context';
import { useLinkerEditorPageService } from '../../../context/linker/linker-editor-context';
import { useLinkerProfilesService, useLinkerProfilesState } from '../../../context/profiles/linker-profiles-context';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import PopperClickAwayListener from '../../util/popper-click-away-listener';
import ArticleProfileInfoButton from '../article-linker/profile/article-profile-info-button';
import ProductProfileInfoButton from '../product-linker/profile/product-profile-info-button';
import {
  ArticleLinkerProfileSelector,
  ProductLinkerProfileSelector,
} from './profile/linker-profile-selector';
import { ConcreteProfileInfoButtonProps } from './profile/profile-info-button';

type Props<P extends GenericLinkerProfileResponse> = ControlProps<P> | {
  linkType: null
}

interface ControlProps<P extends GenericLinkerProfileResponse> {
  linkType: LinkType
  ProfileSelector: () => JSX.Element
  ProfileInfoButton: (props: ConcreteProfileInfoButtonProps<P>) => JSX.Element
  computeAction: CombinedLinkerAction.COMPUTE_ARTICLE_RECOMMENDATIONS | CombinedLinkerAction.COMPUTE_PRODUCT_RECOMMENDATIONS
  selectedProfile: P | undefined
}

export function HeaderControls<P extends GenericLinkerProfileResponse>(props: Props<P>) {
  const { t } = useTranslation();
  const { linkType } = props;
  const {
    articleId,
    combinedLinkRecommendationElements,
    showSelections,
    collapsedLinkIds,
  } = useCombinedLinkerPageState();
  const dispatch = useCombinedLinkerPageDispatch();

  const hasRecommendations = useMemo(() => (
    Array.from(combinedLinkRecommendationElements.keys()).filter((r) => linkTypeFilter(r, linkType)).length > 0
  ), [articleId, linkType, combinedLinkRecommendationElements]);

  const handleCollapseAllClick = () => {
    dispatch({ type: CombinedLinkerAction.COLLAPSE_ALL });
  };

  const handleExpandAllClick = () => {
    dispatch({ type: CombinedLinkerAction.EXPAND_ALL });
  };

  const isAnyLinkCollapsed = collapsedLinkIds.size > 0;

  const handleCollapseExpandClick = () => {
    if (isAnyLinkCollapsed) {
      handleExpandAllClick();
    } else {
      handleCollapseAllClick();
    }
  };

  return (
    <FlexBoxColumn alignItems="stretch" gap="8px" mt="8px">
      {linkType ? (
        <HeaderControlRow {...props} />
      ) : (
        <Box height={HEADER_CONTROLS_HEIGHT} />
      )}
      {articleId && (
        <FlexBoxRow
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: '10px', height: '25px' }}
        >
          <Typography
            variant="body2"
            color="textPrimary"
          >
            {t('lo-create-manually-hint')}
          </Typography>
          {hasRecommendations && !showSelections && (
            <Tooltip title={isAnyLinkCollapsed ? t('lo-expand-all') : t('lo-collapse-all')}>
              <IconButton
                size="small"
                color="inherit"
                onClick={handleCollapseExpandClick}
              >
                {isAnyLinkCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
              </IconButton>
            </Tooltip>
          )}
        </FlexBoxRow>
      )}
    </FlexBoxColumn>
  );
}
export const HEADER_CONTROLS_HEIGHT = '32px';

function HeaderControlRow<P extends GenericLinkerProfileResponse>(props: ControlProps<P>) {
  const {
    ProfileSelector,
    ProfileInfoButton,
    computeAction,
    selectedProfile,
    linkType,
  } = props;
  const { t } = useTranslation();
  const { articleId, combinedLinkRecommendations, removedCombinedLinkRecommendations } = useCombinedLinkerPageState();
  const {
    getCombinedLinksRecommendationService,
    postArticleLinksRecommendationService,
    postArticleLinkRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  } = useCombinedLinkerPageService()[articleId];
  const articleByIdService = useLinkerEditorPageService()[articleId];
  const dispatch = useCombinedLinkerPageDispatch();

  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const moreOptionsAnchorRef = useRef<HTMLDivElement>(null);

  const doCompute = () => {
    dispatch({ type: computeAction, clean: false });
  };
  const doCleanCompute = () => {
    dispatch({ type: computeAction, clean: true });
    setMoreOptionsOpen(false);
  };

  const toggleMoreOptions = () => setMoreOptionsOpen((v) => !v);

  const hasLinks = [
    ...(combinedLinkRecommendations?.article_links ?? []),
    ...(combinedLinkRecommendations?.product_links ?? []),
  ].filter((r) => linkTypeFilter(r, linkType)).length > 0;

  const hasUnsavedDeletions = (removedCombinedLinkRecommendations?.article_links ?? []).length > 0
    || (removedCombinedLinkRecommendations?.product_links ?? []).length > 0;
  const disableGenerationButton = isRestServicesRunning([
    articleByIdService,
    getCombinedLinksRecommendationService,
    postArticleLinksRecommendationService,
    postArticleLinkRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  ]) || hasUnsavedDeletions || !articleId;

  useEffect(() => {
    if (moreOptionsOpen && hasUnsavedDeletions) {
      setMoreOptionsOpen(false);
    }
  }, [moreOptionsOpen, hasUnsavedDeletions]);

  // Button dropdown idea from: https://mui.com/material-ui/react-button-group/#split-button
  return (
    <FlexBoxRow
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
      height={HEADER_CONTROLS_HEIGHT}
    >
      <Box flexShrink="0">
        <Tooltip title={hasUnsavedDeletions ? 'Before you can generate more links, you need to save (or undo) your deletions' : ''}>
          <ActionButtonGroup ref={moreOptionsAnchorRef}>
            <ActionButton
              icon={hasLinks ? <PlaylistAdd /> : <SubjectIcon />}
              disabled={disableGenerationButton}
              onClick={doCompute}
              sx={{ height: HEADER_CONTROLS_HEIGHT, '&:hover': { zIndex: 1 } }}
            >
              {hasLinks ? t('lo-generate-more-links') : t('lo-generate-links')}
            </ActionButton>
            {hasLinks && (
              <ActionButton
                onClick={toggleMoreOptions}
                disabled={disableGenerationButton}
                sx={{
                  width: 30,
                  height: HEADER_CONTROLS_HEIGHT,
                  // This is the default color in our theme that is used for a hovered action button.
                  // -> We want this color to stay as long as the more-options-dropdown is shown.
                  borderColor: moreOptionsOpen ? PurpleBaseColors.purpleLight : undefined,
                }}
                data-testid="generate-options-dropdown-button"
              >
                <ArrowDropDownIcon />
              </ActionButton>
            )}
          </ActionButtonGroup>
        </Tooltip>
        <Popper open={moreOptionsOpen} anchorEl={moreOptionsAnchorRef.current} placement="bottom">
          <Paper sx={{ borderRadius: '9999px', mt: '1px' }}>
            <PopperClickAwayListener
              parentRef={moreOptionsAnchorRef}
              onClickAway={() => setMoreOptionsOpen(false)}
            >
              <div>
                <ActionButton
                  icon={<ReplayIcon />}
                  disabled={getCombinedLinksRecommendationService.status !== REST_STATUS.LOADED}
                  onClick={doCleanCompute}
                >
                  {t('lo-generate-new-links')}
                </ActionButton>
              </div>
            </PopperClickAwayListener>
          </Paper>
        </Popper>
      </Box>
      <Box flex="1 1 0px" />
      <ProfileSelector />
      <ProfileInfoButton profile={selectedProfile} />
    </FlexBoxRow>
  );
}

export function ArticleHeaderControls() {
  const { getArticleLinkerProfilesService } = useLinkerProfilesService();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  const { selectedGlobalArticleProfileId } = useCombinedLinkerPageState();

  return (
    <DataLoading
      service={getArticleLinkerProfilesService}
      errorMessage="Couldn't load recommendation profiles"
    >
      <HeaderControls
        linkType={LinkType.ARTICLE}
        ProfileSelector={ArticleLinkerProfileSelector}
        ProfileInfoButton={ArticleProfileInfoButton}
        computeAction={CombinedLinkerAction.COMPUTE_ARTICLE_RECOMMENDATIONS}
        selectedProfile={articleLinkerProfiles.get(selectedGlobalArticleProfileId)}
      />
    </DataLoading>
  );
}

export function ProductHeaderControls() {
  const { getProductLinkerProfilesService } = useLinkerProfilesService();
  const { productLinkerProfiles } = useLinkerProfilesState();
  const { selectedGlobalProductProfileId } = useCombinedLinkerPageState();

  return (
    <DataLoading
      service={getProductLinkerProfilesService}
      errorMessage="Couldn't load recommendation profiles"
    >
      <HeaderControls
        linkType={LinkType.PRODUCT}
        ProfileSelector={ProductLinkerProfileSelector}
        ProfileInfoButton={ProductProfileInfoButton}
        computeAction={CombinedLinkerAction.COMPUTE_PRODUCT_RECOMMENDATIONS}
        selectedProfile={productLinkerProfiles.get(selectedGlobalProductProfileId)}
      />
    </DataLoading>
  );
}
