import { CircularProgress } from '@mui/material';
import { FlexBoxRow } from '@purple/react-components';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function NotFoundPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // we need to wait a bit before showing the 404 page,
    // because it could be that routes are still loading,
    // and we would in this case wrongly show the 404 page for a split second
    const t = setTimeout(() => {
      setVisible(true);
    }, 5000);
    return () => clearTimeout(t);
  }, []);

  if (!visible) {
    // just show a spinner while we wait to be sure this is really a 404 case
    return (
      <FlexBoxRow justifyContent="center">
        <CircularProgress />
      </FlexBoxRow>
    );
  }

  return (
    <div style={{ padding: 40 }}>
      <h1>404 Page not found</h1>
      <p>
        Either the URL
        &quot;{location.pathname}&quot;
        does not exist, or you do not have the rights to see it.
      </p>
      <p>
        <button
          type="button"
          onClick={() => navigate('/')}
        >
          Click here to return to the dashboard.
        </button>
      </p>
    </div>
  );
}
