import * as React from 'react';
import { UserResponse } from '../../api/interfaces/user';

/**
 * This class defines a button-action that an admin can click on in the customer table overview.
 */
export default class AdminAction {
  public static ABORT_TOKEN = 'aborted by user';

  public shortLabel: string;

  constructor(
    public label: string,
    public icon: React.ReactElement,
    private action: (user: UserResponse) => Promise<any> | any,
    public showPopups: boolean = true,
    public readonly color: string | undefined = undefined,
  ) {
    if (label.split(' ').length === 1) {
      this.shortLabel = label;
    } else {
      this.shortLabel = label.split(' ').map((word) => `${word[0].toUpperCase()}.`).join('');
    }
  }

  async execute(user: UserResponse): Promise<any> {
    if (!this.showPopups || confirm(`Run action\n\n      "${this.label}"\n\nfor user\n\n     "${user.name}"\n\n?`)) {
      return this.action(user);
    }

    return Promise.resolve(AdminAction.ABORT_TOKEN);
  }
}
