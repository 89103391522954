import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import { ConfirmationDialog, HelpHoverButton, TextInput } from '@purple/react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeletionReasonType } from '../../../../api/interfaces/recommendation';

interface Props {
  dialogOpen: boolean
  handleDialogConfirmation: (reason: string) => void
  closeDialog: () => void
  textSelection: string
  showBadResultsOption?: boolean
}

export default function LinkRecommendationDeleteDialog(props: Props) {
  const {
    dialogOpen, handleDialogConfirmation, closeDialog, showBadResultsOption = true, textSelection,
  } = props;
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>('article_context');
  const [otherReason, setOtherReason] = useState<string>('');

  function handleDialogFinish() {
    let deletionReason;
    switch (reason) {
      case 'relevant_selection': // bad selection
        deletionReason = DeletionReasonType.BAD_SELECTION.valueOf();
        break;
      case 'article_context': // bad selection in context
        deletionReason = DeletionReasonType.BAD_SELECTION_IN_CONTEXT.valueOf();
        break;
      case 'link_recommendations': // bad recommendation results
        deletionReason = DeletionReasonType.BAD_RESULTS.valueOf();
        break;
      case 'other': // other reason
        deletionReason = otherReason;
        break;
      default:
        deletionReason = DeletionReasonType.NONE.valueOf();
    }
    handleDialogConfirmation(deletionReason);
  }

  function handleReasonChange(newReason: string) {
    setReason(newReason);
    setOtherReason('');
  }

  return (
    <ConfirmationDialog
      dialogTitle={t('lo-delete-why')}
      confirmText={t('lo-delete-save')}
      cancelText={t('lo-delete-cancel')}
      open={dialogOpen}
      onConfirm={() => handleDialogFinish()}
      onCancel={() => closeDialog()}
      confirmDisabled={reason === 'other' && otherReason === ''}
    >
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="reason"
          name="reason2"
          value={reason}
          onChange={((event) => handleReasonChange(event.target.value))}
        >
          <FormControlLabel
            value="relevant_selection"
            control={<Radio color="primary" />}
            label={
              <>
                {t('lo-delete-selection-not-relevant')}
                <HelpHoverButton hoverText={t('lo-delete-selection-not-relevant-tooltip', { textSelection })} />
              </>
            }
          />
          <FormControlLabel
            value="article_context"
            control={<Radio color="primary" />}
            label={
              <>
                {t('lo-delete-selection-not-relevant-in-context')}
                <HelpHoverButton hoverText={t('lo-delete-selection-not-relevant-in-context-tooltip', { textSelection })} />
              </>
            }
          />
          {showBadResultsOption && (
            <FormControlLabel
              value="link_recommendations"
              control={<Radio color="primary" />}
              label={
                <>
                  {t('lo-delete-bad-results')}
                  <HelpHoverButton hoverText={t('lo-delete-bad-results-tooltip', { textSelection })} />
                </>
              }
            />
          )}
          <FormControlLabel
            value="other"
            control={<Radio color="primary" />}
            label={
              <>
                {t('lo-delete-other')}
                <HelpHoverButton hoverText={t('lo-delete-other-tooltip')} />
              </>
              }
          />
        </RadioGroup>
        {(reason === 'other') && (
          <TextInput
            autoFocus
            fullWidth
            margin="dense"
            label={t('lo-delete-deletion-reason')}
            value={otherReason}
            setValue={setOtherReason}
            data-testid="deletion-reason-input"
          />
        )}
      </FormControl>
    </ConfirmationDialog>
  );
}
