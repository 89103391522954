import { Box, BoxProps } from '@mui/material';
import { PurpleBaseColors, sxMerge } from '@purple/react-components';

function getColor(value: number) {
  if (value < 0.5) {
    return PurpleBaseColors.error;
  } else if (value < 1) {
    return PurpleBaseColors.yellow;
  } else {
    return PurpleBaseColors.success;
  }
}

type Props = BoxProps & {
  value: number
}

export default function ProgressBar(props: Props) {
  const {
    value, children, sx: propsSx, ...restProps
  } = props;

  return (
    <Box
      sx={sxMerge({
        height: '20px',
        backgroundColor: PurpleBaseColors.neutralLightGray,
        borderRadius: '10px',
        overflow: 'hidden',
        position: 'relative',
      }, propsSx)}
      {...restProps}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: `${value * 100}%`,
          backgroundColor: getColor(value),
          borderRadius: '10px',
          transition: 'all 0.3s ease',
        }}
      />
      {children && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
