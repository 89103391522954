import { useEffect, useRef } from 'react';

/**
 * run the given callback on every AnimationFrame
 */
export default function useEveryFrame(callback: () => void) {
  const requestRef = useRef<number>(-1);

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate);
    callback();
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [callback]);
}
