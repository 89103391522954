import { STEntry, STGroup } from '@purple/react-components';
import * as React from 'react';
import { GenericLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import { makeObjectUpdater, ProfileAttributeCheckbox, ProfileAttributeSlider } from './slider-helper';

interface Props<T extends GenericLinkerProfileResponse> {
  recommendedEntitiesName: string // e.g. "Articles" or "Products"
  linkProfile: T
  setLinkProfile: React.Dispatch<React.SetStateAction<T>>
  showProductSpecificProperties?: boolean
}

export default function ArticleProfileSliders<T extends GenericLinkerProfileResponse>(props: Props<T>) {
  const {
    recommendedEntitiesName, linkProfile, setLinkProfile, showProductSpecificProperties,
  } = props;
  const updateLinkProfile = makeObjectUpdater(setLinkProfile);
  const {
    selection_length_weight,
    preferred_selection_length,
    selection_to_source_weight,
    avg_links_per_paragraph,
    articles_per_selection,
    include_generic_products,
  } = linkProfile.article_parameters;

  function setAvgLinksPerParagraph(newValue: number) {
    updateLinkProfile((lp) => lp.article_parameters.avg_links_per_paragraph = newValue);
  }

  function setArticlesPerSelection(newValue: number) {
    updateLinkProfile((lp) => lp.article_parameters.articles_per_selection = newValue);
  }

  function setPreferredSelectionLength(newValue: number) {
    updateLinkProfile((lp) => lp.article_parameters.preferred_selection_length = newValue);
  }

  function setSelectionLengthWeight(newValue: number) {
    updateLinkProfile((lp) => lp.article_parameters.selection_length_weight = newValue);
  }

  function setSelectionToSourceWeight(newValue: number) {
    updateLinkProfile((lp) => lp.article_parameters.selection_to_source_weight = newValue);
  }

  function setIncludeGenericProducts(newValue: boolean) {
    updateLinkProfile((lp) => lp.article_parameters.include_generic_products = newValue);
  }

  return (
    <>
      <STGroup>
        <STEntry
          label="Average Links Per Paragraph"
          helpText="Define how many links (per paragraph on average) the article should have. Existing links of any kind will be taken into account when generating new links to match the desired link count."
        >
          <ProfileAttributeSlider
            value={avg_links_per_paragraph}
            setValue={setAvgLinksPerParagraph}
            min={0.0}
            max={2.0}
            step={0.1}
            testId="average-links-per-paragraph"
          />
        </STEntry>
        <STEntry
          label={`${recommendedEntitiesName} Per Selection`}
          helpText={`Define how many ${recommendedEntitiesName} should be suggested per selection. Setting this to a higher number gives you more ${recommendedEntitiesName} to choose from, but also slows down the process.`}
        >
          <ProfileAttributeSlider
            value={articles_per_selection}
            setValue={setArticlesPerSelection}
            min={1}
            max={10}
            testId={`${recommendedEntitiesName.toLowerCase()}-per-selection`}
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Preferred Selection Length"
          helpText="Define how long (in amount of words) link selections should ideally be. Longer and shorter link selections might still get generated, although less likely and with a lower score."
        >
          <ProfileAttributeSlider
            value={preferred_selection_length}
            setValue={setPreferredSelectionLength}
            min={1}
            max={5}
            testId="preferred-selection-length"
          />
        </STEntry>
        <STEntry
          label="Selection Length Weight"
          helpText="Define how important it is for a link selection to have the correct amount of words. When set to 0, link selections of all lengths are scored equally."
        >
          <ProfileAttributeSlider
            value={selection_length_weight}
            setValue={setSelectionLengthWeight}
            min={0}
            max={100}
            testId="selection-length-weight"
          />
        </STEntry>
        <STEntry
          label="Selection to Source Article Weight"
          helpText="Define how important it is for a link selection to have a similar content as the whole article the link is placed inside. Setting this to a high value ensures that those link selections that are most representative of the article are preferred."
        >
          <ProfileAttributeSlider
            value={selection_to_source_weight}
            setValue={setSelectionToSourceWeight}
            min={0}
            max={100}
            testId="selection-to-source-article-weight"
          />
        </STEntry>
      </STGroup>
      {showProductSpecificProperties && (
        <STGroup>
          <STEntry
            label="Include Generic Products"
            helpText="Check this to include generic products (like 'iPhone') or product categories (like 'Smartphone') in the link suggestions. When unchecked, only concrete product names (like 'iPhone 14') are used to generate links."
          >
            <ProfileAttributeCheckbox
              value={include_generic_products}
              setValue={setIncludeGenericProducts}
              testId="include-generic-products"
            />
          </STEntry>
        </STGroup>
      )}
    </>
  );
}
