import isEqual from 'lodash/isEqual';
import * as React from 'react';
import {
  RefObject, useContext, useEffect, useMemo, useReducer,
} from 'react';
import useGetCombinedLinksRecommendationService
  from '../../api/hooks/linker/links/combined/get-combined-links-recommendation';
import usePostCombinedArticleLinkRecommendationService
  from '../../api/hooks/linker/links/combined/post-article-link-recommendation';
import usePostCombinedArticleLinksRecommendationService
  from '../../api/hooks/linker/links/combined/post-article-links-recommendation';
import usePostCombinedLinksSaveService, {
  SelectedCombinedLinks,
} from '../../api/hooks/linker/links/combined/post-combined-links-save';
import usePostCombinedProductLinkRecommendationService
  from '../../api/hooks/linker/links/combined/post-product-link-recommendation';
import usePostCombinedProductLinksRecommendationService
  from '../../api/hooks/linker/links/combined/post-product-links-recommendation';
import { markAsNoContent, REST_STATUS, RESTService } from '../../api/hooks/rest-service';
import { ArticleScoreResponse } from '../../api/interfaces/article';
import {
  ArticleLinkRecommendationResponse,
  CombinedRecommendationsResponse,
  ProductLinkRecommendationResponse,
  ProductScoreResponse,
  RecommendationOriginType,
} from '../../api/interfaces/recommendation';
import { MessageResponse, SelectedPhraseData } from '../../constants/interfaces/interfaces';
import { performanceEnd, performanceStart } from '../../util/performance';
import { assertNever } from '../../util/reducer-utils';
import { useACMUserState } from '../acm-page-context';
import { useLinkerProfilesState } from '../profiles/linker-profiles-context';
import { LinkerEditorAction, useLinkerEditorPageDispatch, useLinkerEditorPageState } from './linker-editor-context';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

export type CombinedLinkRecommendationResponse = ArticleLinkRecommendationResponse | ProductLinkRecommendationResponse;
type CombinedRecommendationElement = ArticleScoreResponse | ProductScoreResponse

export function isArticleRR(recommendation?: CombinedLinkRecommendationResponse): recommendation is ArticleLinkRecommendationResponse {
  return recommendation !== undefined && (recommendation as ArticleLinkRecommendationResponse).articles !== undefined;
}

export function isProductRR(recommendation?: CombinedLinkRecommendationResponse): recommendation is ProductLinkRecommendationResponse {
  return recommendation !== undefined && (recommendation as ProductLinkRecommendationResponse).products !== undefined;
}

export function isArticleR(recommendation: CombinedRecommendationElement): recommendation is ArticleScoreResponse {
  return !isProductR(recommendation);
}

export function isProductR(recommendation: CombinedRecommendationElement): recommendation is ProductScoreResponse {
  return (recommendation as ProductScoreResponse).asin !== undefined;
}

export enum LinkType {
  ARTICLE = 'article',
  PRODUCT = 'product',
  ALL = 'all',
}

/** Check if the given response object should be visible, given a LinkType filter */
export function linkTypeFilter(rec: CombinedLinkRecommendationResponse, linkType: LinkType | null): boolean {
  if (!linkType || linkType === LinkType.ALL) return true;
  if (linkType === LinkType.ARTICLE) return isArticleRR(rec);
  if (linkType === LinkType.PRODUCT) return isProductRR(rec);
  throw assertNever(linkType);
}

export interface RecommendationElement {
  ref: RefObject<any>
}

export interface RecommendationElementPair {
  entityEl?: RecommendationElement
  detailsEl?: RecommendationElement
}

export interface CombinedLinkerPageState {
  articleId: string
  currentLinkTypeFilter: LinkType | null
  selectedGlobalArticleProfileId?: string
  selectedPhraseArticleProfileId?: string
  selectedGlobalProductProfileId?: string
  selectedPhraseProductProfileId?: string
  combinedLinkRecommendations?: CombinedRecommendationsResponse
  doArticleLinkRecommendation: boolean
  doProductLinkRecommendation: boolean
  scrollTarget?: CombinedLinkRecommendationResponse
  savedRecommendation?: SelectedCombinedLinks[]
  removedCombinedLinkRecommendations?: CombinedRecommendationsResponse // TODO instead only use two lists of links
  combinedLinkRecommendationSelections: Map<CombinedLinkRecommendationResponse, CombinedRecommendationElement>
  showSelections?: CombinedLinkRecommendationResponse
  combinedLinkRecommendationElements: Map<CombinedLinkRecommendationResponse, RecommendationElementPair>
  scrollToRecommendationDetails?: boolean
  scrollToRecommendationEntity?: boolean
  hasUnsavedChanges: boolean
  collapsedLinkIds: Set<string>
  articlePhraseData?: SelectedPhraseData
  productPhraseData?: SelectedPhraseData
}

interface Props {
  articleId?: string
  children: React.ReactNode
}

export interface SingleService {
  getCombinedLinksRecommendationService: RESTService<CombinedRecommendationsResponse>
  postArticleLinksRecommendationService: RESTService<CombinedRecommendationsResponse>
  postArticleLinkRecommendationService: RESTService<CombinedRecommendationsResponse>
  postProductLinksRecommendationService: RESTService<CombinedRecommendationsResponse>
  postProductLinkRecommendationService: RESTService<CombinedRecommendationsResponse>
  saveAllCombinedLinksService: RESTService<MessageResponse>
}

export interface Service {
  [articleId: string]: SingleService
}

let services: Service = {};

export enum CombinedLinkerAction {
  UPDATE_ARTICLE_ID,
  // Profile Related
  SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID,
  SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID,
  SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID,
  SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID,

  // Combined Recommendation Related
  INIT_COMBINED_RECOMMENDATIONS,
  SET_COMBINED_RECOMMENDATIONS,
  CLEAR_COMBINED_RECOMMENDATIONS,
  COMPUTE_ARTICLE_RECOMMENDATIONS,
  COMPUTE_PRODUCT_RECOMMENDATIONS,

  // Selection Links and their Recommendations Related
  SELECT_RECOMMENDATION,
  SHOW_LINK_RECOMMENDATIONS,
  CLEAR_LINK_RECOMMENDATIONS,
  SCROLL_TO_RECOMMENDATION_DETAILS,
  SCROLL_TO_RECOMMENDATION_DETAILS_FINISHED,
  SCROLL_TO_RECOMMENDATION_ENTITY,
  SCROLL_TO_RECOMMENDATION_ENTITY_FINISHED,
  FILTER_LINKS_BY_TYPE,

  // Saving Links Related
  REMOVE_RECOMMENDATION,
  ADD_RECOMMENDATION,
  SAVE_COMBINED_RECOMMENDATIONS,
  SAVE_COMBINED_RECOMMENDATIONS_FINISH,

  // Visuals
  COLLAPSE_SINGLE,
  EXPAND_SINGLE,
  COLLAPSE_ALL,
  EXPAND_ALL,

  // Connection Line Related
  ADD_ENTITY_ELEMENT,
  ADD_DETAILS_ELEMENT,

  // Phrase Recommendation Related
  DO_ARTICLE_PHRASE_RECOMMENDATION,
  DO_PRODUCT_PHRASE_RECOMMENDATION,
  CLEAR_ARTICLE_PHRASE_SELECTION,
  CLEAR_PRODUCT_PHRASE_SELECTION,
}

export interface ArticleLinkRecommendationSelection {
  recommendation: ArticleLinkRecommendationResponse
  item: ArticleScoreResponse
}

export interface ProductLinkRecommendationSelection {
  recommendation: ProductLinkRecommendationResponse
  item: ProductScoreResponse
}

type Action =
  | { type: CombinedLinkerAction.UPDATE_ARTICLE_ID, payload: string }
  | { type: CombinedLinkerAction.SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID, payload?: string }
  | { type: CombinedLinkerAction.SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID, payload?: string }
  | { type: CombinedLinkerAction.SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID, payload?: string }
  | { type: CombinedLinkerAction.SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID, payload?: string }
  | { type: CombinedLinkerAction.CLEAR_COMBINED_RECOMMENDATIONS }
  | { type: CombinedLinkerAction.INIT_COMBINED_RECOMMENDATIONS, payload: CombinedRecommendationsResponse}
  | { type: CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS, payload: CombinedRecommendationsResponse}
  | { type: CombinedLinkerAction.COMPUTE_ARTICLE_RECOMMENDATIONS, clean: boolean }
  | { type: CombinedLinkerAction.COMPUTE_PRODUCT_RECOMMENDATIONS, clean: boolean }
  | { type: CombinedLinkerAction.SELECT_RECOMMENDATION, linkType: LinkType.ARTICLE, payload: ArticleLinkRecommendationSelection }
  | { type: CombinedLinkerAction.SELECT_RECOMMENDATION, linkType: LinkType.PRODUCT, payload: ProductLinkRecommendationSelection }
  | { type: CombinedLinkerAction.SHOW_LINK_RECOMMENDATIONS, payload: CombinedLinkRecommendationResponse }
  | { type: CombinedLinkerAction.CLEAR_LINK_RECOMMENDATIONS }
  | { type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS, payload: CombinedLinkRecommendationResponse }
  | { type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS_FINISHED }
  | { type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY, payload: CombinedLinkRecommendationResponse }
  | { type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY_FINISHED }
  | { type: CombinedLinkerAction.FILTER_LINKS_BY_TYPE, payload: LinkType | null }
  | { type: CombinedLinkerAction.REMOVE_RECOMMENDATION, payload: { entityDataId: string, reason: string } }
  | { type: CombinedLinkerAction.ADD_RECOMMENDATION, payload: { entityDataId: string } }
  | { type: CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS }
  | { type: CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS_FINISH }
  | { type: CombinedLinkerAction.COLLAPSE_SINGLE, payload: CombinedLinkRecommendationResponse }
  | { type: CombinedLinkerAction.EXPAND_SINGLE, payload: CombinedLinkRecommendationResponse }
  | { type: CombinedLinkerAction.COLLAPSE_ALL }
  | { type: CombinedLinkerAction.EXPAND_ALL }
  | { type: CombinedLinkerAction.ADD_ENTITY_ELEMENT, payload: { recommendation: CombinedLinkRecommendationResponse, entity: RecommendationElement } }
  | { type: CombinedLinkerAction.ADD_DETAILS_ELEMENT, payload: { recommendation: CombinedLinkRecommendationResponse, details: RecommendationElement } }
  | { type: CombinedLinkerAction.DO_ARTICLE_PHRASE_RECOMMENDATION, payload?: SelectedPhraseData }
  | { type: CombinedLinkerAction.DO_PRODUCT_PHRASE_RECOMMENDATION, payload?: SelectedPhraseData }
  | { type: CombinedLinkerAction.CLEAR_ARTICLE_PHRASE_SELECTION }
  | { type: CombinedLinkerAction.CLEAR_PRODUCT_PHRASE_SELECTION }

export type CombinedLinkerPageDispatch = (action: Action) => void

const defaultState: CombinedLinkerPageState = {
  articleId: '',
  currentLinkTypeFilter: null,
  doArticleLinkRecommendation: false,
  doProductLinkRecommendation: false,
  articlePhraseData: undefined,
  productPhraseData: undefined,
  combinedLinkRecommendationElements: new Map(),
  combinedLinkRecommendationSelections: new Map(),
  hasUnsavedChanges: false,
  collapsedLinkIds: new Set(),
};

const CombinedLinkerPageServiceContext = React.createContext<Service>(services);
const CombinedLinkerPageStateContext = React.createContext<CombinedLinkerPageState | undefined>(undefined);
const CombinedLinkerPageDispatchContext = React.createContext<CombinedLinkerPageDispatch | undefined>(undefined);

function combinedLinkerPageReducer(state: CombinedLinkerPageState, action: Action): CombinedLinkerPageState {
  const emptyRecommendations: Partial<CombinedLinkerPageState> = {
    combinedLinkRecommendations: undefined,
    scrollTarget: undefined,
    savedRecommendation: undefined,
    removedCombinedLinkRecommendations: undefined,
    combinedLinkRecommendationSelections: new Map(),
    showSelections: undefined,
    doArticleLinkRecommendation: false,
    doProductLinkRecommendation: false,
    articlePhraseData: undefined,
    productPhraseData: undefined,
  };

  function getStateForLoadingRecommendations(payload: CombinedRecommendationsResponse): CombinedLinkerPageState {
    const { article_links, product_links } = payload;
    state.combinedLinkRecommendationElements.clear();

    const defaultArticleSelections = article_links.map((link) => {
      let selected_article_index = link.articles.findIndex((article) => article.id === link.selected_suggestion_id);
      if (selected_article_index === -1) {
        selected_article_index = 0;
      }
      return [link, link.articles[selected_article_index]] as [CombinedLinkRecommendationResponse, CombinedRecommendationElement];
    });
    const defaultProductSelections = product_links.map((link) => {
      let selected_product_index = link.products.findIndex((product) => product.id === link.selected_suggestion_id);
      if (selected_product_index === -1) {
        selected_product_index = 0;
      }
      return [link, link.products[selected_product_index]] as [CombinedLinkRecommendationResponse, CombinedRecommendationElement];
    });
    const defaultSelections: Map<CombinedLinkRecommendationResponse, CombinedRecommendationElement> = new Map(
      [...defaultArticleSelections, ...defaultProductSelections],
    );
    const newLinkIds = new Set([...Array.from(defaultSelections.keys()).map((l) => l.id)]);

    return {
      ...state,
      ...emptyRecommendations,
      selectedGlobalArticleProfileId: payload.article_profile_id,
      selectedGlobalProductProfileId: payload.product_profile_id, // TODO(DEEP-375) also set the phrase profiles?
      combinedLinkRecommendations: {
        ...payload,
        article_links,
        product_links,
      },
      removedCombinedLinkRecommendations: {
        ...payload,
        article_links: [],
        product_links: [],
      },
      collapsedLinkIds: new Set(Array.from(state.collapsedLinkIds).filter((l) => newLinkIds.has(l))),
      combinedLinkRecommendationSelections: defaultSelections,
    };
  }

  switch (action.type) {
    case CombinedLinkerAction.UPDATE_ARTICLE_ID: {
      state.combinedLinkRecommendationElements.clear();
      return {
        ...state,
        ...emptyRecommendations,
        articleId: action.payload,
      };
    }
    case CombinedLinkerAction.SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID: {
      return {
        ...state,
        selectedGlobalArticleProfileId: action.payload,
        selectedPhraseArticleProfileId: action.payload, // selecting a global profile should also update the phrase profile
      };
    }
    case CombinedLinkerAction.SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID: {
      return {
        ...state,
        selectedGlobalProductProfileId: action.payload,
        selectedPhraseProductProfileId: action.payload, // selecting a global profile should also update the phrase profile
      };
    }
    case CombinedLinkerAction.SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID: {
      return {
        ...state,
        selectedPhraseArticleProfileId: action.payload || state.selectedGlobalArticleProfileId,
      };
    }
    case CombinedLinkerAction.SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID: {
      return {
        ...state,
        selectedPhraseProductProfileId: action.payload || state.selectedGlobalProductProfileId,
      };
    }
    case CombinedLinkerAction.CLEAR_COMBINED_RECOMMENDATIONS: {
      state.combinedLinkRecommendationElements.clear();
      return {
        ...state,
        ...emptyRecommendations,
      };
    }
    case CombinedLinkerAction.INIT_COMBINED_RECOMMENDATIONS: {
      return getStateForLoadingRecommendations(action.payload);
    }
    case CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS: {
      const newState = getStateForLoadingRecommendations(action.payload);

      // Preserve the previous link deletion state:
      // All new links, that were deleted before (checked by their ID), get deleted again
      const oldDeletedArticleLinkIDs = new Set(state.removedCombinedLinkRecommendations?.article_links.map((l) => l.id) ?? []);
      const oldDeletedProductLinkIDs = new Set(state.removedCombinedLinkRecommendations?.product_links.map((l) => l.id) ?? []);
      const removedArticleLinks = newState.combinedLinkRecommendations?.article_links.filter((l) => oldDeletedArticleLinkIDs.has(l.id)) ?? [];
      const removedProductLinks = newState.combinedLinkRecommendations?.product_links.filter((l) => oldDeletedProductLinkIDs.has(l.id)) ?? [];

      return {
        ...newState,
        combinedLinkRecommendations: newState.combinedLinkRecommendations && {
          ...newState.combinedLinkRecommendations,
          article_links: newState.combinedLinkRecommendations.article_links.filter((link) => !oldDeletedArticleLinkIDs.has(link.id)),
          product_links: newState.combinedLinkRecommendations.product_links.filter((link) => !oldDeletedProductLinkIDs.has(link.id)),
        },
        removedCombinedLinkRecommendations: newState.removedCombinedLinkRecommendations && {
          ...newState.removedCombinedLinkRecommendations,
          article_links: newState.removedCombinedLinkRecommendations.article_links.concat(removedArticleLinks),
          product_links: newState.removedCombinedLinkRecommendations.product_links.concat(removedProductLinks),
        },
        hasUnsavedChanges: !isEqual(state.combinedLinkRecommendationSelections, newState.combinedLinkRecommendationSelections),
      };
    }
    case CombinedLinkerAction.COMPUTE_ARTICLE_RECOMMENDATIONS: {
      let extraChanges: Partial<CombinedLinkerPageState> = {};
      if (action.clean) {
        if (state.combinedLinkRecommendations && state.removedCombinedLinkRecommendations) {
          extraChanges = {
            combinedLinkRecommendations: {
              ...state.combinedLinkRecommendations,
              article_links: [],
            },
            removedCombinedLinkRecommendations: {
              ...state.removedCombinedLinkRecommendations,
              article_links: state.removedCombinedLinkRecommendations.article_links.concat(state.combinedLinkRecommendations.article_links),
            },
            hasUnsavedChanges: true,
          };
        }
      }
      return {
        ...state,
        ...extraChanges,
        showSelections: undefined,
        doArticleLinkRecommendation: true,
      };
    }
    case CombinedLinkerAction.COMPUTE_PRODUCT_RECOMMENDATIONS: {
      let extraChanges: Partial<CombinedLinkerPageState> = {};
      if (action.clean) {
        if (state.combinedLinkRecommendations && state.removedCombinedLinkRecommendations) {
          extraChanges = {
            combinedLinkRecommendations: {
              ...state.combinedLinkRecommendations,
              product_links: [],
            },
            removedCombinedLinkRecommendations: {
              ...state.removedCombinedLinkRecommendations,
              product_links: state.removedCombinedLinkRecommendations.product_links.concat(state.combinedLinkRecommendations.product_links),
            },
            hasUnsavedChanges: true,
          };
        }
      }
      return {
        ...state,
        ...extraChanges,
        showSelections: undefined,
        doProductLinkRecommendation: true,
      };
    }
    case CombinedLinkerAction.SELECT_RECOMMENDATION: {
      const newSelection = action.payload.item;
      return {
        ...state,
        combinedLinkRecommendationSelections: state.combinedLinkRecommendationSelections.set(action.payload.recommendation, newSelection),
        hasUnsavedChanges: true,
      };
    }
    case CombinedLinkerAction.SHOW_LINK_RECOMMENDATIONS: {
      return { ...state, showSelections: action.payload };
    }
    case CombinedLinkerAction.CLEAR_LINK_RECOMMENDATIONS: {
      return { ...state, showSelections: undefined };
    }
    case CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS: {
      if (state.showSelections) return state; // forbid while showing recommendations for a specific link
      return {
        ...state,
        scrollTarget: action.payload,
        scrollToRecommendationDetails: true,
      };
    }
    case CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS_FINISHED: {
      return {
        ...state,
        scrollTarget: undefined,
        scrollToRecommendationDetails: false,
      };
    }
    case CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY: {
      if (state.showSelections) return state; // forbid while showing recommendations for a specific link
      return {
        ...state,
        scrollTarget: action.payload,
        scrollToRecommendationEntity: true,
      };
    }
    case CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY_FINISHED: {
      return {
        ...state,
        scrollTarget: undefined,
        scrollToRecommendationEntity: false,
      };
    }
    case CombinedLinkerAction.FILTER_LINKS_BY_TYPE: {
      return {
        ...state,
        currentLinkTypeFilter: action.payload,
      };
    }
    case CombinedLinkerAction.REMOVE_RECOMMENDATION: {
      const { entityDataId } = action.payload;
      if (!state.combinedLinkRecommendations || !state.removedCombinedLinkRecommendations) return state;
      const allLinks = [
        ...state.combinedLinkRecommendations.article_links,
        ...state.combinedLinkRecommendations.product_links,
      ];
      const removedLink = allLinks.find((recommendation) => recommendation.id === entityDataId);
      if (!removedLink) return state;
      removedLink.reason = action.payload.reason;
      return {
        ...state,
        showSelections: undefined,
        combinedLinkRecommendations: {
          ...state.combinedLinkRecommendations,
          article_links: state.combinedLinkRecommendations.article_links.filter((recommendation) => recommendation.id !== entityDataId) || [],
          product_links: state.combinedLinkRecommendations.product_links.filter((recommendation) => recommendation.id !== entityDataId) || [],
        },
        removedCombinedLinkRecommendations: {
          ...state.removedCombinedLinkRecommendations,
          article_links: state.removedCombinedLinkRecommendations.article_links.concat(isArticleRR(removedLink) ? removedLink : []),
          product_links: state.removedCombinedLinkRecommendations.product_links.concat(isProductRR(removedLink) ? removedLink : []),
        },
        hasUnsavedChanges: true,
      };
    }
    case CombinedLinkerAction.ADD_RECOMMENDATION: {
      const { entityDataId } = action.payload;
      if (!state.removedCombinedLinkRecommendations || !state.combinedLinkRecommendations) return state;
      const allRemovedLinks = [
        ...state.removedCombinedLinkRecommendations.article_links,
        ...state.removedCombinedLinkRecommendations.product_links,
      ];
      const addedLink = allRemovedLinks.find((recommendation) => recommendation.id === entityDataId);
      if (!addedLink) return state;
      return {
        ...state,
        showSelections: undefined,
        combinedLinkRecommendations: {
          ...state.combinedLinkRecommendations,
          article_links: state.combinedLinkRecommendations.article_links.concat(isArticleRR(addedLink) ? addedLink : []),
          product_links: state.combinedLinkRecommendations.product_links.concat(isProductRR(addedLink) ? addedLink : []),
        },
        removedCombinedLinkRecommendations: {
          ...state.removedCombinedLinkRecommendations,
          article_links: state.removedCombinedLinkRecommendations.article_links.filter((recommendation) => recommendation.id !== entityDataId),
          product_links: state.removedCombinedLinkRecommendations.product_links.filter((recommendation) => recommendation.id !== entityDataId),
        },
        hasUnsavedChanges: true,
      };
    }
    case CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS: {
      const allLinks = [
        ...(state.combinedLinkRecommendations?.article_links ?? []),
        ...(state.combinedLinkRecommendations?.product_links ?? []),
      ];
      return {
        ...state,
        savedRecommendation: Array.from(state.combinedLinkRecommendationSelections)
          .filter(([k, v]) => (k.origin !== RecommendationOriginType.EXTERNAL))
          .filter(([k, v]) => ( // remove all deselected links
            allLinks.find((link) => link.id === k.id)
          ))
          .map(([k, v]) => (isArticleRR(k) ? { id: k.id, selectedArticleId: v.id } : { id: k.id, selectedProductId: v.id })),
      };
    }
    case CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS_FINISH: {
      return {
        ...state,
        savedRecommendation: undefined,
        hasUnsavedChanges: false,
        removedCombinedLinkRecommendations: state.removedCombinedLinkRecommendations ? {
          ...state.removedCombinedLinkRecommendations,
          article_links: [],
          product_links: [],
        } : undefined,
        combinedLinkRecommendationElements: new Map(Array.from(state.combinedLinkRecommendationElements.entries()).filter(([k, v]) => (
          !((isArticleRR(k) && state.removedCombinedLinkRecommendations?.article_links.includes(k))
            || (isProductRR(k) && state.removedCombinedLinkRecommendations?.product_links.includes(k)))
        ))),
        combinedLinkRecommendationSelections: new Map(Array.from(state.combinedLinkRecommendationSelections.entries()).filter(([k, v]) => (
          !((isArticleRR(k) && state.removedCombinedLinkRecommendations?.article_links.includes(k))
            || (isProductRR(k) && state.removedCombinedLinkRecommendations?.product_links.includes(k)))
        ))),
      };
    }
    case CombinedLinkerAction.COLLAPSE_SINGLE: {
      return {
        ...state,
        collapsedLinkIds: new Set(state.collapsedLinkIds.add(action.payload.id)),
      };
    }
    case CombinedLinkerAction.EXPAND_SINGLE: {
      return {
        ...state,
        collapsedLinkIds: new Set(Array.from(state.collapsedLinkIds).filter((linkId) => linkId !== action.payload.id)),
      };
    }
    case CombinedLinkerAction.COLLAPSE_ALL: {
      return {
        ...state,
        collapsedLinkIds: new Set(Array.from(state.combinedLinkRecommendationElements.keys()).map((linkId) => linkId.id)),
      };
    }
    case CombinedLinkerAction.EXPAND_ALL: {
      return {
        ...state,
        collapsedLinkIds: new Set(),
      };
    }
    case CombinedLinkerAction.ADD_ENTITY_ELEMENT: {
      const filteredLinkRecommendations: CombinedRecommendationsResponse | undefined = state.combinedLinkRecommendations
        // remove all deselected links, necessary to do here because otherwise elements are not rendered (they filter for linkrecommendations)
        // TODO: make sure this works properly
        ? {
          ...state.combinedLinkRecommendations,
          article_links: state.combinedLinkRecommendations.article_links.filter((link) => !state.removedCombinedLinkRecommendations?.article_links.includes(link)),
          product_links: state.combinedLinkRecommendations.product_links.filter((link) => !state.removedCombinedLinkRecommendations?.product_links.includes(link)),
        }
        : state.combinedLinkRecommendations;
      const recommendationElement = state.combinedLinkRecommendationElements.get(action.payload.recommendation);
      const newCombinedLinkRecommendationElements = new Map(state.combinedLinkRecommendationElements.set(action.payload.recommendation, {
        ...recommendationElement,
        entityEl: {
          ...action.payload.entity,
        },
      }));
      return {
        ...state,
        combinedLinkRecommendations: filteredLinkRecommendations,
        combinedLinkRecommendationElements: newCombinedLinkRecommendationElements,
      };
    }
    case CombinedLinkerAction.ADD_DETAILS_ELEMENT: {
      const recommendationElement = state.combinedLinkRecommendationElements.get(action.payload.recommendation);
      return {
        ...state,
        combinedLinkRecommendationElements: new Map(state.combinedLinkRecommendationElements.set(action.payload.recommendation, {
          ...recommendationElement,
          detailsEl: {
            ...action.payload.details,
          },
        })),
      };
    }
    case CombinedLinkerAction.DO_ARTICLE_PHRASE_RECOMMENDATION: {
      return {
        ...state,
        articlePhraseData: action.payload,
      };
    }
    case CombinedLinkerAction.DO_PRODUCT_PHRASE_RECOMMENDATION: {
      return {
        ...state,
        productPhraseData: action.payload,
      };
    }
    case CombinedLinkerAction.CLEAR_ARTICLE_PHRASE_SELECTION: {
      return {
        ...state,
        articlePhraseData: undefined,
      };
    }
    case CombinedLinkerAction.CLEAR_PRODUCT_PHRASE_SELECTION: {
      return {
        ...state,
        productPhraseData: undefined,
      };
    }
    default: throw assertNever(action);
  }
}

export function CombinedLinkerPageProvider(props: Props) {
  const { articleId: articleIdProps, children } = props;
  const { articleId: toplevelArticleId, articleById } = useLinkerEditorPageState();
  const { articleLinkerProfiles, productLinkerProfiles } = useLinkerProfilesState();
  const dispatchEditor = useLinkerEditorPageDispatch();
  const [state, dispatch] = useReducer(combinedLinkerPageReducer, {
    ...defaultState,
    articleId: articleIdProps || '',
  });
  const selectedGlobalArticleProfile = articleLinkerProfiles.get(state.selectedGlobalArticleProfileId);
  const selectedGlobalProductProfile = productLinkerProfiles.get(state.selectedGlobalProductProfileId);
  const selectedPhraseArticleProfile = articleLinkerProfiles.get(state.selectedPhraseArticleProfileId);
  const selectedPhraseProductProfile = productLinkerProfiles.get(state.selectedPhraseProductProfileId);
  const { featureFlags } = useACMUserState();
  useEffect(() => {
    dispatch({ type: CombinedLinkerAction.UPDATE_ARTICLE_ID, payload: toplevelArticleId });
  }, [toplevelArticleId]);
  const getCombinedLinksRecommendationService = useGetCombinedLinksRecommendationService(
    articleById ? state.articleId : undefined,
  );
  const postArticleLinksRecommendationService = usePostCombinedArticleLinksRecommendationService(
    state.doArticleLinkRecommendation ? state.articleId : undefined,
    selectedGlobalArticleProfile,
    state.removedCombinedLinkRecommendations,
  );
  const postArticleLinkRecommendationService = usePostCombinedArticleLinkRecommendationService(
    state.articleId,
    selectedPhraseArticleProfile || selectedGlobalArticleProfile,
    state.articlePhraseData,
  );
  const postProductLinksRecommendationService = usePostCombinedProductLinksRecommendationService(
    state.doProductLinkRecommendation ? state.articleId : undefined,
    selectedGlobalProductProfile,
    state.removedCombinedLinkRecommendations,
  );
  const postProductLinkRecommendationService = usePostCombinedProductLinkRecommendationService(
    state.articleId,
    selectedPhraseProductProfile || selectedGlobalProductProfile,
    state.productPhraseData,
  );
  const saveAllCombinedLinksService = usePostCombinedLinksSaveService(
    state.articleId,
    state.savedRecommendation,
    state.removedCombinedLinkRecommendations,
  );

  const singleServices: SingleService = {
    getCombinedLinksRecommendationService,
    postArticleLinksRecommendationService,
    postArticleLinkRecommendationService,
    postProductLinksRecommendationService,
    postProductLinkRecommendationService,
    saveAllCombinedLinksService,
  };

  services = {
    [state.articleId]: singleServices,
  };

  const articleLinkerProfilesArray = useMemo(() => (
    Array.from(articleLinkerProfiles.values())
  ), [articleLinkerProfiles]);

  const productLinkerProfilesArray = useMemo(() => (
    Array.from(productLinkerProfiles.values())
  ), [productLinkerProfiles]);

  useEffect(() => {
    // whenever no profile is selected, try selecting the default one
    if (!selectedGlobalArticleProfile) {
      const defaultProfile = articleLinkerProfilesArray.find((p) => p.is_default) ?? articleLinkerProfilesArray?.[0];
      if (defaultProfile) {
        dispatch({
          type: CombinedLinkerAction.SELECT_GLOBAL_ARTICLE_LINKER_PROFILE_ID,
          payload: defaultProfile.id,
        });
      }
    }
  }, [selectedGlobalArticleProfile, articleLinkerProfilesArray]);

  useEffect(() => {
    // whenever no profile is selected, try selecting the default one
    if (!selectedGlobalProductProfile) {
      const defaultProfile = productLinkerProfilesArray.find((p) => p.is_default) ?? productLinkerProfilesArray?.[0];
      if (defaultProfile) {
        dispatch({
          type: CombinedLinkerAction.SELECT_GLOBAL_PRODUCT_LINKER_PROFILE_ID,
          payload: defaultProfile.id,
        });
      }
    }
  }, [selectedGlobalProductProfile, productLinkerProfilesArray]);

  useEffect(() => {
    if (saveAllCombinedLinksService.status === REST_STATUS.LOADED) {
      dispatch({ type: CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS_FINISH });
    }
  }, [saveAllCombinedLinksService]);

  useEffect(() => {
    if (getCombinedLinksRecommendationService.status === REST_STATUS.LOADED) {
      const { content } = getCombinedLinksRecommendationService.payload;

      dispatch({
        type: CombinedLinkerAction.INIT_COMBINED_RECOMMENDATIONS,
        payload: getCombinedLinksRecommendationService.payload,
      });

      dispatchEditor({
        type: LinkerEditorAction.SET_EDITOR_STATE_HTML,
        payload: content,
      });
    } else if (getCombinedLinksRecommendationService.status === REST_STATUS.LOADING) {
      dispatch({ type: CombinedLinkerAction.CLEAR_COMBINED_RECOMMENDATIONS });
    }
  }, [getCombinedLinksRecommendationService.status]);

  useEffect(() => {
    if (postArticleLinksRecommendationService.status === REST_STATUS.LOADING) {
      performanceStart('LO.GenerateAL');
    } else if (postArticleLinksRecommendationService.status === REST_STATUS.LOADED) {
      const oldLinkCount = state.combinedLinkRecommendations?.article_links.length ?? 0;
      dispatch({ type: CombinedLinkerAction.CLEAR_COMBINED_RECOMMENDATIONS });

      dispatch({
        type: CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS,
        payload: postArticleLinksRecommendationService.payload,
      });
      dispatchEditor({
        type: LinkerEditorAction.SET_EDITOR_STATE_HTML,
        payload: postArticleLinksRecommendationService.payload.content,
      });

      const generatedLinks = postArticleLinksRecommendationService.payload.article_links;
      if (generatedLinks.length <= oldLinkCount) {
        markAsNoContent(postArticleLinksRecommendationService);
      }
      performanceEnd('LO.GenerateAL');
    }
  }, [postArticleLinksRecommendationService.status]);

  useEffect(() => {
    if (postProductLinksRecommendationService.status === REST_STATUS.LOADING) {
      performanceStart('LO.GeneratePL');
    } else if (postProductLinksRecommendationService.status === REST_STATUS.LOADED) {
      const oldLinkCount = state.combinedLinkRecommendations?.product_links.length ?? 0;
      dispatch({ type: CombinedLinkerAction.CLEAR_COMBINED_RECOMMENDATIONS });

      dispatch({
        type: CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS,
        payload: postProductLinksRecommendationService.payload,
      });
      dispatchEditor({
        type: LinkerEditorAction.SET_EDITOR_STATE_HTML,
        payload: postProductLinksRecommendationService.payload.content,
      });

      const generatedLinks = postProductLinksRecommendationService.payload.product_links;
      if (generatedLinks.length <= oldLinkCount) {
        markAsNoContent(postProductLinksRecommendationService);
      }
      performanceEnd('LO.GeneratePL');
    }
  }, [postProductLinksRecommendationService.status]);

  useEffect(() => {
    if (postArticleLinkRecommendationService.status === REST_STATUS.LOADING) {
      performanceStart('LO.GenerateOneAL');
    } else if (postArticleLinkRecommendationService.status === REST_STATUS.LOADED) {
      const { content } = postArticleLinkRecommendationService.payload;
      const recommendationResponse = postArticleLinkRecommendationService.payload.article_links.find((link) => (
        link.text_selection === state.articlePhraseData?.selectedText
        // && link.index_paragraph === phraseSelection?.paragraphIndex // TODO fetch correct paragraph index
        && link.occurrence_in_paragraph === state.articlePhraseData?.occurrence
      ));

      dispatch({
        type: CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS,
        payload: postArticleLinkRecommendationService.payload,
      });

      dispatchEditor({
        type: LinkerEditorAction.SET_EDITOR_STATE_HTML,
        payload: content,
      });

      if (recommendationResponse) {
        dispatch({
          type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS,
          payload: recommendationResponse,
        });
      }
      performanceEnd('LO.GenerateOneAL');
    } else if (postArticleLinkRecommendationService.status === REST_STATUS.NO_CONTENT
      || postArticleLinkRecommendationService.status === REST_STATUS.ERROR) {
      dispatch({ type: CombinedLinkerAction.CLEAR_ARTICLE_PHRASE_SELECTION });
      dispatchEditor({ type: LinkerEditorAction.CLEAR_PHRASE_SELECTION });
      performanceEnd('LO.GenerateOneAL');
    }
  }, [postArticleLinkRecommendationService.status]);

  useEffect(() => {
    if (postProductLinkRecommendationService.status === REST_STATUS.LOADING) {
      performanceStart('LO.GenerateOnePL');
    } else if (postProductLinkRecommendationService.status === REST_STATUS.LOADED) {
      const { content } = postProductLinkRecommendationService.payload;
      const recommendationResponse = postProductLinkRecommendationService.payload.product_links.find((link) => (
        link.text_selection === state.productPhraseData?.selectedText
        // && link.index_paragraph === phraseSelection?.paragraphIndex // TODO fetch correct paragraph index
        && link.occurrence_in_paragraph === state.productPhraseData?.occurrence
      ));

      dispatch({
        type: CombinedLinkerAction.SET_COMBINED_RECOMMENDATIONS,
        payload: postProductLinkRecommendationService.payload,
      });

      dispatchEditor({
        type: LinkerEditorAction.SET_EDITOR_STATE_HTML,
        payload: content,
      });

      if (recommendationResponse) {
        dispatch({
          type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS,
          payload: recommendationResponse,
        });
      }
      performanceEnd('LO.GenerateOnePL');
    } else if (postProductLinkRecommendationService.status === REST_STATUS.NO_CONTENT
      || postProductLinkRecommendationService.status === REST_STATUS.ERROR) {
      dispatch({ type: CombinedLinkerAction.CLEAR_PRODUCT_PHRASE_SELECTION });
      dispatchEditor({ type: LinkerEditorAction.CLEAR_PHRASE_SELECTION });
      performanceEnd('LO.GenerateOnePL');
    }
  }, [postProductLinkRecommendationService.status]);

  // check that the selected link type is in line with the feature flags:
  useEffect(() => {
    if (!featureFlags) return;
    if (featureFlags.LINK_OPTIMIZER_ARTICLE_LINKING && !featureFlags.LINK_OPTIMIZER_PRODUCT_LINKING && state.currentLinkTypeFilter !== LinkType.ARTICLE) {
      dispatch({ type: CombinedLinkerAction.FILTER_LINKS_BY_TYPE, payload: LinkType.ARTICLE });
    }
    if (featureFlags.LINK_OPTIMIZER_PRODUCT_LINKING && !featureFlags.LINK_OPTIMIZER_ARTICLE_LINKING && state.currentLinkTypeFilter !== LinkType.PRODUCT) {
      dispatch({ type: CombinedLinkerAction.FILTER_LINKS_BY_TYPE, payload: LinkType.PRODUCT });
    }
  }, [featureFlags, state.currentLinkTypeFilter]);

  return (
    <CombinedLinkerPageServiceContext.Provider value={services}>
      <CombinedLinkerPageStateContext.Provider value={state}>
        <CombinedLinkerPageDispatchContext.Provider value={dispatch}>
          {children}
        </CombinedLinkerPageDispatchContext.Provider>
      </CombinedLinkerPageStateContext.Provider>
    </CombinedLinkerPageServiceContext.Provider>
  );
}

export function useCombinedLinkerPageStateMaybe(): CombinedLinkerPageState | undefined {
  return useContext(CombinedLinkerPageStateContext);
}

export function useCombinedLinkerPageState(): CombinedLinkerPageState {
  const context = useContext(CombinedLinkerPageStateContext);
  if (context === undefined) {
    throw new Error('useCombinedLinkerPageState must be used within a CombinedLinkerPageProvider');
  }
  return context;
}

export function useCombinedLinkerPageDispatch(): CombinedLinkerPageDispatch {
  const context = useContext(CombinedLinkerPageDispatchContext);
  if (context === undefined) {
    throw new Error('useCombinedLinkerPageDispatch must be used within a CombinedLinkerPageProvider');
  }
  return context;
}

export function useCombinedLinkerPageService(): Service {
  const context = useContext(CombinedLinkerPageServiceContext);
  if (context === undefined || Object.keys(context).length === 0) {
    throw new Error('useCombinedLinkerPageService must be used within a CombinedLinkerPageProvider');
  }
  return context;
}
