import { useEffect, useState } from 'react';
import useGetEntitiesByTextService from '../../../../api/hooks/core/article-coverage/get-entities-by-text';
import { REST_STATUS } from '../../../../api/hooks/rest-service';
import { EntityResponse } from '../../../../api/interfaces/entity';
import { ArticleTextState } from '../../../../static/types/acm-bridge';
import DataLoading from '../../../../theme/sprylab/components/data-loading';
import { distinctBy } from '../../../../util/common-utils';
import { EditableList } from '../../../../util/use-editable-list';
import SidebarArea from '../../common/sidebar-area';
import SidebarInfoText from '../../common/SidebarInfoText';
import EntityList from './entity-list';

interface Props {
  articleTextState?: ArticleTextState
  keywords: EditableList<string>
}

export default function EntitiesArea(props: Props) {
  const { articleTextState, keywords } = props;
  const [entities, setEntities] = useState<EntityResponse[]>([]);
  const getEntitiesByTextService = useGetEntitiesByTextService(articleTextState?.article.rawContent);

  useEffect(() => {
    if (getEntitiesByTextService.status === REST_STATUS.LOADED) {
      setEntities(distinctBy('name', getEntitiesByTextService.payload));
    }
  }, [getEntitiesByTextService]);

  const hasNoText = !articleTextState?.article || articleTextState?.article.rawContent === '';
  const hasNoData = hasNoText || entities.length === 0;

  const content = hasNoData ? (
    <SidebarInfoText>
      {hasNoText ? 'We don\'t have any article or text to work with' : 'No entities found'}
    </SidebarInfoText>
  ) : (
    <EntityList
      entities={entities}
      keywords={keywords}
    />
  );

  return (
    <SidebarArea title="Entities">
      <DataLoading
        service={getEntitiesByTextService}
        errorMessage="Couldn't load text entities"
      >
        {content}
      </DataLoading>
    </SidebarArea>
  );
}
