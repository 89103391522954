import * as React from 'react';
import { useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { getToken, setToken } from '../api/auth-functions';
import { FeatureFlags } from '../api/interfaces/feature-flags';
import { FrontendConfigurationDetailResponse } from '../api/interfaces/frontend-configuration';
import { LoginResponse, UserResponse } from '../api/interfaces/user';
import RestService from '../api/rest.service';
import { makeSingleUseIFrameAdapter } from '../bridge/generic-iframe-adapter';
import LocalStorageVolatile from '../constants/session';
import { AcmServicesInterface } from '../static/types/acm-services';
import { inIframe } from '../util/dom-utils';
import { assertNever } from '../util/reducer-utils';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface State {
  acmToken?: string
  customerToken?: string
  userId?: string
  user?: UserResponse
  isAdmin?: boolean
  frontendConfig?: FrontendConfigurationDetailResponse
  featureFlags?: FeatureFlags
}

export enum ACMUserActionType {
  LOGIN,
  LOGOUT,
  SET_USER_DATA,
  SET_FRONTEND_CONFIG,
  SET_USER_FEATURE_FLAGS,
}

type Action =
  | { type: ACMUserActionType.LOGIN, loginResponse: LoginResponse }
  | { type: ACMUserActionType.LOGOUT }
  | { type: ACMUserActionType.SET_USER_DATA, user: UserResponse }
  | { type: ACMUserActionType.SET_FRONTEND_CONFIG, config: FrontendConfigurationDetailResponse }
  | { type: ACMUserActionType.SET_USER_FEATURE_FLAGS, featureFlags: FeatureFlags }

type Dispatch = (action: Action) => void
interface ProviderProps {
  children: React.ReactNode
}

const ACMUserStateContext = React.createContext<State | undefined>(undefined);
const ACMUserDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function acmUserReducer(state: State, action: Action): State {
  function setLocalStorage(apiTokenACM?: string, publicUserId?: string) {
    if (!apiTokenACM && !publicUserId) return;

    if (inIframe()) {
      setToken(LocalStorageVolatile.API_TOKEN_ACM_IFRAME, apiTokenACM);
      setToken(LocalStorageVolatile.USER_ID_IFRAME, publicUserId);
    } else {
      setToken(LocalStorageVolatile.API_TOKEN_ACM, apiTokenACM);
      setToken(LocalStorageVolatile.USER_ID, publicUserId);
    }
  }

  switch (action.type) {
    case ACMUserActionType.LOGIN: {
      setLocalStorage(action.loginResponse.token, action.loginResponse.user.public_id);
      return {
        ...state,
        userId: action.loginResponse.user.public_id,
        acmToken: action.loginResponse.token,
        user: action.loginResponse.user,
        isAdmin: action.loginResponse.user.role === 'admin',
      };
    }

    case ACMUserActionType.LOGOUT: {
      if (inIframe()) {
        localStorage.removeItem(LocalStorageVolatile.API_TOKEN_ACM_IFRAME);
        localStorage.removeItem(LocalStorageVolatile.USER_ID_IFRAME);
        localStorage.removeItem(LocalStorageVolatile.API_TOKEN_CUSTOMER_IFRAME);
      } else {
        localStorage.removeItem(LocalStorageVolatile.API_TOKEN_ACM);
        localStorage.removeItem(LocalStorageVolatile.USER_ID);
        localStorage.removeItem(LocalStorageVolatile.API_TOKEN_CUSTOMER);
      }
      return {};
    }
    case ACMUserActionType.SET_USER_DATA: {
      setLocalStorage(state.acmToken, action.user.public_id);
      return {
        ...state,
        user: action.user,
        isAdmin: action.user.role === 'admin',
      };
    }
    case ACMUserActionType.SET_FRONTEND_CONFIG: {
      return {
        ...state,
        frontendConfig: action.config,
      };
    }
    case ACMUserActionType.SET_USER_FEATURE_FLAGS: {
      return {
        ...state,
        featureFlags: action.featureFlags,
      };
    }
    default: throw assertNever(action);
  }
}

export function ACMUserProvider({ children }: ProviderProps) {
  const isInIframe = inIframe();
  const location = useLocation();
  // const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const spACMToken = params.get(LocalStorageVolatile.API_TOKEN_ACM);
  const lsACMToken = isInIframe ? getToken(LocalStorageVolatile.API_TOKEN_ACM_IFRAME) : getToken(LocalStorageVolatile.API_TOKEN_ACM);

  const spUserId = params.get(LocalStorageVolatile.USER_ID);
  const lsUserId = isInIframe ? getToken(LocalStorageVolatile.USER_ID_IFRAME) : getToken(LocalStorageVolatile.USER_ID);

  const spCustomerToken = params.get(LocalStorageVolatile.API_TOKEN_CUSTOMER);
  const lsCustomerToken = isInIframe ? getToken(LocalStorageVolatile.API_TOKEN_CUSTOMER_IFRAME) : getToken(LocalStorageVolatile.API_TOKEN_CUSTOMER);

  if (spACMToken || spCustomerToken || spUserId) {
    // const locationTmp = { ...location };

    // if (spACMToken) params.delete(LocalStorageVolatile.API_TOKEN_ACM);
    // if (spUserId) params.delete(LocalStorageVolatile.USER_ID);
    // if (spCustomerToken) params.delete(LocalStorageVolatile.API_TOKEN_CUSTOMER);

    // locationTmp.search = params.toString();

    // navigate(locationTmp);
  }

  const [state, dispatch] = useReducer(acmUserReducer, {
    acmToken: spACMToken && spUserId ? spACMToken : lsACMToken,
    userId: spACMToken && spUserId ? spUserId : lsUserId,
    customerToken: spACMToken && spUserId && spCustomerToken ? spCustomerToken : lsCustomerToken,
  });

  return (
    <ACMUserStateContext.Provider value={state}>
      <ACMUserDispatchContext.Provider value={dispatch}>
        {children}
      </ACMUserDispatchContext.Provider>
    </ACMUserStateContext.Provider>
  );
}

export function useACMUserState(): State {
  const context = useContext(ACMUserStateContext);
  if (context === undefined) {
    throw new Error('useACMUserState must be used within a ACMUserStateContext');
  }
  return context;
}

export function useACMUserDispatch(): Dispatch {
  const context = useContext(ACMUserDispatchContext);
  if (context === undefined) {
    throw new Error('useACMUserDispatch must be used within a ACMUserDispatchContext');
  }
  return context;
}

export async function authenticate(dispatch: Dispatch, username: string, password: string) {
  await RestService.login(username, password)
    .then((loginResponse: LoginResponse) => {
      if (inIframe()) {
        makeSingleUseIFrameAdapter<AcmServicesInterface>([window.parent])
          .saveTokenToWordpress({
            apiTokenACM_IFrame: loginResponse.token,
            userID_IFrame: loginResponse.user.public_id,
          });
      }
      dispatch({ type: ACMUserActionType.LOGIN, loginResponse });
      return loginResponse;
    })
    .catch((e) => {
      throw e;
    });
}
