import {
  Autocomplete,
  CircularProgress,
  SxProps,
} from '@mui/material';
import { TextInput, sxMerge } from '@purple/react-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetArticlesService from '../../api/hooks/core/articles/get-articles';
import { REST_STATUS } from '../../api/hooks/rest-service';
import { ArticleResponse } from '../../api/interfaces/article';
import useDebounce from '../../util/use-debounce';

interface Props {
  disabled?: boolean
  onChange: (value: ArticleResponse | null) => void
  sx?: SxProps
}

export default function AutocompleteArticles(props: Props) {
  const { t } = useTranslation();
  const { disabled, onChange, sx: propsSx } = props;

  const [articleSearch, setArticleSearch] = useState<string>('');
  const debouncedArticleSearch = useDebounce(articleSearch, 300);
  const articlesService = useGetArticlesService(debouncedArticleSearch);

  const articleAutocompleteOptions: ArticleResponse[] = useMemo(() => {
    if (articlesService.status === REST_STATUS.LOADED) {
      return articlesService.payload;
    } else {
      return [];
    }
  }, [articlesService.status]);

  const loading: boolean = useMemo(() => (
    articlesService.status === REST_STATUS.INIT
    || articlesService.status === REST_STATUS.LOADING
  ), [articlesService.status]);

  return (
    <Autocomplete
      disableClearable
      options={articleAutocompleteOptions}
      onChange={(_e, v) => onChange(v)}
      disabled={disabled}
      loading={loading}
      getOptionLabel={(option) => option.title}
      sx={sxMerge({ mt: '10px' }, propsSx)}
      disablePortal // so that the dropdown is not rendered above the ACM header bar
      renderInput={(params) => (
        <TextInput
          {...params}
          value={articleSearch}
          setValue={setArticleSearch}
          fullWidth
          placeholder={t('lo-search-articles-placeholder')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
