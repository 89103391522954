import { Box } from '@mui/material';
import * as Draft from 'draft-js';
import { Editor } from 'draft-js';
import * as React from 'react';
import { useRef } from 'react';

interface Props {
  editorState: Draft.EditorState
  decorator?: Draft.CompositeDecorator
  rootRef?: React.RefObject<HTMLDivElement>
}

export interface EntityProps {
  contentState: any
  decoratedText: string
  start: number
  end: number
  blockKey: string
  entityKey: string
  offsetKey: string
  onClick: (entityId: string, text: string) => void
}

export function ACMEditor(props: Props) {
  const {
    editorState, rootRef, decorator,
  } = props;
  const editor = useRef<Editor>(null);

  const decoratedEditorState = Draft.EditorState.set(editorState, { decorator });

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '8px',
      }}
      onClick={() => editor.current?.focus()}
      ref={rootRef}
    >
      <Editor
        ref={editor}
        editorState={decoratedEditorState}
        readOnly
        handleReturn={() => 'not-handled'}
        handleKeyCommand={() => 'not-handled'}
        onChange={() => undefined}
      />
    </Box>
  );
}
