import RequestError from '../../../interfaces/RequestError';

export default async function makeRequestError(response: Response): Promise<RequestError> {
  const errorMessage = `Request failed with code ${response.status}`;
  const body = await response.json();
  if (body && body.message) {
    return new RequestError(errorMessage, response.status, body.message);
  }
  return new RequestError(errorMessage, response.status);
}
