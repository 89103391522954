import { useState } from 'react';
import { RecommendationElement } from '../../../context/linker/combined-linker-context';
import { useIsomorphicLayoutEffect, useRectListener } from '../../../hooks/rect-listener';
import { LINK_CARD_ANIMATION_MS } from '../../../theme/style-utils';

interface Props {
  isShown: boolean
  isFadedOut?: boolean
  strokeColor: string
  startEl: RecommendationElement
  targetEl: RecommendationElement
  startContainerRef: HTMLElement
  targetContainerRef: HTMLElement
  index: number
}

type Coords = { x: number, y: number };

const TO_BE_VISIBLE_TOP = 0.9;
const TO_BE_VISIBLE_BOTTOM = 0.95;

/**
 * Link Optimizer Page connection lines, connecting selected text in the content display
 * and link elements on the right
 */
export default function LinkToDetailsReferenceLine(props: Props) {
  const {
    isShown,
    isFadedOut,
    strokeColor,
    startEl,
    targetEl,
    startContainerRef,
    targetContainerRef,
    index,
  } = props;
  const [startRect, setStartAnchorRect] = useState<DOMRect>(new DOMRect());
  const [targetRect, setTargetAnchorRect] = useState<DOMRect>(new DOMRect());
  const [startAnchorCoords, setStartAnchorCoords] = useState<Coords>();
  const [midAnchorX, setMidAnchorX] = useState<number>();
  const [targetAnchorCoords, setTargetAnchorCoords] = useState<Coords>();

  useIsomorphicLayoutEffect(() => {
    const startContainerRect = startContainerRef.getBoundingClientRect();

    const isOutsideTopStart = startContainerRect.top > (startRect.top + (startRect.height * TO_BE_VISIBLE_TOP));
    const isOutsideBottomStart = startContainerRect.top + startContainerRect.height < (startRect.top + (startRect.height * TO_BE_VISIBLE_BOTTOM));

    const targetContainerRect = targetContainerRef.getBoundingClientRect();

    const targetRectConnectionY = targetRect.top + 21; // in the middle of the card header

    const isOutsideTopTarget = targetContainerRect.top > targetRectConnectionY;
    const isOutsideBottomTarget = targetContainerRect.top + targetContainerRect.height < targetRectConnectionY;

    const isTargetInView = !isOutsideTopTarget && !isOutsideBottomTarget;
    const isStartInView = !isOutsideTopStart && !isOutsideBottomStart;

    if (!isTargetInView || !isStartInView) {
      setStartAnchorCoords(undefined);
      setTargetAnchorCoords(undefined);
      setMidAnchorX(undefined);
    } else {
      let startY = startContainerRect.top;
      if (startContainerRect.top <= (startRect.top + (startRect.height * TO_BE_VISIBLE_TOP) - 1)) {
        startY = startRect.top + startRect.height - 1; // a bit higher up, looks nicer
      }
      setStartAnchorCoords({
        x: startRect.left + 4, // start a bit to the left, to avoid reaching into the area of the rounded corner
        y: startY,
      });

      if (targetContainerRef) {
        setMidAnchorX(targetContainerRect.x - ((index + 1) * 5));
      } else {
        setMidAnchorX((window.innerWidth / 2) - startRect.left + startRect.width + (index * 5));
      }

      setTargetAnchorCoords({
        x: targetRect.left + 1, // to not have any gap, prefer overlapping a pixel into the card header
        y: targetRectConnectionY,
      });
    }
  }, [startRect, targetRect]);

  useRectListener(
    ({ currRect }) => setStartAnchorRect(currRect),
    startEl.ref,
    false,
  );

  useRectListener(
    ({ currRect }) => setTargetAnchorRect(currRect),
    targetEl.ref,
    false,
  );

  return (
    <svg
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 1,
        pointerEvents: 'none',
        opacity: isFadedOut ? '0' : isShown ? '1' : '0.4',
        transition: `opacity ${LINK_CARD_ANIMATION_MS}ms`,
      }}
      stroke={strokeColor}
      strokeWidth="2"
      fill="none"
    >
      {startAnchorCoords && midAnchorX && targetAnchorCoords && (
        <>
          <path
            d={''
              + `M ${startAnchorCoords.x} ${startAnchorCoords.y} `
              + `L ${midAnchorX + 1} ${startAnchorCoords.y} `}
          />
          <path
            d={''
              + `M ${midAnchorX} ${startAnchorCoords.y} `
              + `L ${midAnchorX} ${targetAnchorCoords.y} `}
          />
          <path
            d={''
              + `M ${midAnchorX - 1} ${targetAnchorCoords.y} `
              + `L ${targetAnchorCoords.x} ${targetAnchorCoords.y} `}
          />
        </>
      )}
    </svg>
  );
}
