import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, ListItemButton, ListItemIcon } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { PurpleDrawerListItem } from '@purple/react-components';
import { useState } from 'react';
import { ACMPageList, shouldShowPageList } from '../../constants/interfaces/page';
import { useACMUserState } from '../../context/acm-page-context';
import AppBarListItem from './app-bar-list-item';

interface Props {
  label: string
  items: ACMPageList[]
}

export default function AppBarCollapseGroup(props: Props) {
  const { label, items } = props;
  const { featureFlags } = useACMUserState();
  const [collapseOpen, setCollapseOpen] = useState<boolean>(true);

  const handleMenuOpen = () => {
    setCollapseOpen((prevState) => !prevState);
  };

  return (
    <List>
      <PurpleDrawerListItem disablePadding>
        <ListItemButton onClick={handleMenuOpen} sx={{ pl: 4 }}>
          <ListItemText primary={label} />
          <ListItemIcon>
            {collapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        </ListItemButton>
      </PurpleDrawerListItem>
      <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.filter((x) => shouldShowPageList(x, featureFlags)).map(({ pageData }) => (
            <AppBarListItem label={<pageData.label />} path={pageData.path} key={pageData.path} />
          ))}
        </List>
      </Collapse>
    </List>
  );
}
