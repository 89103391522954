enum LocalStorageVolatile {
  API_TOKEN_ACM = 'api_token_acm',
  API_TOKEN_ACM_IFRAME = 'api_token_acm_iframe',
  API_TOKEN_CUSTOMER = 'api_token_customer',
  API_TOKEN_CUSTOMER_IFRAME = 'api_token_customer_iframe',
  USER_ID = 'user_id',
  USER_ID_IFRAME = 'user_id_iframe',
}

export default LocalStorageVolatile;
