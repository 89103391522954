import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { PurpleDrawerListItem } from '@purple/react-components';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  label: React.ReactNode
  path?: string
  onClick?: () => void
}

export default function AppBarListItem(props: Props) {
  const { label, path, onClick } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleTabClick = () => {
    onClick?.();
    if (path) {
      navigate(path);
    }
  };

  return (
    <PurpleDrawerListItem disablePadding>
      <ListItemButton
        selected={pathname === path}
        onClick={handleTabClick}
        sx={{ pl: 4 }}
      >
        <ListItemText>
          {label}
        </ListItemText>
      </ListItemButton>
    </PurpleDrawerListItem>
  );
}
