import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import IFrameBridge from '../../bridge/iframe-bridge';
import { ArticleDataState, ArticleTextState } from '../../static/types/acm-bridge';
import { inIframe } from '../../util/dom-utils';
import { useEditableList } from '../../util/use-editable-list';
import SidebarPanels from './topic-coverage/sidebar-panels';

export default function SidebarMain() {
  const [articleTextState, setArticleTextState] = useState<ArticleTextState>();
  const [articleDataState, setArticleDataState] = useState<ArticleDataState>();
  const [currentlySavingKeywords, setCurrentlySavingKeywords] = useState(false);

  function handleKeywordChanges(newKeywords: string[]) {
    setArticleDataState({
      ...articleDataState,
      keywords: uniq(newKeywords),
      silent: false,
    });
  }

  const keywords = useEditableList<string>(articleDataState?.keywords || [], handleKeywordChanges);

  useEffect(() => {
    if (inIframe()) {
      IFrameBridge.connectWithEditorBridgeText((newArticleTextState: ArticleTextState) => {
        if (articleTextState && articleTextState.article.article_id !== newArticleTextState.article.article_id) {
          return;
        }
        setArticleTextState(newArticleTextState);
      });
      IFrameBridge.connectWithEditorBridgeData((newArticleDataState: ArticleDataState) => {
        setArticleDataState((prevState) => ({
          ...prevState,
          ...newArticleDataState,
        }));
      });
    }
  }, []);

  /* The sidebar should not update the Wordpress Article Text, only its data
  useEffect(() => {
    if (articleTextState !== undefined && !articleTextState.silent) {
      // only update editor if silent flag is not set
      IFrameBridge.updateEditorText(articleTextState);
    }
  }, [articleTextState]); */

  useEffect(() => {
    if (articleDataState !== undefined && !articleDataState.silent) {
      // only update editor if silent flag is not set
      setCurrentlySavingKeywords(true);
      IFrameBridge.updateEditorData(articleDataState)
        .catch((e) => {
          console.error('Error updating editor data:', e);
          alert('Error updating editor data. See console for details.');
        })
        .finally(() => setCurrentlySavingKeywords(false));
    }
  }, [articleDataState]);

  return (
    <SidebarPanels
      articleTextState={articleTextState}
      keywords={keywords}
      keywordsAreSaving={currentlySavingKeywords}
    />
  );
}
