import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ChipProps } from '@mui/material/Chip';
import { ActionButton, BoxChip } from '@purple/react-components';
import { useRef, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import Synonyms from '../../../components/sidebar/synonyms/synonyms';
import { Keyword } from '../../../constants/interfaces/interfaces';
import ActionButtonsArrowPopper from '../../action-buttons-arrow-popper';

export type KeywordChipVariant = 'default' | 'activated' | 'medium';

const ColorFromVariant: Record<KeywordChipVariant, ChipProps['color']> = {
  default: 'default',
  activated: 'success',
  medium: 'secondary',
};

interface Props {
  keyword: Keyword
  variant?: KeywordChipVariant
  onDelete?: () => void
  lessImportant?: boolean
  handleSynonymReplacement?: (replacement: string) => void
}

export const SortableKeywordChip = SortableElement<Props>(KeywordChip);

export default function KeywordChip(props: Props) {
  const {
    keyword,
    variant,
    onDelete,
    lessImportant,
    handleSynonymReplacement,
  } = props;

  const buttonRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [synonymsOpen, setSynonymsOpen] = useState(false);

  return (
    <div
      style={{
        display: 'inline-block',
        maxWidth: '100%',
      }}
    >
      <BoxChip
        ref={buttonRef}
        color={ColorFromVariant[variant ?? 'default']}
        label={keyword.name}
        sx={{
          opacity: lessImportant ? 0.5 : 1,
          m: '3px',
        }}
        onDelete={onDelete}
        onClick={() => {
          setPopoverOpen((o) => !o);
          setSynonymsOpen(false);
        }}
      />
      <ActionButtonsArrowPopper
        elementRef={buttonRef}
        open={popoverOpen}
        onClose={() => {
          setPopoverOpen(false);
          setSynonymsOpen(false);
        }}
      >
        {synonymsOpen ? (
          <Synonyms
            keyword={keyword}
            onReplace={(replacement: string) => {
              setSynonymsOpen(false);
              setPopoverOpen(false);
              handleSynonymReplacement?.(replacement);
            }}
          />
        ) : (
          <ActionButton
            onClick={() => setSynonymsOpen(true)}
            icon={<CompareArrowsIcon />}
          >
            Synonyms
          </ActionButton>
        )}
      </ActionButtonsArrowPopper>
    </div>
  );
}
