const PerformanceMeasurements = {
  'LO.GenerateAL': 'Generate Article Links',
  'LO.GeneratePL': 'Generate Product Links',
  'LO.GenerateOneAL': 'Generate one Article Link',
  'LO.GenerateOnePL': 'Generate one Product Link',
};

export type PerformanceMeasurementKey = keyof typeof PerformanceMeasurements;

function startKey(key: PerformanceMeasurementKey) {
  return `${key}.start`;
}

function endKey(key: PerformanceMeasurementKey) {
  return `${key}.end`;
}

/**
 * Helper function to calculate the duration between two PerformanceMarks in seconds
 * and clean up the associated PerformanceMeasure
 */
function computePerfDuration(key: PerformanceMeasurementKey) {
  const name = PerformanceMeasurements[key];
  performance.clearMeasures(name);
  performance.measure(name, startKey(key), endKey(key));

  const measures = performance.getEntriesByName(name, 'measure');
  performance.clearMeasures(name);

  return measures[0].duration;
}

function logDuration(key: PerformanceMeasurementKey) {
  const name = PerformanceMeasurements[key];
  try {
    const duration = computePerfDuration(key);
    console.log(`${name}: Finished in ${(duration / 1000.0).toFixed(2)}s`);
  } catch {
    console.log(`${name}: Could not measure duration`);
  }
}

export function performanceStart(key: PerformanceMeasurementKey) {
  performance.mark(startKey(key));
}

export function performanceEnd(key: PerformanceMeasurementKey) {
  performance.mark(endKey(key));
  logDuration(key);
}
