import { STEntry, STGroup } from '@purple/react-components';
import { useState } from 'react';
import { useToastMessagesDispatch, ToastMessagesActionType } from '../../../context/toast-messages-context';
import { LockableSlider, ProfileAttributeSlider } from '../../link-optimizer/generic/profile/slider-helper';

enum SliderLabels {
    Selection = 'SIMILARITY TARGET TO SELECTION',
    Paragraph = 'SIMILARITY TARGET TO PARAGRAPH',
    Source = 'SIMILARITY TARGET TO SOURCE',
}

export interface TargetSimilarityWeights {
    toSelection: number
    toParagraph: number
    toSource: number
  }

interface Props {
    targetSimilarityWeights: TargetSimilarityWeights
    updateTargetSimilarityWeights: ((_: TargetSimilarityWeights) => void)
    targetConfidenceWeight: number
    setTargetConfidenceWeight: ((_: number) => void)
  }

export function SimilarityWeightSlidersBox(props: Props) {
  const {
    targetSimilarityWeights,
    updateTargetSimilarityWeights,
    targetConfidenceWeight,
    setTargetConfidenceWeight,
  } = props;

  const {
    toSelection,
    toParagraph,
    toSource,
  } = targetSimilarityWeights;

  const maxWeightsSum: number = 100;

  const [isSimilaritySelectionToTargetSliderLock, setIsSimilaritySelectionToTargetSliderLock] = useState<boolean>(false);
  const [isSimilarityParagraphToTargetSliderLock, setIsSimilarityParagraphToTargetSliderLock] = useState<boolean>(false);
  const [isSimilaritySourceToTargetSliderLock, setIsSimilaritySourceToTargetSliderLock] = useState<boolean>(false);

  const dispatch = useToastMessagesDispatch();

  function showInvalidSimilarityWeightValueToastMessage() {
    const message = `The sum of the Similarity Weights must be ${maxWeightsSum}. Please change the other sliders.`;
    dispatch({
      type: ToastMessagesActionType.SHOW_TOAST_MESSAGE,
      payload: {
        id: message,
        message,
        durationMs: 2000,
        severity: 'warning',
      },
    });
  }

  function computeNewWeights(newWeight: number, otherWeight1: number, otherWeight2: number, isWeight1Locked: boolean, isWeight2Locked: boolean): Array<number> {
    const otherWeightsMustSum = (maxWeightsSum - newWeight);

    let maxNewWeight: number = 0;
    let newOtherWeight1: number = otherWeight1;
    let newOtherWeight2: number = otherWeight2;

    if (!isWeight1Locked && !isWeight2Locked) {
      const currentOtherWeightsSum = otherWeight1 + otherWeight2;
      const excess = otherWeightsMustSum - currentOtherWeightsSum;
      if (currentOtherWeightsSum === 0) {
        newOtherWeight1 = excess / 2;
        newOtherWeight2 = excess / 2;
      } else {
        newOtherWeight1 *= 1 + excess / currentOtherWeightsSum; // Proportional variation
        newOtherWeight2 *= 1 + excess / currentOtherWeightsSum;
      }
    } else if (isWeight1Locked && !isWeight2Locked) {
      maxNewWeight = maxWeightsSum - otherWeight1;
      if (maxNewWeight < newWeight) {
        showInvalidSimilarityWeightValueToastMessage();
        newWeight = maxNewWeight;
        newOtherWeight2 = maxWeightsSum - newWeight - otherWeight1;
      } else {
        newOtherWeight2 = otherWeightsMustSum - otherWeight1;
      }
    } else if (isWeight2Locked && !isWeight1Locked) {
      maxNewWeight = maxWeightsSum - otherWeight2;
      if (maxNewWeight < newWeight) {
        showInvalidSimilarityWeightValueToastMessage();
        newWeight = maxNewWeight;
        newOtherWeight1 = maxWeightsSum - newWeight - otherWeight2;
      } else {
        newOtherWeight1 = otherWeightsMustSum - otherWeight2;
      }
    } else {
      throw Error('Only one slider can be locked');
    }
    return [newWeight, Math.round(newOtherWeight1), Math.round(newOtherWeight2)];
  }

  function handleSelectionToTargetWeightChange(newWeight: number) {
    const [newToSelection, newToParagraph, newToSource] = computeNewWeights(newWeight, toParagraph, toSource, isSimilarityParagraphToTargetSliderLock, isSimilaritySourceToTargetSliderLock);
    updateTargetSimilarityWeights({ toSelection: newToSelection, toParagraph: newToParagraph, toSource: newToSource });
  }

  function handleParagraphToTargetWeightChange(newWeight: number) {
    const [newSimilarityToParagraphWeight, newSimilarityToSelectionWeight, newSimilarityToSourceWeight] = computeNewWeights(newWeight, toSelection, toSource, isSimilaritySelectionToTargetSliderLock, isSimilaritySourceToTargetSliderLock);
    updateTargetSimilarityWeights({ toSelection: newSimilarityToSelectionWeight, toParagraph: newSimilarityToParagraphWeight, toSource: newSimilarityToSourceWeight });
  }

  function handleSourceToTargetWeightChange(newWeight: number) {
    const [newSimilarityToSourceWeight, newSimilarityToSelectionWeight, newSimilarityToParagraphWeight] = computeNewWeights(newWeight, toSelection, toParagraph, isSimilaritySelectionToTargetSliderLock, isSimilarityParagraphToTargetSliderLock);
    updateTargetSimilarityWeights({ toSelection: newSimilarityToSelectionWeight, toParagraph: newSimilarityToParagraphWeight, toSource: newSimilarityToSourceWeight });
  }

  const sliderLockSetters = { [SliderLabels.Selection]: setIsSimilaritySelectionToTargetSliderLock, [SliderLabels.Paragraph]: setIsSimilarityParagraphToTargetSliderLock, [SliderLabels.Source]: setIsSimilaritySourceToTargetSliderLock };

  function unlockAllSliders() {
    setIsSimilaritySelectionToTargetSliderLock(false);
    setIsSimilarityParagraphToTargetSliderLock(false);
    setIsSimilaritySourceToTargetSliderLock(false);
  }

  function handleLockChange(lock: boolean, sliderLabel: SliderLabels) {
    unlockAllSliders();
    sliderLockSetters[sliderLabel](lock);
  }

  function handleSimilarityToSelectionSliderLockChange(lock: boolean) {
    handleLockChange(lock, SliderLabels.Selection);
  }

  function handleSimilarityToParagraphSliderLockChange(lock: boolean) {
    handleLockChange(lock, SliderLabels.Paragraph);
  }

  function handleSimilarityToSourceSliderLockChange(lock: boolean) {
    handleLockChange(lock, SliderLabels.Source);
  }

  return (
    <STGroup>
      <STEntry
        label="Similarity to Selection"
        helpText="Define, how important it is to recommend links to articles that are similar to the selection. When set to 0, no extra consideration will be given to the words of the selection."
      >
        <LockableSlider
          value={toSelection}
          setValue={handleSelectionToTargetWeightChange}
          isLocked={isSimilaritySelectionToTargetSliderLock}
          setIsLocked={handleSimilarityToSelectionSliderLockChange}
          testId="similarity-to-selection"
        />
      </STEntry>
      <STEntry
        label="Similarity to Sentence"
        helpText="Define, how important it is to recommend links to articles that are similar to the sentence containing the link."
      >
        <LockableSlider
          value={toParagraph}
          setValue={handleParagraphToTargetWeightChange}
          isLocked={isSimilarityParagraphToTargetSliderLock}
          setIsLocked={handleSimilarityToParagraphSliderLockChange}
          testId="similarity-to-sentence"
        />
      </STEntry>
      <STEntry
        label="Similarity to Article"
        helpText="Define, how important it is to recommend links to articles that are similar to this whole article."
      >
        <LockableSlider
          value={toSource}
          setValue={handleSourceToTargetWeightChange}
          isLocked={isSimilaritySourceToTargetSliderLock}
          setIsLocked={handleSimilarityToSourceSliderLockChange}
          testId="similarity-to-article"
        />
      </STEntry>
      <STEntry
        label="Similarity Confidence"
        helpText="Define how important the match quality between anchor text and suggested recommendations should be. Choose a low value (0) for for exploration purposes and a higher value (100) for high certainty."
      >
        <ProfileAttributeSlider
          value={targetConfidenceWeight}
          setValue={setTargetConfidenceWeight}
          step={5}
          testId="similarity-confidence"
        />
      </STEntry>
    </STGroup>
  );
}
