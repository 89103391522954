import { Collapse } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { FlexBoxColumn } from '@purple/react-components';
import * as React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { assertNever } from '../util/reducer-utils';
import useRerender from '../util/use-rerender';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface State {
  currentlyShownMessages: ToastMessageInfo[]
}

export enum ToastMessagesActionType {
  SHOW_TOAST_MESSAGE = 'showToastMessage',
  HIDE_TOAST = 'hideToast',
  HIDE_ALL_TOASTS = 'hideAllToasts',
}

export interface ToastMessageInfo {
  id: string
  message: string
  autoHideTimestamp?: number
  severity?: 'success' | 'info' | 'warning' | 'error'
}

export interface ToastMessageCreateInfo {
  id: string
  message: string
  durationMs?: number
  severity?: 'success' | 'info' | 'warning' | 'error'
}

type Action =
  | { type: ToastMessagesActionType.SHOW_TOAST_MESSAGE, payload: ToastMessageCreateInfo }
  | { type: ToastMessagesActionType.HIDE_TOAST, id: string }
  | { type: ToastMessagesActionType.HIDE_ALL_TOASTS }

type Dispatch = (action: Action) => void

interface ProviderProps {
  children: React.ReactNode
}

const defaultState: State = {
  currentlyShownMessages: [],
};

const ToastMessagesStateContext = React.createContext<State | undefined>(undefined);
const ToastMessagesDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function toastMessagesReducer(state: State, action: Action): State {
  function isNotOutdated(t: ToastMessageInfo) {
    return t.autoHideTimestamp === undefined || t.autoHideTimestamp + 1000 >= Date.now(); // after a second of being hidden, we can safely remove it
  }
  switch (action.type) {
    case ToastMessagesActionType.SHOW_TOAST_MESSAGE: {
      const newMessage = action.payload;
      const newToast: ToastMessageInfo = {
        id: newMessage.id,
        message: newMessage.message,
        autoHideTimestamp: newMessage.durationMs ? Date.now() + newMessage.durationMs : undefined,
        severity: newMessage.severity,
      };
      return {
        ...state,
        currentlyShownMessages: [newToast, ...state.currentlyShownMessages.filter(isNotOutdated).filter((m) => m.id !== newMessage.id)],
      };
    }
    case ToastMessagesActionType.HIDE_TOAST: {
      const t = state.currentlyShownMessages.find((m) => m.id === action.id);
      if (!t) return state; // no toast with this ID, nothing to hide
      if (t.autoHideTimestamp && t.autoHideTimestamp <= Date.now()) {
        // if the toast is already on its way to being hidden, remove it immediately
        return {
          ...state,
          currentlyShownMessages: state.currentlyShownMessages.filter(isNotOutdated).filter((m) => m.id !== action.id),
        };
      } else {
        // else: set it to hide right now
        t.autoHideTimestamp = Date.now();
        return {
          ...state,
          currentlyShownMessages: [...state.currentlyShownMessages.filter(isNotOutdated)],
        };
      }
    }
    case ToastMessagesActionType.HIDE_ALL_TOASTS: {
      return {
        ...state,
        currentlyShownMessages: [],
      };
    }
    default: throw assertNever(action);
  }
}

export function ToastMessagesProvider(props: ProviderProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(toastMessagesReducer, defaultState);
  const location = useLocation();

  useEffect(() => {
    // when the URL changes (i.e. when the user navigated to another page), hide all toast messages
    dispatch({ type: ToastMessagesActionType.HIDE_ALL_TOASTS });
  }, [location.pathname]);

  return (
    <ToastMessagesStateContext.Provider value={state}>
      <ToastMessagesDispatchContext.Provider value={dispatch}>
        <ToastMessagesDisplay />
        {children}
      </ToastMessagesDispatchContext.Provider>
    </ToastMessagesStateContext.Provider>
  );
}

export function useToastMessagesState(): State {
  const context = useContext(ToastMessagesStateContext);
  if (context === undefined) {
    throw new Error('useToastMessagesState must be used within a ToastMessagesStateContext');
  }
  return context;
}

export function useToastMessagesDispatch(): Dispatch {
  const context = useContext(ToastMessagesDispatchContext);
  if (context === undefined) {
    throw new Error('useToastMessagesDispatch must be used within a ToastMessagesDispatchContext');
  }
  return context;
}

function ToastMessagesDisplay() {
  const { currentlyShownMessages } = useToastMessagesState();
  const dispatch = useToastMessagesDispatch();
  const rerender = useRerender();

  useEffect(() => {
    // setting individual timeouts to rerender the component when a message should be hidden
    const timeouts = currentlyShownMessages
      .filter((m) => m.autoHideTimestamp !== undefined && m.autoHideTimestamp > Date.now())
      .map((m) => setTimeout(rerender, m.autoHideTimestamp! - Date.now()));
    return () => timeouts.forEach((t) => clearTimeout(t));
  }, [currentlyShownMessages]);

  const closeAlert = (id: string) => dispatch({ type: ToastMessagesActionType.HIDE_TOAST, id });

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <FlexBoxColumn alignItems="center" gap="10px">
        {currentlyShownMessages.map((m) => (
          <Collapse
            key={m.id}
            in={m.autoHideTimestamp === undefined || m.autoHideTimestamp > Date.now()}
            timeout={{ appear: 0, enter: 0, exit: 300 }} // to unify the appearance behavior, we let the toasts appear immediately
          >
            <Alert
              severity={m.severity}
              onClose={() => closeAlert(m.id)}
            >
              {m.message}
            </Alert>
          </Collapse>
        ))}
      </FlexBoxColumn>
    </Snackbar>
  );
}
