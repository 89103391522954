import { TFunction } from 'i18next';
import { ArticleLevelParametersBody } from '../../../../api/interfaces/linker-profile';
import { InfoItem } from './profile-info-tooltip-content';

export default function getGenericProfileInfo(article_parameters: ArticleLevelParametersBody, t: TFunction): InfoItem[] {
  const info: InfoItem[] = [];
  info.push({
    text: `${t('lo-average-links-per-paragraph')}: ${article_parameters.avg_links_per_paragraph}`,
  });
  info.push({
    text: `${t('lo-recommendations-per-selection')}: ${article_parameters.articles_per_selection}`,
  });
  info.push({
    text: `${t('lo-preferred-length')}: ${article_parameters.preferred_selection_length}`,
    weight: article_parameters.selection_length_weight,
  });
  info.push({
    text: t('lo-similarity-to-source'),
    weight: article_parameters.selection_to_source_weight,
  });
  return info;
}
