import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PurpleBaseColors } from '@purple/react-components';
import {
  ReactNode,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { isRestServiceRunning, isRestServicesRunning, isRestServiceSuccessful } from '../../../api/hooks/rest-service';
import { ArticleResponse } from '../../../api/interfaces/article';
import { RecommendationOriginType } from '../../../api/interfaces/recommendation';
import IFrameBridge from '../../../bridge/iframe-bridge';
import { ACMAppBarProvider } from '../../../context/acm-app-bar-context';
import { useACMUserState } from '../../../context/acm-page-context';
import {
  CombinedLinkerAction,
  CombinedLinkerPageProvider,
  LinkType,
  RecommendationElementPair,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../context/linker/combined-linker-context';
import {
  LinkerEditorAction,
  LinkerEditorPageProvider,
  useLinkerEditorPageDispatch,
  useLinkerEditorPageService,
  useLinkerEditorPageState,
} from '../../../context/linker/linker-editor-context';
import { LinkerProfilesProvider, useLinkerProfilesService } from '../../../context/profiles/linker-profiles-context';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import SplitViewPage from '../../../theme/sprylab/components/pages/split-view-page';
import RestSnackbar, { RestSnackbarMultiple } from '../../../theme/sprylab/components/rest-snackbar';
import { inIframe } from '../../../util/dom-utils';
import Scrolling from '../../../util/scrolling';
import useSearchQueryParams from '../../../util/use-search-query-params';
import AutocompleteArticles from '../../autocomplete/autocomplete-articles';
import TabPanel from '../../common/tab-panel';
import UserSpecificLnkOptFeedbackForm from '../../feedback/user-specific-lnk-opt-feedback-form';
import CombinedLinkToDetailsReferenceLine from '../connection-lines/combined-link-to-details-reference-line';
import LinkerEditor from '../editor/linker-editor';
import { ArticleHeaderControls, HeaderControls, ProductHeaderControls } from '../generic/header-controls';
import { ArticleLinkerPhraseConfig, ProductLinkerPhraseConfig } from '../generic/phrase/linker-phrase-config';
import SaveButton from '../generic/save-button';
import LinkStatisticsTooltip from '../statistics-for-article/link-statistics-tooltip';
import CombinedLinkerPhraseConfig from './phrase/combined-linker-phrase-config';
import CombinedLinkRecommendationsDetails from './recommendation-details/combined-link-recommendations-details';

interface Props {
  articleId?: string
}

function CombinedLinkerPageWithoutProvider(props: Props) {
  const { t } = useTranslation();
  const { articleId: propsArticleId } = props;
  const { phraseSelection } = useLinkerEditorPageState();
  const { getArticleLinkerProfilesService, getProductLinkerProfilesService } = useLinkerProfilesService();
  const dispatchEditor = useLinkerEditorPageDispatch();
  const {
    articleId: stateArticleId,
    combinedLinkRecommendationElements,
    scrollTarget,
    scrollToRecommendationDetails,
    scrollToRecommendationEntity,
    currentLinkTypeFilter,
    hasUnsavedChanges,
  } = useCombinedLinkerPageState();
  const articleId = propsArticleId || stateArticleId;
  const articleByIdService = useLinkerEditorPageService()[articleId];
  const dispatchCombined = useCombinedLinkerPageDispatch();
  const servicesCombined = useCombinedLinkerPageService();
  const {
    getCombinedLinksRecommendationService,
    postArticleLinkRecommendationService,
    postArticleLinksRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  } = servicesCombined[articleId];
  const [isAutoScrollingRight, setIsAutoScrollingRight] = useState<boolean>(false);
  const [isAutoScrollingLeft, setIsAutoScrollingLeft] = useState<boolean>(false);
  const [scrollToDetails, setScrollToDetails] = useState<{ key: string, elements?: RecommendationElementPair }>();
  const [scrollToEntity, setScrollToEntity] = useState<{ key: string, elements?: RecommendationElementPair }>();
  const [allElements, setAllElements] = useState<{ key: string, elements?: RecommendationElementPair }[]>();
  const [scrollingOnTheRight, setScrollingOnTheRight] = useState<boolean>(false);
  const refLeftContentRef = useRef<HTMLDivElement>(null);
  const refRightContentRef = useRef<HTMLDivElement>(null);
  const { featureFlags } = useACMUserState();

  const theme = useTheme();

  const isWaitingForApiResponse = isRestServicesRunning([
    articleByIdService,
    getArticleLinkerProfilesService,
    getProductLinkerProfilesService,
    getCombinedLinksRecommendationService,
    postArticleLinkRecommendationService,
    postArticleLinksRecommendationService,
    postProductLinkRecommendationService,
    postProductLinksRecommendationService,
    saveAllCombinedLinksService,
  ]);

  const linksSaveSuccessful: boolean = useMemo(() => (
    isRestServiceSuccessful(saveAllCombinedLinksService, 'Update successful')
  ), [saveAllCombinedLinksService.status]);

  const hasPhraseSelection = !!phraseSelection?.paragraphText;
  const showConnectionLines = !hasPhraseSelection && isRestServiceSuccessful(articleByIdService);

  const { hideHeader, hideCloseButton } = useSearchQueryParams();

  useEffect(() => {
    refLeftContentRef.current?.parentElement?.addEventListener('scroll', handleLeftScroll);
    refRightContentRef.current?.parentElement?.addEventListener('scroll', handleRightScroll);
  }, []);

  useEffect(() => {
    if (inIframe() && linksSaveSuccessful) {
      IFrameBridge.reloadEditor();
    }
  }, [linksSaveSuccessful]);

  function handleLeftScroll() {
    setScrollingOnTheRight(false);
  }

  function handleRightScroll() {
    setScrollingOnTheRight(true);
  }

  useEffect(() => {
    let foundTopEntity = false;
    let foundTopDetails = false;
    const scrollElements: any[] = [];
    combinedLinkRecommendationElements.forEach((value, key) => {
      const element = { key: key.keyword, elements: value };
      scrollElements.push(element);
      if (!isAutoScrollingLeft && !foundTopEntity) {
        foundTopEntity = true;
        const keyForState = key.keyword;

        if (keyForState !== scrollToDetails?.key) {
          setScrollToDetails({ key: keyForState, elements: value });
        }
      }
      if (!isAutoScrollingRight && !foundTopDetails) {
        foundTopDetails = true;
        const keyForState = key.keyword;

        if (keyForState !== scrollToEntity?.key) {
          setScrollToEntity({ key: keyForState, elements: value });
        }
      }
    });
    setAllElements(scrollElements);
  }, [combinedLinkRecommendationElements]);

  useEffect(() => {
    if (scrollTarget !== undefined) {
      const keyForRecommendation = scrollTarget.keyword;
      // find the entity pair with the key of the selected recommendation
      const entityPair = allElements?.find((el) => keyForRecommendation === el.key);
      // "reset" scrolling on the right because you're about to scroll on the right
      setScrollingOnTheRight(false);
      // set entities to scroll to
      setScrollToDetails(entityPair);
      setScrollToEntity(entityPair);
    } else {
      // So that when setting the same scrollTarget again, we actually scroll again,
      // we have to reset this state after a scroll is done.
      setScrollToDetails(undefined);
      setScrollToEntity(undefined);
    }
  }, [scrollToRecommendationEntity, scrollToRecommendationDetails, scrollTarget]);

  useEffect(() => {
    if (!scrollToDetails || !scrollToEntity) return;

    if (scrollToRecommendationDetails) {
      const scrollContainer = refRightContentRef.current?.parentElement;
      let scrollToElement = scrollToDetails.elements?.detailsEl?.ref.current;

      if (scrollTarget) {
        const selectedRecommendationKey = scrollTarget.keyword;
        if (scrollToDetails?.key !== selectedRecommendationKey) {
          const selectedEl = allElements?.find((el) => selectedRecommendationKey === el.key);
          if (selectedEl) {
            scrollToElement = selectedEl.elements?.detailsEl?.ref.current;
          }
        }
      }

      if (scrollToElement && scrollContainer) {
        setIsAutoScrollingRight(true);
        if (scrollToEntity.key !== scrollToDetails.key) {
          setScrollToEntity({
            key: scrollToDetails.key,
            elements: scrollToDetails?.elements,
          });
        }
        // prevent autoscroll (on the right side) if you are scrolling yourself on the right side
        if (!scrollingOnTheRight) {
          Scrolling.scrollBasedOnDistance(scrollContainer, scrollToElement, undefined, () => setIsAutoScrollingRight(false));
        } else {
          setIsAutoScrollingRight(false);
        }
      }

      dispatchCombined({ type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS_FINISHED });
    }
  }, [scrollToDetails]);

  useEffect(() => {
    if (!scrollToEntity || !scrollToDetails) return;

    if (scrollToRecommendationEntity) {
      const scrollContainer = refLeftContentRef.current?.parentElement;
      const scrollToElement = scrollToEntity.elements?.entityEl?.ref.current;

      if (scrollToElement && scrollContainer) {
        setIsAutoScrollingLeft(true);
        if (scrollToEntity.key !== scrollToDetails.key) {
          setScrollToDetails({
            key: scrollToEntity.key,
            elements: scrollToEntity.elements,
          });
        }
        Scrolling.scrollBasedOnDistance(scrollContainer, scrollToElement, -60, () => setIsAutoScrollingLeft(false));
      }
    }

    dispatchCombined({ type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY_FINISHED });
  }, [scrollToEntity]);

  useEffect(() => {
    // To fix the bug that in chrome the link cards flicker when we are scrolled to the very bottom,
    // we prevent the container from ever reaching the very bottom. See also DEEP-467.
    // This way, the bug is gone while the scroll progress is stationary.
    // The bug might still be visible while a fling-scroll is still ongoing, though.
    const callback = () => {
      const recommendationsScrollContainer = refRightContentRef.current?.parentElement;
      if (recommendationsScrollContainer) {
        const maxScrollTop = recommendationsScrollContainer.scrollHeight - recommendationsScrollContainer.clientHeight;
        if (recommendationsScrollContainer.scrollTop >= maxScrollTop - 1) {
          // If we are at the very bottom of the list, scroll up a bit again, to prevent flickering effects on chrome.
          recommendationsScrollContainer.scrollTop = maxScrollTop - 1;
        }
      }
      id = requestAnimationFrame(callback);
    };
    let id = requestAnimationFrame(callback);
    return () => cancelAnimationFrame(id);
  }, []);

  const handleArticleSelectionChange = (value: ArticleResponse | null) => {
    if (value) {
      dispatchEditor({
        type: LinkerEditorAction.SET_ARTICLE_ID,
        payload: value.id,
      });
    }
  };

  const onTabClick = (tabValue: LinkType | null) => {
    dispatchCombined({ type: CombinedLinkerAction.FILTER_LINKS_BY_TYPE, payload: tabValue });
  };

  const handleCloseInIFrame = () => {
    // @ts-ignore -- comes from 'iframe-resizer-react' in the ACM WP Plugin
    window.parentIFrame.sendMessage('close');
  };

  const leftHeaderButton = (
    <>
      <LinkStatisticsTooltip />
      <SaveButton />
    </>
  );

  const leftContent = (
    <div ref={refLeftContentRef}>
      {!propsArticleId && (
        <AutocompleteArticles
          disabled={isRestServiceRunning(getCombinedLinksRecommendationService)}
          onChange={handleArticleSelectionChange}
        />
      )}
      <DataLoading
        service={articleByIdService}
        justify="flex-start"
        errorMessage={`Failed loading article with ID: ${propsArticleId}`}
      >
        <LinkerEditor />
      </DataLoading>
    </div>
  );

  function makeThreeTabPanels(noLinkType: ReactNode, article: ReactNode, product: ReactNode) {
    return (
      <>
        <TabPanel value={currentLinkTypeFilter} index={null}>
          {noLinkType}
        </TabPanel>
        <TabPanel value={currentLinkTypeFilter} index={LinkType.ARTICLE}>
          {article}
        </TabPanel>
        <TabPanel value={currentLinkTypeFilter} index={LinkType.PRODUCT}>
          {product}
        </TabPanel>
      </>
    );
  }

  const tabStyle = {
    ...theme.typography.body1,
    color: PurpleBaseColors.purpleDark,
    textTransform: 'none',
  } as const;
  const combinedRightHeader = (
    <>
      <Tabs
        variant="fullWidth"
        value={currentLinkTypeFilter}
        onChange={(e, v) => onTabClick(v)}
      >
        <Tab sx={tabStyle} label={t('lo-all-links')} value={null} />
        <Tab sx={tabStyle} label={t('lo-article-links')} value={LinkType.ARTICLE} />
        <Tab sx={tabStyle} label={t('lo-product-links')} value={LinkType.PRODUCT} />
      </Tabs>
      {!hasPhraseSelection && makeThreeTabPanels(
        <HeaderControls linkType={null} />,
        <ArticleHeaderControls />,
        <ProductHeaderControls />,
      )}
    </>
  );
  const rightHeader = featureFlags?.LINK_OPTIMIZER_ARTICLE_LINKING && !featureFlags?.LINK_OPTIMIZER_PRODUCT_LINKING
    ? <ArticleHeaderControls />
    : featureFlags?.LINK_OPTIMIZER_PRODUCT_LINKING && !featureFlags?.LINK_OPTIMIZER_ARTICLE_LINKING
      ? <ProductHeaderControls />
      : combinedRightHeader;

  const rightContent = (
    <div ref={refRightContentRef} style={{ height: '100%' }}>
      <DataLoading
        service={articleByIdService}
        justify="flex-start"
        hideError // we only want to show the error once, on the left side
      >
        {hasPhraseSelection ? makeThreeTabPanels(
          <CombinedLinkerPhraseConfig />,
          <ArticleLinkerPhraseConfig />,
          <ProductLinkerPhraseConfig />,
        ) : (
          articleId && <CombinedLinkRecommendationsDetails />
        )}
      </DataLoading>
    </div>
  );

  return (
    <>
      {showConnectionLines && (
        Array.from(combinedLinkRecommendationElements)
          .map(([recommendation, element], index) => {
            if (recommendation.origin === RecommendationOriginType.EXTERNAL || !element.entityEl || !element.detailsEl || !refLeftContentRef.current?.parentElement || !refRightContentRef.current?.parentElement) {
              return undefined;
            }
            return (
              <CombinedLinkToDetailsReferenceLine
                recommendation={recommendation}
                key={recommendation.id}
                startEl={element.entityEl}
                targetEl={element.detailsEl}
                startContainerRef={refLeftContentRef.current.parentElement}
                targetContainerRef={refRightContentRef.current.parentElement}
                index={index}
              />
            );
          })
      )}
      {inIframe() && !hideCloseButton && (
        <IconButton
          disabled={isWaitingForApiResponse}
          sx={{
            top: hideHeader ? 0 : '10px',
            right: 0,
            padding: hideHeader ? '5px' : undefined,
            position: 'absolute',
            zIndex: 100000,
          }}
          onClick={handleCloseInIFrame}
          size="large"
        >
          <CloseIcon sx={{ color: hideHeader ? undefined : 'white' }} />
        </IconButton>
      )}
      <SplitViewPage
        leftHeaderTitle={t('lo-generated-links-header')}
        leftHeaderButton={leftHeaderButton}
        leftContent={leftContent}
        rightHeaderButton={rightHeader}
        rightContent={rightContent}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <UserSpecificLnkOptFeedbackForm />
      <RestSnackbar
        service={saveAllCombinedLinksService}
        id="saveAllCombinedLinksService"
        loadingMessage={t('lo-saving-links')}
      />
      <RestSnackbarMultiple
        services={[postArticleLinkRecommendationService, postProductLinkRecommendationService]}
        id="postCombinedLinkRecommendationService"
        loadingMessage={t('lo-getting-recommendation')}
        noContentMessage={t('lo-no-recommendations-found-phrase')}
        autoHideDuration={6000}
        successAutoHideDuration={1000}
      />
      <RestSnackbarMultiple
        services={[postArticleLinksRecommendationService, postProductLinksRecommendationService]}
        id="postCombinedLinksRecommendationService"
        loadingMessage={t('lo-getting-recommendations')}
        noContentMessage={t('lo-no-recommendations-found-article')}
        autoHideDuration={6000}
        successAutoHideDuration={1000}
      />
    </>
  );
}

export default function CombinedLinkerPage() {
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const articleId = location.state?.articleId ?? params.id;
  const { hideHeader, postType } = useSearchQueryParams();

  return (
    <LinkerEditorPageProvider articleId={articleId} postType={postType}>
      <ACMAppBarProvider disable={!!articleId} hideHeader={hideHeader}>
        <LinkerProfilesProvider>
          <CombinedLinkerPageProvider articleId={articleId}>
            <CombinedLinkerPageWithoutProvider articleId={articleId} />
          </CombinedLinkerPageProvider>
        </LinkerProfilesProvider>
      </ACMAppBarProvider>
    </LinkerEditorPageProvider>
  );
}
