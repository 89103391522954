import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ActionButton, ExternalLinkButton } from '@purple/react-components';
import { EntityResponse, EntityType } from '../../../api/interfaces/entity';

type Props = {
  selectedEntity: EntityResponse
  alreadyAddedAsKeyword: boolean
  onKeywordAddOrRemove: () => void
}

export default function EntityClickActionPopoverButtons(props: Props) {
  const {
    selectedEntity, alreadyAddedAsKeyword, onKeywordAddOrRemove,
  } = props;
  return (
    <>
      <ActionButton
        onClick={onKeywordAddOrRemove}
        startIcon={alreadyAddedAsKeyword ? <RemoveIcon /> : <AddIcon />}
      >
        {alreadyAddedAsKeyword ? 'Remove' : 'Add'}
      </ActionButton>
      {selectedEntity.wiki_link && (
        <ExternalLinkButton href={selectedEntity.wiki_link}>
          Wikipedia
        </ExternalLinkButton>
      )}
      {selectedEntity.kind === EntityType.LOC && (
        <ExternalLinkButton
          href={`https://www.google.com/maps/search/?api=1&query=${selectedEntity.name}`}
        >
          Google Maps
        </ExternalLinkButton>
      )}
    </>
  );
}
