import * as React from 'react';

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

/**
 * A component that is only shown if the correct tab is selected.
 * From: https://v4.mui.com/components/tabs/#tabs
 */
export default function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index } = props;

  if (value === index) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  } else {
    return null;
  }
}
