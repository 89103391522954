import { ActionButton } from '@purple/react-components';
import { useState } from 'react';
import * as React from 'react';
import { getDefaultProfileByType, ProfileType } from '../../../context/profiles/linker-profiles-context';
import CreateProfileConfirm from './create-profile';

interface Props {
  profileType: ProfileType
}

export default function ProfileNewButton(props: Props) {
  const { profileType } = props;
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const defaultProfile = getDefaultProfileByType(profileType);

  const handleCreateClick = (event: React.MouseEvent<HTMLElement>) => {
    setCreateDialogOpen(true);
  };

  const handleCreateClose = () => {
    setCreateDialogOpen(false);
  };

  return (
    <>
      <ActionButton
        floating
        onClick={handleCreateClick}
        data-testid="create-new-profile-button"
      >
        Create New
      </ActionButton>
      <CreateProfileConfirm
        isOpen={createDialogOpen}
        handleCreateClose={handleCreateClose}
        profileType={profileType}
        startingName="New Profile"
        profile={defaultProfile}
      />
    </>
  );
}
