import $ from 'jquery';

export default class Scrolling {
  public static scrollBasedOnDistance = (scrollContainer: HTMLElement, scrollToElement: HTMLElement, offsetTop?: number, complete?: Function) => {
    const docHeight = $(document).height();
    const targetTop = scrollToElement.offsetTop - 10; // scroll a little bit further up so that any potential drop shadow is also visible

    if (docHeight) {
      // Source: https://css-tricks.com/snippets/jquery/smooth-scrolling/#comment-1604143
      // (but simplified to just have a constant animation duration)

      $(scrollContainer).animate(
        {
          scrollTop: targetTop + (offsetTop || 0),
        },
        400,
        () => complete && complete(),
      );
    }
  };
}
