import { useTranslation } from 'react-i18next';
import { ProductLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import { ProductLinkRecommendationResponse } from '../../../../api/interfaces/recommendation';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import GenericLinkScoresTooltip from '../../combined-linker/recommendation-details/generic-link-scores-tooltip';

export interface RecommendationScoreProps {
  linkScores: ProductLinkRecommendationResponse
}

export function ProductLinkRecommendationScores(props: RecommendationScoreProps) {
  const { linkScores } = props;
  const { t } = useTranslation();
  const { productLinkerProfiles } = useLinkerProfilesState();
  const profile: ProductLinkerProfileResponse | undefined = productLinkerProfiles.get(linkScores.profile_id);

  return (
    <GenericLinkScoresTooltip
      mainScore={{
        scoreValue: linkScores.score,
      }}
      tooltipScoreDataList={[
        {
          scoreValue: linkScores.score_selection_to_source,
          label: t('lo-selection-relevance'),
          weight: profile?.article_parameters.selection_to_source_weight,
        },
        {
          scoreValue: linkScores.score_articles,
          label: t('lo-relevance-of-products'),
        },
      ]}
    />
  );
}
