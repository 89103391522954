export default class StringUtils {
  public static toUpperCamelCase = (str: string): string => {
    if (str === undefined) return str;
    return str.replace(/\w\S*/g, (txt) => (
      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    ));
  };

  public static getNumberedName = (name: string, nameList: string[]) => {
    let numberedNewName = name;
    let nameExists = nameList.includes(numberedNewName);
    let counter = 1;

    while (nameExists) {
      numberedNewName = `${name} (${counter})`;
      counter += 1;
      nameExists = nameList.includes(numberedNewName);
    }

    return numberedNewName;
  };
}
