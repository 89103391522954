import Typography from '@mui/material/Typography';
import { GridBox } from '@purple/react-components';
import React from 'react';
import useArticleLinkStatistics from './use-article-link-statistics';

interface Props {
  articleContent?: string
}

export default function LinkStatisticsTooltipContent(props: Props) {
  const { articleContent } = props;

  const statistics = useArticleLinkStatistics(articleContent);

  return (
    <GridBox
      gridTemplateColumns="auto auto"
      gap="4px"
      sx={{ p: '8px' }}
    >
      {statistics.map(([amount, label]) => (
        <React.Fragment key={label}>
          <div>
            <Typography align="right" sx={{ color: '#777' }}>
              {amount.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography>
              {label}
            </Typography>
          </div>
        </React.Fragment>
      ))}
    </GridBox>
  );
}
