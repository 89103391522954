import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { REST_STATUS, RESTService } from '../../../api/hooks/rest-service';

interface Props {
  service?: RESTService<any>
  expectedWaitTimeSeconds?: number
  errorMessage?: string
  ignoreError?: boolean
  hideError?: boolean
  alignContent?: string
  justify?: string
  children?: React.ReactNode
}

export default function DataLoading(props: Props) {
  const {
    service, expectedWaitTimeSeconds, children, errorMessage, ignoreError, hideError, alignContent, justify,
  } = props;
  const { t } = useTranslation();

  const durationString = useMemo(() => {
    if (!expectedWaitTimeSeconds || expectedWaitTimeSeconds < 5) {
      return '';
    } else if (expectedWaitTimeSeconds <= 90) {
      // we round seconds to multiples of 5, so that it looks a bit more ambiguous.
      const roundedSeconds = Math.round(expectedWaitTimeSeconds / 5) * 5;
      return `${roundedSeconds} ${t('data-loading-seconds')}`;
    } else {
      const roundedMinutes = Math.round(expectedWaitTimeSeconds / 60);
      return `${roundedMinutes} ${t('data-loading-minutes')}`;
    }
  }, [expectedWaitTimeSeconds]);

  if (!service || service.status === REST_STATUS.LOADING) {
    return (
      <Grid
        container
        direction="column"
        justifyContent={justify || 'center'}
        alignContent={alignContent || 'center'}
        alignItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <CircularProgress />
        {durationString && (
          <Typography variant="caption">
            {t('data-loading-expect-wait-time', { durationString })}
          </Typography>
        )}
      </Grid>
    );
  } else if (service?.status === REST_STATUS.ERROR && !ignoreError) {
    if (hideError) {
      return <div />;
    } else {
      return (
        <Grid
          sx={{
            background: 'antiquewhite',
            overflowWrap: 'anywhere',
          }}
        >
          <Typography color="error" variant="h3">
            {errorMessage || 'Error, the backend moved to the dark side.'}
          </Typography>
          <Typography variant="subtitle2">Error Message:</Typography>
          <Typography variant="caption">
            {service.error.message}
            {service.error.detailMessage && <><br />{service.error.detailMessage}</>}
          </Typography>
          <Typography variant="subtitle2">Requested URL:</Typography>
          <Typography variant="caption">{service.requestURL}</Typography>
        </Grid>
      );
    }
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
}
