import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ActionButton } from '@purple/react-components';
import { useState } from 'react';
import { ContentType } from '../../constants/interfaces/interfaces';

interface Props {
  contentTypeFilter: ContentType[]
  setContentTypeFilter: React.Dispatch<React.SetStateAction<ContentType[]>>
}

export default function ContentViewerMenu(props: Props) {
  const { contentTypeFilter, setContentTypeFilter } = props;

  const [filterAnchorEl, setFilterAnchorEl] = useState<undefined | HTMLElement>();

  function toggleType(type: ContentType) {
    setContentTypeFilter((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
  }

  return (
    <>
      <ActionButton
        size="large"
        onClick={(event) => setFilterAnchorEl(event.currentTarget)}
        icon={<FilterListRoundedIcon />}
      />
      <Menu
        anchorEl={filterAnchorEl}
        open={!!filterAnchorEl}
        onClose={() => setFilterAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem key="heading" disabled>
          Content Type
        </MenuItem>
        {Object.values(ContentType).map((type) => {
          const isSelected = contentTypeFilter.includes(type);
          return (
            <MenuItem key={type} onClick={() => toggleType(type)}>
              <Checkbox checked={isSelected} />
              <ListItemText primary={type} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
