import EditorHelper from '../components/common/editor/editor-helper';

export function isFullWordSelected(selection: Selection, parent: Node): boolean {
  if (parent != null && parent.textContent) {
    const { textContent } = parent;
    const preSelectionText = textContent.slice(0, EditorHelper.getSelectionCharacterOffset(parent).start);
    const postSelectionText = textContent.slice(EditorHelper.getSelectionCharacterOffset(parent).end, textContent.length);
    const preSelectionTextSplit = preSelectionText.split(/\W/);
    const postSelectionTextSplit = postSelectionText.split(/\W/);
    return (preSelectionTextSplit[preSelectionTextSplit.length - 1] === '' && postSelectionTextSplit[0] === '');
  }
  return false;
}

/**
 * Defines start and end of a Selection
 */
interface SelectionExtreme {
  node: Node
  offset: number
}

/**
 * Relevant Selection properties
 */
interface SelectionProperties {
  anchor: SelectionExtreme
  focus: SelectionExtreme
  isForward: boolean
}

function getSelectionProperties(selection: Selection): SelectionProperties {
  const anchor: SelectionExtreme = { node: selection.anchorNode as Node, offset: selection.anchorOffset };
  const focus: SelectionExtreme = { node: selection.focusNode as Node, offset: selection.focusOffset };

  // Get selection direction: https://stackoverflow.com/a/23512678/20496072
  let isForward = true;
  const position = selection.anchorNode?.compareDocumentPosition(selection.focusNode as Node); // position equals 0 if nodes are the same
  if (!position && selection.anchorOffset > selection.focusOffset
    || position === Node.DOCUMENT_POSITION_PRECEDING) {
    isForward = false;
  }

  return {
    anchor, focus, isForward,
  };
}
function isValidSelectionNodes(s: Selection) {
  return (s.anchorNode && s.focusNode && !s.isCollapsed);
}

function invalidateSelection(s: Selection) {
  moveCursorToAnchor(s);
}

function moveCursorToAnchor(s: Selection) {
  s.collapse(s.anchorNode, s.anchorOffset);
}

function extendToInitialFocus(s: Selection, p: SelectionProperties) {
  s.extend(p.focus.node, p.focus.offset);
}

function moveCursorToNextNewCharacter(s: Selection, p: SelectionProperties) {
  s.modify('move', p.isForward ? 'backward' : 'forward', 'character');
}

function moveCursorToNextExistingCharacter(s: Selection, p: SelectionProperties) {
  s.modify('move', p.isForward ? 'forward' : 'backward', 'character');
}

function getCharacterInAnchorPosition(s: Selection): string {
  if (getSelectionProperties(s).isForward) {
    return s.toString().slice(0, 1);
  } else {
    return s.toString().slice(-1);
  }
}

function isCharacterAlphaNumeric(c: string): boolean | Error {
  // from https://stackoverflow.com/a/66808435/4354423
  if (c.length !== 1) throw Error('Not a single character string');
  return /^[\p{L}\p{N}]*$/u.test(c);
}

function isNewCharacterInStopWordCharacters(s: Selection): boolean {
  return !isCharacterAlphaNumeric(getCharacterInAnchorPosition(s)) || /\n/u.test(s.toString()) || getCharacterInAnchorPosition(s) === ' ';
}

function isAtAnchorEnd(s: Selection): boolean {
  return s.anchorOffset === 0 || s.anchorOffset === s.anchorNode?.textContent?.length;
}

/**
 * Selection is not valid when only contain non-alphanumeric characters.
 * See DEEP-643
 */
export function isNotValidSelection(selection: Selection): boolean {
  const text = selection.toString();
  const chars = text.split('');
  return chars.every((char) => !isCharacterAlphaNumeric(char));
}

/**
 * Check whether the selection contains no link entities. (DEEP-916)
 * Article text spans in draft-js have a `data-offset-key` attribute, while link entities do not.
 */
export function otherLinksInSelection(selection: Selection): boolean {
  const selectionContent = selection.getRangeAt(0).cloneContents();
  const spans = selectionContent.querySelectorAll('span');
  const linkSpans = Array.from(spans).filter((span) => !span.hasAttribute('data-offset-key'));
  return linkSpans.length > 0;
}

function moveAnchorUntilEndOfWord(selection: Selection) {
  if (!isValidSelectionNodes(selection)) {
    return;
  }
  const properties: SelectionProperties = getSelectionProperties(selection);

  while (true) {
    // check if we can even go another character in the current anchor node, or if we reached the end.
    // (We count node boundaries as word boundaries)
    // See https://jira.sprylab.com/browse/DEEP-991
    if (isAtAnchorEnd(selection)) {
      break;
    }

    // select one more character
    moveCursorToAnchor(selection);
    moveCursorToNextNewCharacter(selection, properties);
    extendToInitialFocus(selection, properties);

    // if this turns out to be a bad character, exclude it again and stop
    if (isNewCharacterInStopWordCharacters(selection)) {
      moveCursorToAnchor(selection);
      moveCursorToNextExistingCharacter(selection, properties);
      extendToInitialFocus(selection, properties);
      break;
    }
  }
}

export function automaticFullWordSelection(selection: Selection): void {
  if (!isValidSelectionNodes(selection)) {
    return;
  }

  moveAnchorUntilEndOfWord(selection);
  invertSelectionDirection(selection);
  moveAnchorUntilEndOfWord(selection);
}

function removeTrailingSpacesAtAnchor(s: Selection) {
  const p: SelectionProperties = getSelectionProperties(s);
  const whiteSpace = ' ';

  if (s.toString() === whiteSpace) {
    invalidateSelection(s);
    return;
  }

  while (true) {
    if (getCharacterInAnchorPosition(s) !== whiteSpace) {
      break;
    } else {
      moveCursorToAnchor(s);
      moveCursorToNextExistingCharacter(s, p);
      extendToInitialFocus(s, p);
    }
  }
}

function invertSelectionDirection(s: Selection) {
  s.setBaseAndExtent(s.focusNode as Node, s.focusOffset, s.anchorNode as Node, s.anchorOffset);
}

export function removeTrailingSpacesFromSelection(selection: Selection): void {
  if (!isValidSelectionNodes(selection)) {
    return;
  }

  removeTrailingSpacesAtAnchor(selection);
  invertSelectionDirection(selection);
  removeTrailingSpacesAtAnchor(selection);
}
