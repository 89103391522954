import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, IconButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { FlexBoxColumn, FlexBoxRow, PurpleBaseColors } from '@purple/react-components';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleScoreResponse } from '../../../../api/interfaces/article';
import { ArticleLinkerProfileResponse, ProductLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import {
  ArticleLinkRecommendationResponse,
  ProductLinkRecommendationResponse,
  ProductScoreResponse,
} from '../../../../api/interfaces/recommendation';
import { useACMUserState } from '../../../../context/acm-page-context';
import {
  CombinedLinkerAction,
  isArticleRR,
  LinkType,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import {
  LinkerEditorAction,
  useLinkerEditorPageDispatch,
  useLinkerEditorPageState,
} from '../../../../context/linker/linker-editor-context';
import { purpleCircleStyle } from '../../../../theme/style-utils';
import { elideTextAfterLines } from '../../../articles/article-card';
import { ArticleLinkScores } from '../../article-linker/recommendations-details/article-link-scores';
import ImagePreview from '../../combined-linker/recommendation-details/image-preview';
import { ArticleLinkerPhraseConfig, ProductLinkerPhraseConfig } from '../../generic/phrase/linker-phrase-config';
import { mergeCaptionPhrases } from '../../generic/recommendation-details/helper';
import { ProductLinkScores } from '../../product-linker/recommendation-details/product-link-scores';

type Props = ({
  linkType: LinkType.ARTICLE
  item: ArticleScoreResponse
  recommendation: ArticleLinkRecommendationResponse
  usedProfile: ArticleLinkerProfileResponse | undefined
} | {
  linkType: LinkType.PRODUCT
  item: ProductScoreResponse
  recommendation: ProductLinkRecommendationResponse
  usedProfile: ProductLinkerProfileResponse | undefined
}) & {
  captionPhrases: Array<[any, string]>
  selectable?: boolean
  checked?: boolean
  isRecommended?: boolean
  children?: React.ReactNode
}
function recommendationCount(recommendation: ArticleLinkRecommendationResponse | ProductLinkRecommendationResponse) {
  return (isArticleRR(recommendation) ? recommendation.articles : recommendation.products).length;
}

export default function GenericLinkRecommendationDetailsContent(props: Props) {
  const {
    recommendation, item, selectable, checked, isRecommended, captionPhrases, usedProfile, children, linkType,
  } = props;
  const { frontendConfig } = useACMUserState();
  const showScores = !!frontendConfig?.lnkopt_config?.show_scores;

  const { t } = useTranslation();
  const { showSelections } = useCombinedLinkerPageState();
  const dispatchEditor = useLinkerEditorPageDispatch();
  const { phraseSelection } = useLinkerEditorPageState();
  const dispatchLinker = useCombinedLinkerPageDispatch();

  const showPhraseRecommendationConfig = useMemo(() => (
    phraseSelection?.recommendationId === recommendation.id
  ), [recommendation, phraseSelection]);

  const handleShowRecommendationSelectionOnClick = () => {
    dispatchLinker({
      type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_ENTITY,
      payload: recommendation,
    });
    dispatchLinker({
      type: CombinedLinkerAction.SHOW_LINK_RECOMMENDATIONS,
      payload: recommendation,
    });
  };

  const handleRecommendationSelectionClick = (event: React.MouseEvent<HTMLElement>) => {
    // This if-else should have identical branches and is only needed for the type checker to understand the situation
    if (linkType === LinkType.ARTICLE) {
      dispatchLinker({
        type: CombinedLinkerAction.SELECT_RECOMMENDATION,
        linkType,
        payload: { recommendation, item },
      });
    } else {
      dispatchLinker({
        type: CombinedLinkerAction.SELECT_RECOMMENDATION,
        linkType,
        payload: { recommendation, item },
      });
    }
    dispatchLinker({ type: CombinedLinkerAction.CLEAR_LINK_RECOMMENDATIONS });
  };

  const handleRecommendationConfigClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!showPhraseRecommendationConfig) {
      dispatchEditor({
        type: LinkerEditorAction.SET_PHRASE_SELECTION,
        payload: {
          occurrence: recommendation.occurrence_in_paragraph,
          paragraphIndex: recommendation.index_paragraph,
          selectedText: recommendation.text_selection,
          recommendationId: recommendation.id,
        },
      });
      dispatchLinker({
        type: linkType === LinkType.ARTICLE ? CombinedLinkerAction.SELECT_PHRASE_ARTICLE_LINKER_PROFILE_ID : CombinedLinkerAction.SELECT_PHRASE_PRODUCT_LINKER_PROFILE_ID,
        payload: recommendation.profile_id,
      });
    } else {
      dispatchLinker({ type: linkType === LinkType.ARTICLE ? CombinedLinkerAction.CLEAR_ARTICLE_PHRASE_SELECTION : CombinedLinkerAction.CLEAR_PRODUCT_PHRASE_SELECTION });
      dispatchEditor({ type: LinkerEditorAction.CLEAR_PHRASE_SELECTION });
    }
  };

  const trimmedTitle = item.title?.trim();

  return (
    <div style={{ position: 'relative' }}>
      <Box
        position="absolute"
        top="8px"
        right="8px"
        color={PurpleBaseColors.purpleDark}
      >
        {selectable ? (
          <Radio
            color="primary"
            checked={checked}
            onClick={handleRecommendationSelectionClick}
            checkedIcon={<CheckCircleIcon />}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          />
        ) : (
          <FlexBoxColumn gap="4px">
            <IconButton
              onClick={handleRecommendationConfigClick}
              data-testid="tune-phrase-recommendation-config-button"
              size="large"
              sx={{ p: '6px', color: 'unset' }}
            >
              {showPhraseRecommendationConfig ? (
                <CloseIcon />
              ) : (
                <TuneIcon />
              )}
            </IconButton>
            {!showPhraseRecommendationConfig && (
              <IconButton
                size="large"
                disabled={!isRecommended || recommendationCount(recommendation) < 2}
                onClick={handleShowRecommendationSelectionOnClick}
                sx={{ p: '6px', color: 'unset' }}
                data-testid="show-link-recommendation-selection-button"
              >
                <ChevronRightIcon sx={purpleCircleStyle('24px')} />
              </IconButton>
            )}
          </FlexBoxColumn>
        )}
      </Box>
      {showPhraseRecommendationConfig ? (
        <Box sx={{ p: '22px' }}>
          {(linkType === LinkType.ARTICLE) ? (
            <ArticleLinkerPhraseConfig />
          ) : (
            <ProductLinkerPhraseConfig />
          )}
        </Box>
      ) : (
        <>
          <CardContent
            sx={{
              padding: '22px',
              cursor: showSelections ? 'pointer' : undefined,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={showSelections ? handleRecommendationSelectionClick : undefined}
          >
            <FlexBoxColumn gap="18px">
              <FlexBoxRow
                gap="12px"
                sx={{
                  height: '100px',
                  mr: '20px', // to not be shown behind the floating buttons on the top right
                }}
              >
                <ImagePreview url={item.image_url} />
                <FlexBoxColumn
                  alignItems="flex-start"
                  gap="4px"
                  sx={{ flex: '1 1 0px' }}
                >
                  {showScores && (
                    (linkType === LinkType.ARTICLE) ? (
                      <ArticleLinkScores
                        articleScores={item}
                        profile={usedProfile}
                        label={t('lo-match')}
                      />
                    ) : (
                      <ProductLinkScores
                        product={item}
                        profile={usedProfile}
                        label={t('lo-match')}
                      />
                    )
                  )}
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    sx={{
                      color: !trimmedTitle ? '#999' : undefined,
                      ...elideTextAfterLines(2),
                      flexShrink: 0,
                    }}
                  >
                    {trimmedTitle || `(${t('lo-no-title')})`}
                  </Typography>
                  <Typography variant="caption">
                    {mergeCaptionPhrases(captionPhrases)}
                  </Typography>
                </FlexBoxColumn>
              </FlexBoxRow>
              {(linkType === LinkType.ARTICLE) && /* TODO do we maybe want descriptions for products as well? */ (
                <div>
                  <Typography variant={item.description?.trim() ? 'subtitle1' : 'caption'}>
                    {item.description?.trim() || `(${t('lo-no-description')})`}
                  </Typography>
                </div>
              )}
            </FlexBoxColumn>
          </CardContent>
          {children}
        </>
      )}
    </div>
  );
}
