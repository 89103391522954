import RequestError from '../interfaces/RequestError';

export enum REST_STATUS {
  INIT,
  LOADING,
  LOADED,
  ERROR,
  NO_CONTENT,
}

export function isRestServiceRunning(service?: RESTService<any>) {
  return service?.status === REST_STATUS.LOADING;
}

export function isRestServicesRunning(services: (RESTService<any> | undefined)[]) {
  return services.some((service) => isRestServiceRunning(service));
}

export function isRestServiceFinished(service?: RESTService<any>) {
  return (
    service?.status === REST_STATUS.LOADED
    || service?.status === REST_STATUS.ERROR
    || service?.status === REST_STATUS.NO_CONTENT
  );
}

export function isRestServiceSuccessful(service?: RESTService<any>, withMessage?: string) {
  if (withMessage) {
    return service?.status === REST_STATUS.LOADED && service?.payload.message === withMessage;
  } else {
    return (
      service?.status === REST_STATUS.LOADED
      || service?.status === REST_STATUS.NO_CONTENT
    );
  }
}

export function isRestServiceFailed(service?: RESTService<any>) {
  return (
    service?.status === REST_STATUS.ERROR
  );
}

export function isRestServicesFinished(services: (RESTService<any> | undefined)[]) {
  return services.some((service) => !isRestServiceFinished(service));
}

interface RESTServiceInit {
  status: REST_STATUS.INIT
}

interface RESTServiceLoading {
  status: REST_STATUS.LOADING
}

interface RESTServiceLoaded<T> {
  status: REST_STATUS.LOADED
  payload: T
}

interface RESTServiceError {
  requestURL: string
  status: REST_STATUS.ERROR
  error: RequestError
}

interface RESTServiceNoContent {
  status: REST_STATUS.NO_CONTENT
}

export type RESTService<T> =
  | RESTServiceInit
  | RESTServiceLoading
  | RESTServiceLoaded<T>
  | RESTServiceError
  | RESTServiceNoContent;

export function markAsNoContent<T>(service: RESTServiceLoaded<T>) {
  // Modify the rest service object to make it seem like there was no content.
  // This is intended for services that return a "200 OK" with payload,
  // but that should be handled as if there was no content, e.g. showing a warning popup.
  ((service as RESTService<T>) as RESTServiceNoContent).status = REST_STATUS.NO_CONTENT;
}

export function restServiceResultMapped<A, B>(service: RESTService<A>, mapper: (a: A) => B): RESTService<B> {
  if (service.status === REST_STATUS.LOADED) {
    return {
      ...service,
      payload: mapper(service.payload),
    };
  } else {
    return service;
  }
}

/** A rest service object that is only ever in the INIT state. */
export const restServicePlaceholder: RESTServiceInit = Object.freeze({ status: REST_STATUS.INIT });
