import { ConfirmationDialog } from '@purple/react-components';
import { LinkerProfilesAction, useLinkerProfilesDispatch } from '../../../context/profiles/linker-profiles-context';

interface Props {
  isDialogOpen: boolean
  onConfirmation?: () => void
  onCancel: () => void
}

export default function DiscardChangesDialog(props: Props) {
  const { isDialogOpen, onConfirmation, onCancel } = props;
  const dispatch = useLinkerProfilesDispatch();

  const handleDirtyDialogConfirm = () => {
    dispatch({
      type: LinkerProfilesAction.SET_PROFILE_DIRTY,
      payload: false,
    });
    onConfirmation?.();
  };

  return (
    <ConfirmationDialog
      dialogTitle="You have Unsaved Changes. Discard them?"
      confirmText="Discard"
      open={isDialogOpen}
      onConfirm={handleDirtyDialogConfirm}
      onCancel={onCancel}
    />
  );
}
