import { TFunction } from 'i18next';
import { ArticleLinkerProfileResponse, PremiumFilter } from '../../../../api/interfaces/linker-profile';
import { assertNever } from '../../../../util/reducer-utils';
import ProfileInfoButton, { ConcreteProfileInfoButtonProps } from '../../generic/profile/profile-info-button';
import { InfoItem } from '../../generic/profile/profile-info-tooltip-content';

export default function ArticleProfileInfoButton(props: ConcreteProfileInfoButtonProps<ArticleLinkerProfileResponse>) {
  return (
    <ProfileInfoButton
      infoGetter={getArticleProfileInfo}
      {...props}
    />
  );
}

function getArticleProfileInfo(profile: ArticleLinkerProfileResponse, t: TFunction): InfoItem[] {
  const info: InfoItem[] = [];

  if (profile.link_parameters.date_range > 0) {
    info.push({
      text: `${t('lo-max-age')}: ${profile.link_parameters.date_range} ${t('lo-days')}`,
    });
  }
  info.push({
    text: t('lo-recency'),
    weight: profile.link_parameters.date_weight,
  });
  const filteredCategories = profile.link_parameters.categories.filter((category) => category.trim().length > 0);
  // If the weight is 100, categories turn into a hard filter, so we omit the weight info
  const categoriesWeight = profile.link_parameters.categories_weight < 100 ? profile.link_parameters.categories_weight : undefined;
  if (filteredCategories.length === 1) {
    info.push({
      text: `${t('lo-category')}: ${filteredCategories[0]}`,
      weight: categoriesWeight,
    });
  } else if (filteredCategories.length > 1) {
    info.push({
      text: `${t('lo-categories')}: ${filteredCategories.join(', ')}`,
      weight: categoriesWeight,
    });
  }
  if (profile.link_parameters.premium !== PremiumFilter.ALL) {
    if (profile.link_parameters.premium === PremiumFilter.PREMIUM) {
      info.push({
        text: t('lo-premium-articles-only'),
      });
    } else if (profile.link_parameters.premium === PremiumFilter.FREE) {
      info.push({
        text: t('lo-free-articles-only'),
      });
    } else {
      assertNever(profile.link_parameters.premium);
    }
  }
  info.push({
    text: t('lo-similarity-to-selection'),
    weight: profile.link_parameters.selection_to_target_weight,
  });
  info.push({
    text: t('lo-similarity-to-sentence'),
    weight: profile.link_parameters.sentence_to_target_weight,
  });
  info.push({
    text: t('lo-similarity-to-article'),
    weight: profile.link_parameters.source_to_target_weight,
  });
  info.push({
    text: t('lo-similarity-confidence'),
    weight: profile.link_parameters.target_confidence_weight,
  });

  return info;
}
