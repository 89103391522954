import { Typography } from '@mui/material';
import { FlexBoxColumn } from '@purple/react-components';
import { useTranslation } from 'react-i18next';

export default function CombinedLinkerPhraseConfig() {
  const { t } = useTranslation();

  return (
    <FlexBoxColumn
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%' }}
    >
      <Typography variant="h5" color="textPrimary">
        {t('lo-hint-select-link-type')}
      </Typography>
    </FlexBoxColumn>
  );
}
