import * as React from 'react';
import { FeatureFlag, FeatureFlags } from '../../api/interfaces/feature-flags';

export enum ACMPublicPage {
  LOGIN = 'LOGIN',
  EXTERNAL_ACM_FUNCTIONS = 'EXTERNAL_ACM_FUNCTIONS',
}

export enum ACMPrivatePage {
  DASHBOARD = 'DASHBOARD',
  CONTENT_VIEWER = 'CONTENT_VIEWER',
  SIDEBAR_MAIN = 'SIDEBAR_MAIN',
  COMBINED_LINKER = 'COMBINED_LINKER',
  COMBINED_LINKER_ID = 'COMBINED_LINKER_ID',
  LINK_OPTIMIZER_PROFILES = 'LINK_OPTIMIZER_PROFILES',
  LINK_OPTIMIZER_BLOCKLIST = 'LINK_OPTIMIZER_BLOCKLIST',
  LINK_OPTIMIZER_STATISTICS = 'LINK_OPTIMIZER_STATISTICS',
}

export enum ACMAdminPage {
  CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
  CUSTOMERS = 'CUSTOMERS',
  LINK_OPTIMIZER_DASHBOARD = 'LINK_OPTIMIZER_DASHBOARD',
}

export enum LinkOptimizerTab {
  COMBINED_LINKER = ACMPrivatePage.COMBINED_LINKER,
  LINK_OPTIMIZER_PROFILES = ACMPrivatePage.LINK_OPTIMIZER_PROFILES,
  LINK_OPTIMIZER_BLOCKLIST = ACMPrivatePage.LINK_OPTIMIZER_BLOCKLIST,
  LINK_OPTIMIZER_STATISTICS = ACMPrivatePage.LINK_OPTIMIZER_STATISTICS,
}

export enum ReaderTab {
  CONTENT_VIEWER = ACMPrivatePage.CONTENT_VIEWER,
}

export enum AdminTab {
  CUSTOMERS = ACMAdminPage.CUSTOMERS,
  LINK_OPTIMIZER_DASHBOARD = ACMAdminPage.LINK_OPTIMIZER_DASHBOARD
}

export interface ACMPageData {
  label: React.FC
  path: string
  component: React.ComponentType<any>
  featureFlag?: FeatureFlag
}

export interface ACMPageList {
  key: string
  pageData: ACMPageData
}

export function shouldShowPage(pageData: ACMPageData, featureFlags?: FeatureFlags): boolean {
  if (!pageData.featureFlag) {
    return true;
  }
  if (!featureFlags) {
    return false;
  }
  return featureFlags[pageData.featureFlag];
}

export function shouldShowPageList(pageList: ACMPageList, featureFlags?: FeatureFlags): boolean {
  return shouldShowPage(pageList.pageData, featureFlags);
}
