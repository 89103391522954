import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import * as React from 'react';
import { GenericLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import { ProfileType } from '../../../context/profiles/linker-profiles-context';
import CreateProfileConfirm from './create-profile';

interface Props {
  profile: GenericLinkerProfileResponse
  profileType: ProfileType
}

export default function ProfileCopyButton(props: Props) {
  const { profile, profileType } = props;
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  const handleCopyClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setCreateDialogOpen(true);
  };

  const handleCreateClose = () => {
    setCreateDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Create a copy of this profile">
        <IconButton
          size="small"
          onClick={handleCopyClick}
          data-testid={`duplicate-profile-button-${profile.name}`}
        >
          <LibraryAddIcon color="primary" />
        </IconButton>
      </Tooltip>
      <CreateProfileConfirm
        isOpen={createDialogOpen}
        handleCreateClose={handleCreateClose}
        profileType={profileType}
        startingName={`Copy of ${profile.name}`}
        profile={profile}
      />
    </>
  );
}
