import {
  ConfirmationDialog,
  Dropdown,
  PasswordInput,
  SettingsTable,
  STEntry,
  TextInput,
} from '@purple/react-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { USER_ROLES } from '../../api/interfaces/user';
import RestService from '../../api/rest.service';

interface NewCustomerProps {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
}

export default function NewCustomer(props: NewCustomerProps) {
  const { dialogOpen, setDialogOpen } = props;
  const [name, setName] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [role, setRole] = useState<string>('customer');

  const createCustomerDisabled = !name || !username || !password || !role;

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function createUser() {
    if (name !== '' && password !== '' && username !== '') {
      if (password.length < 6) {
        window.alert('The password has to be at least 6 symbols long');
      } else {
        RestService.postCustomer({
          name, username, password, role,
        })
          .then((response) => {
            if (response.public_id) {
              window.location.href = `/admin/customer/detail/${response.public_id}`;
            } else {
              window.alert('Error while creating the user. Check all fields and try again');
            }
          }).catch((error) => {
            window.alert('Error while creating the user. Check all fields and try again');
          });
      }
    } else {
      window.alert('Please fill in all fields!');
    }
  }

  return (
    <ConfirmationDialog
      dialogTitle="Create a new user"
      confirmText="Create"
      cancelText="Cancel"
      open={dialogOpen}
      onCancel={handleDialogClose}
      onConfirm={createUser}
      confirmDisabled={createCustomerDisabled}
    >
      <SettingsTable>
        <STEntry label="Customer name">
          <TextInput value={name} setValue={setName} />
        </STEntry>
        <STEntry label="Customer username">
          <TextInput value={username} setValue={setUsername} />
        </STEntry>
        <STEntry label="Customer password">
          <PasswordInput value={password} setValue={setPassword} />
        </STEntry>
        <STEntry label="Customer role">
          <Dropdown options={USER_ROLES} value={role} setValue={setRole} />
        </STEntry>
      </SettingsTable>
    </ConfirmationDialog>
  );
}
