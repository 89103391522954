import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '../../api/interfaces/feature-flags';
import CustomerDetail from '../../components/admin/customer-detail';
import CustomerTable from '../../components/admin/customer-table';
import LinkOptimizerDashboardPage from '../../components/admin/link-optimizer-dashboard/dashboard-page';
import ContentViewer from '../../components/content-viewer/content-viewer';
import Dashboard from '../../components/dashboard/dashboard-site';
import BlocklistPage from '../../components/link-optimizer/blocklist-page/blocklist-page';
import CombinedLinkerPage from '../../components/link-optimizer/combined-linker/combined-linker-page';
import LinkOptimizerStatistics from '../../components/link-optimizer/statistics-page/link-optimizer-statistics';
import Login from '../../components/login/login';
import ProfilesPage from '../../components/profiles-page/profiles-page';
import SidebarMain from '../../components/sidebar/sidebar-main';
import ExternalAcmFunctions from '../../externalAcmFunctions';
import {
  ACMAdminPage, ACMPageData, ACMPrivatePage, ACMPublicPage,
} from '../interfaces/page';

/** This object helps the i18next babel plugin detect translation keys in single-line-components */
const myi18next = {
  t: (key: string) => useTranslation().t(key),
};

const PageConfig: { [key: string]: ACMPageData } = {
  [ACMPublicPage.LOGIN]: {
    label: () => myi18next.t('menu-login'),
    path: '/login',
    component: Login,
  },
  [ACMPublicPage.EXTERNAL_ACM_FUNCTIONS]: {
    label: () => 'externalAcmFunctions',
    path: '/externalAcmFunctions',
    component: ExternalAcmFunctions,
  },
  [ACMPrivatePage.DASHBOARD]: {
    label: () => myi18next.t('menu-dashboard'),
    path: '/',
    component: Dashboard,
  },
  [ACMPrivatePage.CONTENT_VIEWER]: {
    label: () => myi18next.t('menu-content-viewer'),
    path: '/content-viewer',
    component: ContentViewer,
    featureFlag: FeatureFlag.RECOMMENDATION_ENGINE,
  },
  [ACMAdminPage.CUSTOMERS]: {
    label: () => myi18next.t('menu-customers'),
    path: '/admin/customers',
    component: CustomerTable,
  },
  [ACMAdminPage.CUSTOMER_DETAILS]: {
    label: () => myi18next.t('menu-customer-details'),
    path: '/admin/customer/detail/:id',
    component: CustomerDetail,
  },
  [ACMPrivatePage.SIDEBAR_MAIN]: {
    label: () => myi18next.t('menu-sidebar'),
    path: '/sidebar',
    component: SidebarMain,
  },
  [ACMPrivatePage.COMBINED_LINKER]: {
    label: () => myi18next.t('menu-link-optimizer'),
    path: '/link-optimizer',
    component: CombinedLinkerPage,
    featureFlag: FeatureFlag.LINK_OPTIMIZER,
  },
  [ACMPrivatePage.COMBINED_LINKER_ID]: {
    label: () => myi18next.t('menu-link-optimizer'),
    path: '/link-optimizer/:id',
    component: CombinedLinkerPage,
    featureFlag: FeatureFlag.LINK_OPTIMIZER,
  },
  [ACMPrivatePage.LINK_OPTIMIZER_PROFILES]: {
    label: () => myi18next.t('menu-link-optimizer-profiles'),
    path: '/link-optimizer-profiles',
    component: ProfilesPage,
    featureFlag: FeatureFlag.LINK_OPTIMIZER,
  },
  [ACMPrivatePage.LINK_OPTIMIZER_BLOCKLIST]: {
    label: () => myi18next.t('menu-link-optimizer-blocklist'),
    path: '/link-optimizer-blocklist',
    component: BlocklistPage,
    featureFlag: FeatureFlag.LINK_OPTIMIZER,
  },
  [ACMPrivatePage.LINK_OPTIMIZER_STATISTICS]: {
    label: () => myi18next.t('menu-link-optimizer-statistics'),
    path: '/link-optimizer-statistics',
    component: LinkOptimizerStatistics,
    featureFlag: FeatureFlag.LINK_OPTIMIZER,
  },
  [ACMAdminPage.LINK_OPTIMIZER_DASHBOARD]: {
    label: () => myi18next.t('menu-link-optimizer-dashboard'),
    path: '/admin/link-optimizer-dashboard',
    component: LinkOptimizerDashboardPage,
  },
};

export default PageConfig;
