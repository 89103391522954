import { Box, Popper, PopperProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export type ArrowPopperProps = Omit<PopperProps, 'sx' | 'children'> & PropsWithChildren & {
  arrowColor?: string
}

/**
 * A MUI Popper component with a colorable arrow.
 * Adapted from: https://mui.com/material-ui/react-popper/#scroll-playground
 * Source code: https://github.com/mui/material-ui/blob/master/docs/data/material/components/popper/ScrollPlayground.js
 */
export default function ArrowPopper(props: ArrowPopperProps) {
  const {
    arrowColor: propsArrowColor, children, modifiers, ...restProps
  } = props;
  const arrowColor = propsArrowColor ?? '#fff';
  return (
    <Popper
      sx={{
        zIndex: 1,
        '& > div': {
          position: 'relative',
        },
        '&[data-popper-placement*="bottom"]': {
          '& .MuiPopper-arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
              borderWidth: '0 1em 1em 1em',
              borderColor: `transparent transparent ${arrowColor} transparent`,
            },
          },
        },
        '&[data-popper-placement*="top"]': {
          '& .MuiPopper-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
              borderWidth: '1em 1em 0 1em',
              borderColor: `${arrowColor} transparent transparent transparent`,
            },
          },
        },
        '&[data-popper-placement*="right"]': {
          '& .MuiPopper-arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
              borderWidth: '1em 1em 1em 0',
              borderColor: `transparent ${arrowColor} transparent transparent`,
            },
          },
        },
        '&[data-popper-placement*="left"]': {
          '& .MuiPopper-arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
              borderWidth: '1em 0 1em 1em',
              borderColor: `transparent transparent transparent ${arrowColor}`,
            },
          },
        },
      }}
      modifiers={[
        {
          name: 'arrow',
          options: { padding: 4 },
        },
        {
          name: 'offset',
          options: { offset: [0, 5] },
        },
        ...(modifiers ?? []),
      ]}
      {...restProps}
    >
      <Box
        data-popper-arrow
        className="MuiPopper-arrow"
        sx={{
          position: 'absolute',
          fontSize: 7,
          width: '3em',
          height: '3em',
          '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderColor: 'transparent',
          },
        }}
      />
      {children}
    </Popper>
  );
}
