import { stateFromHTML } from '@sprylab/draft-js-import-html';
import * as Draft from 'draft-js';

export default class EditorHelper {
  public static findEntityRangesByType(entityType: Draft.DraftEntityType) {
    return (contentBlock: Draft.ContentBlock, callback: any, contentState: Draft.ContentState) => {
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          if (entityKey === null) return false;

          return contentState.getEntity(entityKey).getType() === entityType;
        },
        callback,
      );
    };
  }

  public static createEditorStateWithContent(content: string) {
    return Draft.EditorState.createWithContent(
      stateFromHTML(EditorHelper.replaceWordpressGalleryTags(content), {
        customInlineFn: (element, inlineCreators) => {
          if (element.tagName === 'A') {
            return inlineCreators.Entity(
              'LINK',
              Object.fromEntries(Array.from(element.getAttributeNames()).map((attr) => [attr, element.getAttribute(attr)])),
            );
          }
          return undefined;
        },
      }),
    );
  }

  public static replaceWordpressGalleryTags(content: string) {
    // See DEEP-464: We detect wp:gallery blocks and hide their content for the 20min demo
    // eslint-disable-next-line arrow-body-style
    return content.replace(/<!-- wp:gallery .*-->(.+)<!-- \/wp:gallery -->/gs, (match, p1) => {
      // TODO the img-tags do not work correctly, apparently DRAFTjs cannot directly display them.
      //  Maybe there are workarounds to enable them anyway?
      //  See https://github.com/sstur/draft-js-utils/issues/59#issuecomment-271458909
      //  But for now we simply completely hide the images
      // const galleryDom = new DOMParser().parseFromString(p1, 'text/html');
      // const galleryImages = galleryDom.querySelectorAll('img');
      // const galleryImagesSrc = Array.from(galleryImages).map((image) => image.getAttribute('src'));
      // const images = galleryImagesSrc.map((src) => `<img src="${src}" />`).join('');
      // return `<p>${images}</p>`;
      return '';
    });
  }

  /**
   * This gives the correct Caret / Selection Offset
   * If you select text inside a container which contains span / strong or other inline elements,
   * the offset is wrong if the caret or selection is after the inline element
   */
  public static getSelectionCharacterOffset(element: Node) {
    let start = 0;
    let end = 0;
    const doc = element.ownerDocument;

    if (doc) {
      const win = doc.defaultView;
      if (win) {
        const sel = win.getSelection();
        if (sel && sel.rangeCount > 0) {
          const range = sel.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.startContainer, range.startOffset);
          start = preCaretRange.toString().length;
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          end = preCaretRange.toString().length;
        }
      }
    }

    return { start, end };
  }
}
