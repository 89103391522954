export function distinctBy(by: string, array: any[]) {
  return array.filter((value, index, self) => self.findIndex((entry) => entry[by] === value[by]) === index);
}

export function toPercent(value: number): string {
  if (value) {
    return `${Math.round(value * 100) / 100 * 100} %`;
  } else {
    return '0 %';
  }
}

export function hashNumberFromString(data: string) {
  // calculate a simple hash number representing an arbitrary string
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    hash = data.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function generateUniqueCssColorString(dataToHash: string, saturation: number, lightness: number) {
  // idea: go around the hue circle in golden-ratio-steps,
  // based on a hash of the input data,
  // to generate a unique hue for each unique input / hash.
  const conjugateGoldenRatio = 2 / (1 + Math.sqrt(5));
  const hue = (hashNumberFromString(dataToHash) * conjugateGoldenRatio) % 1;
  return `hsl(${hue * 360}, ${saturation * 100}%, ${lightness * 100}%)`;
}

export function pluralize(count: number, singular: string, plural?: string): string {
  if (count === 1) {
    return singular;
  } else {
    return plural || `${singular}s`;
  }
}

export function mapToRange(minValue: number, maxValue: number, minScaledValue: number, maxScaledValue: number, value: number): number {
  return (maxScaledValue - minScaledValue) * (value - minValue) / (maxValue - minValue) + minScaledValue;
}
