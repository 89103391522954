import { Theme } from '@mui/material';
import { GenericLinkRecommendationResponse } from '../../../../api/interfaces/recommendation';
import { isArticleRR, isProductRR } from '../../../../context/linker/combined-linker-context';
import { AcmExtraColors } from '../../../../theme/style-utils';

export function findRecommendationStrokeColor(recommendation: GenericLinkRecommendationResponse, theme: Theme) {
  if (isArticleRR(recommendation)) {
    return AcmExtraColors.articleLink;
  } else if (isProductRR(recommendation)) {
    return AcmExtraColors.productLink;
  }
  return AcmExtraColors.recommendation;
}

/**
 * Merges a list of caption tags to a single string,
 * in such a way that the text of a single caption is never broken up upon multiple lines.
 * The resulting string can only line-break after a caption-phrase-separator.
 */
export function mergeCaptionPhrases(captionPhrases: Array<[any, string]>): string {
  const nbsp = '\u00A0'; // space character that cannot be broken apart onto multiple lines
  return captionPhrases
    .filter(([enabled, _]) => enabled)
    .map(([_, phrase]) => phrase.replace(/ /g, nbsp))
    .join(`${nbsp}| `);
}
