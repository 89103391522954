import useTheme from '@mui/material/styles/useTheme';
import { useMemo } from 'react';
import {
  CombinedLinkRecommendationResponse,
  linkTypeFilter,
  RecommendationElement,
  useCombinedLinkerPageState,
} from '../../../context/linker/combined-linker-context';
import { findRecommendationStrokeColor } from '../generic/recommendation-details/helper';
import LinkToDetailsReferenceLine from './link-to-details-reference-line';

interface Props {
  recommendation: CombinedLinkRecommendationResponse
  startEl: RecommendationElement
  targetEl: RecommendationElement
  startContainerRef: HTMLElement
  targetContainerRef: HTMLElement
  index: number
}

/**
 * Link Optimizer Page connection lines, connecting selected text in the content display
 * and link elements on the right
 */
export default function CombinedLinkToDetailsReferenceLine(props: Props) {
  const { recommendation, ...restProps } = props;
  const theme = useTheme();
  const { combinedLinkRecommendations, currentLinkTypeFilter } = useCombinedLinkerPageState();

  const isFadedOut = !linkTypeFilter(recommendation, currentLinkTypeFilter);

  const isShown = useMemo(() => (
    !!combinedLinkRecommendations?.article_links.some((rec) => rec.keyword === recommendation.keyword)
    || !!combinedLinkRecommendations?.product_links.some((rec) => rec.keyword === recommendation.keyword)
  ), [combinedLinkRecommendations]);

  return (
    <LinkToDetailsReferenceLine
      isShown={isShown}
      isFadedOut={isFadedOut}
      strokeColor={findRecommendationStrokeColor(recommendation, theme)}
      {...restProps}
    />
  );
}
