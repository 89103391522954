import { PrimaryButton, PurpleBaseColors } from '@purple/react-components';
import { useTranslation } from 'react-i18next';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import {
  CombinedLinkerAction,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageService,
  useCombinedLinkerPageState,
} from '../../../context/linker/combined-linker-context';
import useSearchQueryParams from '../../../util/use-search-query-params';

export default function SaveButton() {
  const { articleId, hasUnsavedChanges, savedRecommendation } = useCombinedLinkerPageState();
  const dispatch = useCombinedLinkerPageDispatch();
  const service = useCombinedLinkerPageService()[articleId];
  const { allowSavingWithoutChanges } = useSearchQueryParams();
  const {
    getCombinedLinksRecommendationService,
    saveAllCombinedLinksService,
  } = service;

  const { t } = useTranslation();

  const internalOnSaveClick = () => {
    if (saveAllCombinedLinksService.status !== REST_STATUS.LOADING) {
      dispatch({ type: CombinedLinkerAction.SAVE_COMBINED_RECOMMENDATIONS });
    }
  };

  const canSave = getCombinedLinksRecommendationService.status === REST_STATUS.LOADED;
  const isSaving = saveAllCombinedLinksService.status === REST_STATUS.LOADING || savedRecommendation !== undefined; // checking the state variable as well prevents flickering
  const allSaved = saveAllCombinedLinksService.status === REST_STATUS.LOADED && !hasUnsavedChanges;

  let disabled = !canSave;
  if (!allowSavingWithoutChanges) {
    disabled = disabled || isSaving || !hasUnsavedChanges;
  }

  return (
    <PrimaryButton
      disabled={disabled}
      busy={isSaving}
      onClick={internalOnSaveClick}
      sx={{
        backgroundColor: hasUnsavedChanges || isSaving ? PurpleBaseColors.yellow : '',
        width: '120px',
      }}
    >
      {isSaving ? t('lo-saving') : allSaved ? t('lo-saved') : t('lo-save')}
    </PrimaryButton>
  );
}
