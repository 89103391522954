import { ProductLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import { ProductScoreResponse } from '../../../../api/interfaces/recommendation';
import GenericLinkScoresTooltip from '../../combined-linker/recommendation-details/generic-link-scores-tooltip';

export interface RecommendationScoreProps {
  product: ProductScoreResponse
  // TODO remove eslint exception when the property is used
  // eslint-disable-next-line react/no-unused-prop-types
  profile?: ProductLinkerProfileResponse
  label?: string
}

export function ProductLinkScores(props: RecommendationScoreProps) {
  const { product, label } = props;

  // TODO once we have detailed scores for product recommendations,
  //  use their values (and the weights from the profile) to show them in a tooltip like done for article linking.

  return (
    <GenericLinkScoresTooltip
      mainScore={{
        scoreValue: product.score,
        label,
      }}
      tooltipScoreDataList={[]}
    />
  );
}
