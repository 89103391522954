import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmationDialog } from '@purple/react-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useDeleteArticleLinkerProfileService
  from '../../../api/hooks/linker/profiles/articles/delete-article-linker-profile.ts';
import useDeleteProductLinkerProfileService
  from '../../../api/hooks/linker/profiles/products/delete-product-linker-profile';
import { isRestServiceFinished } from '../../../api/hooks/rest-service';
import { GenericLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import {
  LinkerProfilesAction,
  ProfileType,
  useLinkerProfilesDispatch,
  useLinkerProfilesService,
  useLinkerProfilesState,
} from '../../../context/profiles/linker-profiles-context';
import RestSnackbar from '../../../theme/sprylab/components/rest-snackbar';
import DiscardChangesDialog from './profile-discard-changes';

interface Props {
  profile: GenericLinkerProfileResponse
  profileType: ProfileType
}

export default function ProfileDeleteButton(props: Props) {
  const { profile, profileType } = props;
  const { selectedArticleLinkerId, selectedProductLinkerId, isProfileDirty } = useLinkerProfilesState();
  const dispatch = useLinkerProfilesDispatch();
  const { refreshArticleProfiles, refreshProductProfiles } = useLinkerProfilesService();
  const [deleteProfileDialogOpen, setDeleteProfileDialogOpen] = useState<boolean>(false);
  const [doDeleteAction, setDoDeleteAction] = useState<boolean>();

  const isArticle = profileType === ProfileType.ARTICLE;
  const isProduct = profileType === ProfileType.PRODUCT;

  const deleteArticleProfile = useDeleteArticleLinkerProfileService(isArticle && doDeleteAction ? profile.id : undefined);
  const deleteProductProfile = useDeleteProductLinkerProfileService(isProduct && doDeleteAction ? profile.id : undefined);

  useEffect(() => {
    if (doDeleteAction && isRestServiceFinished(deleteArticleProfile)) {
      refreshArticleProfiles();
      setDoDeleteAction(false);
      if (selectedArticleLinkerId === profile.id) {
        dispatch({
          type: LinkerProfilesAction.SELECT_ARTICLE_LINKER_PROFILE,
          payload: undefined,
        });
      }
    }
  }, [deleteArticleProfile.status]);

  useEffect(() => {
    if (doDeleteAction && isRestServiceFinished(deleteProductProfile)) {
      refreshProductProfiles();
      setDoDeleteAction(false);
      if (selectedProductLinkerId === profile.id) {
        dispatch({
          type: LinkerProfilesAction.SELECT_PRODUCT_LINKER_PROFILE,
          payload: undefined,
        });
      }
    }
  }, [deleteProductProfile.status]);

  const handleDeleteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleteProfileDialogOpen(true);
  };

  return (
    <>
      <Tooltip title="Delete this profile">
        <IconButton
          size="small"
          onClick={handleDeleteClick}
          data-testid={`delete-profile-button-${profile.name}`}
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
      <DiscardChangesDialog
        isDialogOpen={deleteProfileDialogOpen && isProfileDirty}
        onCancel={() => setDeleteProfileDialogOpen(false)}
      />
      <ConfirmationDialog
        dialogTitle={`Sure to delete Profile with name "${profile.name}"?`}
        confirmText="Yes"
        open={deleteProfileDialogOpen && !isProfileDirty}
        onConfirm={() => setDoDeleteAction(true)}
        onCancel={() => setDeleteProfileDialogOpen(false)}
      />
      <RestSnackbar
        loadingMessage="Deleting article profile ..."
        noContentMessage="Profile deleted!"
        service={deleteArticleProfile}
        id="deleteArticleProfile"
      />
      <RestSnackbar
        loadingMessage="Deleting product profile ..."
        noContentMessage="Profile deleted!"
        service={deleteProductProfile}
        id="deleteProductProfile"
      />
    </>
  );
}
