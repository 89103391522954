import { Paper } from '@mui/material';
import { Instance } from '@popperjs/core';
import { FlexBoxColumn, PurpleBaseColors } from '@purple/react-components';
import { useResizeObserver } from '@react-hookz/web';
import { useRef } from 'react';
import PopperClickAwayListener from '../components/util/popper-click-away-listener';
import ArrowPopper, { ArrowPopperProps } from './arrow-popper';

const POPPER_COLOR = PurpleBaseColors.neutralLightGray;

type Props = ArrowPopperProps & {
  elementRef: React.RefObject<HTMLElement>
  onClose: () => void
}

/**
 * An ArrowPopper for showing a list of ActionButton context actions
 */
export default function ActionButtonsArrowPopper(props: Props) {
  const {
    elementRef, onClose, children, ...restProps
  } = props;

  const paperRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<Instance>(null);

  useResizeObserver(paperRef, () => {
    popperRef.current?.update().catch(console.log);
  });

  return (
    <ArrowPopper
      popperRef={popperRef}
      arrowColor={POPPER_COLOR}
      placement="bottom"
      anchorEl={elementRef.current}
      modifiers={[{
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      }]}
      {...restProps}
    >
      <PopperClickAwayListener
        parentRef={elementRef}
        onClickAway={onClose}
      >
        <Paper
          ref={paperRef}
          sx={{ backgroundColor: POPPER_COLOR, borderRadius: '20px' }}
        >
          <FlexBoxColumn
            gap="4px"
            sx={{ p: '6px' }}
          >
            {children}
          </FlexBoxColumn>
        </Paper>
      </PopperClickAwayListener>
    </ArrowPopper>
  );
}
