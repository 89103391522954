import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { FlexBoxRow, PurpleBaseColors } from '@purple/react-components';
import { useTranslation } from 'react-i18next';

export interface RecommendationScoreProps {
  scoreValue: number | null
  label?: string
  weight?: number
}

export function LinkRecommendationScore(props: RecommendationScoreProps) {
  const { scoreValue, label, weight } = props;
  const { t } = useTranslation();

  if (scoreValue === null) return null;
  if (weight !== undefined && weight <= 0) return null;

  if (scoreValue < 0 || scoreValue > 100) throw new Error('prop scoreValue is not within range of 0 - 100');

  const weightText = weight !== undefined ? ` (${t('lo-score-weight')}: ${Math.round(weight)}%)` : '';

  const ringProps = {
    variant: 'determinate',
    thickness: 5,
    size: 30,
  } as const;
  const ringStyle = {
    position: 'absolute',
    top: '-3px',
    left: '-3px',
    zIndex: 1,
  } as const;

  return (
    <FlexBoxRow
      alignItems="center"
      style={{
        height: '35px',
        padding: '3px',
      }}
      data-testid="link-recommendation-score"
    >
      <div style={{ position: 'relative' }}>
        <FlexBoxRow style={{ width: '24px', height: '24px' }}>
          <Typography
            variant="h6"
            sx={{ m: 'auto', color: PurpleBaseColors.purpleDark }}
          >
            {scoreValue}
          </Typography>
        </FlexBoxRow>
        <CircularProgress
          {...ringProps}
          value={100}
          style={{
            ...ringStyle,
            color: PurpleBaseColors.neutralGrayAsAlpha,
          }}
        />
        <CircularProgress
          {...ringProps}
          value={scoreValue}
          style={{
            ...ringStyle,
            color: PurpleBaseColors.purpleDark,
          }}
        />
      </div>
      {(label || weightText) && (
        <Typography color="textPrimary" sx={{ ml: '10px' }}>
          {label}
          <span style={{ fontStyle: 'italic', color: 'rgba(0,0,0,0.5)' }}>{weightText}</span>
        </Typography>
      )}
    </FlexBoxRow>
  );
}
