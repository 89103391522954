import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { ArticleResponse } from '../../../interfaces/article';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetArticlesService = (search: string = ''): RESTService<ArticleResponse[]> => {
  const requestURL = `${CORE_BACKEND_URL}/articles?text=${search}`;
  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetArticlesService;
