import { useCallback, useState } from 'react';
import useRerender from './use-rerender';

/**
 * A boolean that can be set to true and which auto-resets to false after the given time.
 */
export default function useTimer(durationMs: number) {
  const [startTime, setStartTime] = useState<number>(0);
  const refresh = useRerender();
  const start = useCallback(() => {
    setStartTime(Date.now());
    setTimeout(refresh, durationMs);
  }, [durationMs]);
  const isRunning = Date.now() - startTime < durationMs;
  return [start, isRunning] as const;
}
