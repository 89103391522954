import {
  useRef, useLayoutEffect, useEffect, MutableRefObject,
} from 'react';

// Source Code copied from: https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
// Modified to give Rect instead of Position

const isBrowser = typeof window !== 'undefined';

export interface PositionChange {
  prevRect: DOMRect
  currRect: DOMRect
}

type EffectFunction = ({ prevRect, currRect }: PositionChange) => void;

interface GetScrollPositionConfig {
  element?: MutableRefObject<HTMLElement | null>
  useWindow?: boolean
}

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

function getRect({ element, useWindow }: GetScrollPositionConfig): DOMRect {
  if (!isBrowser) return new DOMRect();

  const target = element ? element.current : document.body;

  if (!target) return new DOMRect();

  const rawRect = target.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  // convert from "client" (viewport) coordinates to "page" (scroll-independent) coordinates
  const rect = DOMRectReadOnly.fromRect({
    x: rawRect.left - bodyRect.left,
    y: rawRect.top - bodyRect.top,
    width: rawRect.width,
    height: rawRect.height,
  });

  if (useWindow) {
    return {
      ...rect,
      x: window.scrollX,
      y: window.scrollY,
    };
  } else {
    return rect;
  }
}

export function useRectListener(
  effect: EffectFunction,
  element?: MutableRefObject<HTMLElement | null>,
  useWindow?: boolean,
) {
  const rect = useRef(getRect({ useWindow }));
  const requestRef = useRef<number>();

  // we check for the rect on every animation frame, only changing react state when the rect changed
  // see https://css-tricks.com/using-requestanimationframe-with-react-hooks/
  const animate = () => {
    const currRect = getRect({ element, useWindow });
    if (currRect.top !== rect.current.top
        || currRect.left !== rect.current.left
        || currRect.width !== rect.current.width
        || currRect.height !== rect.current.height) {
      effect({ prevRect: rect.current, currRect });
      rect.current = currRect;
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, [element]);
}
