import { STEntry, STGroup } from '@purple/react-components';
import * as React from 'react';
import { ProductLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import {
  makeObjectUpdater,
  ProfileAttributeCheckbox,
  ProfileAttributeMoneySlider,
  ProfileAttributeSlider,
  ProfileAttributeTextField,
} from '../../link-optimizer/generic/profile/slider-helper';

interface Props {
  linkProfile: ProductLinkerProfileResponse
  setLinkProfile: React.Dispatch<React.SetStateAction<ProductLinkerProfileResponse>>
}

export default function ProductLinkProfileSliders(props: Props) {
  const { linkProfile, setLinkProfile } = props;
  const updateLinkProfile = makeObjectUpdater(setLinkProfile);

  const {
    minimum_price_cents,
    maximum_price_cents,
    minimum_user_rating_stars,
    prime_only,
    associate_tag,
  } = linkProfile.link_parameters;

  function setMinimumPriceEuro(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.minimum_price_cents = Math.round(newValue * 100));
  }

  function validateWithMinimumPriceEuro(newValue: number) {
    const newMinimumPriceCents = Math.round(newValue * 100);
    if (newMinimumPriceCents > maximum_price_cents && maximum_price_cents > 0) {
      updateLinkProfile((lp) => lp.link_parameters.maximum_price_cents = newMinimumPriceCents);
    }
  }

  function setMaximumPriceEuro(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.maximum_price_cents = Math.round(newValue * 100));
  }

  function validateWithMaximumPriceEuro(newValue: number) {
    const newMaximumPriceCents = Math.round(newValue * 100);
    if (newMaximumPriceCents < minimum_price_cents && newMaximumPriceCents > 0) {
      updateLinkProfile((lp) => lp.link_parameters.minimum_price_cents = newMaximumPriceCents);
    }
  }

  function setMinimumUserRatingStars(newValue: number) {
    updateLinkProfile((lp) => lp.link_parameters.minimum_user_rating_stars = newValue);
  }

  function setPrimeOnly(newValue: boolean) {
    updateLinkProfile((lp) => lp.link_parameters.prime_only = newValue);
  }

  function setAssociateTag(newValue: string) {
    if (newValue.trim().length > 0) {
      updateLinkProfile((lp) => lp.link_parameters.associate_tag = newValue.trim());
    } else {
      updateLinkProfile((lp) => delete lp.link_parameters.associate_tag);
    }
  }

  return (
    <>
      <STGroup>
        <STEntry
          label="Minimum Price"
          helpText="Required minimum price for recommended products."
        >
          <ProfileAttributeMoneySlider
            value={minimum_price_cents / 100}
            setValue={setMinimumPriceEuro}
            commitToValue={validateWithMinimumPriceEuro}
            testId="minimum-price"
          />
        </STEntry>
        <STEntry
          label="Maximum Price"
          helpText="Required maximum price for recommended products."
        >
          <ProfileAttributeMoneySlider
            value={maximum_price_cents / 100}
            setValue={setMaximumPriceEuro}
            commitToValue={validateWithMaximumPriceEuro}
            testId="maximum-price"
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Minimum User Rating"
          helpText="Required minimum user rating for recommended products."
        >
          <ProfileAttributeSlider
            value={minimum_user_rating_stars}
            setValue={setMinimumUserRatingStars}
            min={1}
            max={4}
            valueLabelFormat={(v) => `${v}/5`}
            testId="minimum-user-rating"
          />
        </STEntry>
        <STEntry
          label="Prime Only"
          helpText="Check this to only recommend products that are Prime eligible."
        >
          <ProfileAttributeCheckbox
            value={prime_only}
            setValue={setPrimeOnly}
            testId="prime-only"
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STEntry
          label="Custom Associate Tag"
          helpText="Set a custom Amazon Associate Tag for the generated links."
        >
          <ProfileAttributeTextField
            value={associate_tag || ''}
            setValue={setAssociateTag}
            testId="associate-tag"
          />
        </STEntry>
      </STGroup>
    </>
  );
}
