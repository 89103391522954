import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import {
  TextInput, DropdownOptions, DropdownMulti, Dropdown, FlexBoxRow,
} from '@purple/react-components';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import useGetCategoriesService from '../../../../api/hooks/core/categories/get-categories';
import { REST_STATUS } from '../../../../api/hooks/rest-service';
import DataLoading from '../../../../theme/sprylab/components/data-loading';
import { mapToRange } from '../../../../util/common-utils';

/**
 * Create function that creates a deep copy of an object with a single change applied
 * @param updater will be called once with the cloned object
 */
export function updateObj<T>(updater: (obj: T) => void) {
  return (obj: T) => {
    const newObj = cloneDeep(obj);
    updater(newObj);
    return newObj;
  };
}

/**
 * Wrap a React object setter to instead update an object via a function.
 * @example
 * const [myObj, setMyObj] = useState<MyObjClass>({foo: 1, bar: 2});
 * const updateMyObj = makeObjectUpdater(setMyObj);
 * return <Slider onChange={(newValue) => updateMyObj((o) => o.foo = newValue)}>
 */
export function makeObjectUpdater<T>(objectSetter: React.Dispatch<React.SetStateAction<T>>) {
  return (updater: (obj: T) => void) => {
    objectSetter(updateObj(updater));
  };
}

/**
 * Wrap a React object setter to instead update an object via a function.
 * @example
 * const [myObj, setMyObj] = useState<MyObjClass>({foo: 1, bar: 2});
 * const updateMyObj = makeObjectUpdater(setMyObj);
 * return <Slider onChange={updateMyObj((o, v) => o.foo = v)}>
 */
export function makeObjectUpdaterSetter<T>(objectSetter: React.Dispatch<React.SetStateAction<T>>) {
  return <V extends any>(updater: (obj: T, newValue: V) => void) => (newValue: V) => {
    objectSetter(updateObj((obj) => updater(obj, newValue)));
  };
}

interface GeneralSliderProps<T = number> {
  value: T
  setValue: (_: T) => void
  testId?: string
}

interface SliderProps extends GeneralSliderProps {
  min?: number
  max?: number
  step?: number
  valueLabelFormat?: ((_: number) => any)
}

interface SimilaritySliderProps extends SliderProps {
  isLocked: boolean
  setIsLocked: ((_: boolean) => void)
}

function createTestId(testId: string | undefined) {
  if (!testId) {
    return undefined;
  }
  return `profile-slider-${testId.replace(/\s/g, '-').toLowerCase()}`;
}

export function ProfileAttributeSlider(props: SliderProps) {
  const {
    value,
    setValue,
    min = 0,
    max = 100,
    step = 1,
    valueLabelFormat = (x) => x,
    testId,
  }: SliderProps = props;
  return (
    <SliderGridItem>
      <Slider
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={(e: any, newValue: any) => setValue(newValue as number)}
        data-testid={createTestId(testId)}
      />
      <Typography variant="overline" sx={{ minWidth: '50px', textAlign: 'right' }}>
        {valueLabelFormat(value)}{(max === 100 && min === 0) ? '%' : ''}
      </Typography>
    </SliderGridItem>
  );
}

export function LockableSlider(props: SimilaritySliderProps) {
  const {
    value,
    setValue,
    min = 0,
    max = 100,
    step = 1,
    valueLabelFormat = (x) => x,
    isLocked,
    setIsLocked,
    testId,
  }: SimilaritySliderProps = props;

  return (
    <SliderGridItem>
      <Checkbox
        icon={<LockOpenIcon />}
        checkedIcon={<LockIcon />}
        checked={isLocked}
        onChange={(e: React.ChangeEvent, v: any) => { setIsLocked(v as boolean); }}
        data-testid={`${createTestId(testId)}-lock`}
      />
      <Slider
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={(e: any, newValue: any) => setValue(newValue as number)}
        disabled={isLocked}
        data-testid={createTestId(testId)}
      />
      <Typography variant="overline" sx={{ minWidth: '50px', textAlign: 'right' }}>
        {valueLabelFormat(value)}{'%'}
      </Typography>
    </SliderGridItem>
  );
}

export function formatEuroToString(scaledValue: number) {
  const maximumFractionDigits = scaledValue < 99.999 ? 2 : 0;
  return scaledValue.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits,
  });
}

export function ProfileAttributeMoneySlider(props: GeneralSliderProps & {commitToValue?: (value: number) => void}) {
  const {
    value,
    setValue,
    commitToValue,
    testId,
  } = props;

  const markerPoints = [0, 1, 10, 100, 1000, 10000];

  function scaleValue(rawValue: number) {
    const stepDistance = 1.0 / (markerPoints.length - 1);
    const stepIndex = Math.floor(rawValue / stepDistance);
    const nextStepIndex = Math.min(markerPoints.length - 1, stepIndex + 1);
    const stepValue = markerPoints[stepIndex];
    const nextStepValue = markerPoints[nextStepIndex];
    const rawValueBetween = (rawValue - (stepDistance * stepIndex)) / stepDistance;
    return stepValue + (nextStepValue - stepValue) * rawValueBetween;
  }

  function unScaleValue(scaledValue: number) {
    if (scaledValue <= markerPoints[0]) {
      return 0;
    }
    if (scaledValue >= markerPoints[markerPoints.length - 1]) {
      return 1;
    }
    const stepDistance = 1 / (markerPoints.length - 1);
    let rawValue = 0;
    let stepIndex = 0;
    while (scaledValue > markerPoints[stepIndex + 1]) {
      stepIndex++;
      rawValue += stepDistance;
    }
    const rawValueBetween = (scaledValue - markerPoints[stepIndex]) / (markerPoints[stepIndex + 1] - markerPoints[stepIndex]);
    return rawValue + rawValueBetween * stepDistance;
  }

  return (
    <SliderGridItem>
      <Slider
        min={0}
        max={1}
        step={0.01}
        valueLabelDisplay="auto"
        valueLabelFormat={formatEuroToString}
        value={unScaleValue(value)}
        scale={scaleValue}
        onChange={(_e: any, newValue: any) => setValue(scaleValue(newValue as number))}
        onChangeCommitted={(_e: any, newValue: any) => commitToValue?.(scaleValue(newValue as number))}
        data-testid={createTestId(testId)}
      />
      <Typography
        variant="overline"
        sx={{ minWidth: '100px', textAlign: 'right' }}
        data-testid={`${createTestId(testId)}-label`}
      >
        {value === 0 ? 'No Filter' : formatEuroToString(value)}
      </Typography>
    </SliderGridItem>
  );
}

export function ProfileAttributeCoarseSlider(props: SliderProps) {
  const {
    value,
    setValue,
    min = 0,
    max = 100,
    step = 25,
    valueLabelFormat = (x: number) => x,
    testId,
  } = props;
  return (
    <SliderGridItem>
      <Slider
        min={min}
        max={max}
        step={step}
        marks
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={(e: any, newValue: any) => setValue(newValue as number)}
        data-testid={createTestId(testId)}
      />
      <Typography variant="overline" sx={{ minWidth: '100px', textAlign: 'right' }}>
        {value === min
          ? 'disabled'
          : value === max
            ? 'required'
            : `${value}%`}
      </Typography>
    </SliderGridItem>
  );
}

export function ProfileAttributeDateSlider(props: GeneralSliderProps) {
  const {
    value,
    setValue,
    testId,
  } = props;

  const daysInYear = 365;
  const minSliderValue = 0;
  const maxSliderValue = 1;
  const sliderStep = 0.001;
  const interval = 0.05;

  interface Markers {
    min: number
    maxFor1Year: number
    maxFor2Years: number
    maxFor3Years: number
    max: number
  }

  const dayMarkers: Markers = {
    min: minSliderValue,
    maxFor1Year: daysInYear,
    maxFor2Years: daysInYear * 2,
    maxFor3Years: daysInYear * 3,
    max: daysInYear * 3,
  };

  const rawValueMarkers: Markers = {
    min: minSliderValue,
    maxFor1Year: maxSliderValue - 2 * interval,
    maxFor2Years: maxSliderValue - 1 * interval,
    maxFor3Years: maxSliderValue,
    max: maxSliderValue,
  };

  const days = [dayMarkers.min, 1, 7, 14, 30, 90, 180, 240, dayMarkers.maxFor1Year, dayMarkers.maxFor2Years, dayMarkers.maxFor3Years];

  function mapToRangeByMarkers(valueToScale: number, valueDomain: Markers, valueRange: Markers, ceilResult: boolean = false) {
    if (valueToScale >= valueDomain.min && valueToScale < valueDomain.maxFor1Year) {
      const scaledValue = mapToRange(valueDomain.min, valueDomain.maxFor1Year, valueRange.min, valueRange.maxFor1Year, valueToScale);
      return ceilResult ? Math.ceil(scaledValue) : scaledValue;
    } else if (valueToScale < (valueDomain.maxFor1Year + valueDomain.maxFor2Years) / 2) {
      return valueRange.maxFor1Year;
    } else if (valueToScale < (valueDomain.maxFor2Years + valueDomain.maxFor3Years) / 2) {
      return valueRange.maxFor2Years;
    } else {
      return valueRange.maxFor3Years;
    }
  }

  function scaleValue(rawValue: number) {
    return mapToRangeByMarkers(rawValue, rawValueMarkers, dayMarkers, true);
  }

  function unScaleValue(scaledValue: number) {
    return mapToRangeByMarkers(scaledValue, dayMarkers, rawValueMarkers, false);
  }

  function dayToFormattedString(day: number): string {
    if (day === 0) {
      return 'No Filter';
    } else if (dayMarkers.min <= day && day < dayMarkers.maxFor1Year) {
      return day === 1 ? '1 DAY' : `${day.toString()} DAYS`;
    } else if (day === dayMarkers.maxFor1Year) {
      return '1 YEAR';
    } else if (day === dayMarkers.maxFor2Years) {
      return '2 YEARS';
    } else if (day === dayMarkers.maxFor3Years) {
      return '3 YEARS';
    } else {
      throw new Error(`Day value = ${day} out of range. Value to scale must be between: [${dayMarkers.min}, ${dayMarkers.max}]`);
    }
  }

  return (
    <SliderGridItem>
      <Slider
        min={minSliderValue}
        max={maxSliderValue}
        step={sliderStep}
        valueLabelDisplay="off"
        value={unScaleValue(value)}
        scale={scaleValue}
        onChange={(_e: any, newValue: any) => setValue(scaleValue(newValue as number))}
        data-testid={createTestId(testId)}
      />
      <Box
        sx={{ minWidth: '100px', textAlign: 'right' }}
      >
        <Select
          disableUnderline
          variant="standard"
          value={value}
          renderValue={(displayValue) => (
            <Typography variant="overline">
              {dayToFormattedString(displayValue as number)}
            </Typography>
          )}
          onChange={(e) => setValue(e.target.value as number)}
        >
          {days.map((day) => (
            <MenuItem value={day}>
              {dayToFormattedString(day)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </SliderGridItem>
  );
}

export function ProfileAttributeCategorySelect(props: GeneralSliderProps<string[]> & { disabled?: boolean }) {
  const {
    value,
    setValue,
    disabled = false,
    testId,
  } = props;
  const [categories, setCategories] = useState<string[]>([]);
  const categoriesService = useGetCategoriesService();

  useEffect(() => {
    if (categoriesService.status === REST_STATUS.LOADED) {
      setCategories(categoriesService.payload.map((c) => c.name));
    }
  }, [categoriesService]);

  return (
    <SliderGridItem>
      <DataLoading
        service={categoriesService}
        errorMessage="Failed to load categories"
      >
        <DropdownMulti
          options={categories}
          value={value}
          setValue={setValue}
          disabled={disabled}
          fullWidth
          displayEmpty
          renderValue={(selected) => ((disabled || selected.length === 0) ? '(disabled) ' : '') + selected.join(', ')}
          data-testid={createTestId(testId)}
        />
      </DataLoading>
    </SliderGridItem>
  );
}

export function ProfileAttributeSelect(props: GeneralSliderProps<string> & {options: DropdownOptions<string>}) {
  const {
    value,
    setValue,
    options,
    testId,
  } = props;

  return (
    <SliderGridItem>
      <Dropdown
        fullWidth
        options={options}
        value={value}
        setValue={setValue}
        data-testid={createTestId(testId)}
      />
    </SliderGridItem>
  );
}

export function ProfileAttributeCheckbox(props: GeneralSliderProps<boolean>) {
  const {
    value,
    setValue,
    testId,
  } = props;
  return (
    <SliderGridItem>
      <FormControlLabel
        label=""
        control={
          <Checkbox
            checked={value}
            onChange={(_, newValue) => setValue(newValue as boolean)}
            data-testid={createTestId(testId)}
          />
        }
      />
    </SliderGridItem>
  );
}

export function ProfileAttributeTextField(props: GeneralSliderProps<string>) {
  const {
    value,
    setValue,
    testId,
  } = props;

  return (
    <SliderGridItem>
      <TextInput
        fullWidth
        value={value}
        setValue={setValue}
        data-testid={createTestId(testId)}
      />
    </SliderGridItem>
  );
}

/**
 * A grid item with adaptive size used to contain a profile "slider" (input element)
 */
function SliderGridItem(props: PropsWithChildren) {
  const { children } = props;
  return (
    <FlexBoxRow gap="16px" sx={{ px: '4px' }}>
      {children}
    </FlexBoxRow>
  );
}
