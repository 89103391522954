import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  ActionButton,
  Dropdown,
  DropdownMulti,
  FlexBoxColumn,
  FlexBoxRow,
  NumberInput,
  PasswordInput,
  PrimaryButton,
  PurpleLoadingSpinner,
  SettingsTable,
  STDivider,
  STEntry,
  STGroup,
  STHeading,
  SwitchInput,
  TextInput,
} from '@purple/react-components';
import cloneDeep from 'lodash/cloneDeep';
import isEqualWith from 'lodash/isEqualWith';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {
  ConfigurationDetailResponse,
  NewsConfigResponse,
  UserConfigurationPayload,
} from '../../api/interfaces/configuration';
import { UserResponse } from '../../api/interfaces/user';
import RestService from '../../api/rest.service';
import {
  API_LOCATION_TYPES,
  CONFIG_COUNTRY,
  CONFIG_LANGUAGE,
  CONTENT_CONFIG_TYPES,
  ENTITY_RECOGNITION_CONFIG,
  INDEXING_TYPES,
  INTERFACE_LANGUAGE_OPTIONS,
  LINK_ATTRIBUTE_OPTIONS,
  NEWS_CONFIG_TYPES,
  RECSYS_SUPPORTED_MODELS,
} from '../../constants/interfaces/interfaces';
import PageWithHeader from '../../theme/sprylab/components/pages/page-with-header';
import { makeObjectUpdaterSetter } from '../link-optimizer/generic/profile/slider-helper';

function deepEqualIgnoreEmptyStrings(obj1: any, obj2: any): boolean {
  return isEqualWith(obj1, obj2, (v1, v2) => {
    if (!v1 && !v2) {
      return true;
    }
    return undefined;
  });
}

export default function CustomerDetail() {
  const [customer, setCustomer] = useState<UserResponse>();
  const [config, setConfig] = useState<ConfigurationDetailResponse>();
  const [originalCustomer, setOriginalCustomer] = useState<UserResponse>();
  const [originalConfig, setOriginalConfig] = useState<ConfigurationDetailResponse>();
  const [busy, setBusy] = useState(false);

  const location = useLocation();
  const params = useParams<{ id: string }>();
  const userPublicId = location.state?.publicId ?? params.id;
  const navigate = useNavigate();

  useEffect(() => {
    RestService.getUser(userPublicId)
      .then((customerDetail: UserResponse) => {
        setCustomer(customerDetail);
        setOriginalCustomer(cloneDeep(customerDetail));
      });
    RestService.getUserConfig(userPublicId)
      .then((userConfig: ConfigurationDetailResponse) => {
        if (Object.keys(userConfig).length > 0) {
          setConfig(userConfig);
          setOriginalConfig(cloneDeep(userConfig));
        } else {
          const body: UserConfigurationPayload = { user_public_id: userPublicId, config: userConfig };
          RestService.postConfigurations(body)
            .then((configsResponse) => {
              if (configsResponse) {
                RestService.getUserConfig(userPublicId)
                  .then((userConfigResponse: ConfigurationDetailResponse) => {
                    if (userConfigResponse) {
                      setConfig(userConfigResponse);
                      setOriginalConfig(cloneDeep(userConfigResponse));
                    }
                  });
              } else {
                console.error('Error by creating a default configuration.');
              }
            });
        }
      });
  }, []);

  async function handleUpdate() {
    if (!customer || !config) {
      return;
    }
    setBusy(true);
    const userResponse = await RestService.updateUserDetail(customer);
    if (!userResponse) {
      console.error('Can\'t update user');
      return;
    }
    const configResponse = await RestService.updateUserConfig(config);
    if (!configResponse) {
      console.error('Can\'t update configuration');
      return;
    }
    navigate(-1);
  }

  const hasUnsavedChanges = useMemo(() => {
    if (!customer || !originalCustomer || !config || !originalConfig) {
      return false;
    }
    return !deepEqualIgnoreEmptyStrings(customer, originalCustomer) || !deepEqualIgnoreEmptyStrings(config, originalConfig);
  }, [customer, originalCustomer, config, originalConfig]);

  if (!customer || !config) {
    return (
      <PageWithHeader wide>
        <PurpleLoadingSpinner />
      </PageWithHeader>
    );
  }

  return (
    <PageWithHeader wide hasUnsavedChanges={hasUnsavedChanges}>
      <CustomerSettingsComponent
        customer={customer}
        // @ts-ignore
        setCustomer={setCustomer}
        config={config}
        // @ts-ignore
        setConfig={setConfig}
        handleUpdate={handleUpdate}
        busy={busy}
        hasUnsavedChanges={hasUnsavedChanges}
      />
    </PageWithHeader>
  );
}

type Props = {
  customer: UserResponse
  setCustomer: React.Dispatch<React.SetStateAction<UserResponse>>
  config: ConfigurationDetailResponse
  setConfig: React.Dispatch<React.SetStateAction<ConfigurationDetailResponse>>
  handleUpdate: () => void
  busy: boolean
  hasUnsavedChanges: boolean
}

function CustomerSettingsComponent(props: Props) {
  const {
    customer, setCustomer, config, setConfig, handleUpdate, busy, hasUnsavedChanges,
  } = props;
  const updateCustomer = makeObjectUpdaterSetter<UserResponse>(setCustomer);
  const updateConfig = makeObjectUpdaterSetter<ConfigurationDetailResponse>(setConfig);

  const handleNewsConfigDeletion = (index: number) => {
    if (!config) return;
    const configCopy = { ...config };
    configCopy.news_config.splice(index, 1);
    setConfig({ ...configCopy });
  };

  const handleNewsConfigAdd = () => {
    if (!config) return;
    const configCopy = { ...config };
    const newNewsConfig: NewsConfigResponse = {
      type: '',
      enabled: true,
      locale: '',
      access_token: '',
      search_stream_id: '',
    };
    configCopy.news_config.push(newNewsConfig);
    setConfig({ ...configCopy });
  };

  const userFields = (
    <>
      <STGroup>
        <STHeading>
          User Data
        </STHeading>
        <STEntry label="Username">
          <TextInput readOnly value={customer?.username} />
        </STEntry>
        <STEntry label="Public Id">
          <TextInput readOnly value={customer?.public_id} />
        </STEntry>
        <STEntry label="Name">
          <TextInput
            value={customer.name}
            setValue={updateCustomer((c, v) => c.name = v)}
          />
        </STEntry>
      </STGroup>
      <STEntry
        label="Interface language"
        helpText="All those languages can also temporarily be tested by adding a query parameter 'lang=' to the ACM URL."
      >
        <Dropdown
          options={INTERFACE_LANGUAGE_OPTIONS}
          value={config.interface_language}
          setValue={updateConfig((c, v) => c.interface_language = v)}
        />
      </STEntry>
    </>
  );

  const configFields = (
    <>
      <STGroup>
        <STHeading>
          Features Configuration
        </STHeading>
        <STEntry label="ISIN search">
          <SwitchInput
            checked={config.features_config.isin_search}
            setChecked={updateConfig((c, v) => c.features_config.isin_search = v)}
          />
        </STEntry>
      </STGroup>
      <STGroup>
        <STHeading>
          Content Configuration
          <SwitchInput
            checked={config.content_config.enabled}
            setChecked={updateConfig((c, v) => c.content_config.enabled = v)}
          />
        </STHeading>
        {config.content_config.enabled && (
          <>
            <STEntry label="Content Language">
              <Dropdown
                options={CONFIG_LANGUAGE}
                value={config.content_config.content_language}
                setValue={updateConfig((c, v) => c.content_config.content_language = v)}
              />
            </STEntry>
            <STEntry label="Content Country">
              <Dropdown
                options={CONFIG_COUNTRY}
                value={config.content_config.content_country}
                setValue={updateConfig((c, v) => c.content_config.content_country = v)}
              />
            </STEntry>
            <STEntry label="Type">
              <Dropdown
                options={CONTENT_CONFIG_TYPES}
                value={config.content_config.type}
                setValue={updateConfig((c, v) => c.content_config.type = v)}
              />
            </STEntry>
            <STEntry
              label="Skip CMS Update on Article Load"
              helpText="This setting is intended for testing / demo instances only. Setting this to true will cause ACM and the configured CMS to get out of sync, and might lead to data loss on the CMS side."
            >
              <SwitchInput
                checked={config.content_config.skip_cms_update_on_article_load}
                setChecked={updateConfig((c, v) => c.content_config.skip_cms_update_on_article_load = v)}
              />
            </STEntry>
            <STEntry
              label="Skip CMS Update on Article Save"
              helpText="This setting is intended for testing / demo instances only. Setting this to true will cause ACM and the configured CMS to get out of sync."
            >
              <SwitchInput
                checked={config.content_config.skip_cms_update_on_article_save}
                setChecked={updateConfig((c, v) => c.content_config.skip_cms_update_on_article_save = v)}
              />
            </STEntry>
            <STEntry label="Indexing Type">
              <Dropdown
                options={INDEXING_TYPES}
                value={config.content_config.indexing_type}
                setValue={updateConfig((c, v) => c.content_config.indexing_type = v)}
              />
            </STEntry>
            <STEntry label="Periodic">
              <SwitchInput
                checked={config.content_config.periodic}
                setChecked={updateConfig((c, v) => c.content_config.periodic = v)}
              />
            </STEntry>
            <STEntry label="Period (day)">
              <NumberInput
                value={config.content_config.period}
                setValue={updateConfig((c, v) => c.content_config.period = v)}
              />
            </STEntry>
            <STEntry label="Timeout (min)">
              <NumberInput
                value={config.content_config.timeout}
                setValue={updateConfig((c, v) => c.content_config.timeout = v)}
              />
            </STEntry>
          </>
        )}

        {config?.content_config?.enabled
          && (
            config.content_config.type === 'wp_json'
            || config.content_config.type === 'wp_gql'
            || config.content_config.type === 'wp_multi'
          ) && (
            <>
              <STEntry label="URL">
                <TextInput
                  value={config.content_config.url}
                  setValue={updateConfig((c, v) => c.content_config.url = v)}
                />
              </STEntry>
              <STEntry label="Domain">
                <TextInput
                  value={config.content_config.domain}
                  setValue={updateConfig((c, v) => c.content_config.domain = v)}
                />
              </STEntry>
              <STEntry label="Auth User">
                <TextInput
                  value={config.content_config.auth_user}
                  setValue={updateConfig((c, v) => c.content_config.auth_user = v)}
                />
              </STEntry>
              <STEntry label="Auth Password">
                <PasswordInput
                  value={config.content_config.auth_pass}
                  setValue={updateConfig((c, v) => c.content_config.auth_pass = v)}
                />
              </STEntry>
              {config.content_config.type === 'wp_multi' && (
                <STEntry label="WP Multi Header">
                  <TextInput
                    value={config.content_config.multi_database_header}
                    setValue={updateConfig((c, v) => c.content_config.multi_database_header = v)}
                  />
                </STEntry>
              )}
            </>
        )}

        {config?.content_config?.enabled && config.content_config.type === 'ext_cms' && (
          <>
            <STEntry
              label="Read API CMS URL"
              helpText="This URL will be suffixed with a slash and the id of the article. Any query parameters added here will be preserved."
            >
              <TextInput
                value={config.content_config.read_cms_url}
                setValue={updateConfig((c, v) => c.content_config.read_cms_url = v)}
              />
            </STEntry>
            <STEntry
              label="Write API CMS URL"
              helpText="This URL will be suffixed with a slash and the id of the article. Any query parameters added here will be preserved."
            >
              <TextInput
                value={config.content_config.write_cms_url}
                setValue={updateConfig((c, v) => c.content_config.write_cms_url = v)}
              />
            </STEntry>
            <STEntry label="Auth User">
              <TextInput
                value={config.content_config.auth_user}
                setValue={updateConfig((c, v) => c.content_config.auth_user = v)}
              />
            </STEntry>
            <STEntry label="Auth Password">
              <PasswordInput
                value={config.content_config.auth_pass}
                setValue={updateConfig((c, v) => c.content_config.auth_pass = v)}
              />
            </STEntry>
            <STEntry label="API Token Location">
              <Dropdown
                options={API_LOCATION_TYPES}
                value={config.content_config.api_token_location}
                setValue={updateConfig((c, v) => c.content_config.api_token_location = v)}
              />
            </STEntry>
            <STEntry label="API Token Name">
              <TextInput
                value={config.content_config.api_token_name}
                setValue={updateConfig((c, v) => c.content_config.api_token_name = v)}
              />
            </STEntry>
            <STEntry label="API Key Location">
              <Dropdown
                options={API_LOCATION_TYPES}
                value={config.content_config.api_key_location}
                setValue={updateConfig((c, v) => c.content_config.api_key_location = v)}
              />
            </STEntry>
            <STEntry label="API Key Name">
              <TextInput
                value={config.content_config.api_key_name}
                setValue={updateConfig((c, v) => c.content_config.api_key_name = v)}
              />
            </STEntry>
            <STEntry label="API Key Value">
              <TextInput
                value={config.content_config.api_key_value}
                setValue={updateConfig((c, v) => c.content_config.api_key_value = v)}
              />
            </STEntry>
          </>
        )}
      </STGroup>
      <STHeading>
        News Configurations
        <Tooltip
          title="Add News Config"
          placement="right"
        >
          <IconButton
            size="large"
            onClick={handleNewsConfigAdd}
            sx={{ ml: '4px' }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </STHeading>

      {config?.news_config?.map((_, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <STGroup key={index}>
          <STHeading>
            News Configuration
            <SwitchInput
              checked={config.news_config[index].enabled}
              setChecked={updateConfig((c, v) => c.news_config[index].enabled = v)}
            />
            <Tooltip
              title="Delete News Config Data"
              placement="right"
            >
              <IconButton
                onClick={() => handleNewsConfigDeletion(index)}
                sx={{ ml: '4px' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </STHeading>
          {config.news_config[index].enabled && (
            <>
              <STEntry label="Type">
                <Dropdown
                  options={NEWS_CONFIG_TYPES}
                  value={config.news_config[index].type}
                  setValue={updateConfig((c, v) => c.news_config[index].type = v)}
                />
              </STEntry>
              <STEntry
                label="Locale"
                helpText="Supported values: 'de', 'en' and combinations of language and country separated by dash, for example 'de_AT'"
              >
                <TextInput
                  value={config.news_config[index].locale}
                  setValue={updateConfig((c, v) => c.news_config[index].locale = v)}
                />
              </STEntry>
              {config.news_config[index].type === 'feedly' && (
                <>
                  <STEntry label="Access Token">
                    <TextInput
                      value={config.news_config[index].access_token}
                      setValue={updateConfig((c, v) => c.news_config[index].access_token = v)}
                    />
                  </STEntry>
                  <STEntry label="Search Stream Id">
                    <TextInput
                      value={config.news_config[index].search_stream_id}
                      setValue={updateConfig((c, v) => c.news_config[index].search_stream_id = v)}
                    />
                  </STEntry>
                </>
              )}
            </>
          )}
        </STGroup>
      ))}

      <STGroup>
        <STHeading>
          LnkOpt Configuration
          <SwitchInput
            checked={config.lnkopt_config.enabled}
            setChecked={updateConfig((c, v) => c.lnkopt_config.enabled = v)}
          />
        </STHeading>
        {config.lnkopt_config.enabled && (
          <>
            <STEntry label="Enable Article Linking">
              <SwitchInput
                checked={config.lnkopt_config.article_linking_enabled}
                setChecked={updateConfig((c, v) => c.lnkopt_config.article_linking_enabled = v)}
              />
            </STEntry>
            <STEntry label="Enable Product Linking">
              <SwitchInput
                checked={config.lnkopt_config.product_linking_enabled}
                setChecked={updateConfig((c, v) => c.lnkopt_config.product_linking_enabled = v)}
              />
            </STEntry>
            <STEntry label="Enable Automatic link optimizer Button">
              <SwitchInput
                checked={config.lnkopt_config.automatic_button_enabled}
                setChecked={updateConfig((c, v) => c.lnkopt_config.automatic_button_enabled = v)}
              />
            </STEntry>
            <STEntry label="Display Recommendation Scores">
              <SwitchInput
                checked={config.lnkopt_config.show_scores}
                setChecked={updateConfig((c, v) => c.lnkopt_config.show_scores = v)}
              />
            </STEntry>
            <STEntry
              label="Excluded anchor entity types"
              helpText="Comma separated list of EntityType values. For example LOC,PER"
            >
              <TextInput
                value={config.lnkopt_config.excluded_entity_types}
                setValue={updateConfig((c, v) => c.lnkopt_config.excluded_entity_types = v)}
              />
            </STEntry>
            <STEntry label="Periodic">
              <SwitchInput
                checked={config.lnkopt_config.periodic}
                setChecked={updateConfig((c, v) => c.lnkopt_config.periodic = v)}
              />
            </STEntry>
            <STEntry label="Period (day)">
              <NumberInput
                value={config.lnkopt_config.period}
                setValue={updateConfig((c, v) => c.lnkopt_config.period = v)}
              />
            </STEntry>
            <STEntry label="Preserve HTML Entities">
              <SwitchInput
                checked={config.lnkopt_config.html_entity_preserved}
                setChecked={updateConfig((c, v) => c.lnkopt_config.html_entity_preserved = v)}
              />
            </STEntry>
            <STEntry
              label="Link ACM Attribute"
              helpText="The Link Optimizer needs to store extra info at the generated link, in the form of 'acm-link-...'. This setting defines the name of the html link tag attribute to use for that."
            >
              <Dropdown
                options={LINK_ATTRIBUTE_OPTIONS}
                value={config.lnkopt_config.link_acm_attribute}
                setValue={updateConfig((c, v) => c.lnkopt_config.link_acm_attribute = v)}
              />
            </STEntry>
            <STEntry label="Link Target Attribute Value (Article Links)">
              <TextInput
                value={config.lnkopt_config.link_target_attribute_value_article}
                setValue={updateConfig((c, v) => c.lnkopt_config.link_target_attribute_value_article = v)}
              />
            </STEntry>
            <STEntry label="Link Target Attribute Value (Product Links)">
              <TextInput
                value={config.lnkopt_config.link_target_attribute_value_product}
                setValue={updateConfig((c, v) => c.lnkopt_config.link_target_attribute_value_product = v)}
              />
            </STEntry>
            <STEntry label="Feedback form URL">
              <TextInput
                value={config.lnkopt_config.feedback_form_url}
                setValue={updateConfig((c, v) => c.lnkopt_config.feedback_form_url = v)}
                placeholder="e.g.: https://forms.office.com/Pages/ResponsePage.aspx?id=...&embed=true"
              />
            </STEntry>
            <STDivider />
            <STEntry label="Amazon API Access Key">
              <PasswordInput
                value={config.lnkopt_config.amazon_access_key}
                setValue={updateConfig((c, v) => c.lnkopt_config.amazon_access_key = v)}
              />
            </STEntry>
            <STEntry label="Amazon API Secret Key">
              <PasswordInput
                value={config.lnkopt_config.amazon_secret_key}
                setValue={updateConfig((c, v) => c.lnkopt_config.amazon_secret_key = v)}
              />
            </STEntry>
            <STEntry label="Amazon Associate Tag">
              <TextInput
                value={config.lnkopt_config.amazon_associate_tag}
                setValue={updateConfig((c, v) => c.lnkopt_config.amazon_associate_tag = v)}
              />
            </STEntry>
            <STEntry label="Amazon Region">
              <TextInput
                value={config.lnkopt_config.amazon_region}
                setValue={updateConfig((c, v) => c.lnkopt_config.amazon_region = v)}
              />
            </STEntry>
          </>
        )}
      </STGroup>
      <STGroup>
        <STHeading>
          RecSys Configuration
          <SwitchInput
            checked={config.recsys_config.enabled}
            setChecked={updateConfig((c, v) => c.recsys_config.enabled = v)}
          />
        </STHeading>
        {config.recsys_config.enabled && (
          <>
            <STEntry label="Periodic">
              <SwitchInput
                checked={config.recsys_config.periodic}
                setChecked={updateConfig((c, v) => c.recsys_config.periodic = v)}
              />
            </STEntry>
            <STEntry label="Period (day)">
              <NumberInput
                value={config.recsys_config.period}
                setValue={updateConfig((c, v) => c.recsys_config.period = v)}
              />
            </STEntry>
            <STEntry label="Supported Models">
              <DropdownMulti
                options={RECSYS_SUPPORTED_MODELS}
                value={config.recsys_config.supported_models}
                setValue={updateConfig((c, v) => c.recsys_config.supported_models = v)}
              />
            </STEntry>
          </>
        )}
      </STGroup>
      <STGroup>
        <STHeading>
          Entity Recognition Configuration
          <SwitchInput
            checked={config.named_entity_recognition_config.enabled}
            setChecked={updateConfig((c, v) => c.named_entity_recognition_config.enabled = v)}
          />
        </STHeading>
        {config.named_entity_recognition_config.enabled && (
          <STEntry label="Provider">
            <Dropdown
              options={ENTITY_RECOGNITION_CONFIG}
              value={config.named_entity_recognition_config.provider}
              setValue={updateConfig((c, v) => c.named_entity_recognition_config.provider = v)}
            />
          </STEntry>
        )}
      </STGroup>
    </>
  );

  return (
    <FlexBoxColumn alignItems="stretch" gap="16px" sx={{ width: '100%', height: '100%' }}>
      <SettingsTable>
        <STHeading>
          Basic Data
        </STHeading>
        {customer && config && userFields}
        <STHeading>
          Configuration Data
        </STHeading>
        {config && configFields}
      </SettingsTable>
      {(customer || config) && (
        <FlexBoxRow justifyContent="space-between" alignItems="center" sx={{ py: '32px' }}>
          <Link to="/admin/customers">
            <ActionButton icon={<CloseIcon />}>
              Cancel
            </ActionButton>
          </Link>
          <PrimaryButton onClick={handleUpdate} busy={busy} disabled={!hasUnsavedChanges}>
            Update User
          </PrimaryButton>
        </FlexBoxRow>
      )}
    </FlexBoxColumn>
  );
}
