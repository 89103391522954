import { Box, Chip, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FlexBoxColumn, FlexBoxRow } from '@purple/react-components';
import { ArticleResponse, ArticleScoreResponse } from '../../api/interfaces/article';
import { useACMUserState } from '../../context/acm-page-context';
import calcDateDifference from '../../util/date-utils';
import htmlToText from '../../util/html-text-converter';
import ArticleImage from './article-image';
import RoundedActionCard from './RoundedActionCard';
import SourceBadge from './source-badge';

const BULLET_POINT = '\u2022';

export type ArticleCardProps = {
  article: ArticleScoreResponse | ArticleResponse
  onArticleClick?: (article: ArticleResponse) => void
  hideProvider?: boolean
  hidePlaceholderImage?: boolean
  openEditorOnClick?: boolean
}

function calculateArticleDateCaptionString(published?: string, lastModified?: string): string | undefined {
  if (!published) return undefined;
  const publishedDiff = calcDateDifference(published);
  if (!lastModified) {
    return publishedDiff;
  }
  const lastModifiedDiff = calcDateDifference(lastModified);
  if (publishedDiff === lastModifiedDiff) {
    return publishedDiff;
  }
  return `${publishedDiff} | ${lastModifiedDiff}`;
}

export function elideTextAfterLines(maxLines: number) {
  // show an ellipsis after a fixed amount of lines, if the description is too long
  // see https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#examples
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': `${maxLines}`,
    '-webkit-box-orient': 'vertical',
  } satisfies SxProps;
}

function getArticleEditUrl(article: ArticleResponse, domain: string | undefined): string | undefined {
  if (!domain) {
    return undefined;
  }
  const domainWithTrailingSlash = domain.endsWith('/') ? domain : `${domain}/`;
  return `${domainWithTrailingSlash}wp-admin/post.php?post=${article.id}&action=edit`;
}

export default function ArticleCard(props: ArticleCardProps) {
  const {
    article,
    onArticleClick,
    hideProvider,
    hidePlaceholderImage,
    openEditorOnClick,
  } = props;

  const { frontendConfig } = useACMUserState();

  function renderCaptionPoint(content: string) {
    return (
      <Typography variant="caption">
        <span style={{ margin: '0 5px' }}>
          {BULLET_POINT}
        </span>
        {content}
      </Typography>
    );
  }

  const captions: Array<string | undefined> = [
    article.source?.title,
    article.type ?? 'article',
    article.sub_type,
    calculateArticleDateCaptionString(article.published, article.last_modified),
    article.author,
    article.region,
    article.reading_time ? `${article.reading_time} min read` : '',
  ];
  const captionPoints = captions
    .filter((caption): caption is string => !!caption)
    .map(renderCaptionPoint);

  const bulletPointSection = (
    <Box sx={{ mt: '5px' }}>
      {!hideProvider && (<SourceBadge provider={article.provider} />)}
      {captionPoints}
    </Box>
  );

  const suggestedBySection = article.suggested_by && article.suggested_by.length > 0 && (
    <FlexBoxRow
      flexWrap="wrap"
      gap="8px"
      sx={{ my: '10px' }}
    >
      {article.suggested_by.map((model) => (
        <Chip label={model} variant="outlined" key={model} />
      ))}
    </FlexBoxRow>
  );

  const cardClickLink = openEditorOnClick
    ? getArticleEditUrl(article, frontendConfig?.content_config?.domain)
    : article.link;

  return (
    <RoundedActionCard
      title={openEditorOnClick ? 'Edit article' : 'Open article'}
      to={cardClickLink}
      target="_blank"
      onClick={onArticleClick ? () => onArticleClick(article) : undefined}
      sx={{
        backgroundColor: 'white',
      }}
    >
      <FlexBoxColumn p="10px">
        <ArticleImage
          hidePlaceholderImage={hidePlaceholderImage}
          imageUrl={article.image_url}
        />
        <Typography variant="subtitle1">
          {article.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: '6px',
            ...elideTextAfterLines(7),
          }}
        >
          {htmlToText(article.description)}
        </Typography>
        {bulletPointSection}
        {suggestedBySection}
      </FlexBoxColumn>
    </RoundedActionCard>
  );
}
