/**
 * Basic Message Response.
 *
 * TODO: we should use this across all interfaces
 */
export interface MessageResponse {
  message: string
}

export interface UserResponse extends MessageResponse {
  public_id: string
  username: string
  name: string
  role: string
}

export interface LoginResponse extends MessageResponse {
  token: string
  user: UserResponse
}

export interface AddUserPayload {
  username: string
  password: string
  name: string
  role: string
}

export interface UserNameResponse {
  name: string
}

export const USER_ROLES = ['admin', 'customer'];
