const CACHE_KEY = 'cachedOnWindowObject';

declare global {
  interface Window {
    [CACHE_KEY]?: Map<string, Promise<any>>
  }
}

/**
 * Compute a value, cache it on the window object, and any subsequent calls to this function will return the cached value.
 */
export default function cachedOnWindowObject<T>(key: string, compute: () => Promise<T>): Promise<T> {
  if (!window[CACHE_KEY]) {
    window[CACHE_KEY] = new Map();
  }
  if (!window[CACHE_KEY].has(key)) {
    window[CACHE_KEY].set(key, compute());
  }
  return window[CACHE_KEY].get(key) as Promise<T>;
}
