import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FlexBoxColumn } from '@purple/react-components';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useACMUserState } from '../../context/acm-page-context';
import {
  LinkerProfilesProvider,
  ProfileType,
  useLinkerProfilesService,
  useLinkerProfilesState,
} from '../../context/profiles/linker-profiles-context';
import DataLoading from '../../theme/sprylab/components/data-loading';
import PageActionBars from '../../theme/sprylab/components/pages/page-action-bars';
import TabPanel from '../common/tab-panel';
import DiscardChangesDialog from './actions/profile-discard-changes';
import ArticleLinkProfileConfiguration from './configuration/article-link-profile-configuration';
import ProductLinkProfileConfiguration from './configuration/product-link-profile-configuration';
import ProfilesTab from './profiles-tab';
import ProfilesTable from './profiles-table';

export enum ProfileTabs {
  ARTICLE = 'Article Profiles',
  PRODUCT = 'Product Profiles',
}

export default function ProfilesPage() {
  return (
    <LinkerProfilesProvider>
      <ProfilesPageWithoutProvider />
    </LinkerProfilesProvider>
  );
}

function ProfilesPageWithoutProvider() {
  const {
    articleLinkerProfiles, productLinkerProfiles, selectedArticleLinkerId, selectedProductLinkerId, isProfileDirty,
  } = useLinkerProfilesState();
  const { getArticleLinkerProfilesService, getProductLinkerProfilesService } = useLinkerProfilesService();
  const [selectedTab, setSelectedTab] = useState<ProfileTabs>(ProfileTabs.ARTICLE);
  const [selectedTabAfterConfirmation, setSelectedTabAfterConfirmation] = useState<ProfileTabs>();
  const { featureFlags } = useACMUserState();

  const articleProfiles = useMemo(() => (
    Array.from(articleLinkerProfiles.values())
  ), [articleLinkerProfiles]);

  const productProfiles = useMemo(() => (
    Array.from(productLinkerProfiles.values())
  ), [productLinkerProfiles]);

  const switchTabs = (newTab: ProfileTabs) => {
    setSelectedTab(newTab);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: ProfileTabs) => {
    if (isProfileDirty) {
      setSelectedTabAfterConfirmation(newTab);
    } else {
      switchTabs(newTab);
    }
  };

  const handleDiscardChangesCancel = () => {
    setSelectedTabAfterConfirmation(undefined);
  };

  const handleDiscardChangesConfirm = () => {
    if (!selectedTabAfterConfirmation) return;
    switchTabs(selectedTabAfterConfirmation);
    setSelectedTabAfterConfirmation(undefined);
  };

  const articleProfilesTab = (
    <ProfilesTab
      table={
        <DataLoading service={getArticleLinkerProfilesService}>
          <ProfilesTable
            profileType={ProfileType.ARTICLE}
            profiles={articleProfiles}
          />
        </DataLoading>
      }
      showConfig={Boolean(selectedArticleLinkerId)}
      configuration={
        <DataLoading service={getArticleLinkerProfilesService}>
          <ArticleLinkProfileConfiguration />
        </DataLoading>
      }
    />
  );
  const productProfilesTab = (
    <ProfilesTab
      table={
        <DataLoading service={getProductLinkerProfilesService}>
          <ProfilesTable
            profileType={ProfileType.PRODUCT}
            profiles={productProfiles}
          />
        </DataLoading>
      }
      showConfig={Boolean(selectedProductLinkerId)}
      configuration={
        <DataLoading service={getProductLinkerProfilesService}>
          <ProductLinkProfileConfiguration />
        </DataLoading>
      }
    />
  );
  const bothProfilesContent = (
    <FlexBoxColumn
      justifyContent="flex-start"
      alignItems="stretch"
      height="100%"
    >
      <Tabs
        centered
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ height: '48px' }}
      >
        {Object.keys(ProfileTabs).map((key) => (
          <Tab label={ProfileTabs[key]} value={ProfileTabs[key]} key={ProfileTabs[key]} />
        ))}
      </Tabs>
      <Divider sx={{ height: '1px' }} />
      <Box sx={{ minHeight: '0' }}>
        <TabPanel value={selectedTab} index={ProfileTabs.ARTICLE}>
          {articleProfilesTab}
        </TabPanel>
        <TabPanel value={selectedTab} index={ProfileTabs.PRODUCT}>
          {productProfilesTab}
        </TabPanel>
      </Box>
    </FlexBoxColumn>
  );

  const contentToShow = featureFlags?.LINK_OPTIMIZER_ARTICLE_LINKING && !featureFlags?.LINK_OPTIMIZER_PRODUCT_LINKING
    ? articleProfilesTab
    : featureFlags?.LINK_OPTIMIZER_PRODUCT_LINKING && !featureFlags?.LINK_OPTIMIZER_ARTICLE_LINKING
      ? productProfilesTab
      : bothProfilesContent;

  return (
    <PageActionBars
      pageContent={
        <Paper sx={{ scrollbarGutter: 'stable', height: '100%' }}>
          {contentToShow}
          <DiscardChangesDialog
            isDialogOpen={!!selectedTabAfterConfirmation}
            onConfirmation={handleDiscardChangesConfirm}
            onCancel={handleDiscardChangesCancel}
          />
        </Paper>
      }
    />
  );
}
