import {
  useCombinedLinkerPageStateMaybe,
} from '../../../context/linker/combined-linker-context';

function getDeletedRecommendationIDs(links?: Array<{id: string}>) {
  return links?.map(({ id }) => id) ?? [];
}

/**
 * Get the list of all ACM-Link-IDs that are currently marked as deleted on the page.
 */
export default function useDeletedRecommendationIDs(): string[] {
  const combinedContext = useCombinedLinkerPageStateMaybe();
  return [
    ...(getDeletedRecommendationIDs(combinedContext?.removedCombinedLinkRecommendations?.article_links)),
    ...(getDeletedRecommendationIDs(combinedContext?.removedCombinedLinkRecommendations?.product_links)),
  ];
}
