import * as React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import { inIframe } from '../util/dom-utils';
import { assertNever } from '../util/reducer-utils';

// Source Code copied from: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface State {
  disable?: boolean
  hideHeader?: boolean
}

export enum ACMAppBarActionType {
  SET_DISABLE = 'setDisable',
}

type Action =
  | { type: ACMAppBarActionType.SET_DISABLE, disable?: boolean }

type Dispatch = (action: Action) => void

interface ProviderProps {
  disable?: boolean
  hideHeader?: boolean
  children: React.ReactNode
}

const makeDefaultState = () => ({
  disable: inIframe(),
} satisfies State);

const ACMAppBarStateContext = React.createContext<State | undefined>(undefined);
const ACMAppBarDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function acmAppBarReducer(state: State, action: Action): State {
  switch (action.type) {
    case ACMAppBarActionType.SET_DISABLE: {
      return {
        ...state,
        disable: action.disable,
      };
    }
    default: throw assertNever(action as never);
  }
}

export function ACMAppBarProvider(props: ProviderProps) {
  const { children, disable, hideHeader } = props;
  const [state, dispatch] = useReducer(acmAppBarReducer, { ...makeDefaultState(), hideHeader });

  useEffect(() => {
    if (disable) {
      dispatch({ type: ACMAppBarActionType.SET_DISABLE, disable });
    }
  }, [disable]);

  return (
    <ACMAppBarStateContext.Provider value={state}>
      <ACMAppBarDispatchContext.Provider value={dispatch}>
        {children}
      </ACMAppBarDispatchContext.Provider>
    </ACMAppBarStateContext.Provider>
  );
}

export function useACMAppBarState(): State {
  const context = useContext(ACMAppBarStateContext);
  if (context === undefined) {
    throw new Error('useACMAppBarState must be used within a ACMAppBarStateContext');
  }
  return context;
}

export function useACMAppBarDispatch(): Dispatch {
  const context = useContext(ACMAppBarDispatchContext);
  if (context === undefined) {
    throw new Error('useACMAppBarDispatch must be used within a ACMAppBarDispatchContext');
  }
  return context;
}
