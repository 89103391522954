import { ConfirmationDialog, TextInput } from '@purple/react-components';
import { useEffect, useMemo, useState } from 'react';
import usePostArticleLinkerProfileService
  from '../../../api/hooks/linker/profiles/articles/post-article-linker-profile';
import usePostProductLinkerProfileService
  from '../../../api/hooks/linker/profiles/products/post-product-linker-profile';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import {
  ArticleLinkerProfileResponse,
  GenericLinkerProfileResponse,
  ProductLinkerProfileResponse,
} from '../../../api/interfaces/linker-profile';
import {
  LinkerProfilesAction,
  ProfileType,
  useGetProfilesByType,
  useLinkerProfilesDispatch,
  useLinkerProfilesService,
  useLinkerProfilesState,
} from '../../../context/profiles/linker-profiles-context';
import RestSnackbar from '../../../theme/sprylab/components/rest-snackbar';
import StringUtils from '../../../util/string';
import DiscardChangesDialog from './profile-discard-changes';

interface Props {
  isOpen: boolean
  handleCreateClose: () => void
  profileType: ProfileType
  startingName: string
  profile?: GenericLinkerProfileResponse
}

export default function CreateProfileConfirm(props: Props) {
  const {
    isOpen, handleCreateClose, startingName, profileType, profile,
  } = props;
  const { isProfileDirty } = useLinkerProfilesState();
  const dispatch = useLinkerProfilesDispatch();
  const { refreshArticleProfiles, refreshProductProfiles } = useLinkerProfilesService();
  const currentProfiles = useGetProfilesByType(profileType);
  const [doCreateAction, setDoCreateAction] = useState<boolean>(false);
  const [newProfileName, setNewProfileName] = useState<string>('');
  const trimmedNewProfileName = useMemo(() => newProfileName.trim(), [newProfileName]);

  const isArticle = profileType === ProfileType.ARTICLE;
  const isProduct = profileType === ProfileType.PRODUCT;

  useEffect(() => {
    if (!isOpen) return;
    setNewProfileName(StringUtils.getNumberedName(startingName, currentProfiles.map((p) => p.name)));
  }, [currentProfiles, isOpen, startingName]);

  const nameAlreadyExists = useMemo(() => (
    currentProfiles.map((p) => p.name).includes(trimmedNewProfileName)
  ), [currentProfiles, trimmedNewProfileName]);

  const profileCopy = useMemo(() => {
    if (!doCreateAction) return undefined;
    const numberedNewName = StringUtils.getNumberedName(trimmedNewProfileName, currentProfiles.map((p) => p.name));

    return {
      ...profile,
      name: numberedNewName,
      is_default: false,
    };
  }, [doCreateAction, trimmedNewProfileName, profileType, profile]);

  const createArticleProfile = usePostArticleLinkerProfileService(isArticle ? profileCopy as ArticleLinkerProfileResponse : undefined);
  const createProductProfile = usePostProductLinkerProfileService(isProduct ? profileCopy as ProductLinkerProfileResponse : undefined);

  useEffect(() => {
    if (createArticleProfile.status === REST_STATUS.LOADED) {
      dispatch({
        type: LinkerProfilesAction.SELECT_ARTICLE_LINKER_PROFILE,
        payload: createArticleProfile.payload.id,
      });
      refreshArticleProfiles();
      handleCreateClose();
      setDoCreateAction(false);
    }
  }, [createArticleProfile.status]);

  useEffect(() => {
    if (createProductProfile.status === REST_STATUS.LOADED) {
      dispatch({
        type: LinkerProfilesAction.SELECT_PRODUCT_LINKER_PROFILE,
        payload: createProductProfile.payload.id,
      });
      refreshProductProfiles();
      handleCreateClose();
      setDoCreateAction(false);
    }
  }, [createProductProfile.status]);

  return (
    <>
      <DiscardChangesDialog
        isDialogOpen={isOpen && isProfileDirty}
        onCancel={handleCreateClose}
      />
      <ConfirmationDialog
        dialogTitle="Create a new profile"
        confirmText="Create"
        open={isOpen && !isProfileDirty}
        onConfirm={() => setDoCreateAction(true)}
        onCancel={handleCreateClose}
        confirmDisabled={nameAlreadyExists}
        data-testid="create-profile-dialog"
      >
        <TextInput
          autoFocus
          fullWidth
          margin="normal"
          label="Profile Name"
          id="name"
          error={nameAlreadyExists}
          helperText={nameAlreadyExists && 'Name exists already.'}
          value={newProfileName}
          setValue={setNewProfileName}
          data-testid="new-profile-name-input"
        />
      </ConfirmationDialog>
      <RestSnackbar
        loadingMessage="Creating article profile ..."
        service={createArticleProfile}
        id="createArticleProfile"
      />
      <RestSnackbar
        loadingMessage="Creating product profile ..."
        service={createProductProfile}
        id="createProductProfile"
      />
    </>
  );
}
