import ExtendableError from './ExtendableError';

export default class RequestError extends ExtendableError {
  responseCode: number;

  detailMessage?: string;

  constructor(message: string, responseCode: number, detailMessage?: string) {
    super(message);
    this.responseCode = responseCode;
    this.detailMessage = detailMessage;
  }
}
