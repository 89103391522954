import { LINKER_URL } from '../../../../../constants/configs/urls';
import { ArticleLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

const usePostArticleLinkerProfileService = (profile?: ArticleLinkerProfileResponse): RESTService<ArticleLinkerProfileResponse> => {
  let requestURL;

  if (profile) {
    requestURL = `${LINKER_URL}/linker/profiles/articles`;
  }

  const { id: _, ...profileWithoutId } = { ...profile };

  const [result] = usePostRequestService(requestURL, profileWithoutId);
  return result;
};

export default usePostArticleLinkerProfileService;
