import { LINKER_URL } from '../../../../../constants/configs/urls';
import { ProductLinkerProfileResponse } from '../../../../interfaces/linker-profile';
import usePutRequestService from '../../../_common/requests/put-request';
import { RESTService } from '../../../rest-service';

const usePutProductLinkerProfileService = (profile?: ProductLinkerProfileResponse): RESTService<ProductLinkerProfileResponse> => {
  let requestURL;

  if (profile) {
    requestURL = `${LINKER_URL}/linker/profiles/products/${profile.id}`;
  }

  const [result] = usePutRequestService(requestURL, profile);
  return result;
};

export default usePutProductLinkerProfileService;
