import { useMemo } from 'react';
import { GenericLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import { useLinkerEditorPageState } from '../../../context/linker/linker-editor-context';

export interface TimeGenerationParameters {
  timePerWord: number
  timePerLink: number
}

function estimateLinkGenerationTime(wordCount: number, paragraphCount: number, linksPerParagraph: number, parameters: TimeGenerationParameters) {
  const linkCount = Math.ceil(paragraphCount * linksPerParagraph);

  const timeForWords = wordCount * parameters.timePerWord;
  const timeForLinks = linkCount * parameters.timePerLink;
  return timeForWords + timeForLinks;
}

/**
 * Given some given basic time estimations, and the current article and profile,
 * calculate how long a request to generate links for the article will take.
 */
export default function useTimeEstimate(parameters: TimeGenerationParameters, profile: GenericLinkerProfileResponse) {
  const { editorStateHtml } = useLinkerEditorPageState();

  const avgLinksPerParagraph = profile.article_parameters.avg_links_per_paragraph;
  return useMemo(() => {
    if (editorStateHtml) {
      const parsedArticle = new DOMParser().parseFromString(editorStateHtml, 'text/html');
      const wordCount = parsedArticle.body.textContent?.split(/\s+/).length ?? 0;
      const paragraphCount = parsedArticle.getElementsByTagName('p').length;
      return estimateLinkGenerationTime(wordCount, paragraphCount, avgLinksPerParagraph, parameters);
    }
    return 0;
  }, [avgLinksPerParagraph, parameters]);
}
