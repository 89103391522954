import { useTranslation } from 'react-i18next';
import { ArticleLinkerProfileResponse } from '../../../../api/interfaces/linker-profile';
import { ArticleLinkRecommendationResponse } from '../../../../api/interfaces/recommendation';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import GenericLinkScoresTooltip from '../../combined-linker/recommendation-details/generic-link-scores-tooltip';

export interface RecommendationScoreProps {
  linkScores: ArticleLinkRecommendationResponse
}
export function ArticleLinkRecommendationScores(props: RecommendationScoreProps) {
  const { linkScores } = props;
  const { t } = useTranslation();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  const profile: ArticleLinkerProfileResponse | undefined = articleLinkerProfiles.get(linkScores.profile_id);

  return (
    <GenericLinkScoresTooltip
      mainScore={{
        scoreValue: linkScores.score,
      }}
      tooltipScoreDataList={[
        {
          scoreValue: linkScores.score_length,
          label: t('lo-selection-length'),
          weight: profile?.article_parameters.selection_length_weight,
        },
        {
          scoreValue: linkScores.score_selection_to_source,
          label: t('lo-selection-relevance'),
          weight: profile?.article_parameters.selection_to_source_weight,
        },
        {
          scoreValue: linkScores.score_articles,
          label: t('lo-linked-articles-relevance'),
          weight: profile?.link_parameters.target_confidence_weight,
        },
      ]}
    />
  );
}
