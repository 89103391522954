export default function clearWindowSelection() {
  if (window.getSelection) {
    const foo = window.getSelection();
    if (foo && foo.empty) { // Chrome
      foo.empty();
    } else if (foo && foo.removeAllRanges) { // Firefox
      foo.removeAllRanges();
    }
  }
}
