import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

export interface CategoriesResponse {
  name: string
  count: number
}

const useGetCategoriesService = (): RESTService<CategoriesResponse[]> => {
  const requestURL = `${CORE_BACKEND_URL}/categories`;
  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetCategoriesService;
