import { PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useACMUserState } from './context/acm-page-context';

interface Props extends PropsWithChildren {
  adminPrivilegeRequired?: boolean
}

/**
 * Blocking access to a component based on user authentication.
 */
export default function PrivateRouteCheck(props: Props) {
  const { adminPrivilegeRequired, children } = props;
  const { userId, isAdmin } = useACMUserState();
  const location = useLocation();

  if (!userId) {
    return (
      <Navigate
        to="/login"
        state={{
          from: location,
          search: location.search,
        }}
      />
    );
  }

  if (!adminPrivilegeRequired || (adminPrivilegeRequired && isAdmin)) {
    return children;
  } else {
    return (
      <Navigate
        to="/"
        state={{
          from: location,
          search: location.search,
        }}
      />
    );
  }
}
