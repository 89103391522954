import { useMemo } from 'react';
import { ArticleByIdResponse } from '../api/interfaces/article';

export function articleToEnrichedHtml(article: ArticleByIdResponse): string {
  let html = '<div>';
  html += `<h1>${article.title}</h1>`;

  html += '<p>';

  if (article.source.title) {
    html += `<b>${article.source.title}</b>`;
  }

  if (article.type) {
    html += ` - ${article.type}`;
  }

  if (article.sub_type) {
    if (article.type) {
      html += ' > ';
    } else {
      html += ' ';
    }
    html += article.sub_type;
  }

  if (article.published) {
    html += `, vom ${article.published}`;
  }

  if (article.last_modified && article.last_modified !== article.published) {
    html += `, bearbeitet am ${article.last_modified}`;
  }

  if (article.author) {
    html += `, von ${article.author}`;
  }

  html += '</p>';

  if (article.categories && article.categories.length > 0) {
    html += `<p><b>Kategorien:</b> ${article.categories.join(', ')}</p>`;
  }

  if (article.keywords && article.keywords.length > 0) {
    html += `<p><b>Schlüsselwörter:</b> ${article.keywords.join(', ')}</p>`;
  }

  if (article.image_url && !article.content.includes('<img')) {
    html += `<img style="display: block; margin-left: auto; margin-right: auto; width: 100%;" src="${article.image_url}" alt="Content Cover"/>`;
  }

  if (article.description !== null && article.description !== 'null') {
    html += `<p>${article.description}</p>`;
  }

  if (article.content !== null && article.content !== 'null') {
    html += `<p>${article.content}</p>`;
  }

  html += '</div>';
  return html;
}

export function articleToHtml(article: ArticleByIdResponse): string {
  // TODO keep this method in sync with "construct_content" in acm-backend/main/acm/domain/model/article.py
  let html = '<div>';
  html += `<h1>${article.title}</h1>`;

  if (article.description !== null && article.description !== 'null') {
    html += `<blockquote>${article.description}</blockquote>`;
  }

  if (article.content !== null && article.content !== 'null') {
    html += `<p>${article.content}</p>`;
  }

  html += '</div>';
  return html;
}

export const useFormatDateToEuropeNotation = (dateString: string | undefined): string | undefined => (
  useMemo(() => {
    if (dateString) {
      const publishDate = new Date(dateString);
      const padDate = ''.padStart(publishDate.getDate() < 10 ? 1 : 0, '0');
      const padMonth = ''.padStart((publishDate.getMonth() + 1) < 10 ? 1 : 0, '0');
      return `${padDate + publishDate.getDate()}.${padMonth}${publishDate.getMonth() + 1}.${publishDate.getFullYear()}`;
    }
    return undefined;
  }, [dateString])
);
