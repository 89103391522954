/**
 * This is a separate function, so that we can use the "never" type to make sure
 * that we don't forget to handle a case in the reducer.
 * Hint: Type inference seems to not really work when only one action type exists.
 *       In this case, do not use this method, but just throw the error directly.
 */
// eslint-disable-next-line import/prefer-default-export
export function assertNever(action: never): Error {
  return new Error(`Unhandled action type: ${action}`);
}
