import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { GenericLinkerProfileResponse } from '../../api/interfaces/linker-profile';
import {
  LinkerProfilesAction,
  ProfileType,
  useLinkerProfilesDispatch,
  useLinkerProfilesState,
} from '../../context/profiles/linker-profiles-context';
import { StickyButtonStyle } from '../../theme/style-utils';
import useArrayRef from '../../util/use-array-ref';
import ProfileCopyButton from './actions/profile-copy-button';
import ProfileDeleteButton from './actions/profile-delete-button';
import DiscardChangesDialog from './actions/profile-discard-changes';
import ProfileNewButton from './actions/profile-new-button';

interface Props<T extends GenericLinkerProfileResponse> {
  profileType: ProfileType
  profiles: T[]
}

export default function ProfilesTable<T extends GenericLinkerProfileResponse>(props: Props<T>) {
  const { profileType, profiles } = props;
  const { selectedArticleLinkerId, selectedProductLinkerId, isProfileDirty } = useLinkerProfilesState();
  const dispatch = useLinkerProfilesDispatch();
  const [selectedProfileId, setSelectedProfileId] = useState<string>();
  const [selectedProfileIdAfterConfirmation, setSelectedProfileIdAfterConfirmation] = useState<string>();
  const tableRowRefs = useArrayRef<HTMLTableRowElement>();

  const isArticle = profileType === ProfileType.ARTICLE;
  const isProduct = profileType === ProfileType.PRODUCT;

  const onlyOneProfileLeft = useMemo(() => (
    Array.from(profiles.keys()).length <= 1
  ), [profiles]);

  useEffect(() => {
    if (profileType === ProfileType.ARTICLE) {
      setSelectedProfileId(selectedArticleLinkerId);
    } else if (profileType === ProfileType.PRODUCT) {
      setSelectedProfileId(selectedProductLinkerId);
    }
  }, [profileType, selectedArticleLinkerId, selectedProductLinkerId]);

  const switchProfiles = (profileId?: string) => {
    if (profileId === selectedProfileId) {
      // when clicking the selected profile again, unselect it
      profileId = undefined;
    }
    setSelectedProfileId(profileId);
    if (isArticle) {
      dispatch({
        type: LinkerProfilesAction.SELECT_ARTICLE_LINKER_PROFILE,
        payload: profileId,
      });
    } else if (isProduct) {
      dispatch({
        type: LinkerProfilesAction.SELECT_PRODUCT_LINKER_PROFILE,
        payload: profileId,
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, profileId: string, ref?: HTMLTableRowElement) => {
    if (!ref || !ref.contains(event.target as Node)) return; // only handle clicks that happen directly on the row subtree
    if (isProfileDirty) {
      setSelectedProfileIdAfterConfirmation(profileId);
    } else {
      switchProfiles(profileId);
    }
  };

  const handleDiscardChangesCancel = () => {
    setSelectedProfileIdAfterConfirmation(undefined);
  };

  const handleDiscardChangesConfirm = () => {
    if (!selectedProfileIdAfterConfirmation) return;
    switchProfiles(selectedProfileIdAfterConfirmation);
    setSelectedProfileIdAfterConfirmation(undefined);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="sticky table">
          <TableBody>
            {profiles.map((profile, i) => (
              <TableRow
                hover
                ref={tableRowRefs.subRef(i)}
                sx={{ cursor: 'pointer' }}
                onClick={(event) => handleClick(event, profile.id, tableRowRefs.get(i))}
                selected={profile.id === selectedProfileId}
                key={profile.id}
              >
                <TableCell sx={{ width: '100%' }}>
                  {profile.name}
                  {profile.is_default && (
                    <Tooltip title="This profile will be selected by default when generating links for a new article.">
                      <Chip label="default" size="small" style={{ marginLeft: 10, backgroundColor: '#7ad27a' }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <ProfileCopyButton
                    profile={profile}
                    profileType={profileType}
                  />
                </TableCell>
                {!onlyOneProfileLeft && (
                  <TableCell>
                    <ProfileDeleteButton
                      profile={profile}
                      profileType={profileType}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        sx={StickyButtonStyle}
      >
        <ProfileNewButton profileType={profileType} />
      </Box>
      <DiscardChangesDialog
        isDialogOpen={!!selectedProfileIdAfterConfirmation}
        onConfirmation={handleDiscardChangesConfirm}
        onCancel={handleDiscardChangesCancel}
      />
    </>
  );
}
