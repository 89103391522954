import { useEffect, useState } from 'react';
import { LINKER_URL } from '../../../../../constants/configs/urls';
import { MessageResponse } from '../../../../../constants/interfaces/interfaces';
import { useACMUserState } from '../../../../../context/acm-page-context';
import { CombinedRecommendationsResponse } from '../../../../interfaces/recommendation';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

interface SelectedArticleLinks {
  id: string
  selectedArticleId: string
}
interface SelectedProductLinks {
  id: string
  selectedProductId: string
}

export type SelectedCombinedLinks = SelectedArticleLinks | SelectedProductLinks;

export function isSelectedArticleLinks(selectedLink: SelectedCombinedLinks): selectedLink is SelectedArticleLinks {
  return (selectedLink as SelectedArticleLinks).selectedArticleId !== undefined;
}

export function isSelectedProductLinks(selectedLink: SelectedCombinedLinks): selectedLink is SelectedProductLinks {
  return (selectedLink as SelectedProductLinks).selectedProductId !== undefined;
}

const usePostCombinedLinksSaveService = (
  articleId?: string,
  selectedLinks?: SelectedCombinedLinks[],
  removedLinkRecommendations?: CombinedRecommendationsResponse | undefined,
): RESTService<MessageResponse> => {
  const { customerToken } = useACMUserState();
  const [requestURL, setRequestURL] = useState<string>();
  let body;

  if (articleId && selectedLinks) {
    body = {
      selected_article_links: selectedLinks
        .filter(isSelectedArticleLinks)
        .map(({ id, selectedArticleId }) => ({ id, selected_suggestion_id: selectedArticleId }))
        .filter((selectedLink) => selectedLink.selected_suggestion_id), // filter undefined article selections (external links)
      deleted_article_links: removedLinkRecommendations?.article_links?.map((link) => ({
        id: link.id,
        reason: link.reason,
      })),
      selected_product_links: selectedLinks
        .filter(isSelectedProductLinks)
        .map(({ id, selectedProductId }) => ({ id, selected_suggestion_id: selectedProductId }))
        .filter((selectedLink) => selectedLink.selected_suggestion_id), // filter undefined product selections (external links)
      deleted_product_links: removedLinkRecommendations?.product_links?.map((link) => ({
        id: link.id,
        reason: link.reason,
      })),
    };
  }

  useEffect(() => {
    if (articleId && selectedLinks) {
      let url = `${LINKER_URL}/linker/combined_links/${articleId}/save`;
      if (customerToken) {
        url += `?api_token=${customerToken}`;
      }
      setRequestURL(url);
      refresh();
    } else if (articleId && !requestURL?.includes(`/${articleId}/`)) {
      setRequestURL(undefined);
      refresh(); // this refresh call allows the state of the service to be reset back to INIT on switching articles
    }
  }, [articleId, customerToken, selectedLinks]);

  const [result, refresh] = usePostRequestService(requestURL, body);
  return result;
};

export default usePostCombinedLinksSaveService;
