import { ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ImportanceIndicator, { ImportanceIndicatorWidth } from './importance-indicator';
import { InfoItem } from './profile-info-tooltip-content';

interface Props {
  title: string
  items: InfoItem[]
}

export default function ProfileInfoTooltipGroup(props: Props) {
  const { title, items } = props;

  const infoComponents = items.map((item) => {
    if (item === null) return <Divider />;
    // instead of the 16 padding that the parent element gives us, we want enough space for the importance indicator
    const textPaddingRight = item.weight === undefined ? undefined : ImportanceIndicatorWidth - 16;
    return (
      <ListItem key={item.text}>
        <ListItemText style={{ paddingRight: textPaddingRight }}>
          {item.text}
        </ListItemText>
        {item.weight && (
          <ListItemSecondaryAction data-testid="importance-indicator">
            <ImportanceIndicator value={item.weight} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  });

  return (
    <>
      <ListItem key={title}>
        <ListItemText>
          <u>{title}</u>
        </ListItemText>
      </ListItem>
      {infoComponents}
    </>
  );
}
