import { CORE_BACKEND_URL } from '../../../../constants/configs/urls';
import { useACMUserState } from '../../../../context/acm-page-context';
import { ArticleByIdResponse } from '../../../interfaces/article';
import useGetRequestService from '../../_common/requests/get-request';
import { RESTService } from '../../rest-service';

const useGetArticleByIdService = (articleId?: string, postType?: string): RESTService<ArticleByIdResponse> => {
  const { customerToken } = useACMUserState();
  let requestURL;

  if (articleId) {
    requestURL = `${CORE_BACKEND_URL}/articles/${articleId}`;
    const queryParams: string[] = [];

    if (postType) {
      queryParams.push(`post_type=${postType}`);
    }

    if (customerToken) {
      queryParams.push(`api_token=${customerToken}`);
    }

    if (queryParams.length > 0) {
      requestURL += `?${queryParams.join('&')}`;
    }
  }

  const [result] = useGetRequestService(requestURL);
  return result;
};

export default useGetArticleByIdService;
