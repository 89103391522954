import PageWithCards from './page-with-cards';
import RecentArticlesDashboardCard from './recent-articles-dashboard-card';

/**
 * The dashboard page is the landing page of acm and displays article performance statistics.
 */
export default function Dashboard() {
  return (
    <PageWithCards
      cards={[
        <RecentArticlesDashboardCard />,
      ]}
    />
  );
}
