// Hook
import { useEffect, useRef } from 'react';

type Handler = <K extends keyof HTMLElementEventMap>(ev: HTMLElementEventMap[K]) => any

// Source Code copied from: https://usehooks.com/useEventListener/
export default function useEventListener<K extends keyof HTMLElementEventMap>(eventName: K, handler: Handler, element: Element | Window | undefined | null = window) {
  const savedHandler = useRef<Handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      if (!element) return undefined;

      const isSupported = element && element.addEventListener;
      if (!isSupported) return undefined;

      const eventListener = (event: Event) => savedHandler.current && savedHandler.current(event);

      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}
