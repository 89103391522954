import { useACMUserState } from '../../context/acm-page-context';
import FeedbackForm from './feedback-form';

export default function UserSpecificLnkOptFeedbackForm() {
  const { frontendConfig } = useACMUserState();

  return (
    <FeedbackForm feedbackFormUrl={frontendConfig?.lnkopt_config?.feedback_form_url} />
  );
}
