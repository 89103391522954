import { ClickAwayListener, ClickAwayListenerProps } from '@mui/material';
import { RefObject } from 'react';

type Props = Omit<ClickAwayListenerProps, 'onClickAway'> & {
  onClickAway: () => void
  parentRef: RefObject<HTMLElement>
}

/**
 * A ClickAwayListener that only triggers when the click is outside of the parentRef.
 */
export default function PopperClickAwayListener(props: Props) {
  const { parentRef, onClickAway, ...restProps } = props;

  return (
    <ClickAwayListener
      {...restProps}
      onClickAway={(event: MouseEvent | TouchEvent) => {
        if (!parentRef.current?.contains(event.target as HTMLElement)) {
          onClickAway();
        }
      }}
    />
  );
}
