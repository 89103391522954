import { useRef } from 'react';

interface ArrayRef<T> {
  subRef: (index: number) => (value: NonNullable<T> | null) => void
  get: (index: number) => T | undefined
}

/**
 * A variant of useRef that can store multiple values.
 * Usage:
 * ```ts
 *   const myArrayRef = useArrayRef<HTMLDivElement>();
 *   const myDivList = ['a', 'b', 'c'].map((name, i) => (
 *     <div
 *       ref={myArrayRef.subRef(i)}
 *       onClick={onDivClicked(myArrayRef.get(i))}
 *     >
 *       {name}
 *     </div>
 *   ));
 * ```
 */
export default function useArrayRef<T>(): ArrayRef<T> {
  const ref = useRef<Array<T | undefined>>([]);
  const subRef = (index: number) => (value: NonNullable<T> | null) => {
    ref.current[index] = value === null ? undefined : value;
  };
  const get = (index: number) => ref.current[index];
  return { subRef, get };
}
