import {
  Box, Collapse, Divider,
} from '@mui/material';
import { GridDirection } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  ActionButton, FlexBoxRow, PrimaryButton, STEntry, STHeading, SettingsTable, SliderInput,
} from '@purple/react-components';
import { useEffect, useState } from 'react';
import { ContentRecommendationResponse } from '../../../api/interfaces/recommendation';
import RestService from '../../../api/rest.service';
import ArticleList from '../../articles/article-list';

export enum RecommendationModelType {
  MULTI_CRITERIA = 'multi_criteria',
}

type Props = {
  title: string
  itemId?: string
  limit?: number
  recsysModel?: RecommendationModelType
  contentTypeFilter?: string[]
  columns?: number
  gridDirection: GridDirection
}

export default function Recommendation(props: Props) {
  const {
    title, itemId, recsysModel, contentTypeFilter, limit, columns, gridDirection,
  } = props;

  const [recommendationResponse, setRecommendationResponse] = useState<ContentRecommendationResponse>();
  const [sourceTarget, setSourceTarget] = useState(50);
  const [recency, setRecency] = useState(10);
  const [retentionTime, setRetentionTime] = useState(5);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  function getRecommendations(reset: boolean = true) {
    setLoading(true);
    if (reset) {
      setRecommendationResponse(undefined);
    }

    const weights = {
      source_target: sourceTarget,
      recency,
      retention_time: retentionTime,
    };

    RestService.getRecommendations(itemId, recsysModel, limit, contentTypeFilter, weights)
      .then((response) => {
        setRecommendationResponse(response);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getRecommendations();
  }, [itemId]);

  const options = (
    <SettingsTable sx={{ mb: '20px' }}>
      <STEntry label="Similarity">
        <SliderInput
          value={sourceTarget}
          setValue={setSourceTarget}
          valueLabelDisplay="auto"
          marks
          min={1}
          max={100}
        />
      </STEntry>
      <STEntry label="Recency">
        <SliderInput
          value={recency}
          setValue={setRecency}
          valueLabelDisplay="auto"
          marks
          min={1}
          max={100}
        />
      </STEntry>
      <STEntry label="Retention Time">
        <SliderInput
          value={retentionTime}
          setValue={setRetentionTime}
          valueLabelDisplay="auto"
          marks
          min={1}
          max={100}
        />
      </STEntry>
      <STHeading>
        <PrimaryButton
          onClick={() => getRecommendations(false)}
          busy={loading}
        >
          Update
        </PrimaryButton>
      </STHeading>
    </SettingsTable>
  );

  if (!recommendationResponse?.items?.length) return null;

  return (
    <Box sx={{ mt: '50px', mb: '10px' }}>
      <FlexBoxRow justifyContent="space-between" alignItems="center" my="8px">
        <Typography variant="h4" textTransform="uppercase">
          {recommendationResponse.title ? `Mehr über ${recommendationResponse.title}` : title}
        </Typography>
        <ActionButton onClick={() => setShowOptions((so) => !so)}>
          Options
        </ActionButton>
      </FlexBoxRow>
      <Divider sx={{ mb: '8px' }} />
      <Collapse in={showOptions}>
        {options}
      </Collapse>
      <ArticleList
        hideSeparator
        hideProvider
        gridDirection={gridDirection}
        columns={columns}
        articles={recommendationResponse ? recommendationResponse.items : []}
      />
    </Box>
  );
}
