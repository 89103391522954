import { StyledEngineProvider } from '@mui/material/styles';
import { PurpleThemeProvider } from '@purple/react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import useGetFrontendConfigService from './api/hooks/core/configuration/get-frontend-config';
import useGetUserFeatureFlagsService from './api/hooks/core/users/get-feature-flags';
import useGetUserDataService from './api/hooks/core/users/get-user-data';
import { REST_STATUS, RESTService } from './api/hooks/rest-service';
import { shouldShowPage } from './constants/interfaces/page';
import { ACMAdminPageData, ACMPrivatePageData, ACMPublicPageData } from './constants/statics/pageData';
import { ACMAppBarProvider } from './context/acm-app-bar-context';
import {
  ACMUserActionType, ACMUserProvider, useACMUserDispatch, useACMUserState,
} from './context/acm-page-context';
import { ToastMessagesProvider } from './context/toast-messages-context';
import NotFoundPage from './not-found-page';
import PrivateRouteCheck from './private-route';
import DataLoading from './theme/sprylab/components/data-loading';
import useSearchQueryParams from './util/use-search-query-params';

function Foo() {
  const {
    userId, acmToken, featureFlags, frontendConfig,
  } = useACMUserState();
  const dispatch = useACMUserDispatch();

  const { hideHeader } = useSearchQueryParams();
  const location = useLocation();
  const { i18n } = useTranslation();

  const getUserDataService = useGetUserDataService(acmToken ? userId : undefined);
  const getFrontendConfigService = useGetFrontendConfigService();
  const getUserFeatureFlagsService = useGetUserFeatureFlagsService(acmToken ? userId : undefined);

  const needToWaitForAdminUpdate = userId && acmToken && getUserDataService.status !== REST_STATUS.LOADED;

  const getFrontendConfigServiceDelayed = needToWaitForAdminUpdate ? { status: REST_STATUS.LOADING } as RESTService<any> : getFrontendConfigService;
  const getUserDataServiceDelayed = needToWaitForAdminUpdate ? { status: REST_STATUS.LOADING } as RESTService<any> : getUserDataService;

  useEffect(() => {
    if (getFrontendConfigService.status === REST_STATUS.LOADED) {
      dispatch({ type: ACMUserActionType.SET_FRONTEND_CONFIG, config: getFrontendConfigService.payload });
    } else if (getFrontendConfigService.status === REST_STATUS.ERROR) {
      dispatch({ type: ACMUserActionType.LOGOUT });
    }
  }, [getFrontendConfigService]);

  const config_interface_language = frontendConfig?.interface_language;
  useEffect(() => {
    if (!config_interface_language) return;
    const hasUrlLang = new URLSearchParams(window.location.search).has('lang');
    if (hasUrlLang) return;
    // update the language of the app via i18next
    i18n.changeLanguage(config_interface_language).catch(console.error);
  }, [config_interface_language]);

  useEffect(() => {
    if (getUserDataService.status === REST_STATUS.LOADED) {
      dispatch({ type: ACMUserActionType.SET_USER_DATA, user: getUserDataService.payload });
    } else if (getUserDataService.status === REST_STATUS.ERROR) {
      dispatch({ type: ACMUserActionType.LOGOUT });
    }
  }, [getUserDataService]);

  useEffect(() => {
    if (getUserFeatureFlagsService.status === REST_STATUS.LOADED) {
      dispatch({ type: ACMUserActionType.SET_USER_FEATURE_FLAGS, featureFlags: getUserFeatureFlagsService.payload });
    } else if (getUserFeatureFlagsService.status === REST_STATUS.ERROR) {
      dispatch({ type: ACMUserActionType.LOGOUT });
    }
  }, [getUserFeatureFlagsService]);

  return (
    <DataLoading ignoreError service={getFrontendConfigServiceDelayed}>
      <DataLoading ignoreError service={getUserDataServiceDelayed}>
        <ACMAppBarProvider hideHeader={hideHeader}>
          <StyledEngineProvider injectFirst>
            <PurpleThemeProvider theme="growth">
              <ToastMessagesProvider>
                <Routes>
                  {ACMPublicPageData
                    .filter(({ pageData }) => pageData.path && pageData.component)
                    .map(({ pageData, key }) => (
                      <Route
                        key={key}
                        path={pageData.path}
                        element={<pageData.component />}
                      />
                    ))}
                  {ACMPrivatePageData
                    .filter(({ pageData }) => pageData.path && pageData.component)
                    .filter(({ pageData }) => shouldShowPage(pageData, featureFlags))
                    .map(({ pageData, key }) => (
                      <Route
                        key={key}
                        path={pageData.path}
                        element={(
                          <PrivateRouteCheck>
                            <pageData.component />
                          </PrivateRouteCheck>
                        )}
                      />
                    ))}
                  {ACMAdminPageData
                    .filter(({ pageData }) => pageData.path && pageData.component)
                    .filter(({ pageData }) => shouldShowPage(pageData, featureFlags))
                    .map(({ pageData, key }) => (
                      <Route
                        key={key}
                        path={pageData.path}
                        element={(
                          <PrivateRouteCheck adminPrivilegeRequired>
                            <pageData.component />
                          </PrivateRouteCheck>
                        )}
                      />
                    ))}
                  <Route
                    path="*"
                    key="fallback"
                    element={(
                      userId ? ( // only show 404 if a user is logged in
                        <NotFoundPage />
                      ) : ( // else, assume that the page might exist once logged in, so redirect to the login page
                        <Navigate
                          to={{
                            pathname: '/login',
                          }}
                          state={{
                            from: location,
                            search: location.search,
                          }}
                        />
                      )
                    )}
                  />
                </Routes>
              </ToastMessagesProvider>
            </PurpleThemeProvider>
          </StyledEngineProvider>
        </ACMAppBarProvider>
      </DataLoading>
    </DataLoading>
  );
}

export default function App() {
  return (
    <Router>
      <ACMUserProvider>
        <Foo />
      </ACMUserProvider>
    </Router>
  );
}
