import { LINKER_URL } from '../../../../constants/configs/urls';
import useDeleteRequestService from '../../_common/requests/delete-request';
import { RESTService } from '../../rest-service';

const useDeleteFeedbackSelectionService = (feedbackSelectionId?: string): RESTService<void> => {
  let requestURL;

  if (feedbackSelectionId) {
    requestURL = `${LINKER_URL}/linker/feedback/${feedbackSelectionId}`;
  }

  const [result] = useDeleteRequestService(requestURL, {});
  return result;
};

export default useDeleteFeedbackSelectionService;
