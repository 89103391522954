import { FlexBoxColumn, ToggleGroup } from '@purple/react-components';
import { useState } from 'react';
import useGetExternalArticlesService from '../../../api/hooks/core/news/get-external-articles';
import useGetRelatedArticlesService from '../../../api/hooks/recommendation/get-related-articles';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import ArticleList from '../../articles/article-list';
import SidebarExpansionPanel from '../common/sidebar-expansion-panel';
import SidebarInfoText from '../common/SidebarInfoText';

interface Props {
  keywords: string[]
  articleId?: string
  content?: string
}

export default function SidebarPanelArticleRecommendations(props: Props) {
  const { articleId, content, keywords } = props;
  const [viewMode, setViewMode] = useState<'internal' | 'external'>('internal');

  const getRelatedArticlesService = useGetRelatedArticlesService(articleId, content, 5);
  const getExternalArticlesService = useGetExternalArticlesService(keywords, 5);

  const selectedService = viewMode === 'internal' ? getRelatedArticlesService : getExternalArticlesService;
  const selectedArticles = selectedService.status === REST_STATUS.LOADED ? selectedService.payload : [];

  const articlesDisplay = (
    <DataLoading
      service={selectedService}
      errorMessage="Couldn't load related articles"
    >
      {selectedArticles.length > 0 ? (
        <ArticleList
          hidePlaceholderImage
          hideSeparator
          openEditorOnClick
          gridDirection="column"
          articles={selectedArticles}
        />
      ) : (
        <SidebarInfoText>
          {content ? 'No articles found' : 'We don\'t have any article or text to work with'}
        </SidebarInfoText>
      )}
    </DataLoading>
  );

  return (
    <SidebarExpansionPanel title="Related articles" grayBackground>
      <FlexBoxColumn
        alignItems="center"
        gap="20px"
        sx={{
          py: '20px',
        }}
      >
        <ToggleGroup
          options={[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'News' },
          ]}
          value={viewMode}
          setValue={setViewMode}
        />
        {articlesDisplay}
      </FlexBoxColumn>
    </SidebarExpansionPanel>
  );
}
