import Paper from '@mui/material/Paper';
import * as React from 'react';
import AcmAppBarAndDrawer from '../../../../components/app-bar/acm-app-bar-and-drawer';
import { AcmExtraColors } from '../../../style-utils';

interface Props {
  actionBar?: React.ReactElement
  pageContent?: React.ReactElement
  hasUnsavedChanges?: boolean
}

export default function PageActionBars(props: Props) {
  const { actionBar, pageContent, hasUnsavedChanges } = props;

  return (
    <AcmAppBarAndDrawer actionBar={actionBar} hasUnsavedChanges={hasUnsavedChanges}>
      <Paper
        square
        elevation={0}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: AcmExtraColors.background,
          p: { xs: '0', lg: '20px' },
          transition: 'padding 0.3s ease-in-out',
        }}
      >
        {pageContent}
      </Paper>
    </AcmAppBarAndDrawer>
  );
}
