import PageConfig from '../configs/pages';
import {
  ACMAdminPage,
  ACMPageList,
  ACMPrivatePage,
  ACMPublicPage,
  AdminTab,
  LinkOptimizerTab,
  ReaderTab,
} from '../interfaces/page';

function toPageList(pageObject: {[key: string]: any}): ACMPageList[] {
  return Object.keys(pageObject).map((key) => ({ key, pageData: PageConfig[key] }));
}

export const ACMPublicPageData = toPageList(ACMPublicPage);
export const ACMPrivatePageData = toPageList(ACMPrivatePage);
export const ACMAdminPageData = toPageList(ACMAdminPage);
export const ACMPublicAndPrivatePageData = [...ACMPublicPageData, ...ACMPrivatePageData];
export const ACMAllPageData = [...ACMPublicPageData, ...ACMPrivatePageData, ...ACMAdminPageData];
export const ReaderTabData = toPageList(ReaderTab);
export const AdminTabData = toPageList(AdminTab);
export const LinkOptimizerTabData = toPageList(LinkOptimizerTab);
