import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { assertNever } from '../../../util/reducer-utils';
import useDeletedRecommendationIDs from './use-deleted-recommendation-ids';

type Statistic = [number, string];

type LinkType = 'article' | 'product' | 'non-acm';
const AllLinkTypes: LinkType[] = ['article', 'product', 'non-acm'];

const ProductLinkPrefixes = ['https://www.amazon.', 'https://pvn.saturn.de/trck/eclick/'];

function determineLinkType(el: Element): LinkType | null {
  const isAcmLink = !!tryExtractAcmId(el);
  const href = el.getAttribute('href');

  if (href) {
    if (isAcmLink) {
      if (ProductLinkPrefixes.some((prefix) => href.startsWith(prefix))) {
        return 'product';
      }
      return 'article';
    }
    return 'non-acm';
  }
  return null;
}

export function extractAcmLinkTypeFrom(id: string | null, class_: string | null): LinkType {
  const idLocations = [
    ...(id ? [id] : []),
    ...(class_?.split(' ') ?? []),
  ];
  for (const idLocation of idLocations) {
    if (idLocation.startsWith('acm-link-article-')) {
      return 'article';
    }
    if (idLocation.startsWith('acm-link-product-')) {
      return 'product';
    }
  }
  return 'non-acm';
}

export function tryExtractAcmIdFrom(id: string | null, class_: string | null): string | undefined {
  const idLocations = [
    ...(id ? [id] : []),
    ...(class_?.split(' ') ?? []),
  ];
  for (const idLocation of idLocations) {
    if (idLocation.startsWith('acm-link-article-') || idLocation.startsWith('acm-link-product-')) {
      return idLocation.split('-').slice(3).join('-');
    }
  }
  return undefined;
}

function tryExtractAcmId(el: Element): string | undefined {
  return tryExtractAcmIdFrom(el.getAttribute('id'), el.getAttribute('class'));
}

function extractAcmId(el: Element): string {
  const id = tryExtractAcmId(el);
  if (!id) throw new Error('ACM link element without id!');
  return id;
}

function getLabel(type: LinkType | 'total', count: number, t: TFunction): string {
  if (type === 'article') {
    return t('lo-statistics-article-links', { count });
  } else if (type === 'product') {
    return t('lo-statistics-product-links', { count });
  } else if (type === 'non-acm') {
    return t('lo-statistics-non-acm-links', { count });
  } else if (type === 'total') {
    return t('lo-statistics-total-links', { count });
  } else {
    throw assertNever(type);
  }
}

export default function useArticleLinkStatistics(articleContent?: string) {
  const { t } = useTranslation();
  const deletedIDs = new Set(useDeletedRecommendationIDs());
  return useMemo<Statistic[]>(() => {
    const parsedArticle = new DOMParser().parseFromString(articleContent ?? '', 'text/html');
    const articleCharacterCount = parsedArticle.body.textContent?.length ?? 0;

    const allTags = Array.from(parsedArticle.getElementsByTagName('*'));
    const linksPerType = new Map<LinkType, number>();
    let totalLinkCount = 0;
    for (const tag of allTags) {
      const linkType = determineLinkType(tag);
      if (linkType) {
        if (linkType === 'non-acm' || !deletedIDs.has(extractAcmId(tag))) {
          linksPerType.set(linkType, (linksPerType.get(linkType) ?? 0) + 1);
          totalLinkCount++;
        }
      }
    }
    const linkTypeStatistics = AllLinkTypes
      .map((linkType) => [linkType, linksPerType.get(linkType) ?? 0] as [LinkType, number])
      .filter(([, count]) => count > 0)
      .map(([linkType, count]) => [count, getLabel(linkType, count, t)] as Statistic);

    if (linkTypeStatistics.length !== 1) {
      linkTypeStatistics.push([totalLinkCount, getLabel('total', totalLinkCount, t)]);
    }

    return [
      [articleCharacterCount, t('lo-statistics-character-count')],
      ...linkTypeStatistics,
    ];
  }, [articleContent]);
}
