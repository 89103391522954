import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { ActionButton } from '@purple/react-components';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleScoreResponse } from '../../../../api/interfaces/article';
import { ArticleLinkRecommendationResponse } from '../../../../api/interfaces/recommendation';
import {
  LinkType,
} from '../../../../context/linker/combined-linker-context';
import { useLinkerProfilesState } from '../../../../context/profiles/linker-profiles-context';
import { rightDownTransitionStyles } from '../../../../theme/style-utils';
import { useFormatDateToEuropeNotation } from '../../../../util/article';
import GenericLinkRecommendationDetailsContent
  from '../../combined-linker/recommendation-details/generic-link-recommendation-details-content';
import useLazyArticleContentLoader from '../lazy-article-content-loader';

interface Props {
  article: ArticleScoreResponse
  recommendation: ArticleLinkRecommendationResponse
  selectable?: boolean
  checked?: boolean
  isRecommended?: boolean
}

export default function ArticleLinkRecommendationDetailsContent(props: Props) {
  const { article, recommendation, ...rest } = props;
  const { t } = useTranslation();
  const { articleLinkerProfiles } = useLinkerProfilesState();
  const usedProfile = articleLinkerProfiles.get(recommendation.profile_id);
  const [expanded, setExpanded] = useState(false);
  const publishDate = useFormatDateToEuropeNotation(article.published);
  let modificationDate = useFormatDateToEuropeNotation(article.last_modified);
  if (modificationDate === publishDate) modificationDate = undefined;
  const articleContentHtml = useLazyArticleContentLoader(expanded ? article.id : undefined);
  const handleShowMoreClick = () => {
    setExpanded(!expanded);
  };

  return (
    <GenericLinkRecommendationDetailsContent
      linkType={LinkType.ARTICLE}
      item={article}
      recommendation={recommendation}
      usedProfile={usedProfile}
      captionPhrases={[
        [article.section, t('lo-caption-section', { value: article.section })],
        [publishDate, t('lo-caption-from', { value: publishDate })],
        [modificationDate, t('lo-caption-updated-on', { value: modificationDate })],
        [article.reading_time, t('lo-caption-reading-time', { value: article.reading_time })],
      ]}
      {...rest}
    >
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <ActionButton
          onClick={handleShowMoreClick}
          icon={(
            <ExpandMoreIcon
              sx={{
                height: '20px',
                ...rightDownTransitionStyles(expanded),
              }}
            />
          )}
        >
          {t('lo-show-more')}
        </ActionButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ px: '24px', pb: '24px' }}>
          <div
            style={{ overflow: 'hidden' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleContentHtml) }}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: '20px' }}
          >
            <Link
              href={article.link}
              target="_blank"
            >
              <ActionButton>
                {t('lo-open-article')}
              </ActionButton>
            </Link>
          </Grid>
        </Box>
      </Collapse>
    </GenericLinkRecommendationDetailsContent>
  );
}
