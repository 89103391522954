import { STEntry, STGroup } from '@purple/react-components';
import * as React from 'react';
import { GenericLinkerProfileResponse } from '../../../api/interfaces/linker-profile';
import { makeObjectUpdater, ProfileAttributeCheckbox } from '../../link-optimizer/generic/profile/slider-helper';

interface Props<T extends GenericLinkerProfileResponse> {
  linkProfile: T
  setLinkProfile: React.Dispatch<React.SetStateAction<T>>
}

export default function GeneralLinkProfileSliders<T extends GenericLinkerProfileResponse>(props: Props<T>) {
  const { linkProfile, setLinkProfile } = props;
  const updateLinkProfile = makeObjectUpdater(setLinkProfile);

  function setIsDefault(newValue: boolean) {
    updateLinkProfile((lp) => lp.is_default = newValue);
  }

  return (
    <STGroup>
      <STEntry
        label="Set profile as default"
        helpText="When checked, this profile will be selected by default when generating links for a new article."
      >
        <ProfileAttributeCheckbox
          value={linkProfile.is_default}
          setValue={setIsDefault}
          testId="set-profile-as-default"
        />
      </STEntry>
    </STGroup>
  );
}
