import { Send } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ActionButton, Dropdown, DropdownMulti,
} from '@purple/react-components';
import {
  differenceInDays, subDays, subMonths, subYears, differenceInCalendarDays,
  addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter,
  endOfQuarter, startOfYear, endOfYear,
} from 'date-fns';
import { useEffect, useState } from 'react';
import DataLoading from '../../../../src/theme/sprylab/components/data-loading';
import useGetLinkOptimizerUserNamesService from '../../../api/hooks/core/configuration/get-lo-enabled-user-names';
import useGetLinkOptimizerStatisticsService
  from '../../../api/hooks/linker/links/combined/get-link-optimizer-dashboard-statistics';
import { REST_STATUS } from '../../../api/hooks/rest-service';
import {
  LinkOptimizerDashboardStatistics,
} from '../../../api/interfaces/linkoptimizer-dashboard-statistics';
import { LinkType } from '../../../context/linker/combined-linker-context';
import { ToastMessagesActionType, useToastMessagesDispatch } from '../../../context/toast-messages-context';
import PageWithHeader from '../../../theme/sprylab/components/pages/page-with-header';
import DateRangePicker from './date-range-picker';
import LinkStatisticsTable from './link-statistics-table';
import ValueDisplayer from './value-displayer';

const ALL_USERNAMES: string = 'all';

export enum FixedDateRange {
  EMPTY = '',
  TODAY = 'Today',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month',
  LAST_QUARTER = 'Last Quarter',
  LAST_YEAR = 'Last Year',
}

export default function LinkOptimizerDashboardPage() {
  const [userNames, setUserNames] = useState<string[]>([ALL_USERNAMES]);
  const [linkType, setLinkType] = useState<LinkType>(LinkType.ALL);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const [fixedDateRange, setFixedDateRange] = useState<FixedDateRange | undefined>(FixedDateRange.EMPTY);

  const enabledUsersService = useGetLinkOptimizerUserNamesService(linkType);
  const [enabledUsers, setEnabledUsers] = useState<string[]>([]);

  const [linkOptimizerStatisticsService, refreshStatistics] = useGetLinkOptimizerStatisticsService({
    link_type: linkType,
    user_names: userNames.includes('all') ? enabledUsers : userNames,
    from_date: fromDate || undefined,
    to_date: toDate || undefined,
  });
  const [linkCounters, setLinkCounters] = useState<LinkOptimizerDashboardStatistics>();
  const [linksPerDay, setLinksPerDay] = useState<number>();
  const [performanceMetric, setPerformanceMetric] = useState<number>();

  const dispatch = useToastMessagesDispatch();

  useEffect(() => {
    if (enabledUsersService.status === REST_STATUS.LOADED) {
      setEnabledUsers(enabledUsersService.payload.map((user) => user.name));
    }
  }, [enabledUsersService]);

  useEffect(() => {
    if (linkOptimizerStatisticsService.status === REST_STATUS.LOADED) {
      setLinkCounters(linkOptimizerStatisticsService.payload.statistics);
    }
  }, [linkOptimizerStatisticsService]);

  useEffect(() => {
    if (!linkCounters) {
      setLinksPerDay(undefined);
      setPerformanceMetric(undefined);
      return;
    }
    const publishedLinks = linkCounters.generated_published_links + linkCounters.manual_published_links;
    const deletedLinks = linkCounters.generated_deleted_links + linkCounters.manual_deleted_links;
    const totalLinks = publishedLinks + deletedLinks;
    setPerformanceMetric(totalLinks > 0 ? publishedLinks / totalLinks : undefined);

    if (!toDate || !fromDate) {
      return;
    }
    const totalDays = differenceInCalendarDays(addDays(toDate, 1), fromDate);
    setLinksPerDay(totalDays > 0 ? totalLinks / totalDays : undefined);
  }, [linkCounters]);

  function showInvalidDateToastMessage() {
    const errorMessage: string = 'Invalid Date, Choose another one';
    dispatch({
      type: ToastMessagesActionType.SHOW_TOAST_MESSAGE,
      payload: {
        id: errorMessage,
        message: errorMessage,
        durationMs: 2000,
        severity: 'error',
      },
    });
  }

  function handleUpdateClick() {
    refreshStatistics();
  }

  function handleChangeUserNames(newUserNames: string[]) {
    if (userNames.includes(ALL_USERNAMES)) {
      setUserNames(newUserNames.filter((name) => name !== ALL_USERNAMES));
    } else if (newUserNames.includes(ALL_USERNAMES) || newUserNames.length === enabledUsers.length) {
      setUserNames([ALL_USERNAMES]);
    } else {
      setUserNames(newUserNames);
    }
  }

  function isValidRange(start: Date | null, end: Date | null): boolean {
    return (
      start
      && end
      && (end > start || differenceInDays(start, end) === 0)
      || false
    );
  }

  function handleChangeFromDate(newFromDate: Date | null) {
    if (toDate && !isValidRange(newFromDate, toDate)) {
      showInvalidDateToastMessage();
      return;
    }
    setFromDate(newFromDate);
    setFixedDateRange(FixedDateRange.EMPTY);
  }

  function handleChangeToDate(newToDate: Date | null) {
    if (fromDate && !isValidRange(fromDate, newToDate)) {
      showInvalidDateToastMessage();
      return;
    }
    setToDate(newToDate);
    setFixedDateRange(FixedDateRange.EMPTY);
  }

  function updateToFixedDateRange(dateRange: FixedDateRange) {
    const startOfWeekDay = 1;
    const today = new Date();
    setFixedDateRange(dateRange);
    switch (dateRange) {
      case FixedDateRange.TODAY: {
        setFromDate(today);
        setToDate(today);
        break;
      }
      case FixedDateRange.LAST_WEEK: {
        const weekAgoDate = subDays(today, 6);
        setFromDate(startOfWeek(weekAgoDate, { weekStartsOn: startOfWeekDay }));
        setToDate(endOfWeek(weekAgoDate, { weekStartsOn: startOfWeekDay }));
        break;
      }
      case FixedDateRange.LAST_MONTH: {
        const monthAgoDate = subMonths(today, 1);
        setFromDate(startOfMonth(monthAgoDate));
        setToDate(endOfMonth(monthAgoDate));
        break;
      }
      case FixedDateRange.LAST_QUARTER: {
        const quarterAgoDate = subMonths(today, 3);
        setFromDate(startOfQuarter(quarterAgoDate));
        setToDate(endOfQuarter(quarterAgoDate));
        break;
      }
      case FixedDateRange.LAST_YEAR: {
        const yearAgoDate = subYears(today, 1);
        setFromDate(startOfYear(yearAgoDate));
        setToDate(endOfYear(yearAgoDate));
        break;
      }
      default: {
        const valueErrorMessage = `Fixed date range: ${dateRange} not implemented`;
        console.log(valueErrorMessage);
        throw new Error(valueErrorMessage);
      }
    }
  }

  return (
    <PageWithHeader wide title="Link Optimizer Dashboard">
      <Grid
        container
        justifyContent="space-evenly"
        spacing={4}
        sx={{ pt: '8px' }}
      >
        <Grid xs={2}>
          <Dropdown
            label="Filter by Link Type"
            options={Object.values(LinkType)}
            value={linkType}
            setValue={setLinkType}
            fullWidth
          />
        </Grid>
        <Grid xs={2}>
          <DataLoading service={enabledUsersService}>
            {enabledUsersService.status === REST_STATUS.LOADED && (
              <DropdownMulti
                label="Filter by Users"
                options={[ALL_USERNAMES].concat(enabledUsers)}
                value={userNames}
                setValue={handleChangeUserNames}
                fullWidth
              />
            )}
          </DataLoading>
        </Grid>
        <Grid xs={2}>
          <Dropdown
            label="Set Date Range"
            options={Object.values(FixedDateRange).filter((v) => v !== FixedDateRange.EMPTY)}
            value={fixedDateRange}
            setValue={updateToFixedDateRange}
            fullWidth
          />
        </Grid>
        <Grid xs={5}>
          <DateRangePicker
            fromDate={{
              label: 'From Date',
              date: fromDate,
              handleChangeDate: handleChangeFromDate,
            }}
            toDate={{
              label: 'To Date',
              date: toDate,
              handleChangeDate: handleChangeToDate,
            }}
          />
        </Grid>
        <Grid xs={1} sx={{ m: 'auto' }}>
          <ActionButton
            endIcon={<Send />}
            onClick={handleUpdateClick}
            fullWidth
            disabled={linkOptimizerStatisticsService.status === REST_STATUS.LOADING}
          >
            Update
          </ActionButton>
        </Grid>
        <DataLoading service={linkOptimizerStatisticsService}>
          {linkOptimizerStatisticsService.status === REST_STATUS.LOADED ? (
            <>
              <Grid xs={6}>
                <ValueDisplayer
                  title="Links per Day"
                  value={linksPerDay}
                />
              </Grid>
              <Grid xs={6}>
                <ValueDisplayer
                  title="Performance Metric"
                  value={performanceMetric}
                />
              </Grid>
              <Grid xs={12}>
                {linkCounters && (
                  <LinkStatisticsTable
                    {...linkCounters}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Grid>
              <Typography>
                Modify filters and/or click Update button !
              </Typography>
            </Grid>
          )}
        </DataLoading>
      </Grid>
    </PageWithHeader>
  );
}
