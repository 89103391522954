import { ShoppingCartOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useEffect, useMemo, useRef } from 'react';
import { DeletionReasonType, GenericLinkRecommendationResponse } from '../../../../api/interfaces/recommendation';
import {
  CombinedLinkerAction,
  linkTypeFilter,
  useCombinedLinkerPageDispatch,
  useCombinedLinkerPageState,
} from '../../../../context/linker/combined-linker-context';
import { AcmExtraColors, LINK_CARD_ANIMATION_MS } from '../../../../theme/style-utils';
import { EntityProps } from '../../../common/editor/acm-editor';
import { extractAcmLinkTypeFrom, tryExtractAcmIdFrom } from '../../statistics-for-article/use-article-link-statistics';
import ExternalLinkRecommendationEntity from './external-link-recommendation-entity';

type GenericEntityProps = EntityProps & {
  acmLinkId: string
}

type Props<T extends GenericLinkRecommendationResponse> = GenericEntityProps & {
  IconComponent: React.ComponentType<any>
  allLinks: T[]
  allRemovedLinks: T[]
  iconBackgroundColor: string
}

const TEXT_HEIGHT_PX = 19;

const BORDER_SIZE_PX = 4;
const BORDER_SIZE = `${BORDER_SIZE_PX}px`;
const LINK_ENTITY_SIZE_PX = TEXT_HEIGHT_PX + BORDER_SIZE_PX * 2;
const LINK_ENTITY_SIZE = `${LINK_ENTITY_SIZE_PX}px`;

const CLOSE_BUTTON_OVERLAP_PX = 2;
const CLOSE_BUTTON_OVERLAP = `${CLOSE_BUTTON_OVERLAP_PX}px`;
const CLOSE_BUTTON_SIZE_PX = TEXT_HEIGHT_PX + CLOSE_BUTTON_OVERLAP_PX * 2;
const CLOSE_BUTTON_SIZE = `${CLOSE_BUTTON_SIZE_PX}px`;

export function useLinkEntityStyle(keywordStillRecommended: boolean, disabled: boolean) {
  const theme = useTheme();
  const backgroundColor = disabled
    ? AcmExtraColors.recommendationDimmed
    : AcmExtraColors.recommendation;
  return {
    height: LINK_ENTITY_SIZE,
    display: 'inline', // cannot use 'inline-flex' because it would break multi-line links
    position: 'relative',
    boxDecorationBreak: 'clone',
    WebkitBoxDecorationBreak: 'clone',
    userSelect: 'none',
    backgroundColor,
    // we cannot actually make the span bigger, but we can add a border with the same color
    border: `${BORDER_SIZE} solid ${backgroundColor}`,
    borderRadius: BORDER_SIZE,
    opacity: !keywordStillRecommended || disabled ? '0.5' : '1',
    transition: theme.transitions.create(['background-color', 'border-color', 'opacity'], {
      duration: LINK_CARD_ANIMATION_MS,
    }),
  } as const;
}

/**
 * A link recommendation entity, used for highlighting links inside the link optimizer editor.
 * @param props Interface of type EntityProps, and extra settings for the link type
 */
export default function GenericLinkRecommendationEntity<T extends GenericLinkRecommendationResponse>(props: Props<T>) {
  const {
    decoratedText, acmLinkId, IconComponent, allLinks, allRemovedLinks, iconBackgroundColor,
  } = props;
  const { currentLinkTypeFilter } = useCombinedLinkerPageState();
  const dispatch = useCombinedLinkerPageDispatch();
  const linkRecommendationEntityRef = useRef<HTMLSpanElement>(null);

  const myLinkRecommendation = useMemo(() => {
    const directResult = allLinks.filter((link) => link.id === acmLinkId)[0];
    if (directResult) {
      return directResult;
    }
    return allRemovedLinks.filter((link) => link.id === acmLinkId)[0];
  }, [allLinks]);

  const keywordStillRecommended = useMemo(() => (
    allLinks?.some((recommendation) => recommendation.id === acmLinkId)
  ), [allLinks]);

  useEffect(() => {
    if (myLinkRecommendation && linkRecommendationEntityRef) {
      dispatch({
        type: CombinedLinkerAction.ADD_ENTITY_ELEMENT,
        payload: {
          recommendation: myLinkRecommendation,
          entity: {
            ref: linkRecommendationEntityRef,
          },
        },
      });
    }
  }, [linkRecommendationEntityRef]);

  function scrollToRecommendation() {
    if (myLinkRecommendation) {
      dispatch({
        type: CombinedLinkerAction.SCROLL_TO_RECOMMENDATION_DETAILS,
        payload: myLinkRecommendation,
      });
    }
  }

  const handleRemoveClick = () => {
    // When deleting directly from the article, we default to the
    // "Bad selection in context" option. Cf. DEEP-328
    scrollToRecommendation();
    dispatch({
      type: CombinedLinkerAction.REMOVE_RECOMMENDATION,
      payload: {
        entityDataId: acmLinkId,
        reason: DeletionReasonType.BAD_SELECTION_IN_CONTEXT,
      },
    });
  };

  const handleAddClick = () => {
    scrollToRecommendation();
    dispatch({
      type: CombinedLinkerAction.ADD_RECOMMENDATION,
      payload: {
        entityDataId: acmLinkId,
      },
    });
  };

  const disabled = !(myLinkRecommendation && linkTypeFilter(myLinkRecommendation, currentLinkTypeFilter));

  return (
    <span
      ref={linkRecommendationEntityRef}
      style={{
        ...useLinkEntityStyle(keywordStillRecommended, disabled),
        cursor: disabled ? undefined : 'pointer',
      }}
      onClick={disabled ? undefined : scrollToRecommendation}
    >
      <span
        style={{
          backgroundColor: iconBackgroundColor,
          color: 'white',
          height: LINK_ENTITY_SIZE,
          width: LINK_ENTITY_SIZE,
          display: 'inline-flex', // separate values do not work with chrome yet, so we keep the precomposed ones for now: https://developer.mozilla.org/en-US/docs/Web/CSS/display#which_syntax_should_you_use_now
          verticalAlign: 'text-bottom',
          marginBottom: `-${BORDER_SIZE}`,
          marginLeft: `-${BORDER_SIZE}`,
          borderRadius: `${BORDER_SIZE} 0 0 ${BORDER_SIZE}`,
          fontSize: CLOSE_BUTTON_SIZE,
        }}
      >
        <IconComponent fontSize="inherit" sx={{ m: 'auto' }} />
      </span>
      <span style={{ marginRight: BORDER_SIZE, marginLeft: BORDER_SIZE }}>
        {decoratedText}
      </span>
      <IconButton
        size="small"
        color="inherit"
        sx={{
          display: 'inline',
          p: 0,
          width: CLOSE_BUTTON_SIZE,
          height: CLOSE_BUTTON_SIZE,
          mt: `-${CLOSE_BUTTON_OVERLAP}`,
          mr: `-${CLOSE_BUTTON_OVERLAP}`,
        }}
        disabled={disabled}
        onClick={keywordStillRecommended ? handleRemoveClick : handleAddClick}
      >
        {keywordStillRecommended
          ? <RemoveCircleOutlineIcon sx={{ width: CLOSE_BUTTON_SIZE, height: CLOSE_BUTTON_SIZE }} />
          : <AddCircleOutlineIcon sx={{ width: CLOSE_BUTTON_SIZE, height: CLOSE_BUTTON_SIZE }} />}
      </IconButton>
    </span>
  );
}

function ArticleLinkRecommendationEntity(props: GenericEntityProps) {
  const {
    combinedLinkRecommendations,
    removedCombinedLinkRecommendations,
  } = useCombinedLinkerPageState();
  return (
    <GenericLinkRecommendationEntity
      IconComponent={InsertDriveFileOutlinedIcon}
      allLinks={combinedLinkRecommendations?.article_links ?? []}
      allRemovedLinks={removedCombinedLinkRecommendations?.article_links ?? []}
      iconBackgroundColor={AcmExtraColors.articleLink}
      {...props}
    />
  );
}

function ProductLinkRecommendationEntity(props: GenericEntityProps) {
  const {
    combinedLinkRecommendations,
    removedCombinedLinkRecommendations,
  } = useCombinedLinkerPageState();
  return (
    <GenericLinkRecommendationEntity
      IconComponent={ShoppingCartOutlined}
      allLinks={combinedLinkRecommendations?.product_links ?? []}
      allRemovedLinks={removedCombinedLinkRecommendations?.product_links ?? []}
      iconBackgroundColor={AcmExtraColors.productLink}
      {...props}
    />
  );
}

export function CombinedLinkRecommendationEntity(props: EntityProps) {
  const { contentState, entityKey } = props;
  const entityDataId = contentState.getEntity(entityKey).getData().id;
  const entityDataClass = contentState.getEntity(entityKey).getData().class;
  const acmLinkType = extractAcmLinkTypeFrom(entityDataId, entityDataClass);
  const acmId = tryExtractAcmIdFrom(entityDataId, entityDataClass);

  if (acmLinkType === 'article') {
    if (!acmId) throw new Error('Article link entity without acmId');
    return <ArticleLinkRecommendationEntity {...props} acmLinkId={acmId} />;
  }
  if (acmLinkType === 'product') {
    if (!acmId) throw new Error('Product link entity without acmId');
    return <ProductLinkRecommendationEntity {...props} acmLinkId={acmId} />;
  }
  return <ExternalLinkRecommendationEntity {...props} />;
}
