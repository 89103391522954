import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useSearchQueryParams() {
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  return {
    hideHeader: urlSearchParams.get('hideHeader') === '1',
    hideCloseButton: urlSearchParams.get('hideCloseButton') === '1',
    allowSavingWithoutChanges: urlSearchParams.get('allowSavingWithoutChanges') === '1',
    postType: urlSearchParams.get('postType') ?? undefined,
  };
}
