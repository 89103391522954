import { v4 as uuid } from 'uuid';
import { ProductLinkRecommendationResponse } from '../api/interfaces/recommendation';

export function enhanceWithProducts(text: string, amazonProductRecommendations: ProductLinkRecommendationResponse[]) {
  let result = text;
  amazonProductRecommendations.forEach((amazonProductRecommendation) => {
    const regExp = new RegExp(`(${amazonProductRecommendation.keyword})((?!.*?(<\\/h[1-6]>)|<\\/a>))`, 'i');
    result = result.replace(regExp, (match) => `<acm-product id="${uuid()}">${match}</acm-product>`);
  });
  return result;
}

export function enhanceWithSynonym(text: string, keyword: string, synonym: string) {
  let result = text;
  const regExp = new RegExp(`(${keyword})`, 'ig');
  result = result.replace(regExp, synonym);
  return result;
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function objToAttrbutes(obj: any) {
  return Object.keys({ ...obj }).reduce((acc: string, val: any) => `${acc} ${val}="${obj[val]}"`, '');
}

export function wrap(el: any, wrapper: any) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

export function hashCode(str: string): number {
  return str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}
