import { useTranslation } from 'react-i18next';
import { FeedbackSelectionsProvider, useFeedbackSelectionsService } from '../../../context/feedback/feedback-selections-context';
import DataLoading from '../../../theme/sprylab/components/data-loading';
import PageWithHeader from '../../../theme/sprylab/components/pages/page-with-header';
import BlocklistTab from './blocklist-tab';
import BlocklistTable from './blocklist-table';

export default function BlocklistPage() {
  return (
    <FeedbackSelectionsProvider>
      <BlocklistPageWithoutProvider />
    </FeedbackSelectionsProvider>
  );
}

function BlocklistPageWithoutProvider() {
  const { getFeedbackSelectionsService } = useFeedbackSelectionsService();

  const { t } = useTranslation();

  return (
    <PageWithHeader wide title={t('lo-blocklist-page-title')}>
      <BlocklistTab>
        <DataLoading service={getFeedbackSelectionsService}>
          <BlocklistTable />
        </DataLoading>
      </BlocklistTab>
    </PageWithHeader>
  );
}
