import {
  Box, Theme, Typography, useMediaQuery,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { GridBox } from '@purple/react-components';
import * as React from 'react';

interface Props {
  table: React.ReactNode
  configuration: React.ReactNode
  showConfig?: boolean
}

export default function ProfilesTab(props: Props) {
  const {
    table, configuration, showConfig,
  } = props;

  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <GridBox
      gridTemplateColumns="1fr 2fr"
      gap={smallScreen ? '0px' : '24px'}
      sx={{
        height: '100%',
        p: smallScreen ? '8px' : '20px',
        transition: 'gap 0.3s ease-in-out, padding 0.3s ease-in-out',
      }}
    >
      <Paper
        square
        variant="outlined"
        sx={{ overflowY: 'auto' }}
      >
        {table}
      </Paper>

      {showConfig ? (
        <Box sx={{ overflowY: 'auto' }}>
          {configuration}
        </Box>
      ) : (
        <Typography
          variant="caption"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Select a profile on the left
        </Typography>
      )}
    </GridBox>
  );
}
