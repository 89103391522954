import { LINKER_URL } from '../../../../../constants/configs/urls';
import {
  LinkOptimizerDashboardStatisticsPayload,
  LinkOptimizerDashboardStatisticsResponse,
} from '../../../../interfaces/linkoptimizer-dashboard-statistics';
import usePostRequestService from '../../../_common/requests/post-request';
import { RESTService } from '../../../rest-service';

const useGetLinkOptimizerStatisticsService = (payload: LinkOptimizerDashboardStatisticsPayload): [RESTService<LinkOptimizerDashboardStatisticsResponse>, () => void] => {
  const requestURL = `${LINKER_URL}/linker/dashboard-statistics`;
  return usePostRequestService(requestURL, payload, undefined, true);
};

export default useGetLinkOptimizerStatisticsService;
