import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dataDe from './locales/de.json';
import dataEn from './locales/en.json';

// get language from url GET parameter
const lang = new URLSearchParams(window.location.search).get('lang') || 'en';

const translationProxyHandler = {
  get: (target: { [x: string]: any }, name: string) => {
    if (name in target && target[name] !== '') {
      return target[name];
    } else {
      return `{${name}}`;
    }
  },
};

// see https://react.i18next.com/latest/using-with-hooks
i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: lang,
    defaultNS: 'translation',
    // debug: true,
    keySeparator: false,
    nsSeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: new Proxy(dataDe, translationProxyHandler),
      },
      en: {
        translation: new Proxy(dataEn, translationProxyHandler),
      },
      none: {
        translation: new Proxy({}, { get: () => '---' }),
      },
      // 'cimode' is defined automatically by i18next, and shows the translation keys directly
    },
  });

// @ts-ignore - this unused method here generates a hint into the translation files through its static existence
function generateHint() {
  i18n.t('---hint1---');
  i18n.t('---hint2---');
  i18n.t('---hint3---');
}

export default i18n;
