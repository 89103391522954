import { RECOMMENDATION_URL } from '../../../constants/configs/urls';
import { ArticleResponse } from '../../interfaces/article';
import usePostRequestService from '../_common/requests/post-request';
import { RESTService } from '../rest-service';

const useGetRelatedArticlesService = (article_id?: string, content?: string, limit: number = 6): RESTService<ArticleResponse[]> => {
  let requestURL;

  if (content) {
    requestURL = `${RECOMMENDATION_URL}/recommendation/articles?limit=${limit}`;
  }

  const [result] = usePostRequestService(requestURL, { article_id, content });
  return result;
};

export default useGetRelatedArticlesService;
