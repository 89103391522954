import Tooltip from '@mui/material/Tooltip';
import { PurpleBaseColors } from '@purple/react-components';
import {
  LinkRecommendationScore,
  RecommendationScoreProps,
} from '../../generic/recommendation-details/link-recommendation-score';

interface Props {
  mainScore: RecommendationScoreProps
  tooltipScoreDataList: RecommendationScoreProps[]
}

export default function GenericLinkScoresTooltip(props: Props) {
  const { mainScore, tooltipScoreDataList } = props;

  const mainScoreContainer = (
    <div style={{ height: '30px' }}>
      <LinkRecommendationScore {...mainScore} />
    </div>
  );

  if (tooltipScoreDataList.length === 0) {
    return mainScoreContainer;
  }

  return (
    <Tooltip
      title={
        <>
          {tooltipScoreDataList.map((scoreData) => (
            <div key={scoreData.label} style={{ margin: '20px 10px' }}>
              <LinkRecommendationScore {...scoreData} />
            </div>
          ))}
        </>
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            color: PurpleBaseColors.purple,
            boxShadow: 1,
            borderRadius: '0px',
            maxWidth: 'none', // allowing score tooltips to grow in width as needed for long label texts
          },
        },
      }}
    >
      {mainScoreContainer}
    </Tooltip>
  );
}
